import React, { useContext, useEffect, useState, useRef } from "react";
import classNames from "../../../../../Agency/Dashboard/dashboard.module.scss";
import styles from "./ap.module.scss";

import { IoMdClose } from "react-icons/io";
import Axios from "axios";
import { BankContext } from "../../../../../../context/Context";

const TransferFromEmployee = ({
  selectedMainMenu,
  setSelectedMainMenu,
  selectedRow,
  setSelectedRow,
  balanceObj,
  ownerId,
  initiatedById,
  getEmpBalances,
}) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [notes, setNotes] = useState("");
  const [empBalData, setEmpBalData] = useState(null);

  // const transferMoney = () => {
  //   console.log(
  //     {
  //       from_wallet_id: balanceObj?._id,
  //       to_wallet_id: selectedRow?.client_wallets[0]?._id,
  //       amount: amount,
  //       initiated_by_id: initiatedById,
  //       initiation_reason: notes,
  //     },
  //     "kjkjhwkajkjakfa"
  //   );
  // };

  const transferMoney = () => {
    Axios.post(
      `https://coverfi.apimachine.com/wallet/api/v1/wallet/internal/company/user/transfer`,
      {
        from_wallet_id: balanceObj?._id,
        to_wallet_id: selectedRow?.client_wallets[0]?._id,
        amount: amount * 100,
        initiated_by_id: initiatedById,
        initiation_reason: notes,
      }
    ).then(({ data }) => {
      if (data.status) {
        // todo if api succeeds
        getEmpBalances();

        setSelectedRow(null);
        setSelectedMainMenu(null);
      } else {
        // todo if api fails
      }
    });
  };

  useEffect(() => {
    console.log(selectedRow, "kwjgdkwjhekjwe");
  }, [selectedRow]);

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          setSelectedMainMenu(false);
        }}
      ></div>
      <div
        className={styles.quoteDetailsWrapper}
        style={{ width: "464px", height: "auto", left: "20vw" }}
      >
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setSelectedMainMenu(false);
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Transfer money employee</div>
          <div className={styles.rowWrapper1}>
            <div>From</div>
            <div
              className={styles.inputGroup}
              style={{
                border: "1px solid #e7e7e7",
                borderRadius: "5px",
                padding: "0px 10px",
                paddingTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div>Corporate Wellness Wallet</div>
                <div style={{ fontWeight: "400" }}>{balanceObj?._id}</div>
              </div>
              <div>${balanceObj?.balance?.toFixed(2)}</div>
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div>To</div>
            <div
              className={styles.inputGroup}
              style={{
                border: "1px solid #e7e7e7",
                borderRadius: "5px",
                padding: "0px 10px",
                paddingTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div>{selectedRow?.name}</div>
                <div style={{ fontWeight: "400" }}>
                  {selectedRow?.client_wallets[0]?._id
                    ? selectedRow?.client_wallets[0]?._id
                    : "--"}
                </div>
              </div>
              <div>
                $
                {selectedRow?.client_wallets[0]?.balance
                  ? selectedRow?.client_wallets[0]?.balance?.toFixed(2)
                  : "0.00"}
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div>How much?</div>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="0.00"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.rowWrapper1}>
            <div>Notes</div>
            <div className={styles.inputGroup}>
              <textarea
                rows="5"
                type="text"
                placeholder="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>

          {/* Terms */}

          <div className={styles.rowWrapper1} style={{ width: "100%" }}>
            <div
              onClick={(e) => {
                if (amount) {
                  transferMoney();
                }
              }}
              className={styles.addBtn}
              style={{
                opacity: amount ? 1 : 0.5,
                width: "100%",
              }}
            >
              {loading ? "Loading.." : "Initiate"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferFromEmployee;
