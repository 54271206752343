import React, { useContext, useEffect, useState, useRef } from "react";
import classNames from "../cdbalanceAgency.module.scss";
import styles from "./ac.module.scss";

import { IoMdClose, IoMdSearch } from "react-icons/io";

import arrowDown from "./asset/arrowDown.svg";
import successIcon from "./asset/success.svg";
import calendarIcon from "./asset/calendar.svg";
import calenderIcon from "../../../../assest/images/icons/calender.svg";
import uploadIcon from "./asset/upload.svg";
import Axios from "axios";
import { uploadFileFunc, uploadImageFunc } from "../../../../utils/imageUpload";
import { BankContext } from "../../../../context/Context";
import flatpickr from "flatpickr";

const UploadComInfo = ({ setShowEmpVerify, showEmpVerify, selectedHrId }) => {
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [uploadFileQCR, setUploadFileQCR] = useState("");
  const [uploadFileQCRName, setUploadFileQCRName] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(null);

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("hr_id", selectedHrId);
    formData.append("file", uploadFileQCR);

    Axios.post(
      "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/upload-endorsement",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ).then(({ data }) => {
      if (data.status) {
        // setShowEmpVerify(false);
        setUploadSuccess(data.data[0]);
      }
    });
  };

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          setShowEmpVerify(false);
          setUploadSuccess(null);
          //   setShowQuoteDetails(null);
          //   setConfData(null);
          //   setSelectedQuoteTab("Overview");
          //   setSelectedConfTab("Health");
        }}
      ></div>
      {!uploadSuccess ? (
        <div className={styles.quoteDetailsWrapper} style={{ width: "50vw" }}>
          <IoMdClose
            style={{
              fontSize: "20px",
              position: "absolute",
              right: 10,
              top: 10,
            }}
            onClick={() => {
              setShowEmpVerify(false);
              // setConfData(null);
              // setSelectedQuoteTab("Overview");
              // setSelectedConfTab("Health");
            }}
          />
          <div
            className={styles.popupWrapper}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div className={styles.popupTitle}>Upload communication info</div>
            <div className={styles.downloadUploadWrapper}>
              <div
                onClick={(e) => {
                  window.open(
                    // `https://insurancecoverfi.apimachine.com/insurance/general/files/${selectedHrId}.xlsx`
                    `https://insurancecoverfi.apimachine.com/insurance/general/files/fb0947f0-8f25-4118-8c86-62aff6c57a98.xlsx`
                  );
                }}
              >
                <img
                  src={uploadIcon}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
                <p>Download Template</p>
              </div>
              {/* <div>
              <img src={uploadIcon} alt="" />
              <p>Upload Template</p>
            </div> */}
              <div className={styles.uploadContainer}>
                <label
                  className={classNames.uploadDocument}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  htmlFor="fileUploadQCR"
                >
                  <img src={uploadIcon} alt="" className={styles.upldImg} />
                  <p>
                    {!loadingUpload
                      ? uploadFileQCR.name
                        ? uploadFileQCR?.name
                        : "Upload File"
                      : "Uploading..."}
                  </p>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="fileUploadQCR"
                    onChange={(e) => setUploadFileQCR(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={styles.addBtn}
                style={{
                  opacity: 1,
                  background: "white",
                  border: "1px solid #E5E5E5",
                  color: "var(--main-color)",
                }}
              >
                {loading ? "Adding Lead.." : "Back"}
              </div>
              <div
                onClick={(e) => {
                  if (uploadFileQCR) {
                    uploadFile();
                  }
                }}
                className={styles.addBtn}
                style={{
                  opacity: 1,
                }}
              >
                {loading ? "Adding Lead.." : "Next"}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.quoteDetailsWrapper}
          style={{
            width: "437px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={successIcon} alt="" />
          <div style={{ fontWeight: 700, fontSize: "25px", padding: "30px" }}>
            Enrolment Initiated
          </div>
          <div style={{ fontSize: "16px", fontWeight: 500 }}>Added: 1</div>
          <div
            style={{
              fontSize: "20px",
              fontWeight: 700,
              paddingTop: "65px",
              paddingBottom: "20px",
            }}
          >
            CD Balance
          </div>
          <div style={{ fontSize: "30px", fontWeight: 400 }}>
            USD{" "}
            {uploadSuccess?.cd_balance
              ? (uploadSuccess?.cd_balance / 100).toFixed(2)
              : "0.00"}
          </div>
        </div>
      )}
    </>
  );
};

export default UploadComInfo;
