import React, { useContext, useState } from "react";
import classNames from "./mobilenavbar.module.scss";

//assets
import fullLogo from "../../assest/images/fullLogoWhite.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { logoutToSameLogin } from "../../assest/functions";
import { BankContext } from "../../context/Context";

const MobileNavbar = () => {
  const history = useHistory();
  const { setDashboardHam, userLoginHandler } = useContext(BankContext);
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div className={classNames.mobileNavbar}>
      <div className={classNames.mainContainer}>
        <img src={fullLogo} alt="fullLogo" />
        <div onClick={() => setNavOpen(!navOpen)}>
          {navOpen ? <IoMdClose /> : <RxHamburgerMenu />}
        </div>
      </div>
      {navOpen && (
        <div className={classNames.navContainer}>
          <div
            className={classNames.eachNavigation}
            onClick={() => {
              setNavOpen(false);
              logoutToSameLogin(history, setDashboardHam, userLoginHandler);
            }}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNavbar;
