import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./legacy.module.scss";

import moment from "moment";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { ReactComponent as SearchIcon } from "../../assest/images/icons/search.svg";
import Axios from "axios";
import { BankContext } from "../../context/Context";
import CustomCheckbox, {
  CopyBtn,
  EachCustomPolicyNumberDropdown,
  EachCustomProfileDropdown,
} from "../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV2 from "../../components/NavbarV2";
import NavbarV3 from "../../components/NavbarV3";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutToSameLogin } from "../../assest/functions";

const Legacy = () => {
  const history = useHistory();
  const { email, popularAction, setPopularAction, actionBtns, setActionBtns } =
    useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("Companies");
  const [searchQuery, setSearchQuery] = useState("");
  const [statusDropdown, setStatusDropdown] = useState("");
  const [statesDropdown, setStatesDropdown] = useState("");
  const [appDropdown, setAppDropdown] = useState("");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");
  const [allLeadsAPI, setAllLeadsAPI] = useState("");
  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");

  useEffect(() => {
    console.log("API is being called");
    setAllLeads("");
    setAllLeadsAPI("");
    setAllBrokeragesTotalProfile("");
    setAllLeadsLoading(true);

    const fetchAllBrokerages = () => {
      let URL;
      if (selectedProfileNav === "Companies") {
        URL =
          "https://insurancecoverfi.apimachine.com/insurance/insuranceCompany/get";
      } else {
        URL = "https://insurancecoverfi.apimachine.com/insurance/corporate/get";
      }

      Axios.get(URL)
        .then((response) => {
          console.log(
            response,
            `all insurance tab response ${selectedProfileNav}`
          );
          if (response?.data?.status) {
            setAllLeads(response?.data?.data);
            setAllLeadsAPI(response?.data?.data);
            setAllBrokeragesTotalProfile(response?.data?.total_profiles);
          }
          setAllLeadsLoading(false);
        })
        .catch((error) => {
          console.log(error?.message, "app dropdown error");
          setAllLeadsLoading(false);
        });
    };

    fetchAllBrokerages();
  }, [selectedProfileNav, email]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={["Companies"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />
      <div className={classNames.brokerageDetails}>
        <div className={classNames.title}>
          {selectedProfileNav == "HR's"
            ? `There are ${
                allBrokeragesTotalProfile ? allBrokeragesTotalProfile : "0"
              } Companies`
            : `${
                allBrokeragesTotalProfile ? allBrokeragesTotalProfile : "0"
              } Insurance Companies`}
        </div>
        {/* <div className={classNames.dropdownItems}></div> */}
        <div className={classNames.allLeads}>
          <div className={classNames.nav}>
            <div className={classNames.searchDiv}>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search.."
                onChange={(event) =>
                  setAllBrokeragesSearch(event?.target?.value)
                }
              />
            </div>
            <div
              className={classNames.actionBtn}
              style={{
                display: popularAction
                  ? "none"
                  : actionBtns?.length > 0
                  ? ""
                  : "none",
              }}
            >
              <div>Edit</div>
              <div
                onClick={() => {
                  setPopularAction("deletelead");
                }}
              >
                Delete
              </div>
            </div>
          </div>
          <Table
            allLeads={allLeads}
            allBrokeragesSearch={allBrokeragesSearch}
            actionBtns={actionBtns}
            setActionBtns={setActionBtns}
            allLeadsLoading={allLeadsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Legacy;

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: isOpen ? "250px" : "" }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{ pointerEvents: dropdown ? "" : "none" }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOption
              ? selectedOption
              : title
              ? title
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title === "All Countries"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState(eachitem);
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"All Countries"}
              >
                All Countries
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const Table = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>Name</th>
            <th>Location</th>
            <th>Contact</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr key={"loading"}>
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr key={row?.brokerage_name + index} style={{ width: "100%" }}>
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.uuid}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.profile_picture} alt="profile_picture" />
                      </div>
                      <div>
                        <div>
                          {row?.displayName ? row?.displayName : ""}
                          {row?.displayName && (
                            <CopyBtn
                              copyText={
                                row?.displayName ? row?.displayName : ""
                              }
                            />
                          )}
                        </div>
                        <div>
                          {row?.companyName ? row?.companyName : ""}
                          {row?.companyName && (
                            <CopyBtn
                              copyText={
                                row?.companyName ? row?.companyName : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.profile_picture} alt="profile_picture" />
                      </div>
                      <div>
                        <div>
                          {row?.branchAddressCity ? row?.branchAddressCity : ""}
                          {row?.branchAddressCity && (
                            <CopyBtn
                              copyText={
                                row?.branchAddressCity
                                  ? row?.branchAddressCity
                                  : ""
                              }
                            />
                          )}
                        </div>
                        <div>
                          {row?.branchAddressState
                            ? row?.branchAddressState
                            : ""}
                          {row?.branchAddressState && (
                            <CopyBtn
                              copyText={
                                row?.branchAddressState
                                  ? row?.branchAddressState
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.profile_picture} alt="profile_picture" />
                      </div>
                      <div>
                        <div>
                          {row?.contactMobile ? row?.contactMobile : ""}
                          {row?.contactMobile && (
                            <CopyBtn
                              copyText={
                                row?.contactMobile ? row?.contactMobile : ""
                              }
                            />
                          )}
                        </div>
                        <div>
                          {row?.contactEmail ? row?.contactEmail : ""}
                          {row?.contactEmail && (
                            <CopyBtn
                              copyText={
                                row?.contactEmail ? row?.contactEmail : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row?.status ? row?.status : ""}
                    {row?.status && (
                      <CopyBtn copyText={row?.status ? row?.status : ""} />
                    )}
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};
