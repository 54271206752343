import React, { useContext, useEffect } from "react";
import classNames from "./layout.module.scss";

import rahaxFullLogo from "../assest/images/fullLogo.svg";
import { BankContext } from "../context/Context";

const VisitLayout = ({ child }) => {
  const { setOnLoginFormEmployer } = useContext(BankContext);
  useEffect(() => {
    localStorage.removeItem("onLoginForm");
    setOnLoginFormEmployer(false);
    console.log("removing onLoginForm visitlayout 12");
  }, []);
  return (
    <div className={classNames.visitLayout}>
      <div className={classNames.leftContainer}>
        <img
          src={rahaxFullLogo}
          alt="rahaxFullLogo"
          className={classNames.logo}
        />
        {child}
      </div>
      <div className={classNames.rightContainer}>
        <div className={classNames.title}>How it works</div>
        <div className={classNames.allSteps}>
          <div className={classNames.eachStep}>
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/step-1.png"
              alt=""
              className={classNames.stepImage}
            />
            <div className={classNames.stepDirection}>
              <div className={classNames.title}>Step 1</div>
              <div className={classNames.desc}>
                View your base health insurance benefits
              </div>
            </div>
          </div>
          <div className={classNames.eachStep}>
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/step-2.png"
              alt=""
              className={classNames.stepImage}
            />
            <div className={classNames.stepDirection}>
              <div className={classNames.title}>Step 2</div>
              <div className={classNames.desc}>
                Add/Verify details of your dependents
              </div>
            </div>
          </div>
          <div className={classNames.eachStep}>
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/step-3.png"
              alt=""
              className={classNames.stepImage}
            />
            <div className={classNames.stepDirection}>
              <div className={classNames.title}>Step 3</div>
              <div className={classNames.desc}>
                Enhance your coverage by opting for top-ups
              </div>
            </div>
          </div>
          <div className={classNames.eachStep}>
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/step-4.png"
              alt=""
              className={classNames.stepImage}
            />
            <div className={classNames.stepDirection}>
              <div className={classNames.title}>Step 4</div>
              <div className={classNames.desc}>
                Add Parents/Parents-in-law details to cover them under parental
                coverage
              </div>
            </div>
          </div>
          <div className={classNames.eachStep}>
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/step-5.png"
              alt=""
              className={classNames.stepImage}
            />
            <div className={classNames.stepDirection}>
              <div className={classNames.title}>Step 5</div>
              <div className={classNames.desc}>Submit the form</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitLayout;
