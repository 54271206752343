import React, { useContext, useEffect, useState } from "react";

import "./custom.scss";
import { BankContext } from "../../context/Context";

import rahaLogo from "../../assest/images/logos/rahalogo.svg";

const Overlay = () => {
  const { overlayOpened, popularAction, overlayDeleteItemName, selectedEdit } =
    useContext(BankContext);

  return (
    <div className="overlay" style={{ display: overlayOpened ? "" : "none" }}>
      <div className="overlayContent">
        <img src={rahaLogo} alt="rahaLogo" className="logo" />
        {popularAction == "editleadAPI" ? (
          <div className="message">
            <div>You Have Updated </div>
            <div>
              {selectedEdit?.first_name ? selectedEdit?.first_name : ""} To{" "}
              {overlayOpened ? overlayOpened : ""}
            </div>
          </div>
        ) : (
          <div className="message">
            <div>You Have Deleted</div>
            <div>{overlayDeleteItemName ? overlayDeleteItemName : ""}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overlay;
