import React, { createContext, useEffect, useState } from "react";
import Axios from "axios";

import allPlatforms from "../assest/images/allPlatforms.svg";
import ModalConfirm from "../components/ModalConfirm/ModalConfirm";
import SelectCountry from "../components/SelectCountry/SelectCountry";
import ModalSessionExpired from "../components/ModalSessionExpired/ModalSessionExpired";
import ToastMessage from "../components/ToastMessage/ToastMessage";
import EnterPinUnlock from "../components/EnterPinUnlock/EnterPinUnlock";
import { APP_CODE } from "../config/appConfig";
import Cookies from "js-cookie";
import { APP_USER_TOKEN } from "../config";
import axios from "axios";
import { videosInPlaylist } from "../APIs/educationapi";

import cultfitLogo from "../assest/images/coverfi/proactive/cultfit.svg";
import dialLogo from "../assest/images/coverfi/proactive/dial.svg";
import dummyTest from "../assest/images/employer/wellness/dummytest.svg";
import { parseBoolean } from "../assest/functions";

export const BankContext = createContext();

function BankContextProvider({ children }) {
  const [loggedInUserId, setLoggedInUserId] = useState(
    localStorage.getItem("loggedInUserId")
      ? localStorage.getItem("loggedInUserId")
      : null
  );
  const [loggedInUserRoleId, setLoggedInUserRoleId] = useState(
    localStorage.getItem("loggedInUserRoleId")
      ? localStorage.getItem("loggedInUserRoleId")
      : null
  );
  const [dashboardToggleMain, setDashboardToggleMain] = useState(
    localStorage.getItem("dashboardToggleMain") || "Employee"
  );
  const [dashboardToggle, setDashboardToggle] = useState(
    localStorage.getItem("dashboardToggle") || "Employee"
  );
  const [masterEmployeeID, setMasterEmployeeID] = useState(
    localStorage.getItem("masterEmployeeID") || "Employee"
  );
  const [designation, setDesignation] = useState("");
  const [onLoginFormEmployer, setOnLoginFormEmployer] = useState(
    localStorage?.getItem("onLoginForm")
      ? parseBoolean(localStorage?.getItem("onLoginForm"))
      : localStorage?.getItem("onLoginForm") || ""
  );
  const [onLoginFormEmployerProfile, setOnLoginFormEmployerProfile] = useState(
    (localStorage.getItem("onLoginFormEmployerProfile") &&
      JSON.parse(localStorage.getItem("onLoginFormEmployerProfile"))) ||
      ""
  );
  const [profileDetailsAssociatesClone, setProfileDetailsAssociatesClone] =
    useState(
      (localStorage.getItem("profileDetailsAssociatesClone") &&
        JSON.parse(localStorage.getItem("profileDetailsAssociatesClone"))) ||
        ""
    );
  const [admin, setAdmin] = useState(false);
  const [refreshPage, setRefreshPage] = useState("");
  const [email, setEmail] = useState(
    localStorage.getItem("nvestBankLoginAccount") || ""
  );
  const [globalUserId, setGlobalUserId] = useState(
    localStorage.getItem("globalUserIdRaha") || ""
  );
  const [globalCompanyId, setGlobalCompanyId] = useState(
    localStorage.getItem("globalCompanyIdRaha") || ""
  );
  const [globalCompanyUnderscoreId, setGlobalCompanyUnderscoreId] = useState(
    localStorage.getItem("globalCompanyUnderscoreId") || ""
  );
  const [globalHrId, setGlobalHrId] = useState(
    localStorage.getItem("globalUserHrIdRaha") || ""
  );
  const [selectedGlobalLead, setSelectedGlobalLead] = useState(
    localStorage.getItem("globalLeadDropdown") || ""
  );
  const [globalCompanyProfile, setGlobalCompanyProfile] = useState(
    (localStorage.getItem("globalCompanyProfile") &&
      JSON.parse(localStorage.getItem("globalCompanyProfile"))) ||
      ""
  );
  const [globalAgentId, setGlobalAgentId] = useState(
    localStorage.getItem("globalAgentIdRaha") || ""
  );
  const [globalSelectedPolicy, setGlobalSelectedPolicy] = useState(
    localStorage.getItem("globalSelectedPolicy") || ""
  );
  const [globalUserEmployeeId, setGlobalUserEmployeeId] = useState(
    localStorage.getItem("globalUserEmployeeId") || ""
  );
  const [globalUserEmployeeDetails, setGlobalUserEmployeeDetails] =
    useState("");
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("nvestBankAccessToken") || ""
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem("nvestBankIdToken") || ""
  );

  const appUserToken = Cookies.get(APP_USER_TOKEN);

  const [popupProfileDetails, setPopupProfileDetails] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    setIsScrolled(false);
  }, [window?.location?.pathname]);

  const [usersDataGlobal, setUsersDataGlobal] = useState(
    (localStorage.getItem("usersDataGlobal") &&
      JSON.parse(localStorage.getItem("usersDataGlobal"))) ||
      ""
  );
  const [employeeIDEmployeeApp, setEmployeeIDEmployeeApp] = useState(
    localStorage.getItem("employeeIDEmployeeApp") || ""
  );
  const [employeeVerified, setEmployeeVerified] = useState(
    localStorage.getItem("employeeVerified") || ""
  );
  const [supportDataGlobal, setSupportDataGlobal] = useState("");

  //functions

  async function getSupportData(leadID) {
    try {
      let response = await axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping?${leadID}`
      );
      console.log(response, "Get SupportData response");
      if (response?.data?.status) {
        setSupportDataGlobal(response?.data?.data);
      } else {
        setSupportDataGlobal("");
      }
    } catch (error) {
      console.log(error?.message, "getSupportData error");
    }
  }

  useEffect(() => {
    getProfileDetails();
  }, [email]);

  function getProfileDetails() {
    if (email) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
      )
        .then((response) => {
          if (response?.data?.status) {
            setUsersDataGlobal(response?.data?.data);
            localStorage.setItem(
              "usersDataGlobal",
              JSON.stringify(response?.data?.data)
            );
            if (response?.data?.data?.employeeDetails?.length > 0) {
              setEmployeeIDEmployeeApp(
                response?.data?.data?.employeeDetails[0]._id
              );
              localStorage.setItem(
                "employeeIDEmployeeApp",
                response?.data?.data?.employeeDetails[0]._id
              );
            }

            if (
              window?.location?.pathname?.includes("/app/coverfi/") &&
              response?.data?.data?.leadDetails?._id
            ) {
              getSupportData(
                `lead_id=${response?.data?.data?.leadDetails?._id}`
              );
            } else if (
              window?.location?.pathname?.includes("/app/rahax/") &&
              response?.data?.data?.employeeDetails?.length > 0
            ) {
              getSupportData(
                `company_id=${response?.data?.data?.employeeDetails[0]?.company_ObjectId}`
              );
            } else {
              setSupportDataGlobal("");
            }
          } else {
            setUsersDataGlobal("");
          }
        })
        .catch((error) => {
          console.log(error?.message, "UsersDataError");
        });
    }
  }

  useEffect(() => {
    !admin &&
      localStorage.setItem("nvestBankLoginAccount", email?.toLowerCase());
  }, [email, admin]);
  useEffect(() => {
    localStorage.setItem("nvestBankAccessToken", accessToken);
  }, [accessToken]);
  useEffect(() => {
    localStorage.setItem("nvestBankIdToken", idToken);
  }, [idToken]);

  const userLoginHandler = (paramEmail, paramAccessToken, paramIdToken) => {
    if (paramEmail && paramAccessToken && paramIdToken) {
      setEmail(paramEmail?.toLowerCase());
      setAccessToken(paramAccessToken);
      setIdToken(paramIdToken);
    }
    if (!paramEmail || !paramAccessToken || !paramIdToken) {
      Cookies.remove(APP_USER_TOKEN);
    }
  };

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [profileId, setProfileId] = useState("");
  const [iceProfileId, setIceProfileId] = useState("");

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  const [videoPlaying, setVideoPlaying] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVideoPlaying(false);
    }, 6000);
  }, []);

  useEffect(() => {
    function getUserData() {
      Axios.post("https://comms.globalxchange.io/get_affiliate_data_no_logs", {
        email: email,
      }).then((res) => {
        const data = res.data[0];
        if (data) {
          console.log(data, "checking username");
          setUsername(data.username);
          setName(data.name);
          setProfileImg(data.profile_img);
        }
      });
      if (email) {
        Axios.get(
          `https://comms.globalxchange.io/user/details/get?email=${email}`
        ).then((res) => {
          const { data } = res;
          if (data.status) {
            setProfileId(data.user[`${APP_CODE}_profile_id`]);
            setIceProfileId(data.user.ice_profile_id);
          }
        });
      }
    }
    if (email && idToken) {
      Axios.post("https://comms.globalxchange.io/coin/verifyToken", {
        email,
        token: idToken,
      }).then((res) =>
        res.data.status || admin || res.data.login
          ? getUserData()
          : userLoginHandler("", "", "")
      );
    }
  }, [email, idToken]);

  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastTitle, setToastTitle] = useState("");
  const tostShowOn = (message, title) => {
    setToastShow(true);
    setToastMessage(message);
    setToastTitle(title);
    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  };

  const [ratesRes, setRatesRes] = useState([]);
  const [liquidRates, setLiquidRates] = useState([]);
  useEffect(() => {
    if (idToken) {
      Axios.get(
        "https://comms.globalxchange.io/coin/vault/earnings/getinterestrates"
      )
        .then((res) => {
          const { data } = res;
          if (data.status) {
            setRatesRes(data.rates);
          }
        })
        .catch((error) => {
          console.log(error?.message, "old APIS error");
        });
      Axios.get("https://comms.globalxchange.io/coin/iced/get/liquid/interest")
        .then((res) => {
          const { data } = res;
          if (data.status) {
            const { interest_rates } = data;
            setLiquidRates(interest_rates);
          }
        })
        .catch((error) => {
          console.log(error?.message, "old APIS error");
        });
    }
  }, []);

  const [coinList, setCoinList] = useState([]);
  const updateBalance = () => {
    Axios.post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
      app_code: APP_CODE,
      profile_id: profileId,
    }).then((res) => {
      const { data } = res;
      if (data.status) {
        const { coins_data } = data;
        coins_data.sort(GetSortOrder("type"));
        setCoinList(coins_data);
      }
    });
  };
  useEffect(() => {
    updateBalance();
  }, [profileId, refreshPage.balanceUpdate]);

  const [icedContracts, setIcedContracts] = useState([]);
  function getIcedContracts() {
    email &&
      Axios.get(
        `https://comms.globalxchange.io/coin/iced/contract/get?email=${email}`
      ).then((res) => {
        const { data } = res;
        if (data.status) {
          const { icedContracts } = data;
          setIcedContracts(icedContracts);
        }
      });
  }
  useEffect(() => {
    getIcedContracts();
    // eslint-disable-next-line
  }, [email, refreshPage.balanceUpdate]);

  const [coinListObject, setCoinListObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    coinList.forEach((coin) => {
      coinObj[coin.coinSymbol] = coin;
    });
    setCoinListObject(coinObj);
  }, [coinList]);

  const [coinNameObject, setCoinNameObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    coinList.forEach((coin) => {
      coinObj[coin.coinName] = coin;
    });
    setCoinNameObject(coinObj);
  }, [coinList]);

  const [liquidRatesObject, setLiquidRatesObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    liquidRates.forEach((coin) => {
      coinObj[coin.coin] = coin;
    });
    setLiquidRatesObject(coinObj);
  }, [liquidRates]);

  const [openDefaultCoinSidebar, setOpenDefaultCoinSidebar] = useState(false);
  const [defaultCoin, setDefaultCoin] = useState({
    coin: null,
    name: "Default Coin",
    img: allPlatforms,
  });
  const convertCoin = (amount, coin) => {
    if (defaultCoin.coin && defaultCoin.coin !== null) {
      return (
        (amount * coinListObject[coin].price.USD) /
        coinListObject[defaultCoin.coin].price.USD
      );
    } else {
      return amount;
    }
  };

  const [footerShow, setFooterShow] = useState(true);

  const [updateInterval, setUpdateInterval] = useState(5);

  // Modal Variables
  const [openModal, setOpenModal] = useState(false);
  const [onClose, setOnClose] = useState(() => {});
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [modalText, setModalText] = useState("");
  const [trackerSidebarMenu, setTrackerSidebarMenu] = useState(false);

  const populateModal = (text, onCloseParam, onConfirmParam) => {
    setOpenModal(true);
    setOnClose(() => onCloseParam);
    setOnConfirm(() => onConfirmParam);
    setModalText(text);
  };

  // To Populate List In Sidebar
  const [contentSideBar, setContentSideBar] = useState({});

  // Coin Data For Coin Detail
  const [coinData, setCoinData] = useState({});

  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/vault/get/all/coins").then(
      (res) => {
        const { data } = res;
        if (data.status) {
          let coinObj = {};
          data.coins.forEach((coin) => {
            coinObj[coin.coinSymbol] = coin;
          });
          setCoinData(coinObj);
        }
      }
    );
    return () => {};
  }, []);

  const [conractsObj, setConractsObj] = useState({});
  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/iced/admin/get/data").then(
      (res) => {
        const { data } = res;
        if (data.status) {
          const obj = {};
          data.config_data.forEach((config) => {
            obj[config.coin] = { ...obj[config.coin], ...config };
          });
          setConractsObj(obj);
        }
      }
    );
  }, []);

  const [iceSidebarOpen, setIceSidebarOpen] = useState(false);

  const [modalSessionExpOpen, setModalSessionExpOpen] = useState(false);
  const validateToken = async (paramEmail, paramToken) => {
    if (admin) return true;
    const res = await Axios.post(
      "https://comms.globalxchange.io/coin/verifyToken",
      {
        email: paramEmail,
        token: paramToken,
      }
    );
    if (res.data && res.data.status) {
      return true;
    } else {
      setModalSessionExpOpen(true);
      return false;
    }
  };

  const [bondDetail, setBondDetail] = useState();
  const [chatOn, setChatOn] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const [inboxOpen, setInboxOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    if (videoShow) {
      setChatOn(false);
      setIceSidebarOpen(false);
      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [videoShow]);
  useEffect(() => {
    if (chatOn) {
      setVideoShow(false);
      setIceSidebarOpen(false);
      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [chatOn]);
  useEffect(() => {
    if (iceSidebarOpen) {
      setVideoShow(false);
      setChatOn(false);
      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [iceSidebarOpen]);

  useEffect(() => {
    if (inboxOpen) {
      setVideoShow(false);
      setChatOn(false);
      setSidebarCollapse(true);
      setIceSidebarOpen(false);
    }
  }, [inboxOpen]);

  useEffect(() => {
    if (!sidebarCollapse) {
      setVideoShow(false);
      setChatOn(false);
      setIceSidebarOpen(false);
      setInboxOpen(false);
    }
  }, [sidebarCollapse]);

  useEffect(() => {
    if (settingsOpen) {
      setChatOn(true);
    }
  }, [settingsOpen]);

  function closeSidebars() {
    setVideoShow(false);
    setChatOn(false);
    setIceSidebarOpen(false);
    setInboxOpen(false);
  }
  const [defaultPrecission, setDefaultPrecission] = useState(0);
  const [tempMail, setTempMail] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [globalSelectedCheckbox, setGlobalSelectedCheckbox] = useState("");

  function copyToClipboard(value, title) {
    navigator.clipboard.writeText(value).then(() => {
      tostShowOn(value, title);
    });
  }
  function justCopyToClipboard(value, title) {
    navigator.clipboard.writeText(value);
  }

  const [enterPin, setEnterPin] = useState(false);
  const [selectedTopItemReact, setSelectedTopItemReact] = useState("Retire");

  const [selectedbtn, setSelectedbtn] = useState("asset");
  const [bondcount, setBondcount] = useState("");

  //username
  const [linkname, setLinkname] = useState("");

  const [bankAccountId, setBankAccountId] = useState("");

  //retailer page
  const [retailerTab, setRetailerTab] = useState("Products");
  const [popularAction, setPopularAction] = useState(false);

  const [product, setProduct] = useState([]);

  //onhold
  const [userSelectedOnHold, setUserSelectedOnHold] = useState("");

  //crm page
  const [crmTab, setCrmTab] = useState("Overview");

  //engagement page
  const [engagementTab, setEngagementTab] = useState("Offerings");

  //opportunities page
  const [contentArea, setContentArea] = useState("defaultPage");
  const [layoutOverlay, setLayoutOverlay] = useState(false);
  const [promoteStep, setPromoteStep] = useState(1);

  //commercial page
  const [commercialTab, setCommercialTab] = useState("Product Lines");

  //raha
  const [overlayOpened, setOverlayOpened] = useState(false);
  const [overlayDeleteItemName, setOverlayDeleteItemName] = useState("");
  const [dashboardHam, setDashboardHam] = useState(false);
  const [actionBtns, setActionBtns] = useState([]);

  //protectionScore

  const [protectionScore, setProtectionScore] = useState("");
  const [protectionScoreLoading, setProtectionScoreLoading] = useState(true);

  const [loggedInApp, setLoggedInApp] = useState(() => {
    const storedState = localStorage.getItem("bankState");
    return storedState !== null ? storedState : "";
  });

  const [allLeadsAPI, setAllLeadsAPI] = useState("");
  const [selectedEdit, setSelectedEdit] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  const [addNewEmployeeType, setAddNewEmployeeType] = useState("spreadsheet");
  const [isProfileFound, setIsProfileFound] = useState(false);
  const [policiesApiGlobal, setPoliciesApiGlobal] = useState([]);

  // employers hospital section

  const [allHospitalDetails, setAllHospitalDetails] = useState([]);

  const [groupedItems, setGroupedItems] = useState([]);
  const [groupedItemsLoading, setGroupedItemsLoading] = useState(true);

  const [allHospitalDetail, setAllHospitalDetail] = useState("");

  const [allHospitalPolicies, setAllHospitalPolicies] = useState([]);

  //functions

  function getPoliciesGlobalApi() {
    console.log(loggedInApp, "loggedInApp");
    if (globalHrId && loggedInApp == "employer") {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}`
      )
        .then((response) => {
          console.log(response, "response from dashboard policies employer");
          if (response?.data?.data?.length > 0) {
            setPoliciesApiGlobal(response?.data?.data);
          } else {
            setPoliciesApiGlobal([]);
          }
        })
        .catch((error) => {
          console.log(error?.message, "claims API employer proactive error");
        });
    } else if (loggedInApp == "employee" && email) {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=${email}`
      )
        .then((response) => {
          console.log(response, "response from dashboard policies employee");
          if (response?.data?.data?.length > 0) {
            setEmployeeVerified(
              String(response?.data?.data[0]?.profile_verified)?.toLowerCase()
            );
            localStorage.setItem(
              "employeeVerified",
              String(response?.data?.data[0]?.profile_verified)?.toLowerCase()
            );
            if (response?.data?.data[0]?.policies?.length > 0)
              setPoliciesApiGlobal(response?.data?.data[0]?.policies);
          } else {
            setPoliciesApiGlobal([]);
          }
        })
        .catch((error) => {
          console.log(error?.message, "claims API employer proactive error");
        });
    }
  }

  useEffect(() => {
    if (
      globalHrId &&
      (window?.location?.pathname?.includes("coverfi") ||
        window?.location?.pathname?.includes("rahax"))
    ) {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}&policy_type=Health`
      )
        .then((response) => {
          if (response?.data?.data?.length > 0) {
            setAllHospitalPolicies(response?.data?.data);
            setGlobalSelectedPolicy(response?.data?.data[0]?.policy_number);
            localStorage.setItem(
              "globalSelectedPolicy",
              response?.data?.data[0]?.policy_number
            );
          }
        })
        .catch((error) => {
          console.log(error, "get polic details error");
        });
    }
  }, [globalHrId]);

  useEffect(() => {
    if (globalHrId || email) {
      getPoliciesGlobalApi();
    }
  }, [globalHrId, email, loggedInApp]);

  function sortEmployer(dataArray) {
    const sortedArray = dataArray
      ?.slice()
      ?.sort((a, b) => a?.hospital_name?.localeCompare(b?.hospital_name));

    const groupedItemsObj = {};

    if (Array.isArray(sortedArray) && sortedArray.length > 0) {
      sortedArray.forEach((item) => {
        const firstLetter = item?.hospital_name[0]?.toUpperCase();
        if (!groupedItemsObj[firstLetter]) {
          groupedItemsObj[firstLetter] = [];
        }
        groupedItemsObj[firstLetter]?.push(item);
      });
    }

    // console.log(groupedItemsObj, "groupedItemsObj", groupedItemsLoading);
    setGroupedItems(groupedItemsObj);
    setGroupedItemsLoading(false);
  }

  //hospital data getting effect
  useEffect(() => {
    setGroupedItems([]);
    setGroupedItemsLoading(true);

    if (
      (globalSelectedPolicy || localStorage.getItem("globalSelectedPolicy")) &&
      loggedInApp == "employer"
    ) {
      let filteredItem = allHospitalPolicies?.filter((eachItem, index) => {
        return (
          eachItem?.policy_number ===
          localStorage.getItem("globalSelectedPolicy")
        );
      });
      console.log(filteredItem, "Filtered policy response");
      if (filteredItem?.length > 0) {
        Axios.get(
          `https://coverfi.apimachine.com/api/v1/tpa/getNetworkHospital/${filteredItem[0]?.tpa_id?._id}/${filteredItem[0]?.insurance_company_id?._id}`
        )
          .then((response) => {
            console.log(response, "all hospitals list response");
            if (response?.data?.status && response?.data?.data) {
              // const dataArray = response?.data?.data?.providerData;
              const dataArray = response?.data?.data;
              setAllHospitalDetail(dataArray);
              setAllHospitalDetails(dataArray);
              sortEmployer(dataArray);
              setGroupedItemsLoading(false);

              console.log(dataArray, "dataArraydataArraydataArray");
            } else {
              setGroupedItemsLoading(false);
              setGroupedItems([]);
            }
          })
          .catch((error) => {
            console.log(error?.message, "all hospitals list error");
            setGroupedItemsLoading(false);
          });
      }
    } else if (
      (globalSelectedPolicy || localStorage.getItem("globalSelectedPolicy")) &&
      loggedInApp == "employee" &&
      allHospitalPolicies?.length > 0
    ) {
      let filteredItem = allHospitalPolicies?.filter((eachItem, index) => {
        return (
          eachItem?.policy_number ===
          localStorage.getItem("globalSelectedPolicy")
        );
      });
      console.log(filteredItem, "Filtered policy response employees");
      if (filteredItem?.length > 0) {
        Axios.get(
          `https://coverfi.apimachine.com/api/v1/tpa/getNetworkHospital/${filteredItem[0]?.policy_id?.tpa_id?._id}/${filteredItem[0]?.policy_id?.insurance_company_id?._id}`
        )
          .then((response) => {
            console.log(response, "all hospitals list response employee");
            if (response?.data?.status && response?.data?.data) {
              // const dataArray = response?.data?.data?.providerData;
              const dataArray = response?.data?.data;
              setAllHospitalDetail(dataArray);
              setAllHospitalDetails(dataArray);
              sortEmployer(dataArray);
              setGroupedItemsLoading(false);

              console.log(dataArray, "dataArraydataArraydataArray employee");
            } else {
              setGroupedItemsLoading(false);
              setGroupedItems([]);
            }
          })
          .catch((error) => {
            console.log(error?.message, "all hospitals list error");
            setGroupedItemsLoading(false);
          });
      }
    }
  }, [globalSelectedPolicy, allHospitalPolicies]);

  const [addDependentSidebar, setAddDependentSidebar] = useState(false);
  const [lastSelectedEmployee, setLastSelectedEmployee] = useState("");

  const [reqValue, setreqValue] = useState("");
  const [generatePDFData, setGeneratePDFData] = useState("");
  const [allCartItems, setAllCartItems] = useState([]);

  const allCartProducts = [
    {
      title: "Employee Cult Fit Package",
      desc: "Cult.fit uses the best in technology to give you a world-class experience.",
      price: "8000",
      logo: cultfitLogo,
    },
    {
      title: "Employee Ambulance Assurance Plan",
      desc: "DIAL4242, India's biggest app based ambulance network is built to deliver",
      price: "149",
      logo: dialLogo,
    },
    {
      title: "Popular Packages",
      desc: "Health Shield Comprehensive - Male",
      price: "4999",
      logo: dummyTest,
    },
    {
      title: "Complete Blood Count (CBC) / CBP - Whole Blood",
      desc: `Complete Blood Count (CBC) or Complete Blood Picture is
                  a group of tests that evaluates the status of the circulating
                  blood cells including the Red Blood Cells, White Blood Cells
                  and Platelets in the body. This test is routinely performed
                  to screen or diagnose various conditions .....`,
      price: "100",
      logo: dummyTest,
    },
  ];

  function addToCart(product, type) {
    if (type == "remove") {
      let filterProduct = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title == product;
      });
      let filterProductOthers = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title !== product;
      });

      if (filterProduct?.length > 0) {
        setAllCartItems((prev) => {
          return [
            ...filterProductOthers,
            {
              title: filterProduct[0]?.title,
              quantity: filterProduct[0]?.quantity - 1,
            },
          ];
        });
      } else {
        setAllCartItems((prev) => {
          return [...prev, { title: product, quantity: 1 }];
        });
      }
      // setSelected(true);
    } else {
      let filterProduct = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title == product;
      });
      let filterProductOthers = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title !== product;
      });

      if (filterProduct?.length > 0) {
        setAllCartItems((prev) => {
          return [
            ...filterProductOthers,
            {
              title: filterProduct[0]?.title,
              quantity: filterProduct[0]?.quantity + 1,
            },
          ];
        });
      } else {
        setAllCartItems((prev) => {
          return [...prev, { title: product, quantity: 1 }];
        });
      }
      // setSelected(true);
    }
  }

  //reach education from retired

  const [youtubeApiData, setYoutubeApiData] = useState("");
  const [youtubeApiVideoData, setYoutubeApiVideoData] = useState("");
  const [youtubeApiPlaylistData, setYoutubeApiPlaylistData] = useState("");
  const [educationLeftSelected, seteducationLeftSelected] = useState("Youtube");
  const [selectedPlaylistData, setSelectedPlaylistData] = useState("");
  const [allPlaylistAvailable, setallPlaylistAvailable] = useState("");
  var playListidDefault = "";

  useEffect(() => {
    axios
      .get(
        "https://www.googleapis.com/youtube/v3/search?part=snippet&key=AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU&channelId=UC7ROnJtjwZVVwXzZwMKVaLA"
      )
      .then((response) => {
        let filteredVideos = response.data.items.filter((eachData) => {
          return eachData.id.kind.includes("video");
        });
        setYoutubeApiData(response.data);
        setYoutubeApiVideoData(filteredVideos);
      })
      .catch((error) => {
        console.log(error, "youtube error");
      });
    axios
      .get(
        "https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UC7ROnJtjwZVVwXzZwMKVaLA&key=AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU"
      )
      .then(async (response) => {
        if (response?.data?.items[0]?.id) {
          playListidDefault = response?.data?.items[0]?.id;
          let playlistData = await videosInPlaylist(
            response?.data?.items[0]?.id
          );
          setSelectedPlaylistData(playlistData);
        }
        setYoutubeApiPlaylistData(response?.data?.items[0]);
        setallPlaylistAvailable(response?.data?.items);
      })
      .catch((error) => {
        console.log("playlist error", error);
      });
  }, []);

  useEffect(() => {
    if (!email) {
      localStorage.removeItem("onLoginForm");
      setOnLoginFormEmployer(false);
    }
  }, [email]);

  useEffect(() => {
    if (globalUserId) {
      fetchGlobalCompanyId();
    }
  }, [globalUserId]);

  //functions

  function fetchGlobalCompanyId() {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/hr/get?user_id=${globalUserId}`
    )
      .then((response) => {
        console.log(response?.data?.data, "company profile response main Page");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          console.log(
            response?.data?.data[0]?.company_id,
            "company id response"
          );

          setGlobalCompanyId(response?.data?.data[0]?.company_id);
          setGlobalCompanyUnderscoreId(
            response?.data?.data[0]?.company_profile?._id
          );

          localStorage.setItem(
            "globalCompanyIdRaha",
            response?.data?.data[0]?.company_id
          );
          localStorage.setItem(
            "globalCompanyUnderscoreId",
            response?.data?.data[0]?.company_profile?._id
          );
        }
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");

        // setAllInsuranceCompaniesLoading(false);
      });
  }

  //renderings

  // async function getGlobalAgentID() {
  //   let agentID = await Axios.get(
  //     `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
  //   );

  //   if (
  //     agentID?.data?.data?.employeeDetails?.length > 0 &&
  //     agentID?.data?.data?.employeeDetails[0]?.user_employee_id
  //   ) {
  //     localStorage.setItem(
  //       "masterEmployeeID",
  //       agentID?.data?.data?.employeeDetails[0]?.user_employee_id
  //     );
  //     setMasterEmployeeID(
  //       agentID?.data?.data?.employeeDetails[0]?.user_employee_id
  //     );

  //     setEmployeeIDEmployeeApp(agentID?.data?.data?.employeeDetails[0]._id);
  //     localStorage.setItem(
  //       "employeeIDEmployeeApp",
  //       agentID?.data?.data?.employeeDetails[0]._id
  //     );
  //   }

  //   let hrDetails =
  //     typeof agentID?.data?.data?.hrDetails?.hrCompanyDetails === "object" &&
  //     Object?.keys(agentID?.data?.data?.hrDetails?.hrCompanyDetails)?.length ===
  //       0;

  //   localStorage.setItem("onLoginForm", hrDetails);
  //   setOnLoginFormEmployer(hrDetails);
  //   if (agentID?.data?.data) {
  //     localStorage.setItem(
  //       "onLoginFormEmployerProfile",
  //       JSON.stringify(agentID?.data?.data)
  //     );
  //   }
  //   console.log(agentID?.data?.data, "profile onloginForm");
  //   setOnLoginFormEmployerProfile(agentID?.data?.data);

  //   if (agentID?.data?.status && agentID?.data?.data) {
  //     setGlobalAgentId(agentID?.data?.data?.agentDetails?.agent_id);
  //     localStorage.setItem(
  //       "globalAgentIdRaha",
  //       agentID?.data?.data?.agentDetails?.agent_id
  //     );
  //   }
  // }

  // useEffect(() => {}, []);

  return (
    <BankContext.Provider
      value={{
        userLoginHandler,
        email,
        token: idToken,
        accessToken,
        tostShowOn,
        username,
        name,
        profileImg,
        ratesRes,
        coinList,
        profileId,
        liquidRates,
        liquidRatesObject,
        coinListObject,
        openDefaultCoinSidebar,
        setOpenDefaultCoinSidebar,
        defaultCoin,
        setDefaultCoin,
        convertCoin,
        icedContracts,
        footerShow,
        setFooterShow,
        updateInterval,
        setUpdateInterval,
        populateModal,
        contentSideBar,
        setContentSideBar,
        coinData,
        conractsObj,
        coinNameObject,
        getIcedContracts,
        iceSidebarOpen,
        setIceSidebarOpen,
        validateToken,
        updateBalance,
        chatOn,
        setChatOn,
        admin,
        setAdmin,
        setEmail,
        globalUserId,
        setGlobalUserId,
        globalCompanyId,
        setGlobalCompanyId,
        globalCompanyUnderscoreId,
        globalHrId,
        setGlobalHrId,
        selectedGlobalLead,
        setSelectedGlobalLead,
        globalCompanyProfile,
        setGlobalCompanyProfile,
        globalAgentId,
        setGlobalAgentId,
        globalSelectedPolicy,
        setGlobalSelectedPolicy,
        globalUserEmployeeId,
        setGlobalUserEmployeeId,
        globalUserEmployeeDetails,
        setGlobalUserEmployeeDetails,
        setIdToken,
        setUsername,
        setName,
        setProfileImg,
        setProfileId,
        refreshPage,
        setRefreshPage,
        videoShow,
        setVideoShow,
        sidebarCollapse,
        setSidebarCollapse,
        inboxOpen,
        setInboxOpen,
        defaultPrecission,
        setDefaultPrecission,
        settingsOpen,
        setSettingsOpen,
        closeSidebars,
        bondDetail,
        setBondDetail,
        tempMail,
        setTempMail,
        tempPass,
        setTempPass,
        globalSelectedCheckbox,
        setGlobalSelectedCheckbox,
        iceProfileId,
        copyToClipboard,
        justCopyToClipboard,
        setEnterPin,
        appUserToken,
        selectedTopItemReact,
        setSelectedTopItemReact,
        usersDataGlobal,
        setUsersDataGlobal,
        supportDataGlobal,
        setSupportDataGlobal,
        employeeIDEmployeeApp,
        setEmployeeIDEmployeeApp,

        //createbond - stakingvault in defi page
        selectedbtn,
        setSelectedbtn,
        bondcount,
        setBondcount,
        linkname,
        setLinkname,
        //tracker
        trackerSidebarMenu,
        setTrackerSidebarMenu,
        bankAccountId,
        setBankAccountId,

        //retailer page
        retailerTab,
        setRetailerTab,
        popularAction,
        setPopularAction,

        product,
        setProduct,

        //onhold
        userSelectedOnHold,
        setUserSelectedOnHold,

        //crm page
        crmTab,
        setCrmTab,

        //engagement page
        engagementTab,
        setEngagementTab,

        //opportunities page
        contentArea,
        setContentArea,
        layoutOverlay,
        setLayoutOverlay,
        promoteStep,
        setPromoteStep,

        //commercial page
        commercialTab,
        setCommercialTab,

        //raha
        overlayOpened,
        setOverlayOpened,
        overlayDeleteItemName,
        setOverlayDeleteItemName,
        dashboardHam,
        setDashboardHam,
        actionBtns,
        setActionBtns,
        protectionScore,
        setProtectionScore,
        protectionScoreLoading,
        setProtectionScoreLoading,
        //logged in app
        loggedInApp,
        setLoggedInApp,
        allLeadsAPI,
        setAllLeadsAPI,
        selectedEdit,
        setSelectedEdit,
        updateLoading,
        setUpdateLoading,
        popupProfileDetails,
        setPopupProfileDetails,

        addNewEmployeeType,
        setAddNewEmployeeType,
        isProfileFound,
        setIsProfileFound,

        //hospitals page
        allHospitalDetails,
        setAllHospitalDetails,
        groupedItems,
        setGroupedItems,
        groupedItemsLoading,
        setGroupedItemsLoading,
        allHospitalDetail,
        setAllHospitalDetail,
        sortEmployer,

        dashboardToggleMain,
        setDashboardToggleMain,
        dashboardToggle,
        setDashboardToggle,
        designation,
        setDesignation,
        onLoginFormEmployer,
        setOnLoginFormEmployer,
        onLoginFormEmployerProfile,
        setOnLoginFormEmployerProfile,
        masterEmployeeID,
        setMasterEmployeeID,
        profileDetailsAssociatesClone,
        setProfileDetailsAssociatesClone,

        addDependentSidebar,
        setAddDependentSidebar,
        lastSelectedEmployee,
        setLastSelectedEmployee,
        reqValue,
        setreqValue,
        generatePDFData,
        setGeneratePDFData,
        allCartItems,
        setAllCartItems,
        allCartProducts,
        addToCart,

        //Imported from retired
        educationLeftSelected,
        seteducationLeftSelected,
        selectedPlaylistData,
        setSelectedPlaylistData,
        allPlaylistAvailable,
        setallPlaylistAvailable,

        //isscrolled
        isScrolled,
        setIsScrolled,
        loggedInUserId,
        setLoggedInUserId,
        loggedInUserRoleId,
        setLoggedInUserRoleId,

        getProfileDetails,
        policiesApiGlobal,
        setPoliciesApiGlobal,
        employeeVerified,
        setEmployeeVerified,
      }}
    >
      {children}
      {/* {videoPlaying ? (
        <div className="firstVideo">
          <img src={loadImg} alt="" />
        </div>
      ) : (
        ''
      )} */}
      {openModal ? (
        <ModalConfirm
          onClose={onClose}
          onConfirm={onConfirm}
          text={modalText}
          setOpenModal={setOpenModal}
        />
      ) : (
        ""
      )}
      <SelectCountry />
      {modalSessionExpOpen ? (
        <ModalSessionExpired
          onClose={() => {
            setModalSessionExpOpen(false);
          }}
        />
      ) : (
        ""
      )}
      {toastShow && (
        <ToastMessage textTwo={toastMessage} textOne={toastTitle} />
      )}
      {enterPin && (
        <EnterPinUnlock
          onSucces={() => {
            setAdmin(true);
            setEnterPin(false);
          }}
          onClose={() => setEnterPin(false)}
        />
      )}
    </BankContext.Provider>
  );
}

export default BankContextProvider;
