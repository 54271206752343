import React, { useContext, useEffect, useState } from "react";
import classNames from "../crm.module.scss";
import styles from "./qcr.module.scss";

import { IoMdClose, IoMdSearch } from "react-icons/io";

import arrowDown from "./asset/arrowDown.svg";
import calendarIcon from "./asset/calendar.svg";
import uploadIcon from "./asset/upload.svg";
import Axios from "axios";
import { uploadFileFunc, uploadImageFunc } from "../../../../utils/imageUpload";
import { BankContext } from "../../../../context/Context";

const UploadQCR = ({ showUploadQRC, setShowUploadQRC }) => {
  const { setUpdateLoading } = useContext(BankContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [allQuotes, setAllQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [qcrName, setQcrName] = useState("");
  const [qcrNotes, setQcrNotes] = useState("");
  const [uploadFileQCR, setUploadFileQCR] = useState("");
  const [uploadFileQCRName, setUploadFileQCRName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/policy/get/quote?email_id=${showUploadQRC?.lead_email}&next_Step=History`
    ).then(({ data }) => {
      console.log(data, "get quotes in Upload QCR agency/CRM");
      setAllQuotes(data.data);
    });
  }, [showUploadQRC]);

  const handleUploadQCR = () => {
    console.log(
      selectedQuote,
      qcrName,
      qcrNotes,
      uploadFileQCR,
      "============="
    );

    // if (selectedQuote && qcrName && qcrNotes && uploadFileQCR) {
    setLoading(true);
    Axios.post(
      `https://insurancecoverfi.apimachine.com/insurance/policy/create/rfq`,
      {
        quote_id: selectedQuote?.quote_id,
        rfq_pdf: uploadFileQCR,
        name: qcrName,
        notes: qcrNotes,
      }
    ).then(({ data }) => {
      if (data.status) {
        setShowUploadQRC(false);
        setLoading(false);
        // setQcrName("");
        // setQcrNotes("");
        // setUploadFileQCR("");
        // setUploadFileQCRName("");
      }
    });
    // }
  };

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          setShowUploadQRC(null);
          //   setShowQuoteDetails(null);
          //   setConfData(null);
          //   setSelectedQuoteTab("Overview");
          //   setSelectedConfTab("Health");
        }}
      ></div>
      <div className={classNames.quoteDetailsWrapper} style={{ width: "40vw" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setShowUploadQRC(null);
            // setConfData(null);
            // setSelectedQuoteTab("Overview");
            // setSelectedConfTab("Health");
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Upload QCR</div>
          {!showDropdown ? (
            <div
              className={styles.dropdown}
              onClick={(e) => setShowDropdown(!showDropdown)}
            >
              <div>
                {selectedQuote ? selectedQuote?.quote_id : "Select Quote"}
              </div>
              <img
                src={arrowDown}
                alt=""
                onClick={(e) => setShowDropdown(!showDropdown)}
              />
            </div>
          ) : (
            <div className={styles.dropdown1}>
              <div
                onClick={(e) => setShowDropdown(!showDropdown)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
              >
                <div>Select Quote</div>
                <img src={arrowDown} alt="" style={{ rotate: "180deg" }} />
              </div>
              <div style={{ paddingTop: "10px" }}>
                {Array.isArray(allQuotes) && allQuotes?.length > 0
                  ? allQuotes.map((item, index) => (
                      <div
                        className={styles.individualQuote}
                        onClick={(e) => {
                          setSelectedQuote(item);
                          setShowDropdown(false);
                        }}
                      >
                        <div>Quote {index + 1}</div>
                        <div>{item?.quote_id}</div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          )}
          <input
            value={qcrName}
            onChange={(e) => setQcrName(e.target.value)}
            type="text"
            className={styles.inputStyle}
            placeholder="Name the qcr"
          />
          <textarea
            value={qcrNotes}
            onChange={(e) => setQcrNotes(e.target.value)}
            className={styles.textareaStyle}
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Additional notes"
          />
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Upload policy document</div>
              <div className={styles.uploadContainer}>
                <label
                  className={classNames.uploadDocument}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  htmlFor="fileUploadQCR"
                >
                  <img src={uploadIcon} alt="" className={styles.upldImg} />
                  <div className={styles.upldTxt}>
                    {!loadingUpload
                      ? uploadFileQCRName?.name
                        ? uploadFileQCRName?.name
                        : "Upload File"
                      : "Uploading..."}
                  </div>
                  <input
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      let resFile = uploadFileFunc(
                        e,
                        setUploadFileQCRName,
                        setLoadingUpload,
                        "name"
                      );
                      console.log(await resFile, "res file link await");
                      setUploadFileQCR(await resFile);
                    }}
                    id="fileUploadQCR"
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            onClick={(e) => {
              if (selectedQuote && qcrName && qcrNotes && uploadFileQCR) {
                handleUploadQCR();
              }
            }}
            className={styles.addBtn}
            style={{
              opacity:
                selectedQuote && qcrName && qcrNotes && uploadFileQCR ? 1 : 0.5,
            }}
          >
            {loading ? "Adding QCR.." : "Add"}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadQCR;
