import Axios from "axios";
import {
  convertFamilyDefinitionValue,
  objectValidCheck,
  removeEmptyFields,
} from "../../../../assest/functions";
import classNames from "./popups.module.scss";
import { predefinedToast } from "../../../../utils/toast";
import { useContext, useEffect, useRef, useState } from "react";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
} from "../../../../components/Custom/Input";
import { BankContext } from "../../../../context/Context";
import CustomCheckbox from "../../../../components/Custom";
import { ReactComponent as DownloadIcon } from "../../../../assest/images/employer/members/download.svg";
import uploadFileIcon from "../../../../assest/images/icons/uploadfileIcon.svg";
import { ValidateSpreadsheetComponent } from "../../../Employer/Overview/tabs";

export const MembersFormsEnrollmentForm = ({
  setState,
  enrollmentFormSingle,
  setEnrollmentFormSingle,
  selectedTypesPreEnrollmentSingle: selectedTypes,
  setSelectedTypesPreEnrollmentSingle: setSelectedTypes,
  latestSelectedTypes,
  setLatestSelectedTypes,
  selectedHrId,
}) => {
  const { globalSelectedCheckbox } = useContext(BankContext);

  const [enrollmentFormSingleTemp, setEnrollmentFormSingleTemp] = useState([]);
  const [healthDropdownItems, setHealthDropdownItems] = useState([]);
  const [accidentDropdownItems, setAccidentDropdownItems] = useState([]);
  const [lifeDropdownItems, setLifeDropdownItems] = useState([]);

  const uppercaseLetters = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index)
  );

  //functions

  function removeUnused() {
    let finalObj = enrollmentFormSingle;
    if (!selectedTypes?.includes("personalHealthInsurance")) {
      const { gmc_grade, gmc_si, ...rest } = finalObj;
      finalObj = { ...rest };
    }

    if (!selectedTypes?.includes("personalAccidentInsurance")) {
      const { gpa_grade, gpa_si, ...rest } = finalObj;
      finalObj = { ...rest };
    }

    if (!selectedTypes?.includes("personalLifeInsurance")) {
      const { gtli_grade, gtli_si, ...rest } = finalObj;
      finalObj = { ...rest };
    }
    setEnrollmentFormSingle(finalObj);
  }

  const isMounted = useRef(false);
  const isMounted1 = useRef(false);

  useEffect(() => {
    // This block will run only when the component mounts
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    // This block will run when selectedTypes state changes
    if (selectedTypes?.length > 0) {
      setLatestSelectedTypes(selectedTypes[selectedTypes?.length - 1]);
    }
  }, [selectedTypes]);

  useEffect(() => {
    // This block will run only when the component mounts
    if (!isMounted1.current) {
      isMounted1.current = true;
      return;
    }

    if (latestSelectedTypes === "personalHealthInsurance") {
      console.log("Coming inside health");
      //for health
      if (latestSelectedTypes !== globalSelectedCheckbox) {
        setEnrollmentFormSingle((prev) => {
          let removeEmpty = removeEmptyFields(prev);
          return { ...removeEmpty };
        });
      } else if (selectedTypes?.includes("personalHealthInsurance")) {
        setEnrollmentFormSingle((prev) => {
          return { ...prev, gmc_grade: "", gmc_si: "" };
        });
      } else {
        setEnrollmentFormSingle((prev) => {
          const { gmc_grade, gmc_si, ...rest } = prev;
          return { ...rest };
        });
      }
    } else if (latestSelectedTypes === "personalAccidentInsurance") {
      console.log("Coming inside accident");
      //for accident
      if (latestSelectedTypes !== globalSelectedCheckbox) {
        setEnrollmentFormSingle((prev) => {
          let removeEmpty = removeEmptyFields(prev);
          return { ...removeEmpty };
        });
      } else if (selectedTypes?.includes("personalAccidentInsurance")) {
        setEnrollmentFormSingle((prev) => {
          return { ...prev, gpa_grade: "", gpa_si: "" };
        });
      } else {
        setEnrollmentFormSingle((prev) => {
          const { gpa_grade, gpa_si, ...rest } = prev;
          return { ...rest };
        });
      }
    } else if (latestSelectedTypes === "personalLifeInsurance") {
      console.log("Coming inside life");
      //for life
      if (latestSelectedTypes !== globalSelectedCheckbox) {
        setEnrollmentFormSingle((prev) => {
          let removeEmpty = removeEmptyFields(prev);
          return { ...removeEmpty };
        });
      } else if (selectedTypes?.includes("personalLifeInsurance")) {
        setEnrollmentFormSingle((prev) => {
          return { ...prev, gtli_grade: "", gtli_si: "" };
        });
      } else {
        setEnrollmentFormSingle((prev) => {
          const { gtli_grade, gtli_si, ...rest } = prev;
          return { ...rest };
        });
      }
    }

    //testing data
  }, [latestSelectedTypes]);

  //get grade dropdown values

  async function getGradeDropdownValues(policyType, setState) {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy/grade-details/?hr_id=${selectedHrId}&policy_type=${policyType}`
      );
      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setState(response?.data?.data);
      }
      console.log(response, `getGradeDropdownValues response ${policyType}`);
    } catch (error) {
      console.log(error?.message, "Get grade dropdown error");
    }
  }

  useEffect(() => {
    getGradeDropdownValues("Health", setHealthDropdownItems);
    getGradeDropdownValues("Accidental", setAccidentDropdownItems);
    getGradeDropdownValues("Life", setLifeDropdownItems);
  }, []);

  useEffect(() => {
    console.log(
      enrollmentFormSingleTemp,
      "rendering update",
      enrollmentFormSingle
    );
    if (enrollmentFormSingleTemp?.health) {
      setEnrollmentFormSingle((prev) => {
        return {
          ...prev,
          gmc_grade: enrollmentFormSingleTemp?.health?.grade,
          gmc_si: enrollmentFormSingleTemp?.health?.sum_insured,
        };
      });
    }
    if (enrollmentFormSingleTemp?.acident) {
      setEnrollmentFormSingle((prev) => {
        return {
          ...prev,
          gpa_grade: enrollmentFormSingleTemp?.acident?.grade,
          gpa_si: enrollmentFormSingleTemp?.acident?.sum_insured,
        };
      });
    }
    if (enrollmentFormSingleTemp?.life) {
      setEnrollmentFormSingle((prev) => {
        return {
          ...prev,
          gtli_grade: enrollmentFormSingleTemp?.life?.grade,
          gtli_si: enrollmentFormSingleTemp?.life?.sum_insured,
        };
      });
    }
  }, [
    enrollmentFormSingleTemp?.health,
    enrollmentFormSingleTemp?.acident,
    enrollmentFormSingleTemp?.life,
  ]);

  return (
    <div className={classNames.membersForms}>
      <div className={classNames.title}>
        Select Insurance company and Sum insured
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalHealthInsurance"}
            name={"personalHealthInsurance name"}
            type="array"
          />
        </div>
        <div>Group Health Insurance</div>
      </div>
      {selectedTypes?.includes("personalHealthInsurance") && (
        <div className={classNames.gradeSubSelections}>
          <div className={classNames.dropdownWithTitle}>
            <div>Select grade</div>
            <EachCustomDropdown
              // dropdown={["Flat", ...uppercaseLetters]}
              dropdown={healthDropdownItems}
              name="health"
              placeholder={
                healthDropdownItems?.length > 0
                  ? "Click to select"
                  : "No Policies"
              }
              stateValue={enrollmentFormSingleTemp}
              setState={setEnrollmentFormSingleTemp}
              typee="objVal"
              option={"checkValue"}
              objName={"grade"}
              mapName={["policy_number", "grade", "sum_insured"]}
            />
          </div>
          <div style={{ pointerEvents: "none" }}>
            <EachCustomInput
              title="Enter sum insured"
              placeholder="0.00"
              name="gmc_si"
              stateValue={enrollmentFormSingle}
              setState={setEnrollmentFormSingle}
            />
          </div>
        </div>
      )}
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentInsurance"}
            name={"personalAccidentInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
        {/* <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div> */}
      </div>
      {selectedTypes?.includes("personalAccidentInsurance") && (
        <div className={classNames.gradeSubSelections}>
          <div className={classNames.dropdownWithTitle}>
            <div>Select grade</div>
            <EachCustomDropdown
              // dropdown={["Flat", ...uppercaseLetters]}
              placeholder={
                accidentDropdownItems?.length > 0
                  ? "Click to select"
                  : "No Policies"
              }
              name="accident"
              stateValue={enrollmentFormSingleTemp}
              setState={setEnrollmentFormSingleTemp}
              typee="objVal"
              option={"checkValue"}
              objName={"grade"}
              mapName={["policy_number", "grade", "sum_insured"]}
            />
          </div>
          <div style={{ pointerEvents: "none" }}>
            <EachCustomInput
              title="Enter sum insured"
              placeholder="0.00"
              name="gpa_si"
              stateValue={enrollmentFormSingle}
              setState={setEnrollmentFormSingle}
            />
          </div>
        </div>
      )}
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalLifeInsurance"}
            name={"personalLifeInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Life Insurance</div>
      </div>
      {selectedTypes?.includes("personalLifeInsurance") && (
        <div className={classNames.gradeSubSelections}>
          <div className={classNames.dropdownWithTitle}>
            <div>Select grade</div>
            <EachCustomDropdown
              // dropdown={["Flat", ...uppercaseLetters]}
              dropdown={lifeDropdownItems}
              placeholder={
                lifeDropdownItems?.length > 0
                  ? "Click to select"
                  : "No Policies"
              }
              name="life"
              stateValue={enrollmentFormSingleTemp}
              setState={setEnrollmentFormSingleTemp}
              typee="objVal"
              option={"checkValue"}
              objName={"grade"}
              mapName={["policy_number", "grade", "sum_insured"]}
            />
          </div>
          <div style={{ pointerEvents: "none" }}>
            <EachCustomInput
              title="Enter sum insured"
              placeholder="0.00"
              name="gtli_si"
              stateValue={enrollmentFormSingle}
              setState={setEnrollmentFormSingle}
            />
          </div>
        </div>
      )}
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button
          style={{
            pointerEvents:
              selectedTypes?.length > 0 &&
              objectValidCheck(enrollmentFormSingle)
                ? ""
                : "none",
            opacity:
              selectedTypes?.length > 0 &&
              objectValidCheck(enrollmentFormSingle)
                ? ""
                : "0.5",
          }}
          onClick={() => {
            removeUnused();
            setState("singleentrymemberdetailsenrollmentform");
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const MembersDetailsFormEnrollmentForm = ({
  setState,
  enrollmentFormSingle,
  setEnrollmentFormSingle,
  selectedHrId,
}) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  return (
    <div className={classNames.membersDetailsForm}>
      <div className={classNames.title}>Add Member Details</div>
      <div className={classNames.formFields}>
        <EachCustomInput
          placeholder="Employee Name"
          name="employee_name"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          validation={{ type: "name_validation" }}
        />
        <EachCustomInput
          placeholder="Employee ID"
          name="company_employee_id"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
        />
        <EachCustomDatePicker
          //   title="Policy valid until"
          placeholder="Date of Birth"
          // dropdown={usersDropdown}
          name="date_of_birth"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          typee={"spreadObj"}
          validation={{ type: "relationship", relationship: "self" }}
          //   mandatory={clientOnboardingForm ? true : false}
        />
        <EachCustomDropdown
          buttonText={enrollmentFormSingle ? enrollmentFormSingle : "Sum"}
          // color="var(--main-color)"
          dropdown={["Male", "Female"]}
          name="gender"
          placeholder="Gender"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          typee="single"
          option={"checkValue"}
        />

        <EachCustomInput
          placeholder="Email ID"
          name="email_id"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          validation={{ type: "email" }}
        />
        <EachCustomInput
          placeholder="Designation"
          name="designation"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
        />

        <EachCustomInput
          placeholder="Phone number"
          name="phone_number"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          validation={{ type: "phone_validation" }}
        />
        <EachCustomDropdown
          dropdown={[
            "Employee only",
            "Employee plus spouse",
            "Employee plus spouse, 2 children up to 25 years",
            "Employee plus spouse, 2 Children up to 25 years, Parents OR Parent-in-Law to 80 years",
            "Employee plus spouse, 2 Children up to 25 years, Parents & Parent-in-Law to 80 years",
          ]}
          name="family_definition"
          placeholder="Select family definition"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          typee="single"
          option={"checkValue"}
        />
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("singleentryenrollmentform")}>
          Back
        </button>
        <button
          onClick={() => {
            console.log(enrollmentFormSingle, "enrollmentFormSingle filled");
            setState("singleentrymemberreviewenrollmentform");
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const MembersReviewDetailsEnrollmentForm = ({
  setState,
  enrollmentFormSingle,
  setEnrollmentFormSingle,
  selectedHrId,
}) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  const [memberDetailsFormLoading, setMemberDetailsFormLoading] =
    useState(false);

  async function invitePreEnrollmentSingle() {
    setMemberDetailsFormLoading(true);
    enrollmentFormSingle.family_definition = convertFamilyDefinitionValue(
      enrollmentFormSingle.family_definition
    );
    let obj = {
      hr_id: selectedHrId,
      employee_data: [enrollmentFormSingle],
    };

    try {
      let response = await Axios.post(
        "https://coverfi.apimachine.com/api/v1//employee-spreadsheet/upload-by-api",
        obj
      );
      setMemberDetailsFormLoading(false);
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        setState("");
      } else {
        predefinedToast("Employee adding failed");
        setState("");
      }
      console.log(response, "invitePreEnrollmentSingle success");
    } catch (error) {
      setMemberDetailsFormLoading(false);
      predefinedToast(error?.message);
      console.log(error?.message, "invitePreEnrollmentSingle failed");
    }
  }

  return (
    <div className={classNames.membersDetailsForm}>
      {memberDetailsFormLoading && (
        <div className={classNames.loadingImage}>
          <img
            src="https://talltree.coverfi.app/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
            alt="loadingImage"
          />
        </div>
      )}
      <div className={classNames.title}>Review employee details</div>
      <div className={classNames.subTitle}>Insurance details</div>
      {enrollmentFormSingle?.gmc_grade && (
        <div className={classNames.detailsContainer}>
          <div>
            <div>
              Group Health insurance Policy{" "}
              {enrollmentFormSingle?.gmc_grade === "Flat"
                ? "(Flat)"
                : `(Grade ${enrollmentFormSingle?.gmc_grade})`}
            </div>
            <div>USD {enrollmentFormSingle?.gmc_si}</div>
          </div>
          <div></div>
        </div>
      )}
      {enrollmentFormSingle?.gpa_grade && (
        <div className={classNames.detailsContainer}>
          <div>
            <div>
              Group Accidental insurance Policy{" "}
              {enrollmentFormSingle?.gpa_grade === "Flat"
                ? "(Flat)"
                : `(Grade ${enrollmentFormSingle?.gpa_grade})`}
            </div>
            <div>USD {enrollmentFormSingle?.gpa_si}</div>
          </div>
        </div>
      )}
      {enrollmentFormSingle?.gtli_grade && (
        <div className={classNames.detailsContainer}>
          <div>
            <div>
              Group Life insurance Policy{" "}
              {enrollmentFormSingle?.gtli_grade === "Flat"
                ? "(Flat)"
                : `(Grade ${enrollmentFormSingle?.gtli_grade})`}
            </div>
            <div>USD {enrollmentFormSingle?.gtli_si}</div>
          </div>
        </div>
      )}
      <div className={classNames.subTitle}>Member details</div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>{enrollmentFormSingle?.employee_name}</div>
          <div>{enrollmentFormSingle?.family_definition}</div>
        </div>
        {/* <div>Employee</div> */}
      </div>

      <div className={classNames.messageContainer}>
        Sending invitation to {enrollmentFormSingle?.email_id}
      </div>

      <div className={classNames.btnsContainer}>
        <button
          onClick={() => setState("singleentrymemberdetailsenrollmentform")}
        >
          Back
        </button>
        <button
          onClick={() => {
            invitePreEnrollmentSingle();
            // setState("MembersReviewCompleted");
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const BulkEntryEnrollmentForm = ({ setState, selectedHrId }) => {
  const [uploadedSheetValidationErrors, setUploadedSheetValidationErrors] =
    useState("");
  const [quoteReviewForm, setQuoteReviewForm] = useState("");
  const [downloadSpreadsheet, setDownloadSpreadsheet] = useState("");
  const [fileName, setFileName] = useState("");
  const [memberDetailsFormLoading, setMemberDetailsFormLoading] = useState("");

  //functions

  async function fetchDownloadLinks() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/employee-spreadsheet`
      );
      if (
        response?.data?.data?.length > 0 &&
        Array.isArray(response?.data?.data)
      ) {
        setDownloadSpreadsheet(response?.data?.data[0]);
      }
      console.log(response, "fetch spreadsheet");
    } catch (error) {
      console.log(error?.message, "fetch spreadsheet error");
    }
  }

  async function getDataFillsAndValidate(document) {
    try {
      let url =
        quoteReviewForm?.enrollmentType === "Employee validates the data"
          ? "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate-with-dependents"
          : `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate`;

      const formData = new FormData();
      formData.append("file", document);

      let response = await Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (
        Array?.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setUploadedSheetValidationErrors(response?.data?.data[0]);
      }

      console.log(response?.data?.data, "getDataFills response");
    } catch (error) {
      console.log(error?.message, "Employee fills data error");
    }
  }

  function uploadEnrollmentViaPreEnrollment() {
    let url;

    if (quoteReviewForm?.enrollmentType === "Employee validates the data") {
      url =
        "https://coverfi.apimachine.com/api/v1//employee-spreadsheet/upload-with-dependents";
    } else {
      url =
        "https://coverfi.apimachine.com/api/v1//employee-spreadsheet/upload";
    }

    setMemberDetailsFormLoading(true);
    const formData = new FormData();
    formData.append("hr_id", selectedHrId);
    formData.append("file", quoteReviewForm?.document);

    Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response, "Uploaded endorsements via pre enrolment bulk");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setMemberDetailsFormLoading(false);
          setState("");
        } else {
          setMemberDetailsFormLoading(false);
          predefinedToast(response?.data?.message);
          setState("");
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded endorsements via pre enrolment error"
        );
        // setState("PreEnrollmentDownloads");
        setMemberDetailsFormLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  //rendering

  useEffect(() => {
    fetchDownloadLinks();
  }, []);

  useEffect(() => {
    if (quoteReviewForm?.document) {
      setQuoteReviewForm((prev) => {
        return { ...prev, document: "" };
      });
      setUploadedSheetValidationErrors("");
      setFileName("");
    }
  }, [quoteReviewForm?.enrollmentType]);

  return (
    <div className={classNames.bulkUpload}>
      {memberDetailsFormLoading && (
        <div className={classNames.loadingImage}>
          <img
            src="https://talltree.coverfi.app/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
            alt="loadingImage"
          />
        </div>
      )}
      <div className={classNames.title}>Upload communication info</div>
      <div className={classNames.fieldsContainer}>
        <div className={classNames.singleInput}>
          <EachCustomDropdown
            title="Select type of enrolment"
            dropdown={[
              "Employee fills the data",
              "Employee validates the data",
            ]}
            name="enrollmentType"
            stateValue={quoteReviewForm}
            setState={setQuoteReviewForm}
            typee="single"
          />
        </div>
      </div>
      <div className={classNames.bulkDocuments}>
        <div
          onClick={() => {
            // window.open(
            //   "https://insurancecoverfi.apimachine.com/insurance/general/files/fb0947f0-8f25-4118-8c86-62aff6c57a98.xlsx",
            //   "_blank"
            // );
            if (
              quoteReviewForm?.enrollmentType === "Employee fills the data" &&
              downloadSpreadsheet?.employee_pre_enrollment
            ) {
              window.open(
                downloadSpreadsheet?.employee_pre_enrollment,
                "_blank"
              );
            } else if (
              quoteReviewForm?.enrollmentType ===
                "Employee validates the data" &&
              downloadSpreadsheet?.employee_and_dependent_pre_enrollment
            ) {
              window.open(
                downloadSpreadsheet?.employee_and_dependent_pre_enrollment,
                "_blank"
              );
            }
          }}
        >
          <DownloadIcon />
          <span>Download Template</span>
        </div>

        <div
          style={{
            pointerEvents: quoteReviewForm?.enrollmentType ? "" : "none",
          }}
        >
          <label
            htmlFor={"profileUpdateImgPlanBdocument"}
            className={classNames.uploadFileDiv}
            style={{ maxWidth: "100%" }}
          >
            <img src={uploadFileIcon} alt="uploadFileIcon" />
            <div
              style={{
                paddingTop: "1rem",
                textOverflow: "ellipse",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {fileName ? fileName : "Upload File"}
            </div>
            <div className={classNames.uploadFile}>
              <input
                className={classNames.uploadNewPicPlanB}
                type="file"
                style={{ display: "none" }}
                onChange={(event) => {
                  console.log(event?.target?.files, "files list");
                  if (event?.target?.files?.length > 0) {
                    setFileName(event?.target?.files[0]?.name);
                    setQuoteReviewForm({
                      ...quoteReviewForm,
                      document: event?.target?.files[0],
                    });
                    if (
                      quoteReviewForm?.enrollmentType ===
                      "Employee fills the data"
                    ) {
                      getDataFillsAndValidate(event?.target?.files[0]);
                    } else if (
                      quoteReviewForm?.enrollmentType ===
                      "Employee validates the data"
                    ) {
                      getDataFillsAndValidate(event?.target?.files[0]);
                    }
                  }
                }}
                accept="*/*"
                id={"profileUpdateImgPlanBdocument"}
              />
            </div>
          </label>
        </div>
      </div>
      {uploadedSheetValidationErrors && (
        <ValidateSpreadsheetComponent data={uploadedSheetValidationErrors} />
      )}
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button
          style={{
            pointerEvents:
              quoteReviewForm?.enrollmentType && quoteReviewForm?.document
                ? ""
                : "none",
            opacity:
              quoteReviewForm?.enrollmentType && quoteReviewForm?.document
                ? ""
                : "0.5",
          }}
          onClick={uploadEnrollmentViaPreEnrollment}
        >
          Next
        </button>
      </div>
    </div>
  );
};
