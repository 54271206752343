import React, { useContext, useEffect, useState, useRef } from "react";
import classNames from "../../../../../pages/Agency/Dashboard/dashboard.module.scss";
import styles from "./ap.module.scss";

import { IoMdClose, IoMdCloseCircleOutline, IoMdSearch } from "react-icons/io";
import Axios from "axios";
import { BankContext } from "../../../../../context/Context";

const FundWallet = ({ showFundPopup, setShowFundPopup, initiatedById }) => {
  const { setUpdateLoading, globalHrId, email } = useContext(BankContext);
  const [showAllRoleOwner, setShowAllRoleOwner] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [notes, setNotes] = useState("");

  const handleFundWallet = () => {
    Axios.post(
      `https://coverfi.apimachine.com/wallet/api/v1/paymentGateway/initialize-payment`,
      {
        owner_id: showFundPopup,
        amount: Number(amount),
        user_email: email,
        user_mobile_number: paymentMethod,
        initiated_by_id: initiatedById,
        initiation_reason: notes,
      }
    ).then(({ data }) => {
      if (data.status) {
        window.open(data.data.redirectUrl, "_blank");
        setShowFundPopup(null);
      }
    });
  };

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          setShowFundPopup(null);
        }}
      ></div>
      <div
        className={styles.quoteDetailsWrapper}
        style={{ width: "464px", height: "auto", left: "20vw" }}
      >
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setShowFundPopup(null);
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>
            Fund corporate wellness wallet
          </div>
          <div className={styles.rowWrapper1}>
            <div>How much do you want to add?</div>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="0.00"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div>Payment method</div>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="Enter UPI registered phone number"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div>Notes</div>
            <div className={styles.inputGroup}>
              <textarea
                rows="5"
                type="text"
                placeholder="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>

          {/* Terms */}

          <div className={styles.rowWrapper1} style={{ width: "100%" }}>
            <div
              onClick={(e) => {
                if (amount && paymentMethod) {
                  handleFundWallet();
                }
              }}
              className={styles.addBtn}
              style={{
                opacity: amount && paymentMethod ? 1 : 0.5,
                width: "100%",
              }}
            >
              {loading ? "Loading.." : "Initiate"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundWallet;
