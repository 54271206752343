import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./employeedependents.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV3 from "../../../components/NavbarV3";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ReactComponent as FilterIcon } from "../../../assest/images/allicons/filter.svg";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
} from "../../../components/Custom/Input";
import { predefinedToast } from "../../../utils/toast";
import { logoutToSameLogin } from "../../../assest/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EmployeeDependents = () => {
  const history = useHistory();
  const {
    email,
    actionBtns,
    setActionBtns,
    addDependentSidebar,
    setAddDependentSidebar,
  } = useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("Insurance");
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [allSelectedPolicyClaims, setSelectedPolicyClaims] = useState([]);
  const [allSelectedPolicyClaimsLoading, setSelectedPolicyClaimsLoading] =
    useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [popupContainer, setPopupContainer] = useState("");
  const [refreshPageLocal, setRefreshPageLocal] = useState(false);

  const filterDropdownRef = useRef(null);
  const filterItems = [
    "Active",
    "Invitation Send",
    "Invitation Expired",
    "Pending",
    "Disabled",
    "Deleted",
  ];

  useEffect(() => {
    setSelectedPolicyClaims([]);
    setSelectedPolicyClaimsLoading(true);

    if (email) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${email}`
      )
        .then((response) => {
          console.log(response, "all dependents response");
          if (response?.data?.data.length > 0) {
            setSelectedPolicyClaims(response?.data?.data);
          }
          setSelectedPolicyClaimsLoading(false);
        })
        .catch((error) => {
          console.log(
            error?.message,
            "Get all claims for selected policy error"
          );
          setSelectedPolicyClaimsLoading(false);
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }, [email, refreshPageLocal]);

  const [openNotification, setOpenNotification] = useState(false);

  const notificationRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div
      className={classNames.profile}
      onClick={(event) => {
        if (
          addDependentSidebar &&
          !event.target.closest("#addDependentSidebar")
        ) {
          console.log(event.target, ": clicked id");
          setAddDependentSidebar(false);
        }
      }}
    >
      <NavbarV3
        dropdownItems={["Insurance"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />
      <div className={classNames.topMenuOptions}>
        <div className={classNames.filterDiv}>
          {/* <div className={classNames.filterBtn} ref={filterDropdownRef}>
            <FilterIcon
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            />
            {filterOpen && (
              <div className={classNames.dropdownContent}>
                {filterItems?.map((eachItem, index) => {
                  return (
                    <div
                      className={classNames.eachFilterItem}
                      key={eachItem + index}
                    >
                      {eachItem}
                    </div>
                  );
                })}
              </div>
            )}
          </div> */}
          <div>
            <input
              type="text"
              name=""
              placeholder="Search"
              onChange={(event) => {
                setSearchQuery(event?.target?.value);
              }}
            />
          </div>
        </div>
        <div className={classNames.buttonsDiv}>
          {/* <div className={classNames.normalBtn}>Download This List</div> */}
          {/* <div className={classNames.coloredContainer}>
            <div
              className={classNames.coloredBtn}
              onClick={() => {
                setPopupContainer("addDependents");
              }}
            >
              Add Dependent
            </div>
            {popupContainer === "addDependents" ? (
              <AddDependentsSidebar
                from="dependent_user_company_email"
                employeeName="testuser"
                setPopupContainer={setPopupContainer}
                employeeEmail={email}
                setRefreshPageLocal={setRefreshPageLocal}
              />
            ) : (
              ""
            )}
          </div> */}
        </div>
      </div>
      <div className={classNames.proactiveDetails}>
        <div className={classNames.allLeads}>
          <TableEmployeesSimplified
            allLeads={allSelectedPolicyClaims}
            allBrokeragesSearch={searchQuery}
            actionBtns={actionBtns}
            setActionBtns={setActionBtns}
            allLeadsLoading={allSelectedPolicyClaimsLoading}
          />
        </div>
        {/* <div className={classNames.paginationBar}>
          <div className={classNames.paginationMsg}>
            Showing {allSelectedPolicyClaims?.length} entries
          </div>
          <div className={classNames.allPagination}>
            {currentPage == 1
              ? Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index);
                      }}
                    >
                      {currentPage + index}
                    </div>
                  );
                })
              : Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index - 1 == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index - 1);
                      }}
                    >
                      {currentPage + index - 1}
                    </div>
                  );
                })}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EmployeeDependents;

const TableEmployeesSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}>
            <th></th>
            <th>Name</th>
            <th>Relation</th>
            <th>Date of birth</th>
            <th>Gender</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
            >
              <td>
                <BsThreeDotsVertical />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                >
                  <td>
                    {actionBtns?.includes(row?._id) ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActionBtns([]);
                        }}
                      >
                        X
                      </span>
                    ) : (
                      <BsThreeDotsVertical
                        onClick={() => {
                          if (actionBtns?.includes(row?._id)) {
                            setActionBtns([]);
                          } else {
                            setActionBtns([row?._id]);
                          }
                        }}
                      />
                    )}
                  </td>
                  <td>{row?.dependent_name ? row?.dependent_name : ""}</td>
                  <td>{row?.relationship ? row?.relationship : ""}</td>
                  <td>{row?.date_of_birth ? row?.date_of_birth : "-"}</td>
                  <td>{row?.gender ? row?.gender : "-"}</td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const AddDependentsSidebar = ({
  employeeName,
  employeeEmail,
  from,
  setPopupContainer,
  setRefreshPageLocal,
}) => {
  const {
    email,
    addDependentSidebar: isOpen,
    setAddDependentSidebar: setIsOpen,
    setUpdateLoading,
    lastSelectedEmployee,
    setActionBtns,
  } = useContext(BankContext);
  const [dobDate, setDobDate] = useState("");
  const [isProfileFound, setIsProfileFound] = useState("");
  const [dependentsDetails, setDependentsDetails] = useState({
    dependent_user_company_email: email,
    dependent_name: "",
    dependent_email: "",
    relationship: "",
    gender: "",
    date_of_birth: "",
    primary_phone_number: "",
  });

  function addDependent() {
    setUpdateLoading(true);

    if (from == "dependent_user_company_email") {
      dependentsDetails.dependent_user_company_email = employeeEmail;
    }
    console.log(dependentsDetails, "add dependent dependentsDetails");

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/dependent/create",
      dependentsDetails
    )
      .then((response) => {
        if (response?.data?.data?.status) {
          setIsOpen(false);
          setPopupContainer("");
          setActionBtns([]);
          predefinedToast(`Added new dependent for ${employeeName}`);
          setRefreshPageLocal((prev) => !prev);
        }
        // console.log(response?.data?.data?.status, "add dependent response");
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Add dependent error");
        setUpdateLoading(false);
        predefinedToast("Error while adding new dependent");
      });
  }

  async function getGlobalAgentID() {
    let employeeDetails = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
    );

    if (
      employeeDetails?.data?.data?.employeeDetails?.length > 0 &&
      employeeDetails?.data?.data?.employeeDetails[0]
    ) {
      setIsProfileFound(employeeDetails?.data?.data?.employeeDetails[0]);
      console.log(
        employeeDetails?.data?.data?.employeeDetails[0],
        "employee details"
      );
    }
  }

  useEffect(() => {
    getGlobalAgentID();
  }, []);

  return (
    <div className={classNames.addDependents} id="addDependentSidebar">
      <div className={classNames.title}>
        Add dependent
        <span onClick={() => setPopupContainer("")}>X</span>
      </div>
      <div className={classNames.allInputsParent}>
        <EachCustomDropdown
          // dropdown={
          //   isProfileFound?.family_definition == "Employee + Spouse"
          //     ? ["Spouse"]
          //     : isProfileFound?.family_definition ==
          //       "Employee + Spouse + 2 Children"
          //     ? ["Spouse", "Son", "Daughter"]
          //     : isProfileFound?.family_definition ==
          //       "Employee + Spouse + 2 Children + 2 Parents"
          //     ? ["Spouse", "Son", "Daughter", "Father", "Mother"]
          //     : isProfileFound?.family_definition ==
          //       "Employee + Spouse + 2 Children + 2 Parents"
          //     ? [
          //         "Spouse",
          //         "Son",
          //         "Daughter",
          //         "Father",
          //         "Mother",
          //         "Father-in-Law",
          //         "Mother-in-Law",
          //       ]
          //     : []
          // }
          dropdown={[
            "Spouse",
            "Son",
            "Daughter",
            "Father",
            "Mother",
            "Father-in-Law",
            "Mother-in-Law",
          ]}
          title="What is your relation to the dependent?"
          name="relationship"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          typee="single"
        />
        <EachCustomInput
          placeholder="Name.."
          name="dependent_name"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />
        <div className={classNames.twoInputContainer}>
          <EachCustomDropdown
            name="gender"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            dropdown={["Male", "Female"]}
            typee="single"
          />
          <EachCustomDatePicker
            placeholder="Select date of birth"
            name="date_of_birth"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="spreadObj"
            values={{ state: dependentsDetails }}
            validation={{ type: "relationship" }}
          />
        </div>
        <EachCustomInput
          placeholder="Enter number..."
          name="primary_phone_number"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          validation={{ type: "phone_validation" }}
        />
        <EachCustomInput
          placeholder="Enter email..."
          name="dependent_email"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          validation={{ type: "email" }}
        />
      </div>
      <div className={classNames.submitButton} onClick={addDependent}>
        Add
      </div>
    </div>
  );
};
