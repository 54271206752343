import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./rahastore.module.scss";
import { BankContext } from "../../../context/Context";

import storeBanner from "../../../assest/images/rahax/storebanner.svg";
import wellnessBanner from "../../../assest/images/rahax/wellnessbanner.svg";
import Axios from "axios";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import cultfitLogo from "../../../assest/images/coverfi/proactive/cultfit.svg";
import dialLogo from "../../../assest/images/coverfi/proactive/dial.svg";
import callhealthLogo from "../../../assest/images/coverfi/proactive/callhealth.svg";
import cultCover from "../../../assest/images/coverfi/proactive/cover/cultCover.svg";
import { ReactComponent as AmbulanceIcon } from "../../../assest/images/coverfi/proactive/icons/ambulance.svg";
import { ReactComponent as CheckboxIcon } from "../../../assest/images/coverfi/proactive/icons/checkbox.svg";
import removeItem from "../../../assest/images/icons/removeitem.svg";

import Discover, {
  AllCartItems,
  AllConsultations,
  AllServices,
  AllSubCategory,
} from "./Tabs/Discover";
import NavbarV3 from "../../../components/NavbarV3";
import WalletComponent from "./WalletComponent";
import Orders from "./Tabs/Orders";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutToSameLogin } from "../../../assest/functions";

const EmployeeWellness = () => {
  const history = useHistory();
  const { email, allCartItems } = useContext(BankContext);

  const allItem = [
    { name: "Cult", image: cultfitLogo },
    { name: "Dial 4242", image: dialLogo },
  ];

  const [selectedProfileNav, setSelectedProfileNav] = useState("Discover");
  const [selectedDiscoverSection, setSelectedDiscoverSection] =
    useState("Wellness");
  const [selectedMainTest, setSelectedMainTest] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [allRahaStoreSearch, setAllRahaStoreSearch] = useState("");
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] =
    useState("Physical Wellness");
  const [allInsuranceCompanies, setAllInsuranceCompanies] = useState("");
  const [allInsuranceCompaniesLoading, setAllInsuranceCompaniesLoading] =
    useState("");

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    setAllInsuranceCompaniesLoading(true);
    Axios.get(
      "https://insurancecoverfi.apimachine.com/insurance/insuranceCompany/get"
    )
      .then((response) => {
        // console.log(response, "all insurance companies response");
        if (response?.data?.status) {
          let res = response?.data?.data;
          setAllInsuranceCompanies(res);
          if (res?.length > 0 && selectedProfileNav == "Insurance") {
            setSelectedInsuranceCompany(res[0]?.displayName);
          }
          setAllInsuranceCompaniesLoading(false);
        }
      })
      .catch((error) => {
        console.log(error?.message, "all insurance companies error");
        setAllInsuranceCompaniesLoading(false);
      });
  }, [selectedProfileNav]);

  function onCloseSidebar() {
    if (sidebarOpen) {
      setSidebarOpen((prev) => !prev);
    }
  }

  const handleScrollButtonClick = () => {
    const scrollableDiv = document.getElementById("scrollableDiv");

    // Scroll the div back to the top
    scrollableDiv.scrollTop = 0;
  };

  //imported from claims

  const [agentInfo, setAgentInfo] = useState([]);
  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/get?user_email=${email}`
    )
      .then((response) => {
        if (response?.data?.status) {
          console.log("agentInfo", response?.data?.data);
          setAgentInfo(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setSelectedDiscoverSection("Wellness");
  }, [selectedProfileNav]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={["Wallet", "Discover", "Orders"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
        pageSpecificFunc={setSelectedDiscoverSection}
      />
      <div
        className={classNames.rahastoreDetails}
        id="scrollableDiv"
        style={{
          overflowY: sidebarOpen ? "hidden" : "",
          padding: selectedProfileNav == "Wallet" ? 0 : "",
        }}
      >
        {selectedDiscoverSection === "allcart" ||
        selectedDiscoverSection === "allcartmembers" ||
        selectedDiscoverSection === "allcartreview" ||
        selectedDiscoverSection === "allcartpayment" ? (
          <AllCartItems
            selectedDiscoverSection={selectedDiscoverSection}
            setSelectedDiscoverSection={setSelectedDiscoverSection}
            selectedMainTest={selectedMainTest}
            setSelectedMainTest={setSelectedMainTest}
          />
        ) : selectedDiscoverSection === "AllServices" ? (
          <AllServices
            setSelectedDiscoverSection={setSelectedDiscoverSection}
            selectedMainTest={selectedMainTest}
            setSelectedMainTest={setSelectedMainTest}
          />
        ) : selectedDiscoverSection === "SubCategory" ? (
          <AllSubCategory
            setSelectedDiscoverSection={setSelectedDiscoverSection}
            selectedMainTest={selectedMainTest}
            setSelectedMainTest={setSelectedMainTest}
          />
        ) : selectedDiscoverSection === "AllConsultations" ? (
          <AllConsultations
            setSelectedDiscoverSection={setSelectedDiscoverSection}
            selectedMainTest={selectedMainTest}
            setSelectedMainTest={setSelectedMainTest}
          />
        ) : selectedProfileNav == "Wellness" ? (
          <>
            <div className={classNames.mainFlex}>
              <img src={wellnessBanner} alt="wellnessBanner" />
            </div>
            <div className={classNames.insuranceCompanies}>
              <div className={classNames.title}>Wellness Baskets</div>
              <div className={classNames.allCompanies}>
                {allInsuranceCompaniesLoading ? (
                  <div>
                    <Skeleton style={{ height: "100%" }} />
                    <Skeleton style={{ height: "100%" }} />
                    <Skeleton style={{ height: "100%" }} />
                    <Skeleton style={{ height: "100%" }} />
                  </div>
                ) : (
                  <div>
                    <div
                      className={
                        selectedInsuranceCompany == "Physical Wellness"
                          ? classNames.selectedCompany
                          : ""
                      }
                      onClick={(event) => {
                        setSelectedInsuranceCompany(event?.target?.innerText);
                      }}
                    >
                      Physical Wellness
                    </div>
                    <div
                      className={
                        selectedInsuranceCompany == "Mental Wellness"
                          ? classNames.selectedCompany
                          : ""
                      }
                      onClick={(event) => {
                        setSelectedInsuranceCompany(event?.target?.innerText);
                      }}
                    >
                      Mental Wellness
                    </div>

                    <div
                      className={
                        selectedInsuranceCompany == " Health Assistance"
                          ? classNames.selectedCompany
                          : ""
                      }
                      onClick={(event) => {
                        setSelectedInsuranceCompany(event?.target?.innerText);
                      }}
                    >
                      Health Assistance
                    </div>
                    {/* <div
                      className={
                        selectedInsuranceCompany == "Health Assistance"
                          ? classNames.selectedCompany
                          : ""
                      }
                      onClick={(event) => {
                        setSelectedInsuranceCompany(event?.target?.innerText);
                      }}
                    >
                      Other
                    </div> */}
                  </div>
                )}
                {/* <div className={classNames.allCompaniesBtn}>See All</div> */}
              </div>
            </div>
            <div className={classNames.companyDetailed}>
              <div className={classNames.companyTitle}>
                {selectedInsuranceCompany ? selectedInsuranceCompany : ""} Add
                Ons In Tall Tree
              </div>
              <div
                className={classNames.allItems}
                style={{ pointerEvents: sidebarOpen ? "none" : "" }}
              >
                {allItem?.map(({ name, image }, index) => {
                  return (
                    <div
                      className={classNames.eachWellnessItem}
                      key={name}
                      // onClick={() => {
                      //   setSelectedItem(name);
                      // }}
                      style={{
                        display:
                          name == "Dial 4242" &&
                          selectedInsuranceCompany == "Health Assistance"
                            ? ""
                            : name == "Cult" &&
                              selectedInsuranceCompany == "Physical Wellness"
                            ? ""
                            : // : name == "CallHealth" &&
                              //   selectedInsuranceCompany == "Health Assistance"
                              // ? ""
                              // : name == "Amaha" &&
                              //   selectedInsuranceCompany == "Mental"
                              // ? ""
                              "none",
                      }}
                      onClick={() => {
                        if (
                          (name == "Dial 4242" &&
                            selectedInsuranceCompany == "Health Assistance") ||
                          (name == "Cult" &&
                            selectedInsuranceCompany == "Physical Wellness")
                        ) {
                          setSidebarOpen(name);
                          handleScrollButtonClick();
                        }
                      }}
                    >
                      <img src={image} alt={name} />
                      <div>{name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : selectedProfileNav == "Wallet" ? (
          <WalletComponent
            agentInfo={agentInfo}
            setAllRahaStoreSearch={setAllRahaStoreSearch}
            searchQuery={searchQuery}
          />
        ) : selectedProfileNav == "Discover" ? (
          <>
            <Discover
              setSelectedDiscoverSection={setSelectedDiscoverSection}
              setSelectedMainTest={setSelectedMainTest}
            />
          </>
        ) : selectedProfileNav == "Orders" ? (
          <>
            <Orders
              setSelectedDiscoverSection={setSelectedDiscoverSection}
              setSelectedMainTest={setSelectedMainTest}
            />
          </>
        ) : (
          <>
            <div className={classNames.mainFlex}>
              <img src={storeBanner} alt="storeBanner" />
            </div>
            <div className={classNames.insuranceCompanies}>
              <div className={classNames.title}>Insurance Companies</div>
              <div className={classNames.allCompanies}>
                {allInsuranceCompaniesLoading ? (
                  <div>
                    <Skeleton style={{ height: "100%" }} />
                    <Skeleton style={{ height: "100%" }} />
                    <Skeleton style={{ height: "100%" }} />
                    <Skeleton style={{ height: "100%" }} />
                  </div>
                ) : (
                  <div>
                    {allInsuranceCompanies?.length > 0 &&
                      allInsuranceCompanies
                        ?.filter((eachCompany) =>
                          eachCompany?.displayName
                            ?.toLowerCase()
                            ?.includes(allRahaStoreSearch?.toLowerCase())
                        )
                        ?.slice(0, 4)
                        ?.map((eachCompany) => {
                          return (
                            <div
                              className={
                                selectedInsuranceCompany ==
                                eachCompany?.displayName
                                  ? classNames.selectedCompany
                                  : ""
                              }
                              onClick={(event) => {
                                setSelectedInsuranceCompany(
                                  event?.target?.innerText
                                );
                              }}
                            >
                              {eachCompany?.displayName}
                            </div>
                          );
                        })}
                  </div>
                )}
                {/* <div className={classNames.allCompaniesBtn}>See All</div> */}
              </div>
            </div>
            <div className={classNames.companyDetailed}>
              <div className={classNames.companyTitle}>
                {selectedInsuranceCompany ? selectedInsuranceCompany : ""}
              </div>
            </div>
          </>
        )}
        <SidebarMenu
          position={sidebarOpen ? "0" : ""}
          onClose={onCloseSidebar}
          selected={sidebarOpen}
          setSelected={setSidebarOpen}
        />
      </div>
    </div>
  );
};

export default EmployeeWellness;

const SidebarMenu = ({ position, onClose, selected, setSelected }) => {
  const sidebarRef = useRef(null);

  const { allCartItems, setAllCartItems, allCartProducts } =
    useContext(BankContext);

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedItem, setSelectedItem] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  const handleScrollButtonClick = () => {
    const scrollableDiv = document.getElementById("scrollableDiv");

    // Scroll the div back to the top
    scrollableDiv.scrollTop = 0;

    // Toggle the scroll lock state
    setScrollLocked(!scrollLocked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const allItem = [
    { name: "Cult", image: cultfitLogo },
    { name: "Dial 4242", image: dialLogo },
    { name: "CallHealth", image: callhealthLogo },
  ];

  function addToCart(product, type) {
    if (type == "remove") {
      let filterProduct = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title == product;
      });
      let filterProductOthers = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title !== product;
      });

      if (filterProduct?.length > 0) {
        setAllCartItems((prev) => {
          return [
            ...filterProductOthers,
            {
              title: filterProduct[0]?.title,
              quantity: filterProduct[0]?.quantity - 1,
            },
          ];
        });
      } else {
        setAllCartItems((prev) => {
          return [...prev, { title: product, quantity: 1 }];
        });
      }
      setSelected(true);
    } else {
      let filterProduct = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title == product;
      });
      let filterProductOthers = allCartItems?.filter((eachProduct) => {
        return eachProduct?.title !== product;
      });

      if (filterProduct?.length > 0) {
        setAllCartItems((prev) => {
          return [
            ...filterProductOthers,
            {
              title: filterProduct[0]?.title,
              quantity: filterProduct[0]?.quantity + 1,
            },
          ];
        });
      } else {
        setAllCartItems((prev) => {
          return [...prev, { title: product, quantity: 1 }];
        });
      }
      setSelected(true);
    }
  }

  function removeCartItem(product) {
    let filterProductOthers = allCartItems?.filter((eachProduct) => {
      return eachProduct?.title !== product;
    });
    setAllCartItems([...filterProductOthers]);
  }

  function calculateTotalPrice(products, priceList) {
    let totalAmount = 0;

    for (const product of products) {
      const matchingProduct = priceList.find(
        (item) => item.title === product.title
      );

      if (matchingProduct) {
        const price = parseFloat(matchingProduct.price);
        const quantity = product.quantity;
        totalAmount += price * quantity;
      }
    }

    return totalAmount;
  }

  const [totalCartPrice, setTotalCartPrice] = useState("0.00");

  useEffect(() => {
    let totalPrice = calculateTotalPrice(allCartItems, allCartProducts);
    console.log(totalPrice, "total cart Price");
    setTotalCartPrice(totalPrice);
  }, [allCartItems]);

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      {selected == "Dial 4242" ? (
        <div className={classNames.eachItemDetailed}>
          <div
            className={classNames.emptyCard}
            style={{ background: "#04BFDA" }}
          >
            {/* <div onClick={() => setSelectedItem("")}>Back</div> */}
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <img src={dialLogo} alt="dialLogo" />
              <div>
                <div>Dial 4242</div>
                <div>Ambulance Service Provider</div>
              </div>
            </div>
            <div className={classNames.desc}>
              <div className={classNames.title}>About</div>
              <div className={classNames.para}>
                DIAL4242, India's biggest app based ambulance network is built
                to deliver the best in emergency care to people across the
                nation through its ambulance services that is not only limited
                to emergencies, but can be used by a patient to schedule
                check-ups & appointments or simply to get home after being
                discharged from hospital. The mobile app connects users to
                emergency facilities by providing timely ambulance service when
                required. With just one click or a call, a patient can have our
                associated ambulances at the location of their choice.
              </div>
            </div>
            <div className={classNames.offerings}>
              <div className={classNames.title}>Offerings</div>
              <div className={classNames.allOfferings}>
                <div className={classNames.eachOffering}>
                  <div style={{ background: "#04BFDA40" }}>
                    <AmbulanceIcon />
                  </div>
                  <div>
                    <div>
                      <div className={classNames.title}>
                        Employee Ambulance Assurance Plan
                      </div>
                      <div className={classNames.learnBtn}>Learn More</div>
                    </div>
                    <div className={classNames.priceDiv}>
                      <div>
                        $149
                        <span>$205</span>
                      </div>
                      <div
                        className={classNames.btn}
                        onClick={() => {
                          addToCart("Employee Ambulance Assurance Plan");
                        }}
                      >
                        ADD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : selected == "Cult" ? (
        <div className={classNames.eachItemDetailed}>
          <div className={classNames.emptyCard}>
            <img src={cultCover} alt="cultCover" />
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <img src={cultfitLogo} alt="cultfitLogo" />
              <div>
                <div>Cult Fit</div>
                <div>Gym Service</div>
              </div>
            </div>
            <div className={classNames.desc}>
              <div className={classNames.title}>About</div>
              <div className={classNames.para}>
                At Cult, we make fitness fun and easy. We have best-in-class
                trainers & offer group workouts ranging from yoga to Boxing. Our
                workouts can be done both at a cult center and at home with the
                help of do it yourself (DIY) workout videos. Cult.fit uses the
                best in technology to give you a world-class experience.
              </div>
            </div>
            <div className={classNames.offerings}>
              <div className={classNames.title}>Offerings</div>
              <div className={classNames.allOfferings}>
                <div className={classNames.eachOffering}>
                  <div>
                    <CheckboxIcon />
                  </div>
                  <div>
                    <div>
                      <div className={classNames.title}>
                        Employee Cult Fit Package
                      </div>
                      <div className={classNames.learnBtn}>Learn More</div>
                    </div>
                    <div className={classNames.priceDiv}>
                      <div>
                        $8000
                        <span style={{ textDecoration: "none" }}>Per year</span>
                      </div>
                      <div
                        className={classNames.btn}
                        // onClick={() => {
                        //   setSelectedQuantity(1);
                        //   setSelected("CultBuy");
                        // }}
                        onClick={() => {
                          addToCart("Employee Cult Fit Package");
                        }}
                      >
                        ADD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : Array.isArray(allCartItems) && allCartItems?.length > 0 ? (
        <div
          className={classNames.eachItemDetailed}
          style={{ padding: "0 1rem" }}
        >
          <div className={classNames.allDetails}>
            <div className={classNames.offerings}>
              <div className={classNames.titleBig}>Your Cart</div>
              <div className={classNames.allOfferings}>
                {allCartItems?.length > 0
                  ? allCartItems?.map((eachItem) => {
                      return allCartProducts?.map((eachProduct) => {
                        if (eachItem?.title == eachProduct?.title) {
                          return (
                            <div className={classNames.eachOffering}>
                              <div style={{ padding: "0px" }}>
                                {/* <CheckboxIcon /> */}
                                <img
                                  src={eachProduct?.logo}
                                  alt="cultfitLogo"
                                />
                              </div>
                              <div>
                                <div>
                                  <div className={classNames.title}>
                                    {eachProduct?.title}
                                  </div>
                                  <div className={classNames.desc}>
                                    {eachProduct?.desc}
                                  </div>
                                </div>
                                <div className={classNames.priceDiv}>
                                  <div>
                                    ${eachProduct?.price}
                                    {/* <span style={{ textDecoration: "none" }}>Per year</span> */}
                                  </div>
                                  <div className={classNames.addButton}>
                                    <span
                                      className={classNames.btns}
                                      style={{
                                        pointerEvents:
                                          eachItem?.quantity == 1 ? "none" : "",
                                        opacity:
                                          eachItem?.quantity == 1 ? "0.5" : "",
                                      }}
                                      onClick={() => {
                                        addToCart(eachProduct?.title, "remove");
                                        // setSelectedQuantity((prev) => prev - 1);
                                      }}
                                    >
                                      -
                                    </span>
                                    <span>{eachItem?.quantity}</span>
                                    <span
                                      className={classNames.btns}
                                      onClick={() => {
                                        addToCart(eachProduct?.title);
                                        // setSelectedQuantity((prev) => prev + 1);
                                      }}
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <img
                                src={removeItem}
                                alt="removeItem"
                                onClick={() => {
                                  removeCartItem(eachProduct?.title);
                                }}
                              />
                            </div>
                          );
                        }
                      });
                    })
                  : ""}
              </div>
            </div>
          </div>
          <div className={classNames.bottomDiv}>
            <div className={classNames.buyDetails}>
              <div className={classNames.title}>Additional</div>
              <div className={classNames.priceBreakdown}>
                <div>
                  <span>GST</span>
                  <span>18.00%</span>
                </div>
                <div>
                  <span>Subtotal</span>
                  <span>${totalCartPrice * 1.18}</span>
                </div>
              </div>
            </div>
            <div className={classNames.buyBtn}>Proceed To Pay</div>
          </div>
        </div>
      ) : selected == "allcart" ? (
        <div
          className={`${classNames.eachItemDetailed} ${classNames.allCart}`}
          style={{ padding: "0 1rem" }}
        >
          <div className={classNames.cartBtn}>Your Cart Is Empty</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
