import React from "react";
import classNames from "./mobilenavbar.module.scss";

//assets
import fullLogo from "../../../assest/images/fullLogo.svg";
import { Link, useHistory } from "react-router-dom";

const MobileNavbar = ({ isScrolled }) => {
  const history = useHistory();
  return (
    <div
      className={classNames.navbar}
      style={{
        background: isScrolled ? "var(--main-color)" : "",
        boxShadow: isScrolled ? "0px 2px 2px 0px #00000026" : "",
      }}
    >
      <div className={classNames.topSection}>
        <div
          className={classNames.categories}
          style={{ color: isScrolled ? "white" : "" }}
        >
          <Link to="/employer/login">For Employees</Link>
          <Link to="/employer/login">For Employers</Link>
        </div>
      </div>
      <div className={classNames.bottomSection}>
        <div className={classNames.logoDiv}>
          {/* <img src={hamburgerIcon} alt="hamburgerIcon" /> */}
          <img src={fullLogo} alt="fullLogo" />
        </div>
        <div className={classNames.navOptions}>
          <div className={classNames.btnsContainer}>
            <div
              onClick={() => {
                history.push("/employer/login");
              }}
            >
              Get Started
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
