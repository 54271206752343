import React, { useContext, useEffect } from "react";
import classNames from "./enrollmentform.module.scss";
import { BankContext } from "../../context/Context";

const IobPortal = () => {
  const { reqValue, setreqValue } = useContext(BankContext);
  const txn_initiation_url = "https://www.iobnet.co.in/iobpay/apitxninit.do";
  let reqvaluee = reqValue
    ? reqValue
    : localStorage.getItem("iobReqValue")
    ? JSON.parse(localStorage.getItem("iobReqValue"))
    : "";
  useEffect(() => {
    console.log(reqvaluee, "reqvaluee from rahainsure");
    if (reqvaluee) {
      autoFormSubmit(reqvaluee);
    }
  }, [reqValue]); // Run once on component mount

  const autoFormSubmit = (reqvaluee) => {
    const reqjsonValue = JSON.stringify(reqvaluee);

    if (reqjsonValue.trim().length > 0) {
      document.getElementById("msg").innerHTML =
        "Processing your transaction. Please Wait.....";
      document.getElementById("form1").submit();
    } else {
      document.getElementById("msg").innerHTML =
        "Server Error. Please contact your website administrator!";
    }
  };

  return (
    <div className={classNames.enrollmentForm}>
      <div className={classNames.redirectingMessage}>
        <form action={txn_initiation_url} method="post" id="form1">
          <p id="msg"></p>
          <input
            type="hidden"
            name="reqjson"
            value={JSON.stringify(reqvaluee)}
          />
        </form>
      </div>
    </div>
  );
};

export default IobPortal;
