import React, { useState, useContext, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import Skeleton from "react-loading-skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { LoadingAnimation } from "../../components/LoadingAnimation";

import classNames from "./signInPage.module.scss";
import "./login.scss";
// import GlobalSidebar from "../../components/GlobalSidebar";
import { BankContext } from "../../context/Context";
import jwt_decode from "jwt-decode";
import styles from "./login.module.scss";

import bgImgCompressed from "./sideImgCompressed.jpg";

import fullLogo from "../../assest/images/fullLogo.svg";

import toast from "react-hot-toast";
import info from "./info.svg";

import eye1 from "./eye1.svg";
import eye2 from "./eye2.svg";
import paste from "./paste.svg";
import close from "./close1.svg";

import rahaxFullLogo from "../../assest/images/fullLogo.svg";

import * as qs from "qs";

import {
  loginFunc,
  registerOnApp,
  useRequestLoginChallenge,
  initiateForgotPassword,
  completeForgotPassword,
} from "./api";
import { globalMenu } from "./constants";
import { useAppContextDetails } from "../../context/AppContext";
import { APP_CODE } from "../../config/appConfig";
import Cookies from "js-cookie";
import { APP_USER_TOKEN } from "../../config";
import { useLoadAppDetails } from "../../queryHooks";
import useWindowDimensions from "../../utils/WindowSize";
import axios from "axios";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { predefinedToast } from "../../utils/toast";

function Login() {
  const { loggedapp } = useParams();
  const { width, height } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordPreLogin, setPasswordPreLogin] = useState("");
  const [confirmPasswordPreLogin, setConfirmPasswordPreLogin] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordStep, setResetPasswordStep] = useState(9);
  const [requestChallengeResponse, setRequestChallengeResponse] = useState({});
  const [twoFactorStep, setTwoFactorStep] = useState(0);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [message, setMessage] = useState(
    "For Security Reasons You Need To Change Your Password"
  );
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState("1");
  const [sixDigitPin, setSixDigitPin] = useState("");
  const [confirmPassword1, setConfirmPassword1] = useState("");
  const [confirmPassword2, setConfirmPassword2] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [isForgotPasswordError, setIsForgotPasswordError] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isChanging, setIsChanging] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { appByCode: appLoginByCode, appByCodeLoading: loginByCodeLoading } =
    useLoadAppDetails(APP_CODE);

  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);

  // mobile
  const [mobileStep, setMobileStep] = useState(0);
  const [affEmail, setAffEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [cognitoData, setCognitoData] = useState({});
  const [tempEmail, setTempEmail] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);
  const [affData, setAffData] = useState({});
  const [mobileLoading, setMobileLoading] = useState(false);

  const [appLinks, setAppLinks] = useState(null);
  const [fromSso, setFromSso] = useState(false);

  const {
    userLoginHandler,
    setLoggedInApp,
    setGlobalUserId,
    setGlobalHrId,
    setGlobalCompanyProfile,
    setGlobalAgentId,
    setDashboardToggleMain,
    setDashboardToggle,
    setDesignation,
    setOnLoginFormEmployer,
    setOnLoginFormEmployerProfile,
    setLoggedInUserId,
    setLoggedInUserRoleId,
    setMasterEmployeeID,
    setGlobalSelectedPolicy,
    setAllHospitalPolicies,
    setEmployeeIDEmployeeApp,
    getProfileDetails,
    setProfileDetailsAssociatesClone,
  } = useContext(BankContext);
  const history = useHistory();
  const {
    appCode,
    appName,
    appLogo,
    appFullLogo,
    appColorCode,
    appDetailsLoading,
    showMoreInfo,
    setShowMoreInfo,
  } = useAppContextDetails();

  useEffect(() => {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }, []);

  const [selectedApp, setSelectedApp] = useState(globalMenu(appName)[0]);

  const [isLoading, setIsLoading] = useState(false);

  function getUserId() {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuser?user_email=${email}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          localStorage?.setItem(
            "globalUserIdRaha",
            response?.data?.data[0]?.user_id
          );
          let globalHRID = await getGlobalHrId(
            response?.data?.data[0]?.user_id
          );
          setGlobalUserId(response?.data?.data[0]?.user_id);
        }
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
      });
  }
  function getGlobalHrId(userid) {
    if (userid) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/hr/get?user_id=${userid}`
      )
        .then((response) => {
          if (response?.data?.status && response?.data?.data?.length > 0) {
            // console.log(response?.data?.data[0]?.hr_id, "hr id response");
            if (response?.data?.data[0]?.hr_id) {
              setGlobalHrId(response?.data?.data[0]?.hr_id);
              localStorage.setItem(
                "globalUserHrIdRaha",
                response?.data?.data[0]?.hr_id
              );
            }

            setGlobalCompanyProfile(response?.data?.data[0]?.company_profile);
            localStorage.setItem(
              "globalCompanyProfile",
              JSON.stringify(response?.data?.data[0]?.company_profile)
            );
          } else {
          }
        })
        .catch((error) => {
          console.log(error?.message, "user profile error");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }

  function getLeadIdWithEmail() {
    getPoliciesEmployee();
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/lead/get?lead_email=${email}`
    )
      .then((response) => {
        // console.log(response?.data?.data, "get lead id response");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          // console.log(response?.data?.data[0]?.hr_id, "hr id response");
          createNewUserProfile(response?.data?.data[0]?.lead_id);
        }
      })
      .catch((error) => {
        console.log(error?.message, "get lead id error");
        // setAllInsuranceCompaniesLoading(false);
      });
  }

  function createNewUserProfile(leadId) {
    Axios.post(
      `https://insurancecoverfi.apimachine.com/insurance/user/create`,
      {
        user_email: email,
        lead_id: leadId,
      }
    )
      .then((response) => {
        getGlobalAgentID();
        getUserId();
        checkEmploymentType();
      })
      .catch((error) => {
        console.log(error?.message, "createNewUserProfile error");
        // setAllInsuranceCompaniesLoading(false);
      });
  }

  async function getGlobalAgentID() {
    let agentID = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
    );

    if (
      agentID?.data?.data?.employeeDetails?.length > 0 &&
      agentID?.data?.data?.employeeDetails[0]?.user_employee_id
    ) {
      localStorage.setItem(
        "masterEmployeeID",
        agentID?.data?.data?.employeeDetails[0]?.user_employee_id
      );
      setMasterEmployeeID(
        agentID?.data?.data?.employeeDetails[0]?.user_employee_id
      );

      setEmployeeIDEmployeeApp(agentID?.data?.data?.employeeDetails[0]._id);
      localStorage.setItem(
        "employeeIDEmployeeApp",
        agentID?.data?.data?.employeeDetails[0]._id
      );
    }

    let hrDetails =
      typeof agentID?.data?.data?.hrDetails?.hrCompanyDetails === "object" &&
      Object?.keys(agentID?.data?.data?.hrDetails?.hrCompanyDetails)?.length ===
        0;

    localStorage.setItem("onLoginForm", hrDetails);
    setOnLoginFormEmployer(hrDetails);
    if (agentID?.data?.data) {
      localStorage.setItem(
        "onLoginFormEmployerProfile",
        JSON.stringify(agentID?.data?.data)
      );
    }
    console.log(agentID?.data?.data, "profile onloginForm");
    setOnLoginFormEmployerProfile(agentID?.data?.data);

    if (agentID?.data?.status && agentID?.data?.data) {
      setGlobalAgentId(agentID?.data?.data?.agentDetails?.agent_id);
      localStorage.setItem(
        "globalAgentIdRaha",
        agentID?.data?.data?.agentDetails?.agent_id
      );
    }
  }

  async function checkEmploymentType() {
    let employmentType = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/get/designations?email=${email}`
    );
    setDesignation(employmentType?.data?.data);
    if (employmentType?.data?.data?.is_hr) {
      setDashboardToggle("Employer");
      setDashboardToggleMain("Employer");
      localStorage.setItem("dashboardToggleMain", "Employer");
      localStorage.setItem("dashboardToggle", "Employer");
      // console.log("setting as  :", "Employer");
    } else {
      //remove employee on login form
      localStorage.removeItem("onLoginForm");
      setOnLoginFormEmployer("");
      setOnLoginFormEmployerProfile("");

      setDashboardToggle("Employee");
      setDashboardToggleMain("Employee");
      localStorage.setItem("dashboardToggleMain", "Employee");
      localStorage.setItem("dashboardToggle", "Employee");
      // console.log("setting as  :", "Employee");
    }
    // console.log("Employment type check :", employmentType?.data?.data);
  }

  const getLoggedInUserId = () => {
    axios
      .get(
        `https://coverfi.apimachine.com/api/v1/raha-user/?user_email_id=${email}`
      )
      .then(({ data }) => {
        if (data.status) {
          setLoggedInUserId(data.data[0]?._id);
          setProfileDetailsAssociatesClone(data.data[0]);
          localStorage.setItem(
            "profileDetailsAssociatesClone",
            JSON.stringify(data.data[0])
          );
          localStorage.setItem("loggedInUserId", data.data[0]?._id);
          getRoleId(data.data[0]?._id);
        }
      });
  };

  const getRoleId = (id) => {
    console.log(loggedapp, "ljwefkwfkwfkwgbrjgblekjrg");
    let newApp;
    if (loggedapp == "employee") {
      newApp = "Employee";
    } else if (loggedapp == "employer") {
      newApp = "Employer";
    } else if (loggedapp == "associates") {
      newApp = "Associates";
    } else if (loggedapp == "admin") {
      newApp = "Admin";
    } else if (loggedapp === "teamlead") {
      newApp = "Team Leader";
    } else if (loggedapp === "verticalhead") {
      newApp = "Vertical Head";
    } else if (loggedapp === "businesshead") {
      newApp = "Business Head";
    } else if (loggedapp === "affiliates") {
      newApp = "Business Head";
    }
    axios
      .get(
        `https://coverfi.apimachine.com/api/v1/raha-user/role?user_id=${id}&role_designation=${newApp}&role_type=${
          loggedapp === "affiliates" ? "Service" : "Sales"
        }`
      )
      .then(({ data }) => {
        if (data.status) {
          setLoggedInUserRoleId(data.data[0]?._id);
          localStorage.setItem("loggedInUserRoleId", data.data[0]?._id);
        }
      });
  };

  function getPoliciesEmployee() {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=${email}`
    )
      .then((response) => {
        if (
          response?.data?.data?.length > 0 &&
          response?.data?.data[0].policies_count > 0
        ) {
          setAllHospitalPolicies(response?.data?.data[0].policies);
          setGlobalSelectedPolicy(
            response?.data?.data[0].policies[0]?.policy_number
          );
          localStorage.setItem(
            "globalSelectedPolicy",
            response?.data?.data[0].policies[0]?.policy_number
          );
        }
      })
      .catch((error) => {
        console.log(error, "get polic details error");
      });
  }

  const handleLoginSuccess = (data) => {
    // console.log(data, "login data");
    localStorage.setItem("accessToken aiprowallet", data?.accessToken);
    getUserId();
    userLoginHandler(email, data.accessToken, data.idToken);
    registerApp({ email, app_code: APP_CODE });
    registerApp({ email, app_code: "ice" });
    Cookies.set(APP_USER_TOKEN, data.idToken);
    setLoggedInApp(loggedapp);
    getGlobalAgentID();
    checkEmploymentType();
    getLoggedInUserId();
    getProfileDetails();

    localStorage.setItem("bankState", loggedapp);
    if (loggedapp == "employee") {
      console.log("Employee login");

      getLeadIdWithEmail();
      history.push("/app/rahax/overview");
    } else if (loggedapp == "employer") {
      console.log("Employer login");
      getLeadIdWithEmail();
      history.push("/app/coverfi/overview");
    } else if (loggedapp == "associates") {
      history.push("/app/agency/dashboard");
    } else if (loggedapp == "admin") {
      history.push("/app/brokerapp/staff");
    } else if (loggedapp === "teamlead") {
      history.push("/app/teamlead/dashboard");
    } else if (loggedapp === "verticalhead") {
      history.push("/app/verticalhead/dashboard");
    } else if (loggedapp === "businesshead") {
      history.push("/app/businesshead/dashboard");
    } else if (loggedapp === "affiliates") {
      history.push("/app/affiliates/dashboard");
    }
    setMessage("");
  };

  const handleLoginSuccess1 = (data, email) => {
    userLoginHandler(email, data.access_token, data.id_token);
    registerApp({ email, app_code: APP_CODE });
    registerApp({ email, app_code: "ice" });
    Cookies.set(APP_USER_TOKEN, data.id_token);
    setLoggedInApp(loggedapp);
    localStorage.setItem("bankState", loggedapp);
    if (loggedapp == "employee") {
      history.push("/app/rahax/claims");
    } else if (loggedapp == "employer") {
      history.push("/app/coverfi/overview");
    } else if (loggedapp == "associates") {
      history.push("/app/agency/leads");
    } else if (loggedapp == "admin") {
      history.push("/app/brokerapp/staff");
    }
    setMessage("");
  };

  const { mutate: registerApp } = useMutation(registerOnApp, {});

  const {
    isLoading: isLoggingIn,
    mutate: attemptLogin,
    data: loginAttemptResponse,
  } = useMutation(loginFunc, {
    onSuccess: (data) => {
      if (data.status) {
        setUserNotFound(false);
        setIncorrectCredentials(false);
        handleLoginSuccess(data);
      } else if (!data?.status && data?.message === "User not Found!") {
        setTempEmail(email);
        setUserNotFound(true);
      } else if (
        !data?.status &&
        data?.message === "Incorrect username or password."
      ) {
        setIncorrectCredentials(true);
      }
    },
  });

  const {
    mutate: requestLoginChallenge,
    isLoading: isRequestingLoginChallenge,
    isSuccess: isRequestLoginChallengeSuccess,
    data: requestLoginChallengeResponse,
  } = useRequestLoginChallenge();

  useEffect(() => {
    if (
      isRequestLoginChallengeSuccess &&
      requestLoginChallengeResponse?.data?.status
    ) {
      // handleLoginSuccess(requestLoginChallengeResponse?.data);
      setResetPasswordStep(9);
      window.location.reload();
      history.push(`/${loggedapp}/login`);
    }
  }, [isRequestLoginChallengeSuccess]);

  const isResetPassword =
    loginAttemptResponse?.message === "Reset User Password!" &&
    loginAttemptResponse?.resetPassword &&
    loginAttemptResponse?.challengeName === "NEW_PASSWORD_REQUIRED";

  const isTwoFactorEnabled =
    loginAttemptResponse?.message === "Enter the Authenticator Code!" &&
    loginAttemptResponse?.mfa &&
    loginAttemptResponse?.challengeName === "SOFTWARE_TOKEN_MFA";

  useEffect(() => {
    if (loginAttemptResponse?.message === "Incorrect username or password.") {
      setMessage(
        "You Have Entered Incorrect Login Credentials. Please Try Again"
      );
    } else if (isResetPassword) {
      setMessage("For Security Reasons You Need To Change Your Password");
      setRequestChallengeResponse({
        authChallenge_id: loginAttemptResponse?.authChallenge_id,
        email: loginAttemptResponse?.email,
        username: loginAttemptResponse?.username,
        challengeName: loginAttemptResponse?.challengeName,
        session: loginAttemptResponse?.session,
      });
      setResetPasswordStep(1);
    } else if (isTwoFactorEnabled) {
      setMessage("Please Enter Your 2 Factor Authentication");
      setRequestChallengeResponse({
        authChallenge_id: loginAttemptResponse?.authChallenge_id,
        email: loginAttemptResponse?.email,
        username: loginAttemptResponse?.username,
        challengeName: loginAttemptResponse?.challengeName,
        session: loginAttemptResponse?.session,
      });
      setTwoFactorStep(1);
    }
  }, [loginAttemptResponse]);

  const defaultApp = useMemo(
    () => ({
      appName,
      appLogo,
      appFullLogo,
      appColor: appColorCode,
    }),
    [appCode, appName, appLogo, appFullLogo, appColorCode]
  );

  const rightSideContent = globalMenu(appName).filter(
    (item) => selectedApp.appName === item.appName
  )?.[0]?.content;
  const arrayOfContent = rightSideContent?.split(" ");

  useEffect(() => {
    // const favIcon = document.getElementById('fav-icon');
    // favIcon.href = defaultApp.appLogo;

    if (defaultApp) {
      setSelectedApp(defaultApp);
    } else {
      setSelectedApp(globalMenu(appName)[0]);
    }
  }, [defaultApp]);

  const initializeForgotPassword = () => {
    setIsChanging(true);
    let obj = {
      email,
      app_code: APP_CODE,
    };
    initiateForgotPassword(obj).then((response) => {
      let result = response.data;
      setIsChanging(false);
      if (result.status) {
        setForgotPasswordStep("2");
        setForgotPasswordError("");
      } else {
        setForgotPasswordEmail(email);
        setForgotPasswordError(result?.message);
        if (result?.message === "User is not Found!!") {
          setIsForgotPasswordError(true);
        }
      }
    });
  };

  const completeForgotPass = () => {
    setIsChanging(true);
    let obj = {
      email,
      code: sixDigitPin,
      newPassword: confirmPassword2,
    };
    completeForgotPassword(obj).then((response) => {
      let result = response.data;
      setIsChanging(false);

      if (result.status && result.message === "Password Succesfully changed") {
        setPasswordChanged(true);
        setForgotPasswordError();
      } else {
        setForgotPasswordError(result.message);
      }
      setForgotPasswordStep("1");
      setForgotPassword(false);
      setShowPassword(false);
      setEmail("");
      setPassword("");
      setSixDigitPin("");
      setConfirmPassword1("");
      setConfirmPassword2("");
    });
  };

  useEffect(() => {
    if (window.location.search.includes("code")) {
      handleSSOCallback();
    }
  }, []);

  const checkDB = (email, username, cogData, authorizationCode) => {
    axios
      .post(`https://gxauth.apimachine.com/gx/user/email/exist`, {
        email: email,
      })
      .then(({ data }) => {
        if (data.status) {
          if (data?.data?.sso_g) {
            if (window.innerWidth > 600) {
              handleLoginSuccess1(cogData, email);
            } else {
              // userLoginHandler(email, cogData.access_token, cogData.id_token);
              registerApp({ email, app_code: APP_CODE });
              registerApp({ email, app_code: "ice" });
              // Cookies.set(APP_USER_TOKEN, cogData.id_token);
              setMobileStep(12);

              const url = `viralverse://Landing/data?email=${email}&idToken=${cogData.id_token}&accessToken=${cogData.access_token}`;
              window.location.href = url;
            }
          } else {
            // deleteSSOUser(username);
            toast("This Email has already been used.", {
              duration: 4000,
              position: "top-left",
            });
          }
        } else {
          if (window.innerWidth > 600) {
            setShowMoreInfo(true);
          } else {
            setMobileStep(2);
            setFromSso(true);
          }
        }
      });
  };

  const saveToDB = () => {
    axios
      .post(`https://gxauth.apimachine.com/gx/user/sso/signup`, {
        email: cognitoData?.email,
        ref_affiliate:
          window.innerWidth > 600
            ? affEmail
            : affData?.hardCoded[0]?.data?.username, // reference/upline affiliate-id
        app_code: "dgpayments",
        client_app: "",
        user_full_name: fullName,
        token: cognitoData?.data?.id_token,
      })
      .then(({ data }) => {
        // console.log(data, 'data');
        if (data.status) {
          if (window.innerWidth > 600) {
            handleLoginSuccess1(cognitoData.data, cognitoData.email);
          } else {
            registerApp({ email: cognitoData.email, app_code: APP_CODE });
            registerApp({ email: cognitoData.email, app_code: "ice" });
            // Cookies.set(APP_USER_TOKEN, cogData.id_token);
            setMobileStep(12);
          }
        } else {
          toast("Something went wrong.", {
            duration: 4000,
            position: "top-left",
          });
        }
      });
  };

  const handleSSOCallback = async () => {
    setIsLoading(true);

    const authorizationCode = new URLSearchParams(window.location.search).get(
      "code"
    );

    try {
      // var authCode =
      //   'NjRmcGZnNjl1MWZlNnZjNHBnYWxodGp1MHM6OWN1cDI2OHVqNnNxOG04MjRmMGFxdDNjNm42bWsxOWZsMWR2OGFhNWdya29icmszN2lz';
      var requestData = qs.stringify({
        grant_type: "authorization_code",
        code: authorizationCode,
        redirect_uri: "https://verse.viral.group",
        client_id: "kch0jkervipp30ve614cou33",
      });
      // console.log(requestData, 'kjbefkjwebfkjwhefkwhkkjwbfkejwbfkjwe');
      axios
        .post(
          `https://gxnitrossso.auth.us-east-2.amazoncognito.com/oauth2/token`,
          requestData
        )
        .then(({ data }) => {
          // console.log(data, 'wkejfbkwefwkfwkefkwehf');
          const decoded = jwt_decode(data.id_token);
          setCognitoData({
            email: decoded.email,
            data: data,
          });
          checkDB(
            decoded.email,
            decoded["cognito:username"],
            data,
            authorizationCode
          );
        });
    } catch (err) {
      console.error(
        "Error exchanging authorization code for access token:",
        err
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasteClick = async () => {
    const clipboardData = await navigator.clipboard.readText();
    setSixDigitPin(clipboardData);
  };

  function handleChange(event) {
    const password = event.target.value;
    const capitalRegex = /[A-Z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    setHasCapitalLetter(capitalRegex.test(password));
    setHasSpecialCharacter(specialRegex.test(password));
    setHasNumber(numberRegex.test(password));
    setHasMinLength(password.length >= 7);
  }

  const conditionalForm = () => {
    if (forgotPassword && forgotPasswordStep === "1") {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: "50%", paddingBottom: "20px" }}
          >
            Enter Your Email
          </div>
          <div className={styles.input1}>
            <input
              value={email}
              onChange={(e) => {
                setMessage("");
                // setEmail(e.target.value.toLowerCase());
                setEmail(e.target.value?.toLowerCase());
                if (isForgotPasswordError) {
                  setIsForgotPasswordError(false);
                }
              }}
              type="email"
              placeholder="Email"
            />
            <img
              src={close}
              alt="close"
              className={styles.closeImg}
              onClick={() => {
                setEmail("");
                if (isForgotPasswordError) {
                  setIsForgotPasswordError(false);
                }
              }}
            />
          </div>

          {isForgotPasswordError && (
            <div
              style={{
                background: "#FFFDF2",
                borderRadius: "15px",
                padding: "1rem 1.5rem",
                fontSize: "0.9rem",
                fontWeight: "405",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "50%",
              }}
            >
              <div>
                <img src={info} alt="" />
              </div>
              <div>
                We are not able to find a user with the email{" "}
                {forgotPasswordEmail}. Try again.
              </div>
            </div>
          )}

          <div
            className={styles.loginButton}
            onClick={() => {
              setForgotPasswordError("");
              setPasswordChanged(false);
              if (email?.length > 3) {
                initializeForgotPassword();
              }
            }}
            style={{
              opacity: isForgotPasswordError
                ? "0.25"
                : email.length < 3
                ? "0.25"
                : "1",
              pointerEvents: isForgotPasswordError
                ? "none"
                : email.length < 3
                ? "none"
                : "",
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              style={{ opacity: "0.5", cursor: "not-allowed" }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (forgotPassword && forgotPasswordStep === "2") {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: "50%", paddingBottom: "20px" }}
          >
            Enter The Six Digit Code Sent To{" "}
            <span style={{ fontWeight: "600" }}>{email}</span>
          </div>
          <div className={styles.input1}>
            <input
              value={sixDigitPin}
              onChange={(e) => setSixDigitPin(e.target.value)}
              placeholder="Code"
              maxLength={6}
              minLength={6}
            />
            <img
              src={paste}
              alt="paste"
              className={styles.closeImg}
              onClick={handlePasteClick}
            />
          </div>
          <div
            className={styles.loginButton}
            onClick={() => {
              if (sixDigitPin.length === 6) {
                setForgotPasswordStep("3");
              }
            }}
            style={{
              opacity: sixDigitPin.length === 6 ? "1" : "0.25",
              pointerEvents: sixDigitPin.length === 6 ? "" : "none",
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPasswordStep("1");
                setSixDigitPin("");
              }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
                setForgotPasswordStep("1");
                setSixDigitPin("");
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (forgotPassword && forgotPasswordStep === "3") {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: "50%", paddingBottom: "20px" }}
          >
            Please Enter Your New Password
          </div>
          <div className={styles.input1}>
            <input
              value={confirmPassword1}
              onChange={(e) => {
                setConfirmPassword1(e.target.value);
                handleChange(e);
              }}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
            />
            <div
              className={styles.indicator}
              style={{
                background:
                  hasCapitalLetter &&
                  hasMinLength &&
                  hasNumber &&
                  hasSpecialCharacter
                    ? "#B5E6EA"
                    : "#dc3027",
              }}
            ></div>
            <div className={styles.eyeImg}>
              <img
                src={showPassword ? eye2 : eye1}
                alt=""
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>
          </div>

          <div className={styles.conditions}>
            <div className={styles.eachCondition}>
              Minimum One Capital Letter
              <div
                className={styles.indicator}
                style={{
                  background: hasCapitalLetter
                    ? "#B5E6EA"
                    : "rgba(220, 48, 39, 0.37)",
                }}
              ></div>
            </div>
            <div className={styles.eachCondition}>
              Minimum One Special Character
              <div
                className={styles.indicator}
                style={{
                  background: hasSpecialCharacter
                    ? "#B5E6EA"
                    : "rgba(220, 48, 39, 0.37)",
                }}
              ></div>
            </div>
            <div className={styles.eachCondition}>
              Minimum Seven Characters
              <div
                className={styles.indicator}
                style={{
                  background: hasMinLength
                    ? "#B5E6EA"
                    : "rgba(220, 48, 39, 0.37)",
                }}
              ></div>
            </div>
            <div className={styles.eachCondition}>
              Minimum One Number
              <div
                className={styles.indicator}
                style={{
                  background: hasNumber ? "#B5E6EA" : "rgba(220, 48, 39, 0.37)",
                }}
              ></div>
            </div>
          </div>

          <div
            className={styles.loginButton}
            onClick={() => {
              if (
                hasCapitalLetter &&
                hasMinLength &&
                hasNumber &&
                hasSpecialCharacter
              ) {
                setForgotPasswordStep("4");
              }
            }}
            style={{
              marginTop: "0",
              opacity:
                hasCapitalLetter &&
                hasMinLength &&
                hasNumber &&
                hasSpecialCharacter
                  ? "1"
                  : "0.25",
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              style={{ opacity: "0.5", cursor: "not-allowed" }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
                setForgotPasswordStep("1");
                setSixDigitPin("");
                setConfirmPassword1("");
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (forgotPassword && forgotPasswordStep === "4") {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: "50%", paddingBottom: "20px" }}
          >
            Please Confirm Your New Password
          </div>
          <div className={styles.input1}>
            <input
              value={confirmPassword2}
              onChange={(e) => setConfirmPassword2(e.target.value)}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
            />
            <div
              className={styles.indicator}
              style={{
                background:
                  confirmPassword1 === confirmPassword2 ? "#B5E6EA" : "#dc3027",
              }}
            ></div>
            <div className={styles.eyeImg}>
              <img
                src={showPassword ? eye2 : eye1}
                alt=""
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>
          </div>

          <div
            className={styles.loginButton}
            style={{
              opacity: confirmPassword1 === confirmPassword2 ? 1 : 0.25,
            }}
            onClick={() => {
              if (confirmPassword1 === confirmPassword2) {
                completeForgotPass();
              } else {
                setForgotPasswordError("Password doesn't Match");
              }
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setConfirmPassword2("");
                setForgotPasswordStep("3");
              }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
                setForgotPasswordStep("1");
                setSixDigitPin("");
                setConfirmPassword1("");
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (resetPasswordStep === 1 && isResetPassword) {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* {message && <p style={{ fontSize: "15px" }}>{message}</p>} */}
            <div
              style={{
                background: "#FFFDF2",
                borderRadius: "15px",
                padding: "1rem 1.5rem",
                fontSize: "0.9rem",
                fontWeight: "405",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "50%",
                marginBottom: "1rem",
              }}
            >
              <div>
                <img src={info} alt="" />
              </div>
              <div>
                In order to proceed with the login, you need to create a new
                password
              </div>
            </div>
            <div style={{ width: "50%" }} className={styles.resetPassWrap}>
              <input
                value={passwordPreLogin}
                onChange={(e) => {
                  setMessage("");
                  setPasswordPreLogin(e.target.value);
                  handleChange(e);
                }}
                className={styles.input1}
                type={showPassword ? "text" : "password"}
                placeholder="New Password..."
                style={{ width: "100%", height: "100%" }}
              />
              <img
                className="eye"
                src={showPassword ? eye2 : eye1}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <div className={styles.conditions}>
              <div className={styles.eachCondition}>
                Minimum One Capital Letter
                <div
                  className={styles.indicator}
                  style={{
                    background: hasCapitalLetter
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
              <div className={styles.eachCondition}>
                Minimum One Special Character
                <div
                  className={styles.indicator}
                  style={{
                    background: hasSpecialCharacter
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
              <div className={styles.eachCondition}>
                Minimum Seven Characters
                <div
                  className={styles.indicator}
                  style={{
                    background: hasMinLength
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
              <div className={styles.eachCondition}>
                Minimum One Number
                <div
                  className={styles.indicator}
                  style={{
                    background: hasNumber
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className={styles.loginButton}
            onClick={() => setResetPasswordStep(2)}
            style={{
              pointerEvents:
                hasCapitalLetter &&
                hasSpecialCharacter &&
                hasMinLength &&
                hasNumber
                  ? ""
                  : "none",
              opacity:
                hasCapitalLetter &&
                hasSpecialCharacter &&
                hasMinLength &&
                hasNumber
                  ? ""
                  : "0.5",
            }}
          >
            Next Step
          </div>
        </>
      );
    } else if (resetPasswordStep === 2 && isResetPassword) {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                background: "#FFFDF2",
                borderRadius: "15px",
                padding: "1rem 1.5rem",
                fontSize: "0.9rem",
                fontWeight: "405",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "50%",
                marginBottom: "1rem",
              }}
            >
              <div>
                <img src={info} alt="" />
              </div>
              <div>
                In order to proceed with the login, you need to create a new
                password
              </div>
            </div>
            <div
              style={{ width: "50%" }}
              className={`passwordWrap ${styles.resetPassWrap}`}
            >
              <input
                value={confirmPasswordPreLogin}
                onChange={(e) => {
                  setMessage("");
                  setConfirmPasswordPreLogin(e.target.value);

                  handleChange(e);
                }}
                className={styles.input1}
                // className="inputLogin"
                type={showPassword ? "text" : "password"}
                // type="password"
                placeholder="Confirm Password"
                style={{ width: "100%", height: "100%" }}
              />
              <img
                className="eye"
                src={showPassword ? eye2 : eye1}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <div className={styles.conditions}>
              <div className={styles.eachCondition}>
                Password Matches
                <div
                  // className={styles.indicator}
                  style={{
                    color:
                      confirmPasswordPreLogin === passwordPreLogin
                        ? "var(--main-color)"
                        : "var(--font-color)",
                  }}
                >
                  <IoIosCheckmarkCircle />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              pointerEvents:
                hasCapitalLetter &&
                hasSpecialCharacter &&
                hasMinLength &&
                hasNumber
                  ? ""
                  : "none",
              opacity:
                hasCapitalLetter &&
                hasSpecialCharacter &&
                hasMinLength &&
                hasNumber
                  ? ""
                  : "0.5",
            }}
            className={styles.loginButton}
            onClick={() => {
              if (passwordPreLogin === confirmPasswordPreLogin) {
                requestLoginChallenge({
                  authChallenge_id: requestChallengeResponse?.authChallenge_id,
                  email: requestChallengeResponse?.email,
                  username: requestChallengeResponse?.username,
                  challengeName: requestChallengeResponse?.challengeName,
                  session: requestChallengeResponse?.session,
                  newPassword: confirmPasswordPreLogin,
                });
              } else {
                predefinedToast("Passwords doesn't match!");
              }
            }}
          >
            Confirm Password
          </div>
          <div
            className={styles.loginButton}
            style={{
              background: "white",
              color: "var(--font-color)",
              border: "1px solid #e5e5e5",
              marginTop: "1.5rem",
            }}
            onClick={() => {
              setResetPasswordStep(1);
            }}
          >
            Go Back
          </div>
        </>
      );
    } else if (twoFactorStep === 1 && isTwoFactorEnabled) {
      return (
        <div>
          <p style={{ fontSize: "15px" }}>
            Please Enter Your 2 Factor Authentication
          </p>
          <div className="pt-5 pb-5 passwordWrap">
            <input
              value={twoFactorCode}
              onChange={(e) => {
                setMessage("");
                setTwoFactorCode(e.target.value);
              }}
              className={styles.input1}
              type={showPassword ? "text" : "password"}
              placeholder="Six Digit Code"
            />
          </div>
          <div
            className={styles.loginButton}
            onClick={() => {
              requestLoginChallenge({
                authChallenge_id: requestChallengeResponse?.authChallenge_id,
                email: requestChallengeResponse?.email,
                username: requestChallengeResponse?.username,
                challengeName: requestChallengeResponse?.challengeName,
                session: requestChallengeResponse?.session,
                totp_code: twoFactorCode,
              });
            }}
          >
            Confirm
          </div>
        </div>
      );
    } else {
      if (showMoreInfo) {
        return (
          <>
            <div
              className={styles.forgotPassword}
              style={{
                textAlign: "left",
                fontSize: "17px",
                marginTop: "-4%",
                marginBottom: "5%",
                fontWeight: "600",
              }}
            >
              You have successfully verified your Google account. Please fill
              out the remaining information to complete your registration
            </div>
            <input
              type="email"
              placeholder="Affiliates Code"
              className={styles.input1}
              value={affEmail}
              onChange={(e) => {
                setMessage("");
                // setAffEmail(e.target.value.toLowerCase());
                setAffEmail(e.target.value);
              }}
            />
            <input
              type="text"
              placeholder="Your Full Name"
              className={styles.input2}
              value={fullName}
              onChange={(e) => {
                setMessage("");
                setFullName(e.target.value);
              }}
            />

            <div
              className={styles.loginButton}
              onClick={() => {
                if (affEmail && fullName) {
                  saveToDB();
                } else {
                  setMessage("Please Fill All The Fields");
                }
              }}
            >
              Complete Registration
            </div>
          </>
        );
      } else {
        return (
          <>
            <input
              type="email"
              placeholder="Email"
              className={styles.input1}
              onChange={(e) => {
                setMessage("");
                // setEmail(e.target.value.toLowerCase());
                setEmail(e.target.value?.toLowerCase());
              }}
            />
            <div className={styles.input2}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setMessage("");
                  setPassword(e.target.value);
                }}
              />
              <img
                src={showPassword ? eye2 : eye1}
                alt=""
                className={styles.eyeImg}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <div
              className={styles.forgotPassword}
              style={{
                paddingBottom: userNotFound
                  ? "1.5rem"
                  : incorrectCredentials
                  ? "1.5rem"
                  : "0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                onClick={() => {
                  setForgotPassword(!forgotPassword);
                }}
              >
                Forgot Password?
              </div>
            </div>

            {userNotFound && (
              <div
                style={{
                  background: "#FFFDF2",
                  borderRadius: "15px",
                  padding: "1rem 1.5rem",
                  fontSize: "0.9rem",
                  fontWeight: "405",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <div>
                  <img src={info} alt="" />
                </div>
                <div>
                  Sorry but we are not able to find a user with the email{" "}
                  {tempEmail}.
                </div>
              </div>
            )}

            {incorrectCredentials && (
              <div
                style={{
                  background: "#FFFDF2",
                  borderRadius: "15px",
                  padding: "1rem 1.5rem",
                  fontSize: "0.9rem",
                  fontWeight: "405",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <div>
                  <img src={info} alt="" />
                </div>
                <div>
                  The credentials you entered are incorrect. Please try again or
                  reset your password.
                </div>
              </div>
            )}

            <div
              className={styles.loginButton}
              onClick={() => {
                setForgotPasswordError("");
                setPasswordChanged(false);
                if (appLoginByCode) {
                  attemptLogin({ email, password });
                }
              }}
              style={{
                opacity: isLoggingIn ? "0.25" : "1",
              }}
            >
              {isLoggingIn ? "Loading..." : "Login"}
            </div>
          </>
        );
      }
    }
  };

  const getAppLinks = () => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/mobile/app/links/logs/get?app_code=raha`
      )
      .then(({ data }) => {
        if (data.status) {
          setAppLinks(data.logs[0]);
        }
      });
  };

  useEffect(() => {
    getAppLinks();
  }, []);

  useEffect(() => {
    localStorage.removeItem("onLoginForm");
    setOnLoginFormEmployer(false);
    console.log("removing onLoginForm login 2249");
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>#Tall Tree - Modernize Your Employee Benefits</title>
        <meta
          name="description"
          content="Modenize your employee benefit program with Tall Tree’s suite of insurance and wellness apps."
        />
      </Helmet>
      {width > 768 || width > height ? (
        <div className={styles.mainGrid}>
          <div className={styles.leftSide}>
            {(isChanging || isRequestingLoginChallenge) && (
              <div className="loading-component">
                <LoadingAnimation icon={rahaxFullLogo} width={200} />
              </div>
            )}
            {appDetailsLoading ? (
              <Skeleton className="mb-5" style={{ height: 50 }} />
            ) : (
              <img
                src={fullLogo}
                alt="Full Logo"
                className={classNames.logo}
                style={{
                  width: "35%",
                  paddingBottom: "6%",
                  maxHeight: "170px",
                }}
                onClick={() => {
                  history.push("/");
                }}
              />
            )}

            {conditionalForm()}
          </div>
          <div className={styles.rightSide}>
            <img src={bgImgCompressed} alt="" style={{ height: "95%" }} />
          </div>
        </div>
      ) : (
        <>
          {mobileLoading ||
            (isLoading && (
              <div className={styles.loadingComponent}>
                <LoadingAnimation icon={rahaxFullLogo} width={200} />
              </div>
            ))}

          <div className={styles.mobileMain}>
            <div>
              <img
                src={rahaxFullLogo}
                alt="rahaxFullLogo"
                className={styles.logo}
              />
              <div className={styles.desc}>
                Tall Tree is only currently available on a desktop browser.
              </div>
            </div>
          </div>
        </>
      )}
    </HelmetProvider>
  );
}

export default Login;
