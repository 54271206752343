import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./newemployee.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import uploading from "../../../assest/images/icons/uploading.svg";
import dummyProfile from "../../../assest/images/icons/dummyProfile.svg";
import downloadIcon from "../../../assest/images/icons/download.svg";
import uploadIcon from "../../../assest/images/icons/upload.svg";
import { AppContext } from "../../../context/AppContext";

import { uploadImageFunc } from "../../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import { Redirect } from "react-router-dom";
import { event } from "react-ga";
import { logoutToSameLogin } from "../../../assest/functions";

const NewEmployee = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    addNewEmployeeType,
    setAddNewEmployeeType,
    globalUserId,
    globalHrId,
    setGlobalHrId,
    setUpdateLoading,
  } = useContext(BankContext);

  const [successMessage, setSuccessMessage] = useState("");

  const [leadSourceDropdown, setLeadSourceDropdown] = useState([
    "Just Dial",
    "Instagram",
    "Google",
    "Offline Ads",
    "Referral",
    "Outbound In Person",
  ]);
  const [usersDropdown, setUsersDropdown] = useState("");
  const [associateDropdown, setAssociateDropdown] = useState("");
  const [corporateLead, setCorporateLead] = useState({
    first_name: "",
    last_name: "",
    address: "",
    phone_number: "",
    reference_email: email,
    lead_email: "",
    lead_source: "",
    lead_type: "company",
    company_name: "",
    company_size: "",
  });
  const [selectBrokerage, setSelectBrokerage] = useState("");
  const [uploadFileSelected, setUploadFileSelected] = useState("");
  const [uploadFileSelectedName, setUploadFileSelectedName] = useState("");

  const [createEmployeeEmail, setCreateEmployeeEmail] = useState([]);

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getusernotincompany?company_id=5008196b-0a5d-4ead-a8d3-1d4b65952b5c`
    )
      .then((response) => {
        console.log(response, "users dropdown response");
        if (response?.data?.status) {
          setUsersDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "brokerage dropdown error");
      });
  }, []);

  useEffect(() => {
    // setAllInsuranceCompaniesLoading(true);
    if (globalUserId) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/hr/get?user_id=${globalUserId}`
      )
        .then((response) => {
          console.log(response?.data?.data, "company profile response");
          if (response?.data?.status && response?.data?.data?.length > 0) {
            console.log(response?.data?.data[0]?.hr_id, "hr id response");
            if (response?.data?.data[0]?.hr_id) {
              setGlobalHrId(response?.data?.data[0]?.hr_id);
              localStorage.setItem(
                "globalUserHrIdRaha",
                response?.data?.data[0]?.hr_id
              );
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error?.message, "user profile error");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }, []);

  function createNewEmployeeEndorsements() {
    setUpdateLoading(true);
    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append("file", uploadFileSelected);

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/employee/endorsement/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        console.log(response, "Uploaded Endorsements  form via spreadsheet");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setUpdateLoading(false);
          setSuccessMessage("addedEndorsements BySpreadsheet");
          setTimeout(() => {
            if (window?.location?.pathname?.includes("/agency")) {
              history.push("/app/agency/leads");
            } else {
              history.push("/app/coverfi/members");
            }
            // window.location.reload();
          }, 3000);
        } else {
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded Endorsements  form via spreadsheet error"
        );
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }
  function removeEmployeeEndorsements() {
    setUpdateLoading(true);
    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append("file", uploadFileSelected);

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/employee/endorsement/delete",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        console.log(response, "Deleted Endorsements form via spreadsheet");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setUpdateLoading(false);
          setSuccessMessage("addedEndorsements BySpreadsheet");
          setTimeout(() => {
            if (window?.location?.pathname?.includes("/agency")) {
              history.push("/app/agency/leads");
            } else {
              history.push("/app/coverfi/members");
            }
            // window.location.reload();
          }, 3000);
        } else {
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Deleted Endorsements form via spreadsheet error"
        );
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  function createNewEmployee() {
    setUpdateLoading(true);
    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append("file", uploadFileSelected);

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/employee/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        console.log(response, "Uploaded employee form via spreadsheet");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setUpdateLoading(false);
          setSuccessMessage("addedEmployeeBySpreadsheet");
          setTimeout(() => {
            if (window?.location?.pathname?.includes("/agency")) {
              history.push("/app/agency/leads");
            } else {
              history.push("/app/coverfi/members");
            }
            // window.location.reload();
          }, 3000);
        } else {
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded employee form via spreadsheet error"
        );
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  function createNewEmployeeEmail() {
    setUpdateLoading(true);
    const finalObj = createEmployeeEmail?.filter(
      (obj) => Object.keys(obj).length > 0
    );
    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/employee/uploadWithoutSheet",
      {
        hr_id: globalHrId
          ? globalHrId
          : localStorage.getItem("globalUserHrIdRaha")
          ? localStorage.getItem("globalUserHrIdRaha")
          : "",
        employeeDetails: finalObj,
      }
    )
      .then((response) => {
        // console.log(response, "Uploaded employee form via email");
        setUpdateLoading(false);
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setSuccessMessage("addedEmployeeByEmail");
          setTimeout(() => {
            if (window?.location?.pathname?.includes("/agency")) {
              history.push("/app/agency/leads");
            } else {
              history.push("/app/coverfi/members");
            }
            // window.location.reload();
          }, 3000);
        } else {
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Uploaded employee form via email error");
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(finalObj, "create Employee by Email API");
  }

  // useEffect(() => {
  //   if (selectBrokerage) {
  //     Axios.get(
  //       `https://insurancecoverfi.apimachine.com/insurance/agent/get?brokerage_username=${selectBrokerage}`
  //     )
  //       .then((response) => {
  //         console.log(
  //           response,
  //           "Get all agents for selected brokerage response"
  //         );
  //         if (response?.data?.status) {
  //           setAssociateDropdown(response?.data?.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           error?.message,
  //           "Get all Associates for selected brokerage error"
  //         );
  //       });
  //   }
  //   console.log(selectBrokerage, "selectBrokerage selected");
  // }, [selectBrokerage]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newBrokerageParent}>
      {successMessage ? (
        <div className={classNames.successfulMessage}>
          {successMessage == "addedEmployeeBySpreadsheet" ? (
            <>
              <div>
                You have successfully added new employees via spreadsheet
              </div>
              <div>You will be redirected to your pending employees list</div>
            </>
          ) : (
            <>
              <div>
                You have successfully invited
                {" " +
                  createEmployeeEmail?.filter(
                    (obj) => Object.keys(obj).length > 0
                  )?.length +
                  " "}
                new employees
              </div>
              <div>You will be redirected to your pending employees list</div>
            </>
          )}
        </div>
      ) : (
        <div className={classNames.newBrokerage}>
          {addNewEmployeeType == "EndorsementRemovespreadsheet" ? (
            <>
              <div className={classNames.title}>
                Endorsements - Remove Via Spreadsheet
              </div>
              <div className={classNames.sectionTitle}>
                Step 1: Download The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileDownload
                  title="Download Spreadsheet Template"
                  icon={downloadIcon}
                  stateValue={
                    "https://insurancecoverfi.apimachine.com/insurance/general/files/4a441a73-3996-489e-b8f7-cd4e76a8e341.xlsx"
                  }
                  // setState={setCreateEmployeeEmail}
                />
              </div>
              <div className={classNames.sectionTitle}>
                Step 2: Upload The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileUpload
                  title={
                    uploadFileSelectedName
                      ? uploadFileSelectedName
                      : "Upload Filled Out Spreadsheet"
                  }
                  icon={uploadIcon}
                  stateValue={uploadFileSelected}
                  setState={setUploadFileSelected}
                  setName={setUploadFileSelectedName}
                />
              </div>
            </>
          ) : addNewEmployeeType == "Endorsementspreadsheet" ? (
            <>
              <div className={classNames.title}>
                Endorsements - Via Spreadsheet
              </div>
              <div className={classNames.sectionTitle}>
                Step 1: Download The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileDownload
                  title="Download Spreadsheet Template"
                  icon={downloadIcon}
                  stateValue={
                    "https://insurancecoverfi.apimachine.com/insurance/general/files/68977200-0e2d-4354-aedd-ad2a1072bfa1.xlsx"
                  }
                  // setState={setCreateEmployeeEmail}
                />
              </div>
              <div className={classNames.sectionTitle}>
                Step 2: Upload The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileUpload
                  title={
                    uploadFileSelectedName
                      ? uploadFileSelectedName
                      : "Upload Filled Out Spreadsheet"
                  }
                  icon={uploadIcon}
                  stateValue={uploadFileSelected}
                  setState={setUploadFileSelected}
                  setName={setUploadFileSelectedName}
                />
              </div>
            </>
          ) : addNewEmployeeType == "spreadsheet" ? (
            <>
              <div className={classNames.title}>
                Add New Employee Via Spreadsheet
              </div>
              <div className={classNames.sectionTitle}>
                Step 1: Download The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileDownload
                  title="Download Spreadsheet Template"
                  icon={downloadIcon}
                  stateValue={
                    "https://insurancecoverfi.apimachine.com/insurance/general/files/ad3bf8da-eba3-45e8-afee-78cb9fd25153.xlsx"
                  }
                  // setState={setCreateEmployeeEmail}
                />
              </div>
              <div className={classNames.sectionTitle}>
                Step 2: Upload The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileUpload
                  title={
                    uploadFileSelectedName
                      ? uploadFileSelectedName
                      : "Upload Filled Out Spreadsheet"
                  }
                  icon={uploadIcon}
                  stateValue={uploadFileSelected}
                  setState={setUploadFileSelected}
                  setName={setUploadFileSelectedName}
                />
              </div>
            </>
          ) : addNewEmployeeType == "email" ? (
            <>
              <div className={classNames.title}>Add New Employee</div>
              <div className={classNames.sectionTitle}>Create Email List</div>
              <div
                className={classNames.inputContainers}
                style={{ display: "flex" }}
              >
                <EachProfileInput
                  title="Enter The Email Of The Employee You Want To Add"
                  placeholder="Enter email..."
                  dropdown=""
                  stateValue={createEmployeeEmail}
                  setState={setCreateEmployeeEmail}
                />
              </div>
            </>
          ) : addNewEmployeeType == "bulkupload" ? (
            <>
              <div className={classNames.title}>
                Bulk Upload Directly To Associate
              </div>
              <div className={classNames.sectionTitle}>
                Step 1: Download The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileDownload
                  title="Download Spreadsheet Template"
                  icon={downloadIcon}
                  stateValue={
                    "https://insurancecoverfi.apimachine.com/insurance/general/files/eac8342f-907a-482b-a0a9-70f59decfbe7.xlsx"
                  }
                  // setState={setCreateEmployeeEmail}
                />
              </div>
              <div className={classNames.sectionTitle}>
                Step 2: Upload The Spreadsheet Template
              </div>
              <div className={classNames.inputContainers}>
                <EachInputFileUpload
                  title={
                    uploadFileSelectedName
                      ? uploadFileSelectedName
                      : "Upload Filled Out Spreadsheet"
                  }
                  icon={uploadIcon}
                  stateValue={uploadFileSelected}
                  setState={setUploadFileSelected}
                  setName={setUploadFileSelectedName}
                />
              </div>
            </>
          ) : (
            <>
              <div className={classNames.title}>Add New Employee</div>
              <div className={classNames.sectionTitle}>Employee Details</div>
              <div className={classNames.inputContainers}>
                <EachCustomDropdown
                  title="Select User"
                  dropdown={usersDropdown}
                  name="select_user"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachInput
                  title="Employee's Company Email"
                  placeholder="Enter email.."
                  name="company_email"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachInput
                  title="Employee ID"
                  placeholder="Enter id.."
                  name="empid"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
              </div>
              <div
                className={classNames.sectionTitle}
                style={{ marginTop: "2rem" }}
              >
                Employment Details
              </div>
              <div className={classNames.inputContainers}>
                <EachInput
                  title="Job Title"
                  placeholder="Enter job title.."
                  name="job_title"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachInput
                  title="Date Of Joining"
                  placeholder="Enter date.."
                  name="date_of_joining"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachCustomDropdown
                  title="Job Type"
                  dropdown={leadSourceDropdown}
                  name="job_type"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
              </div>
              <div
                className={classNames.sectionTitle}
                style={{ marginTop: "2rem" }}
              >
                Employment Location Details
              </div>
              <div className={classNames.inputContainers}>
                <EachCustomDropdown
                  title="Country"
                  dropdown={leadSourceDropdown}
                  name="country"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachInput
                  title="State"
                  placeholder="Enter state.."
                  name="state"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachInput
                  title="City"
                  placeholder="Enter city.."
                  name="city"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachInput
                  title="Address"
                  placeholder="Enter address.."
                  name="address"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
                <EachInput
                  title="Postal Code"
                  placeholder="Enter postal code.."
                  name="postal_code"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                />
              </div>
            </>
          )}
        </div>
      )}
      {successMessage ? (
        ""
      ) : addNewEmployeeType == "EndorsementRemovespreadsheet" ? (
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => {
              if (popularAction) {
                setPopularAction(false);
              } else {
                setDashboardHam(!dashboardHam);
              }
              if (window?.location?.pathname?.includes("/coverfi")) {
                history.push("/app/coverfi/overview");
              } else {
                history.push("/app/agency/leads");
              }
            }}
          >
            Close Form
          </div>
          <div onClick={removeEmployeeEndorsements}>Save Spreadsheet Data</div>
        </div>
      ) : addNewEmployeeType == "Endorsementspreadsheet" ? (
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => {
              if (popularAction) {
                setPopularAction(false);
              } else {
                setDashboardHam(!dashboardHam);
              }
              if (window?.location?.pathname?.includes("/coverfi")) {
                history.push("/app/coverfi/overview");
              } else {
                history.push("/app/agency/leads");
              }
            }}
          >
            Close Form
          </div>
          <div onClick={createNewEmployeeEndorsements}>
            Save Spreadsheet Data
          </div>
        </div>
      ) : addNewEmployeeType == "spreadsheet" ||
        addNewEmployeeType == "bulkupload" ? (
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => {
              if (popularAction) {
                setPopularAction(false);
              } else {
                setDashboardHam(!dashboardHam);
              }
              if (window?.location?.pathname?.includes("/coverfi")) {
                history.push("/app/coverfi/overview");
              } else {
                history.push("/app/agency/leads");
              }
            }}
          >
            Close Form
          </div>
          <div onClick={createNewEmployee}>Save Spreadsheet Data</div>
        </div>
      ) : addNewEmployeeType == "email" ? (
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => {
              if (popularAction) {
                setPopularAction(false);
              } else {
                setDashboardHam(!dashboardHam);
              }
              if (window?.location?.pathname?.includes("/coverfi")) {
                history.push("/app/coverfi/overview");
              } else {
                history.push("/app/agency/leads");
              }
            }}
          >
            Close Form
          </div>
          <div onClick={createNewEmployeeEmail}>Add New Employee Via Email</div>
        </div>
      ) : (
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => {
              if (popularAction) {
                setPopularAction(false);
              } else {
                setDashboardHam(!dashboardHam);
              }
              if (window?.location?.pathname?.includes("/coverfi")) {
                history.push("/app/coverfi/overview");
              } else {
                history.push("/app/agency/leads");
              }
            }}
          >
            Close Form
          </div>
          <div onClick={createNewEmployee} style={{ pointerEvents: "none" }}>
            Add New Employee
          </div>
        </div>
      )}
    </div>
  );
};

export default NewEmployee;

const EachInput = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      // style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : title == "Job Type" || title == "Select User"
              ? "Click To Select"
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "Lead Source"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select Brokerage"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.brokerage_name,
                            eachitem?.brokerage_icon
                          );
                          setState(eachitem?.brokerage_username);
                        }}
                        key={eachitem?.brokerage_name + index}
                        style={{
                          display:
                            eachitem?.brokerage_username === stateValue
                              ? "none"
                              : "",
                        }}
                      >
                        <img
                          src={eachitem?.brokerage_icon}
                          alt={eachitem?.brokerage_name}
                        />
                        {eachitem?.brokerage_name}
                      </li>
                    );
                  })
              : title == "Select User"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.first_name + eachitem?.last_name,
                            eachitem?.profile_picture
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem?.user_email,
                          });
                        }}
                        key={eachitem?.first_name + index}
                      >
                        <img
                          src={eachitem?.profile_picture}
                          alt={eachitem?.first_name}
                        />
                        <div>
                          <div>
                            {eachitem?.first_name} &nbsp; {eachitem?.last_name}
                          </div>
                          <div>{eachitem?.user_email}</div>
                        </div>
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name, eachitem?.app_icon);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"Select Brokerage"}
              >
                {title == "Select Brokerage"
                  ? "Search one of your brokerage.."
                  : "Select an option"}
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanB"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePic = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDivProfilePic}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanBProfilePic"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanBProfilePic"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanBProfilePic"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await", name);
          }}
          accept="image/*"
          id="profileUpdateImgPlanBProfilePic"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

const EachProfileInput = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
}) => {
  const [numberOfEmails, setNumberOfEmails] = useState(1);

  const handleEmailChange = (index, key, value) => {
    const updatedStateValue = [...stateValue];
    if (!updatedStateValue[index]) {
      updatedStateValue[index] = {};
    }
    updatedStateValue[index][key] = value;
    setState(updatedStateValue);
  };

  const handleAddEmail = () => {
    setNumberOfEmails((prev) => prev + 1);
    setState([...stateValue, {}]); // Add an empty object to the array
  };

  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      {Array.from({ length: numberOfEmails })?.map((email, index) => (
        <div className={classNames.addByEmail} key={index}>
          <div className={classNames.inputParent}>
            <input
              type="text"
              name="employee_email"
              placeholder={placeholder}
              value={stateValue[index]?.employee_email || ""}
              className={classNames.inputContainer}
              onChange={(event) =>
                handleEmailChange(index, "employee_email", event.target.value)
              }
            />
            <div
              className={classNames.addButton}
              onClick={handleAddEmail}
              style={{ display: numberOfEmails - 1 === index ? "" : "none" }}
            >
              +
            </div>
          </div>
          <div className={classNames.optionalThings}>
            <input
              type="text"
              className={classNames.inputContainer}
              placeholder="Enter First Name (Optional)"
              name="first_name"
              value={stateValue[index]?.first_name || ""}
              onChange={(event) =>
                handleEmailChange(index, "first_name", event.target.value)
              }
            />
            <input
              type="text"
              className={classNames.inputContainer}
              placeholder="Enter Last Name (Optional)"
              name="last_name"
              value={stateValue[index]?.last_name || ""}
              onChange={(event) =>
                handleEmailChange(index, "last_name", event.target.value)
              }
            />
            <input
              type="text"
              className={classNames.inputContainer}
              placeholder="Phone Number (Optional)"
              name="phone_number"
              value={stateValue[index]?.phone_number || ""}
              onChange={(event) =>
                handleEmailChange(index, "phone_number", event.target.value)
              }
            />
          </div>
        </div>
      ))}

      {dropdown && <IoMdArrowDropdown />}
    </div>
  );
};

// const EachProfileInput = ({
//   title,
//   placeholder,
//   dropdown,
//   status,
//   stateValue,
//   setState,
//   removeState,
// }) => {
//   const [numberOfEmails, setNumberOfEmails] = useState(1);

//   const handleEmailChange = (index, value) => {
//     const updatedStateValue = [...stateValue];
//     updatedStateValue[index] = value;
//     setState(updatedStateValue);
//   };

//   const handleAddEmail = () => {
//     setNumberOfEmails((prev) => prev + 1);
//     setState([...stateValue, ""]); // Add an empty email to the array
//   };

//   return (
//     <div className={classNames.eachProfileInput}>
//       <div className={classNames.title}>{title}</div>
//       {Array.from({ length: numberOfEmails })?.map((email, index) => (
//         <div className={classNames.addByEmail}>
//           <div key={index} className={classNames.inputParent}>
//             <input
//               type="text"
//               name="employee_email"
//               placeholder={placeholder}
//               value={email}
//               className={classNames.inputContainer}
//               onChange={(event) => handleEmailChange(index, event.target.value)}
//             />
//             <div
//               className={classNames.addButton}
//               onClick={handleAddEmail}
//               style={{ display: numberOfEmails - 1 === index ? "" : "none" }}
//             >
//               +
//             </div>
//           </div>
//           <div className={classNames.optionalThings}>
//             <input
//               type="text"
//               className={classNames.inputContainer}
//               placeholder="Enter First Name (Optional)"
//               name="first_name"
//             />
//             <input
//               type="text"
//               className={classNames.inputContainer}
//               placeholder="Enter Last Name (Optional)"
//               name="last_name"
//             />
//             <input
//               type="text"
//               className={classNames.inputContainer}
//               placeholder="Phone Number (Optional)"
//               name="phone_number"
//             />
//           </div>
//         </div>
//       ))}

//       {dropdown && <IoMdArrowDropdown />}
//     </div>
//   );
// };

const EachInputFileDownload = ({ title, icon, stateValue, setState }) => {
  const handleDownloadClick = () => {
    // You can replace 'FILE_URL' with the actual URL of your file
    const fileUrl = stateValue;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "employeespreadsheettemplate.xlsx"; // Specify the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classNames.eachInput}>
      <div className={classNames.fileAction} onClick={handleDownloadClick}>
        <img src={icon} alt="downloadIcon" />
        <span>{title}</span>
      </div>
    </div>
  );
};

export const EachInputFileUpload = ({
  title,
  icon,
  stateValue,
  setState,
  setName,
}) => {
  const handleFileChange = (event) => {
    if (event?.target?.files?.length > 0) {
      setName(event?.target?.files[0]?.name);
      setState(event?.target?.files[0]);
    }
  };

  return (
    <div className={classNames.eachInput}>
      <label
        className={classNames.fileAction}
        htmlFor="fileUpload"
        onClick={handleFileChange}
      >
        <img src={icon} alt="downloadIcon" />
        <span>{title}</span>
      </label>
      <input
        type="file"
        id="fileUpload"
        accept=".xls,.xlsx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};
