import React, { useState, useEffect } from "react";
import styles from "./policyDetails.module.scss";
import Axios from "axios";
import { base64ToBlob, downloadFile } from "../../../../assest/functions";
import { predefinedToast } from "../../../../utils/toast";

const HealthCards = ({ selectedLead, selectedEmployee }) => {
  const [selectedUserPlocies, setSelectedUserPlocies] = useState([]);

  useEffect(() => {
    console.log(selectedEmployee, "ljdgkjwegdkwjed");
  }, [selectedEmployee]);

  const getHealthCards = () => {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/policy/policy-employee-map?employee_email_id=${selectedEmployee?.user_company_email}`
      // `https://coverfi.apimachine.com/api/v1/policy/policy-employee-map?employee_email_id=bokad16219@roborena.com`
    ).then(({ data }) => {
      if (data.status) {
        setSelectedUserPlocies(data.data);
      }
    });
  };

  useEffect(() => {
    console.log(selectedLead, selectedEmployee, "i am inside add policy");
    if (selectedLead && selectedEmployee) {
      getHealthCards();
      console.log("i am inside add policy");
    }
  }, [selectedLead, selectedEmployee]);

  const addPolicy = () => {
    // Axios.post(`https://coverfi.apimachine.com/api/v1/policy/policy-employee-map/create`, {
    //     employee_email_id: "SDsdsd",
    //     employee_id: "",
    //     policy_grade: "",
    //     company_employee_id: "E001",
    //     policy_id: "65a369358d208919ebeb9ab3"
    // }).then(({data}) => {
    // })
  };

  const downloadHealthCard = (tpaID, policyNum, employeeID) => {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/tpa/getEcard/${tpaID}/${encodeURIComponent(
        policyNum
      )}/${encodeURIComponent(employeeID)}`
    )
      .then((response) => {
        console.log(response, "downloadHealthCardresponse");
        if (
          Array.isArray(response?.data?.data) &&
          response?.data?.data?.length > 0 &&
          response?.data?.data[0]?.type == "browser"
        ) {
          predefinedToast("Policy downloaded successfully!");
          window?.open(
            response?.data?.data[0]?.ecard_url
              ? response?.data?.data[0]?.ecard_url
              : response?.data?.data[0]["e-card"],
            "_blank"
          );
        } else if (
          Array.isArray(response?.data?.data) &&
          response?.data?.data?.length > 0 &&
          response?.data?.data[0]?.type == "base64"
        ) {
          const blob = base64ToBlob(
            response?.data?.data[0]["e-card"],
            "application/pdf"
          );
          downloadFile(blob);
        } else {
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "downloadHealthCard error");
      });
  };

  return (
    <div>
      <div className={styles.tabWrapper}>
        {selectedUserPlocies.map((item) => (
          <div className={styles.tabItem}>
            <div className={styles.row1}>
              <div className={styles.compDetails}>
                <div>{item?.policy_type}</div>
                <div>
                  Policy By:{" "}
                  {item?.insurance_company_id?.insurance_company_name}
                </div>
              </div>
            </div>
            <div className={styles.row2}>
              <div>Start Date: {item?.start_date}</div>
              <div>Policy Number: {item?.policy_number}</div>
            </div>
            <div className={styles.row2}>
              <div>Valid Till: {item?.end_date}</div>
            </div>
            <div className={styles.row3}>
              <div>Coverage (USD):</div>
              <div className={styles.row4}>
                <div>
                  {item?.policy_rate_card?.sum_insured
                    ? item?.policy_rate_card?.sum_insured
                    : 0.0}
                </div>
                <div
                  onClick={(e) => {
                    console.log(item, "eeeee");
                    downloadHealthCard(
                      item?.policy_id?.tpa_id,
                      item?.policy_number,
                      item?.company_employee_id
                    );
                  }}
                >
                  Download Health Card
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HealthCards;
