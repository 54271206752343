import React, { useEffect, useState } from "react";
import classNames from "./employeehospital.module.scss";

import Skeleton from "react-loading-skeleton";
import { EachCustomPincodeDropdown } from "../../../components/Custom";
import { Redirect } from "react-router-dom";
import { useContext } from "react";
import { BankContext } from "../../../context/Context";
import NavbarV2 from "../../../components/NavbarV2";

import { ReactComponent as LocateIcon } from "../../../assest/images/allicons/locate.svg";
import GoogleMapComponent from "../../../components/MapContainer";
import NavbarV3 from "../../../components/NavbarV3";
import Axios from "axios";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutToSameLogin } from "../../../assest/functions";
import { predefinedToast } from "../../../utils/toast";

const EmployeeHospital = () => {
  const history = useHistory();
  const {
    email,
    globalHrId,
    globalSelectedPolicy,
    setGlobalSelectedPolicy,
    //hospital states
    allHospitalDetails,
    setAllHospitalDetails,
    groupedItems,
    setGroupedItems,
    groupedItemsLoading,
    setGroupedItemsLoading,
    allHospitalDetail,
    setAllHospitalDetail,
    sortEmployer,
  } = useContext(BankContext);
  const [allEmployersSearch, setAllEmployersSearch] = useState("");
  const [selectedProfileNav, setSelectedProfileNav] = useState("Map View");
  const [queriedFilter, setQueriedFilter] = useState("hospital_name");
  const [allHospitalPolicies, setAllHospitalPolicies] = useState([]);
  const [refreshPageLocal, setRefreshPageLocal] = useState(false);

  useEffect(() => {
    if (globalHrId) {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=${email}`
      )
        .then((response) => {
          if (
            response?.data?.data?.length > 0 &&
            response?.data?.data[0].policies_count > 0
          ) {
            setAllHospitalPolicies(response?.data?.data[0].policies);
          }
        })
        .catch((error) => {
          console.log(error, "get policies details error");
        });
    }

    if (allHospitalPolicies?.length < 1) {
      getGlobalHealthPolicies();
    }
  }, [email]);

  useEffect(() => {
    if (groupedItems?.length < 1 && allHospitalPolicies?.length > 0) {
      getHospitalData();
    } else {
      setGroupedItemsLoading(false);
    }
  }, [allHospitalPolicies, refreshPageLocal, globalSelectedPolicy]);

  const filterByDropdown = [
    { name: "Name", key: "hospital_name" },
    { name: "Pin Code", key: "hospital_pincode" },
    { name: "City", key: "hospital_city" },
  ];

  function getHospitalData() {
    setGroupedItemsLoading(true);

    if (globalSelectedPolicy || localStorage.getItem("globalSelectedPolicy")) {
      let filteredItem = allHospitalPolicies?.filter((eachItem, index) => {
        return (
          eachItem?.policy_number ===
          localStorage.getItem("globalSelectedPolicy")
        );
      });
      console.log(
        filteredItem,
        "Filtered policy response employee",
        allHospitalPolicies
      );
      if (filteredItem?.length > 0) {
        Axios.get(
          `https://coverfi.apimachine.com/api/v1/tpa/getNetworkHospital/${filteredItem[0]?.policy_id?.tpa_id?._id}/${filteredItem[0]?.policy_id?.insurance_company_id?._id}`
        )
          .then((response) => {
            console.log(response, "all hospitals list response employee");
            if (response?.data?.status && response?.data?.data) {
              // const dataArray = response?.data?.data?.providerData;
              const dataArray = response?.data?.data;
              setAllHospitalDetail(dataArray);
              setAllHospitalDetails(dataArray);
              sortEmployer(dataArray);

              console.log(dataArray, "dataArraydataArraydataArray employee");
            } else {
              setGroupedItemsLoading(false);
              setGroupedItems([]);
            }
          })
          .catch((error) => {
            console.log(error?.message, "all hospitals list error");
            setGroupedItemsLoading(false);
          });
      }
    }
  }

  function getGlobalHealthPolicies() {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=${email}`
    )
      .then((response) => {
        if (
          response?.data?.data?.length > 0 &&
          response?.data?.data[0].policies_count > 0
        ) {
          console.log(response?.data?.data);
          if (response?.data?.data?.length > 0) {
            setRefreshPageLocal((prev) => !prev);
          }
          setGlobalSelectedPolicy(
            response?.data?.data[0].policies[0]?.policy_number
          );
          localStorage.setItem(
            "globalSelectedPolicy",
            response?.data?.data[0].policies[0]?.policy_number
          );
        }
      })
      .catch((error) => {
        console.log(error, "get policies details error");
      });
  }

  async function getHospitalSheet(policyNumber) {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy?policy_number=${policyNumber}`
      );
      if (response?.data?.data?.length > 0) {
        let tpaID = response?.data?.data[0]?.tpa_id?._id;
        if (tpaID) {
          window.open(
            `https://coverfi.apimachine.com/api/v1/tpa/download/hospital-spreadsheet/${tpaID}`,
            "_blank"
          );
          predefinedToast("Hospital sheet downloaded!");
        }
      }
      console.log(response, "Get TPA id response");
    } catch (error) {
      console.log(error?.message, "TPA id error");
    }
  }

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={["List View", "Map View"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setAllEmployersSearch}
        removeItem={["Policy"]}
        specificPolicies={allHospitalPolicies}
      />
      <div className={classNames.topMenuOptions}>
        <div
          className={classNames.pincodeDropdown}
          style={{ visibility: "hidden" }}
        >
          <span>Filter By:</span>
          <EachCustomPincodeDropdown
            title="filterBy"
            dropdown={filterByDropdown}
            stateValue={queriedFilter}
            setState={setQueriedFilter}
          />
        </div>
        <div className={classNames.buttonsDiv}>
          <div
            className={classNames.normalBtn}
            onClick={() => {
              getHospitalSheet(globalSelectedPolicy);
            }}
          >
            Export
          </div>
          <div className={classNames.coloredBtn}>
            <LocateIcon />
            Locate me
          </div>
        </div>
      </div>
      <div className={classNames.myProfileDetails}>
        {selectedProfileNav == "Map View" ? (
          <AllEmployerHospitalMap
            searchQuery={allEmployersSearch}
            queriedFilter={queriedFilter}
          />
        ) : (
          <AllEmployerHospitalList
            searchQuery={allEmployersSearch}
            queriedFilter={queriedFilter}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeHospital;

const AllEmployerHospitalList = ({ searchQuery, queriedFilter }) => {
  const { groupedItems, groupedItemsLoading, allHospitalDetail } =
    useContext(BankContext);

  const [detailedOpen, setDetailedOpen] = useState(false);
  const [selectedHospitalDetail, setSelectedHospitalDetail] = useState("");

  function closePopup(event) {
    if (event?.target?.id == "detailedPopup") {
      setDetailedOpen(false);
    }
  }

  function filterHospitalDetail(selectedHospital) {
    let filterHospital = allHospitalDetail?.filter((eachHospital) => {
      return eachHospital?.hospital_name == selectedHospital;
    });
    setSelectedHospitalDetail(filterHospital[0]);
    setDetailedOpen(true);
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={classNames.allEmployerList}>
      {groupedItemsLoading ? (
        <div
          style={{ display: "flex", gap: "1.4rem", flexDirection: "column" }}
        >
          <div style={{ display: "flex", gap: "1.4rem" }}>
            <Skeleton width={250} height={250} />
            <Skeleton width={250} height={250} />
          </div>
          <div>
            <Skeleton width={250} height={250} />
          </div>
        </div>
      ) : groupedItems ? (
        Object?.entries(groupedItems)
          ?.filter(([letter, items]) =>
            items?.some((item) => {
              return item[queriedFilter]
                .toLowerCase()
                .includes(searchQuery?.toLowerCase());
            })
          )
          ?.map(([letter, items]) => {
            console.log(items, "each hospital items");
            return (
              <div
                key={letter}
                className={classNames.eachLetterList}
                id={`#employerHospital${letter}`}
                // style={{
                //   display: !queriedFilter
                //     ? ""
                //     : checkPincodeInArray(items, queriedFilter)
                //     ? ""
                //     : "none",
                // }}
              >
                <div className={classNames.title}>
                  <h5>Hospitals Starting With {letter}</h5>
                  <div></div>
                </div>
                <ul className={classNames.companiesList}>
                  {items
                    ?.filter((item) => {
                      return item[queriedFilter]
                        .toLowerCase()
                        .includes(searchQuery);
                    })
                    ?.map((item) => (
                      <li
                        key={item?._id}
                        onClick={() =>
                          filterHospitalDetail(item?.hospital_name)
                        }
                        // style={{
                        //   display:
                        //     queriedFilter && item?.hospital_pincode == queriedFilter
                        //       ? ""
                        //       : !queriedFilter
                        //       ? ""
                        //       : "none",
                        // }}
                      >
                        <div className={classNames.letterSelected}>
                          {letter}
                        </div>
                        <div>{item?.hospital_name}</div>
                      </li>
                    ))}
                </ul>
              </div>
            );
          })
      ) : (
        ""
      )}
      <div
        className={classNames.detailedPopup}
        id="detailedPopup"
        style={{ display: detailedOpen ? "" : "none" }}
        onClick={closePopup}
      >
        <div className={classNames.hospitalPopup}>
          <div className={classNames.flexDiv}>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>Hospital Name</div>
              <div className={classNames.mainValue}>
                {selectedHospitalDetail?.hospital_name
                  ? selectedHospitalDetail?.hospital_name
                  : ""}
              </div>
            </div>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>Phone Number</div>
              <div className={classNames.mainValue}>
                {selectedHospitalDetail?.hospital_contact
                  ? selectedHospitalDetail?.hospital_contact
                  : ""}
              </div>
            </div>
          </div>
          <div className={classNames.normalDiv}>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>Address</div>
              <div className={classNames.value}>
                {selectedHospitalDetail?.hospital_address1
                  ? selectedHospitalDetail?.hospital_address1
                  : ""}
              </div>
            </div>
          </div>
          <div className={classNames.gridDiv}>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>City</div>
              <div className={classNames.value}>
                {selectedHospitalDetail?.hospital_city
                  ? selectedHospitalDetail?.hospital_city
                  : ""}
              </div>
            </div>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>State</div>
              <div className={classNames.value}>
                {" "}
                {selectedHospitalDetail?.hospital_state
                  ? selectedHospitalDetail?.hospital_state
                  : ""}
              </div>
            </div>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>Pincode</div>
              <div className={classNames.value}>
                {" "}
                {selectedHospitalDetail?.hospital_pincode
                  ? selectedHospitalDetail?.hospital_pincode
                  : ""}
              </div>
            </div>
          </div>
          <div className={classNames.gridDiv}>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>Level Of Care</div>
              <div className={classNames.value}>
                {selectedHospitalDetail?.hospital_level_of_care
                  ? selectedHospitalDetail?.hospital_level_of_care
                  : ""}
              </div>
            </div>
            <div className={classNames.eachItem}>
              <div className={classNames.title}>Insurance Company</div>
              <div className={classNames.value}>
                {" "}
                {selectedHospitalDetail?.insurance_Company
                  ? selectedHospitalDetail?.insurance_Company
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames.allLettersList}>
        {Object?.entries(groupedItems)
          ?.filter(([letter, items]) =>
            items?.some((item) =>
              item?.hospital_name.toLowerCase().includes(searchQuery)
            )
          )
          ?.map(([letter, items], index) => (
            <span
              onClick={() => scrollToSection(`#employerHospital${letter}`)}
              key={index}
            >
              {letter}
            </span>
          ))}
      </div>
    </div>
  );
};

const AllEmployerHospitalMap = ({ searchQuery, queriedFilter }) => {
  const { groupedItems, groupedItemsLoading, allHospitalDetail } =
    useContext(BankContext);

  const [detailedOpen, setDetailedOpen] = useState(false);
  const [selectedHospitalDetail, setSelectedHospitalDetail] = useState("");

  function closePopup(event) {
    if (event?.target?.id == "detailedPopup") {
      setDetailedOpen(false);
    }
  }

  function filterHospitalDetail(selectedHospital) {
    let filterHospital = allHospitalDetail?.filter((eachHospital) => {
      return eachHospital?.hospital_name == selectedHospital;
    });
    setSelectedHospitalDetail(filterHospital[0]);
    setDetailedOpen(true);
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [currentLocation, setCurrentLocation] = useState(null);

  // Fetch current location using Geolocation API
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    }
  }, []);

  return (
    <div className={classNames.allEmployerListMap}>
      <div className={classNames.hospitalList}>
        <div className={classNames.heading}>
          200+ Hospitals located Near you
        </div>
        {groupedItemsLoading ? (
          <div
            style={{
              display: "flex",
              gap: "1.5rem",
              flexDirection: "column",
            }}
          >
            <div className={classNames.eachHospital}>
              <Skeleton width={40} height={40} borderRadius={10} />{" "}
              <Skeleton width={140} height={22} />
            </div>
            <div className={classNames.eachHospital}>
              <Skeleton width={40} height={40} borderRadius={10} />{" "}
              <Skeleton width={140} height={22} />
            </div>
          </div>
        ) : groupedItems ? (
          Object?.entries(groupedItems)
            ?.filter(([letter, items]) =>
              items?.some((item) => {
                return item[queriedFilter]
                  .toLowerCase()
                  .includes(searchQuery?.toLowerCase());
              })
            )
            ?.map(([letter, items]) => (
              <div
                key={letter}
                className={classNames.eachLetterList}
                id={`#employerHospital${letter}`}
              >
                <ul className={classNames.companiesList}>
                  {items
                    ?.filter((item) => {
                      return item[queriedFilter]
                        .toLowerCase()
                        .includes(searchQuery);
                    })
                    ?.map((item) => (
                      <li
                        key={item?._id}
                        onClick={() =>
                          filterHospitalDetail(item?.hospital_name)
                        }
                        className={classNames.eachHospital}
                      >
                        <div className={classNames.letterSelected}>
                          {letter}
                        </div>
                        <div className={classNames.hospitalName}>
                          {item?.hospital_name}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            ))
        ) : (
          ""
        )}
        <div
          className={classNames.detailedPopup}
          id="detailedPopup"
          style={{ display: detailedOpen ? "" : "none" }}
          onClick={closePopup}
        >
          <div className={classNames.hospitalPopup}>
            <div className={classNames.flexDiv}>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>Hospital Name</div>
                <div className={classNames.mainValue}>
                  {selectedHospitalDetail?.hospital_name
                    ? selectedHospitalDetail?.hospital_name
                    : ""}
                </div>
              </div>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>Phone Number</div>
                <div className={classNames.mainValue}>
                  {selectedHospitalDetail?.hospital_contact
                    ? selectedHospitalDetail?.hospital_contact
                    : ""}
                </div>
              </div>
            </div>
            <div className={classNames.normalDiv}>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>Address</div>
                <div className={classNames.value}>
                  {selectedHospitalDetail?.hospital_address1
                    ? selectedHospitalDetail?.hospital_address1
                    : ""}
                </div>
              </div>
            </div>
            <div className={classNames.gridDiv}>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>City</div>
                <div className={classNames.value}>
                  {selectedHospitalDetail?.hospital_city
                    ? selectedHospitalDetail?.hospital_city
                    : ""}
                </div>
              </div>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>State</div>
                <div className={classNames.value}>
                  {" "}
                  {selectedHospitalDetail?.hospital_state
                    ? selectedHospitalDetail?.hospital_state
                    : ""}
                </div>
              </div>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>Pincode</div>
                <div className={classNames.value}>
                  {" "}
                  {selectedHospitalDetail?.hospital_pincode
                    ? selectedHospitalDetail?.hospital_pincode
                    : ""}
                </div>
              </div>
            </div>
            <div className={classNames.gridDiv}>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>Level Of Care</div>
                <div className={classNames.value}>
                  {selectedHospitalDetail?.hospital_level_of_care
                    ? selectedHospitalDetail?.hospital_level_of_care
                    : ""}
                </div>
              </div>
              <div className={classNames.eachItem}>
                <div className={classNames.title}>Insurance Company</div>
                <div className={classNames.value}>
                  {" "}
                  {selectedHospitalDetail?.insurance_Company
                    ? selectedHospitalDetail?.insurance_Company
                    : ""}
                  u
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.googleMap}>
        <div className={classNames.allLettersList}>
          {Object?.entries(groupedItems)
            ?.filter(([letter, items]) =>
              items?.some((item) =>
                item?.hospital_name.toLowerCase().includes(searchQuery)
              )
            )
            ?.map(([letter, items], index) => (
              <span
                onClick={() => scrollToSection(`#employerHospital${letter}`)}
                key={index}
              >
                {letter}
              </span>
            ))}
        </div>
        <GoogleMapComponent map={map} setMap={setMap} />
      </div>
    </div>
  );
};
