import React, { useContext } from "react";
import "./pdf.scss";
import { BankContext } from "../../../context/Context";

const EReceiptPDF = () => {
  const { generatePDFData } = useContext(BankContext);
  return (
    <div
      class="ereceipt"
      id="pdfDownload"
      style={{
        position: "absolute",
        inset: "0",
        zIndex: "-1",
      }}
    >
      <div class="innerBox">
        <div class="logoBox">
          <img
            src="https://talltree.coverfi.app/static/media/fullLogoWhite.3527c60d2b93c609f04b4efd886c4b42.svg"
            alt="rahaLogoWhite"
          />
        </div>
        <div class="title">Transaction E-Receipt</div>
        <div class="detailsContainer">
          <div class="eachDetailTab">
            <span>Date</span>
            <span>{generatePDFData?.date}</span>
          </div>
          <div class="eachDetailTab">
            <span>Time</span>
            <span>{generatePDFData?.time}</span>
          </div>
          <div class="eachDetailTab">
            <span>Amount</span>
            <span>{generatePDFData?.totalamt}</span>
          </div>
          <div class="eachDetailTab">
            <span>Txn ID</span>
            <span>{generatePDFData?.trackid}</span>
          </div>
          <div class="eachDetailTab">
            <span>Status</span>
            <span>{generatePDFData?.txnstatus}</span>
          </div>
          {/* Primary Member Name, Roll Number, Sum Insured, and Mobile number in the Receipt field. */}
          <div class="eachDetailTab">
            <span>Primary Member Name</span>
            <span>{generatePDFData?.primary_member_details}</span>
          </div>
          <div class="eachDetailTab">
            <span>Roll Number</span>
            <span>{generatePDFData?.roll_number}</span>
          </div>
          <div class="eachDetailTab">
            <span>Sum Insured</span>
            <span>{generatePDFData?.sum_insured}</span>
          </div>
          <div class="eachDetailTab">
            <span>Mobile Number</span>
            <span>{generatePDFData?.phone_number}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EReceiptPDF;
