import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./membersforms.module.scss";
import CustomCheckbox from "../../../../components/Custom";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
} from "../../../../components/Custom/Input";
import { ReactComponent as HeartIcon } from "../../../../assest/images/icons/heart.svg";
import { ReactComponent as DownloadIcon } from "../../../../assest/images/employer/members/download.svg";
import { ReactComponent as UploadIcon } from "../../../../assest/images/employer/members/upload.svg";
import { ReactComponent as InitiatedtIcon } from "../../../../assest/images/employer/members/initiated.svg";
import { ReactComponent as PhoneIcon } from "../../../../assest/images/icons/phone.svg";
import { ReactComponent as MailIcon } from "../../../../assest/images/icons/mail.svg";
import { predefinedToast } from "../../../../utils/toast";
import UploadIconn from "../../../../assest/images/employer/members/upload.svg";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { BankContext } from "../../../../context/Context";
import {
  convertFamilyDefinitionValue,
  getCompanyIDGlobal,
  objectValidCheck,
  removeEmptyFields,
} from "../../../../assest/functions";
import { ValidateSpreadsheetComponent } from "../../../Employer/Overview/tabs";
import uploadFileIcon from "../../../../assest/images/icons/uploadfileIcon.svg";

const MembersForms = ({ setState }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  return (
    <div className={classNames.membersForms}>
      <div className={classNames.title}>
        Select Insurance company and Sum insured
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"healthForm"}
            name={"healthForm name"}
            type="array"
          />
        </div>
        <div>Group Health Insurance</div>
        <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div>
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentInsurance"}
            name={"personalAccidentInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
        <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div>
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentForm"}
            name={"personalAccidentForm name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
        <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button onClick={() => setState("singleentrymemberdetails")}>
          Next
        </button>
      </div>
    </div>
  );
};

export default MembersForms;

export const MembersFormsEnrollmentForm = ({
  setState,
  enrollmentFormSingle,
  setEnrollmentFormSingle,
  selectedTypesPreEnrollmentSingle: selectedTypes,
  setSelectedTypesPreEnrollmentSingle: setSelectedTypes,
  latestSelectedTypes,
  setLatestSelectedTypes,
}) => {
  const { globalSelectedCheckbox, globalHrId } = useContext(BankContext);

  const [enrollmentFormSingleTemp, setEnrollmentFormSingleTemp] = useState([]);
  const [healthDropdownItems, setHealthDropdownItems] = useState([]);
  const [accidentDropdownItems, setAccidentDropdownItems] = useState([]);
  const [lifeDropdownItems, setLifeDropdownItems] = useState([]);

  const uppercaseLetters = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index)
  );

  //functions

  function removeUnused() {
    let finalObj = enrollmentFormSingle;
    if (!selectedTypes?.includes("personalHealthInsurance")) {
      const { gmc_grade, gmc_si, ...rest } = finalObj;
      finalObj = { ...rest };
    }

    if (!selectedTypes?.includes("personalAccidentInsurance")) {
      const { gpa_grade, gpa_si, ...rest } = finalObj;
      finalObj = { ...rest };
    }

    if (!selectedTypes?.includes("personalLifeInsurance")) {
      const { gtli_grade, gtli_si, ...rest } = finalObj;
      finalObj = { ...rest };
    }
    setEnrollmentFormSingle(finalObj);
  }

  const isMounted = useRef(false);
  const isMounted1 = useRef(false);

  useEffect(() => {
    // This block will run only when the component mounts
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    // This block will run when selectedTypes state changes
    if (selectedTypes?.length > 0) {
      setLatestSelectedTypes(selectedTypes[selectedTypes?.length - 1]);
    }
  }, [selectedTypes]);

  useEffect(() => {
    // This block will run only when the component mounts
    if (!isMounted1.current) {
      isMounted1.current = true;
      return;
    }

    if (latestSelectedTypes === "personalHealthInsurance") {
      console.log("Coming inside health");
      //for health
      if (latestSelectedTypes !== globalSelectedCheckbox) {
        setEnrollmentFormSingle((prev) => {
          let removeEmpty = removeEmptyFields(prev);
          return { ...removeEmpty };
        });
      } else if (selectedTypes?.includes("personalHealthInsurance")) {
        setEnrollmentFormSingle((prev) => {
          return { ...prev, gmc_grade: "", gmc_si: "" };
        });
      } else {
        setEnrollmentFormSingle((prev) => {
          const { gmc_grade, gmc_si, ...rest } = prev;
          return { ...rest };
        });
      }
    } else if (latestSelectedTypes === "personalAccidentInsurance") {
      console.log("Coming inside accident");
      //for accident
      if (latestSelectedTypes !== globalSelectedCheckbox) {
        setEnrollmentFormSingle((prev) => {
          let removeEmpty = removeEmptyFields(prev);
          return { ...removeEmpty };
        });
      } else if (selectedTypes?.includes("personalAccidentInsurance")) {
        setEnrollmentFormSingle((prev) => {
          return { ...prev, gpa_grade: "", gpa_si: "" };
        });
      } else {
        setEnrollmentFormSingle((prev) => {
          const { gpa_grade, gpa_si, ...rest } = prev;
          return { ...rest };
        });
      }
    } else if (latestSelectedTypes === "personalLifeInsurance") {
      console.log("Coming inside life");
      //for life
      if (latestSelectedTypes !== globalSelectedCheckbox) {
        setEnrollmentFormSingle((prev) => {
          let removeEmpty = removeEmptyFields(prev);
          return { ...removeEmpty };
        });
      } else if (selectedTypes?.includes("personalLifeInsurance")) {
        setEnrollmentFormSingle((prev) => {
          return { ...prev, gtli_grade: "", gtli_si: "" };
        });
      } else {
        setEnrollmentFormSingle((prev) => {
          const { gtli_grade, gtli_si, ...rest } = prev;
          return { ...rest };
        });
      }
    }

    //testing data
  }, [latestSelectedTypes]);

  //get grade dropdown values

  async function getGradeDropdownValues(policyType, setState) {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy/grade-details/?hr_id=${globalHrId}&policy_type=${policyType}`
      );
      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setState(response?.data?.data);
      }
      console.log(response, `getGradeDropdownValues response ${policyType}`);
    } catch (error) {
      console.log(error?.message, "Get grade dropdown error");
    }
  }

  useEffect(() => {
    getGradeDropdownValues("Health", setHealthDropdownItems);
    getGradeDropdownValues("Accidental", setAccidentDropdownItems);
    getGradeDropdownValues("Life", setLifeDropdownItems);
  }, []);

  useEffect(() => {
    console.log(
      enrollmentFormSingleTemp,
      "rendering update",
      enrollmentFormSingle
    );
    if (enrollmentFormSingleTemp?.health) {
      setEnrollmentFormSingle((prev) => {
        return {
          ...prev,
          gmc_grade: enrollmentFormSingleTemp?.health?.grade,
          gmc_si: enrollmentFormSingleTemp?.health?.sum_insured,
        };
      });
    }
    if (enrollmentFormSingleTemp?.acident) {
      setEnrollmentFormSingle((prev) => {
        return {
          ...prev,
          gpa_grade: enrollmentFormSingleTemp?.acident?.grade,
          gpa_si: enrollmentFormSingleTemp?.acident?.sum_insured,
        };
      });
    }
    if (enrollmentFormSingleTemp?.life) {
      setEnrollmentFormSingle((prev) => {
        return {
          ...prev,
          gtli_grade: enrollmentFormSingleTemp?.life?.grade,
          gtli_si: enrollmentFormSingleTemp?.life?.sum_insured,
        };
      });
    }
  }, [
    enrollmentFormSingleTemp?.health,
    enrollmentFormSingleTemp?.acident,
    enrollmentFormSingleTemp?.life,
  ]);

  return (
    <div className={classNames.membersForms}>
      <div className={classNames.title}>
        Select Insurance company and Sum insured
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalHealthInsurance"}
            name={"personalHealthInsurance name"}
            type="array"
          />
        </div>
        <div>Group Health Insurance</div>
      </div>
      {selectedTypes?.includes("personalHealthInsurance") && (
        <div className={classNames.gradeSubSelections}>
          <div className={classNames.dropdownWithTitle}>
            <div>Select grade</div>
            <EachCustomDropdown
              // dropdown={["Flat", ...uppercaseLetters]}
              dropdown={healthDropdownItems}
              name="health"
              placeholder={
                healthDropdownItems?.length > 0
                  ? "Click to select"
                  : "No Policies"
              }
              stateValue={enrollmentFormSingleTemp}
              setState={setEnrollmentFormSingleTemp}
              typee="objVal"
              option={"checkValue"}
              objName={"grade"}
              mapName={["policy_number", "grade", "sum_insured"]}
            />
          </div>
          <div style={{ pointerEvents: "none" }}>
            <EachCustomInput
              title="Enter sum insured"
              placeholder="0.00"
              name="gmc_si"
              stateValue={enrollmentFormSingle}
              setState={setEnrollmentFormSingle}
            />
          </div>
        </div>
      )}
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentInsurance"}
            name={"personalAccidentInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
        {/* <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div> */}
      </div>
      {selectedTypes?.includes("personalAccidentInsurance") && (
        <div className={classNames.gradeSubSelections}>
          <div className={classNames.dropdownWithTitle}>
            <div>Select grade</div>
            <EachCustomDropdown
              // dropdown={["Flat", ...uppercaseLetters]}
              placeholder={
                accidentDropdownItems?.length > 0
                  ? "Click to select"
                  : "No Policies"
              }
              name="accident"
              stateValue={enrollmentFormSingleTemp}
              setState={setEnrollmentFormSingleTemp}
              typee="objVal"
              option={"checkValue"}
              objName={"grade"}
              mapName={["policy_number", "grade", "sum_insured"]}
            />
          </div>
          <div style={{ pointerEvents: "none" }}>
            <EachCustomInput
              title="Enter sum insured"
              placeholder="0.00"
              name="gpa_si"
              stateValue={enrollmentFormSingle}
              setState={setEnrollmentFormSingle}
            />
          </div>
        </div>
      )}
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalLifeInsurance"}
            name={"personalLifeInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Life Insurance</div>
      </div>
      {selectedTypes?.includes("personalLifeInsurance") && (
        <div className={classNames.gradeSubSelections}>
          <div className={classNames.dropdownWithTitle}>
            <div>Select grade</div>
            <EachCustomDropdown
              // dropdown={["Flat", ...uppercaseLetters]}
              dropdown={lifeDropdownItems}
              placeholder={
                lifeDropdownItems?.length > 0
                  ? "Click to select"
                  : "No Policies"
              }
              name="life"
              stateValue={enrollmentFormSingleTemp}
              setState={setEnrollmentFormSingleTemp}
              typee="objVal"
              option={"checkValue"}
              objName={"grade"}
              mapName={["policy_number", "grade", "sum_insured"]}
            />
          </div>
          <div style={{ pointerEvents: "none" }}>
            <EachCustomInput
              title="Enter sum insured"
              placeholder="0.00"
              name="gtli_si"
              stateValue={enrollmentFormSingle}
              setState={setEnrollmentFormSingle}
            />
          </div>
        </div>
      )}
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button
          style={{
            pointerEvents:
              selectedTypes?.length > 0 &&
              objectValidCheck(enrollmentFormSingle)
                ? ""
                : "none",
            opacity:
              selectedTypes?.length > 0 &&
              objectValidCheck(enrollmentFormSingle)
                ? ""
                : "0.5",
          }}
          onClick={() => {
            removeUnused();
            setState("singleentrymemberdetailsenrollmentform");
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const MembersDetailsFormEnrollmentForm = ({
  setState,
  enrollmentFormSingle,
  setEnrollmentFormSingle,
}) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  return (
    <div className={classNames.membersDetailsForm}>
      <div className={classNames.title}>Add Member Details</div>
      <div className={classNames.formFields}>
        <EachCustomInput
          placeholder="Employee Name"
          name="employee_name"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          validation={{ type: "name_validation" }}
        />
        <EachCustomInput
          placeholder="Employee ID"
          name="company_employee_id"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
        />
        <EachCustomDatePicker
          //   title="Policy valid until"
          placeholder="Date of Birth"
          // dropdown={usersDropdown}
          name="date_of_birth"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          typee={"spreadObj"}
          validation={{ type: "relationship", relationship: "self" }}
          //   mandatory={clientOnboardingForm ? true : false}
        />
        <EachCustomDropdown
          buttonText={enrollmentFormSingle ? enrollmentFormSingle : "Sum"}
          // color="var(--main-color)"
          dropdown={["Male", "Female"]}
          name="gender"
          placeholder="Gender"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          typee="single"
          option={"checkValue"}
        />

        <EachCustomInput
          placeholder="Email ID"
          name="email_id"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          validation={{ type: "email" }}
        />
        <EachCustomInput
          placeholder="Designation"
          name="designation"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
        />

        <EachCustomInput
          placeholder="Phone number"
          name="phone_number"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          validation={{ type: "phone_validation" }}
        />
        <EachCustomDropdown
          dropdown={[
            "Employee only",
            "Employee plus spouse",
            "Employee plus spouse, 2 children up to 25 years",
            "Employee plus spouse, 2 Children up to 25 years, Parents OR Parent-in-Law to 80 years",
            "Employee plus spouse, 2 Children up to 25 years, Parents & Parent-in-Law to 80 years",
          ]}
          name="family_definition"
          placeholder="Select family definition"
          stateValue={enrollmentFormSingle}
          setState={setEnrollmentFormSingle}
          typee="single"
          option={"checkValue"}
        />
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("singleentryenrollmentform")}>
          Back
        </button>
        <button
          onClick={() => {
            console.log(enrollmentFormSingle, "enrollmentFormSingle filled");
            setState("singleentrymemberreviewenrollmentform");
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const MembersReviewDetailsEnrollmentForm = ({
  setState,
  enrollmentFormSingle,
  setEnrollmentFormSingle,
}) => {
  const { globalHrId } = useContext(BankContext);
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  const [memberDetailsFormLoading, setMemberDetailsFormLoading] =
    useState(false);

  async function invitePreEnrollmentSingle() {
    setMemberDetailsFormLoading(true);
    enrollmentFormSingle.family_definition = convertFamilyDefinitionValue(
      enrollmentFormSingle.family_definition
    );
    let obj = {
      hr_id: globalHrId,
      employee_data: [enrollmentFormSingle],
    };

    try {
      let response = await Axios.post(
        "https://coverfi.apimachine.com/api/v1//employee-spreadsheet/upload-by-api",
        obj
      );
      setMemberDetailsFormLoading(false);
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        setState("");
      } else {
        predefinedToast("Employee adding failed");
        setState("");
      }
      console.log(response, "invitePreEnrollmentSingle success");
    } catch (error) {
      setMemberDetailsFormLoading(false);
      predefinedToast(error?.message);
      console.log(error?.message, "invitePreEnrollmentSingle failed");
    }
  }

  return (
    <div className={classNames.membersDetailsForm}>
      {memberDetailsFormLoading && (
        <div className={classNames.loadingImage}>
          <img
            src="https://talltree.coverfi.app/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
            alt="loadingImage"
          />
        </div>
      )}
      <div className={classNames.title}>Review employee details</div>
      <div className={classNames.subTitle}>Insurance details</div>
      {enrollmentFormSingle?.gmc_grade && (
        <div className={classNames.detailsContainer}>
          <div>
            <div>
              Group Health insurance Policy{" "}
              {enrollmentFormSingle?.gmc_grade === "Flat"
                ? "(Flat)"
                : `(Grade ${enrollmentFormSingle?.gmc_grade})`}
            </div>
            <div>USD {enrollmentFormSingle?.gmc_si}</div>
          </div>
          <div></div>
        </div>
      )}
      {enrollmentFormSingle?.gpa_grade && (
        <div className={classNames.detailsContainer}>
          <div>
            <div>
              Group Accidental insurance Policy{" "}
              {enrollmentFormSingle?.gpa_grade === "Flat"
                ? "(Flat)"
                : `(Grade ${enrollmentFormSingle?.gpa_grade})`}
            </div>
            <div>USD {enrollmentFormSingle?.gpa_si}</div>
          </div>
        </div>
      )}
      {enrollmentFormSingle?.gtli_grade && (
        <div className={classNames.detailsContainer}>
          <div>
            <div>
              Group Life insurance Policy{" "}
              {enrollmentFormSingle?.gtli_grade === "Flat"
                ? "(Flat)"
                : `(Grade ${enrollmentFormSingle?.gtli_grade})`}
            </div>
            <div>USD {enrollmentFormSingle?.gtli_si}</div>
          </div>
        </div>
      )}
      <div className={classNames.subTitle}>Member details</div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>{enrollmentFormSingle?.employee_name}</div>
          <div>{enrollmentFormSingle?.family_definition}</div>
        </div>
        {/* <div>Employee</div> */}
      </div>

      <div className={classNames.messageContainer}>
        Sending invitation to {enrollmentFormSingle?.email_id}
      </div>

      <div className={classNames.btnsContainer}>
        <button
          onClick={() => setState("singleentrymemberdetailsenrollmentform")}
        >
          Back
        </button>
        <button
          onClick={() => {
            invitePreEnrollmentSingle();
            // setState("MembersReviewCompleted");
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const MembersDetailsForm = ({ setState }) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  return (
    <div className={classNames.membersDetailsForm}>
      <div className={classNames.title}>Add Member Details</div>
      <div className={classNames.formFields}>
        <EachCustomInput
          placeholder="Employee Name"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomInput
          placeholder="Employee ID"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomDatePicker
          //   title="Policy valid until"
          placeholder="Date of Birth"
          // dropdown={usersDropdown}
          name="startDate"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
          //   mandatory={clientOnboardingForm ? true : false}
        />
        <EachCustomDropdown
          buttonText={memberDetailsForm ? memberDetailsForm : "Sum"}
          // color="var(--main-color)"
          dropdownItems={["Male", "Female"]}
          name="gender"
          placeholder="Gender"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
          typee="single"
        />
        <EachCustomDatePicker
          //   title="Policy valid until"
          placeholder="Date of Joining"
          // dropdown={usersDropdown}
          name="startDate"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
          //   mandatory={clientOnboardingForm ? true : false}
        />
        <EachCustomInput
          placeholder="Designation"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomInput
          placeholder="Email ID"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomInput
          placeholder="Phone number"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("singleentry")}>Back</button>
        <button onClick={() => setState("MemberDetailsAddDependent")}>
          Next
        </button>
      </div>
    </div>
  );
};

export const MemberDetailsAddDependent = ({ setState }) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState({
    name: "",
    dob: "",
    relation: "",
  });
  const [dependentList, setDependentList] = useState([]);

  useEffect(() => {
    setMemberDetailsForm({ name: "", dob: "", relation: "" });
  }, [dependentList]);

  return (
    <div className={classNames.memberAddDependent}>
      <div className={classNames.title}>Add Member Details</div>
      {Array(dependentList?.length >= 0 ? dependentList?.length + 1 : 1)
        .fill()
        .map((eachItem, index) => {
          return (
            <div className={classNames.allDependents} key={eachItem + index}>
              <EachCustomInput
                placeholder="Name"
                name="name"
                stateValue={memberDetailsForm}
                setState={setMemberDetailsForm}
              />
              <EachCustomDatePicker
                //   title="Policy valid until"
                placeholder="Date of Birth"
                // dropdown={usersDropdown}
                name="dob"
                stateValue={memberDetailsForm}
                setState={setMemberDetailsForm}
                //   mandatory={clientOnboardingForm ? true : false}
              />
              <EachCustomDropdown
                buttonText={memberDetailsForm ? memberDetailsForm : "Relation"}
                // color="var(--main-color)"
                dropdownItems={["Self", "Spouse"]}
                name="relation"
                placeholder="Relation"
                stateValue={memberDetailsForm}
                setState={setMemberDetailsForm}
                typee="single"
              />
            </div>
          );
        })}
      <div
        className={classNames.addDependentBtn}
        onClick={() => {
          setDependentList((prev) => {
            return [...prev, memberDetailsForm];
          });
        }}
      >
        <HeartIcon />
        Add another dependent
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("singleentrymemberdetails")}>
          Back
        </button>
        <button onClick={() => setState("MembersReviewDetails")}>Next</button>
      </div>
    </div>
  );
};

export const MembersReviewDetails = ({ setState }) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  return (
    <div className={classNames.membersDetailsForm}>
      <div className={classNames.title}>Review employee details</div>
      <div className={classNames.subTitle}>Insurance details</div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Group Health insurance Policy</div>
          <div>USD 5,00,000</div>
        </div>
        <div></div>
      </div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Group Accidental insurance Policy</div>
          <div>USD 5,00,000</div>
        </div>
      </div>
      <div className={classNames.subTitle}>Member details</div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Shorupan Pirakaspathy</div>
          <div>09/09/2023</div>
        </div>
        <div>Employee</div>
      </div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Shorupan Pirakaspathy</div>
          <div>09/09/2023</div>
        </div>
        <div>Wife</div>
      </div>

      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("MemberDetailsAddDependent")}>
          Back
        </button>
        <button onClick={() => setState("MembersReviewCompleted")}>Next</button>
      </div>
    </div>
  );
};

export const MembersReviewCompleted = ({ setState }) => {
  return (
    <div className={classNames.bulkUploadMemberUpdated}>
      <div className={classNames.title} onClick={() => setState([])}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span>Members Updated</span>
          <div className={classNames.diffTypes}>
            <div>Added: 1</div>
            <div>Deleted: 0</div>
            <div>Updated: 0</div>
          </div>
          <div className={classNames.balanceContainer}>
            <div>CD Balance</div>
            <div>USD 45,000.00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BulkUpload = ({ setState }) => {
  return (
    <div className={classNames.bulkUpload}>
      <div className={classNames.title}>Bulk Upload</div>
      <div className={classNames.bulkDocuments}>
        <div>
          <DownloadIcon />
          <span>Download Template</span>
        </div>
        <div>
          <UploadIcon />
          <span>Upload Template</span>
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button onClick={() => setState("bulkentryupdated")}>Next</button>
      </div>
    </div>
  );
};

export const BulkUploadMemberUpdated = ({ setState }) => {
  return (
    <div className={classNames.bulkUploadMemberUpdated}>
      <div className={classNames.title} onClick={() => setState([])}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span>Members Updated</span>
          <div className={classNames.diffTypes}>
            <div>Added: 1</div>
            <div>Deleted: 0</div>
            <div>Updated: 0</div>
          </div>
          <div className={classNames.balanceContainer}>
            <div>CD Balance</div>
            <div>USD 45,000.00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PreEnrollmentForm = ({ setState }) => {
  const [selectedTypes, setSelectedTypes] = useState([
    "allThreeInsuranceTypes",
  ]);
  return (
    <div className={classNames.membersForms}>
      <div className={classNames.title} style={{ marginBottom: "1rem" }}>
        Step 1: Select Insurance Policy
      </div>
      <div
        className={classNames.eachInsurance}
        style={{ pointerEvents: "none", opacity: "0.5" }}
      >
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"healthForm"}
            name={"healthForm name"}
            type="array"
          />
        </div>
        <div>Group Health Insurance</div>
      </div>
      <div
        className={classNames.eachInsurance}
        style={{ pointerEvents: "none", opacity: "0.5" }}
      >
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentInsurance"}
            name={"personalAccidentInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
      </div>
      <div
        className={classNames.eachInsurance}
        style={{ pointerEvents: "none", opacity: "0.5" }}
      >
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalLifeInsurance"}
            name={"personalLifeInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Life Insurance</div>
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"allThreeInsuranceTypes"}
            name={"allThreeInsuranceTypes name"}
            type="array"
          />
        </div>
        <div>All three insurance types</div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button onClick={() => setState("PreEnrollmentDownloads")}>Next</button>
      </div>
    </div>
  );
};

export const PreEnrollmentDownloads = ({ setState }) => {
  const history = useHistory();
  const { globalHrId, setUpdateLoading } = useContext(BankContext);
  const [uploadFileSelected, setUploadFileSelected] = useState("");
  const [uploadFileSelectedName, setUploadFileSelectedName] = useState("");

  function uploadEnrollmentViaPreEnrollment() {
    setUpdateLoading(true);
    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append("file", uploadFileSelected);

    Axios.post(
      "https://coverfi.apimachine.com/api/v1//employee-spreadsheet/upload-endorsement",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        console.log(response, "Uploaded endorsements via pre enrolment");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setUpdateLoading(false);
          setState("PreEnrollmentUpdated");
          // setTimeout(() => {
          //   if (window?.location?.pathname?.includes("/agency")) {
          //     history.push("/app/agency/leads");
          //   } else {
          //     history.push("/app/coverfi/members");
          //   }
          //   // window.location.reload();
          // }, 3000);
        } else {
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
          setState("PreEnrollmentDownloads");
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded endorsements via pre enrolment error"
        );
        setState("PreEnrollmentDownloads");
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  return (
    <div className={classNames.bulkUpload}>
      <div className={classNames.title}>Upload communication info</div>
      <div className={classNames.bulkDocuments}>
        <div
          onClick={() => {
            window.open(
              "https://insurancecoverfi.apimachine.com/insurance/general/files/fb0947f0-8f25-4118-8c86-62aff6c57a98.xlsx",
              "_blank"
            );
          }}
        >
          <DownloadIcon />
          <span>Download Template</span>
        </div>
        <div>
          {/* <UploadIcon /> */}
          <EachInputFileUpload
            title={
              uploadFileSelectedName
                ? uploadFileSelectedName
                : "Upload Template"
            }
            icon={UploadIconn}
            stateValue={uploadFileSelected}
            setState={setUploadFileSelected}
            setName={setUploadFileSelectedName}
          />
          {/* <span>Upload Template</span> */}
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("PreEnrollmentForm")}>Back</button>
        <button onClick={uploadEnrollmentViaPreEnrollment}>Next</button>
      </div>
    </div>
  );
};

export const PreEnrollmentUpdated = ({ setState }) => {
  return (
    <div className={classNames.bulkUploadMemberUpdated}>
      <div className={classNames.title} onClick={() => setState([])}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span>Enrolment Initiated</span>
          <div className={classNames.diffTypes} style={{ padding: "0 3rem" }}>
            <div>Added: 1</div>
            {/* <div>Deleted: 0</div>
            <div>Updated: 0</div> */}
          </div>
          <div className={classNames.balanceContainer}>
            <div>CD Balance</div>
            <div>USD 45,000.00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormUpdateCompleted = ({ setState }) => {
  return (
    <div className={classNames.formUpdateCompleted}>
      <div className={classNames.title} onClick={() => setState(false)}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span style={{ fontWeight: "500" }}>
            Thank you for your request. We will be of your assistance at every
            step of the way
          </span>
          <span>
            Shortly you will find a confirmation in your email with next steps.
          </span>
          <div className={classNames.contactUs}>
            <div className={classNames.title}>Contact Us</div>
            <div className={classNames.contactDiv}>
              <div>
                Speak to Team Lead
                <div className={classNames.iconDiv}>
                  <PhoneIcon />
                  <MailIcon />
                </div>
              </div>
              <div>
                Speak to Associate
                <div className={classNames.iconDiv}>
                  <PhoneIcon />
                  <MailIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BulkEntryEnrollmentForm = ({ setState }) => {
  const { globalHrId } = useContext(BankContext);
  const [uploadedSheetValidationErrors, setUploadedSheetValidationErrors] =
    useState("");
  const [quoteReviewForm, setQuoteReviewForm] = useState("");
  const [downloadSpreadsheet, setDownloadSpreadsheet] = useState("");
  const [fileName, setFileName] = useState("");
  const [memberDetailsFormLoading, setMemberDetailsFormLoading] = useState("");

  //functions

  async function fetchDownloadLinks() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/employee-spreadsheet`
      );
      if (
        response?.data?.data?.length > 0 &&
        Array.isArray(response?.data?.data)
      ) {
        setDownloadSpreadsheet(response?.data?.data[0]);
      }
      console.log(response, "fetch spreadsheet");
    } catch (error) {
      console.log(error?.message, "fetch spreadsheet error");
    }
  }

  async function getDataFillsAndValidate(document) {
    try {
      let url =
        quoteReviewForm?.enrollmentType === "Employee validates the data"
          ? "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate-with-dependents"
          : `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate`;

      const formData = new FormData();
      formData.append("file", document);

      let response = await Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (
        Array?.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setUploadedSheetValidationErrors(response?.data?.data[0]);
      }

      console.log(response?.data?.data, "getDataFills response");
    } catch (error) {
      console.log(error?.message, "Employee fills data error");
    }
  }

  function uploadEnrollmentViaPreEnrollment() {
    setMemberDetailsFormLoading(true);

    let url;

    if (quoteReviewForm?.enrollmentType === "Employee validates the data") {
      url =
        "https://coverfi.apimachine.com/api/v1//employee-spreadsheet/upload-with-dependents";
    } else {
      url =
        "https://coverfi.apimachine.com/api/v1//employee-spreadsheet/upload";
    }

    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append("file", quoteReviewForm?.document);

    Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response, "Uploaded endorsements via pre enrolment bulk");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setMemberDetailsFormLoading(false);
          setState("");
        } else {
          setMemberDetailsFormLoading(false);
          predefinedToast(response?.data?.message);
          setState("");
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded endorsements via pre enrolment error"
        );
        // setState("PreEnrollmentDownloads");
        setMemberDetailsFormLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  //rendering

  useEffect(() => {
    fetchDownloadLinks();
  }, []);

  useEffect(() => {
    if (quoteReviewForm?.document) {
      setQuoteReviewForm((prev) => {
        return { ...prev, document: "" };
      });
      setUploadedSheetValidationErrors("");
      setFileName("");
    }
  }, [quoteReviewForm?.enrollmentType]);

  return (
    <div className={classNames.bulkUpload}>
      {memberDetailsFormLoading && (
        <div className={classNames.loadingImage}>
          <img
            src="https://talltree.coverfi.app/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
            alt="loadingImage"
          />
        </div>
      )}
      <div className={classNames.title}>Upload communication info</div>
      <div className={classNames.fieldsContainer}>
        <div className={classNames.singleInput}>
          <EachCustomDropdown
            title="Select type of enrolment"
            dropdown={[
              "Employee fills the data",
              "Employee validates the data",
            ]}
            name="enrollmentType"
            stateValue={quoteReviewForm}
            setState={setQuoteReviewForm}
            typee="single"
          />
        </div>
      </div>
      <div className={classNames.bulkDocuments}>
        <div
          onClick={() => {
            // window.open(
            //   "https://insurancecoverfi.apimachine.com/insurance/general/files/fb0947f0-8f25-4118-8c86-62aff6c57a98.xlsx",
            //   "_blank"
            // );
            if (
              quoteReviewForm?.enrollmentType === "Employee fills the data" &&
              downloadSpreadsheet?.employee_pre_enrollment
            ) {
              window.open(
                downloadSpreadsheet?.employee_pre_enrollment,
                "_blank"
              );
            } else if (
              quoteReviewForm?.enrollmentType ===
                "Employee validates the data" &&
              downloadSpreadsheet?.employee_and_dependent_pre_enrollment
            ) {
              window.open(
                downloadSpreadsheet?.employee_and_dependent_pre_enrollment,
                "_blank"
              );
            }
          }}
        >
          <DownloadIcon />
          <span>Download Template</span>
        </div>

        <div
          style={{
            pointerEvents: quoteReviewForm?.enrollmentType ? "" : "none",
          }}
        >
          <label
            htmlFor={"profileUpdateImgPlanBdocument"}
            className={classNames.uploadFileDiv}
            style={{ maxWidth: "100%" }}
          >
            <img src={uploadFileIcon} alt="uploadFileIcon" />
            <div
              style={{
                paddingTop: "1rem",
                textOverflow: "ellipse",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {fileName ? fileName : "Upload File"}
            </div>
            <div className={classNames.uploadFile}>
              <input
                className={classNames.uploadNewPicPlanB}
                type="file"
                style={{ display: "none" }}
                onChange={(event) => {
                  console.log(event?.target?.files, "files list");
                  if (event?.target?.files?.length > 0) {
                    setFileName(event?.target?.files[0]?.name);
                    setQuoteReviewForm({
                      ...quoteReviewForm,
                      document: event?.target?.files[0],
                    });
                    if (
                      quoteReviewForm?.enrollmentType ===
                      "Employee fills the data"
                    ) {
                      getDataFillsAndValidate(event?.target?.files[0]);
                    } else if (
                      quoteReviewForm?.enrollmentType ===
                      "Employee validates the data"
                    ) {
                      getDataFillsAndValidate(event?.target?.files[0]);
                    }
                  }
                }}
                accept="*/*"
                id={"profileUpdateImgPlanBdocument"}
              />
            </div>
          </label>
        </div>
      </div>
      {uploadedSheetValidationErrors && (
        <ValidateSpreadsheetComponent data={uploadedSheetValidationErrors} />
      )}
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button
          style={{
            pointerEvents:
              quoteReviewForm?.enrollmentType && quoteReviewForm?.document
                ? ""
                : "none",
            opacity:
              quoteReviewForm?.enrollmentType && quoteReviewForm?.document
                ? ""
                : "0.5",
          }}
          onClick={uploadEnrollmentViaPreEnrollment}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const BulkAssignPoliciesMembers = ({ stateValue, setState }) => {
  const { globalHrId, globalCompanyUnderscoreId } = useContext(BankContext);
  const [uploadedSheetValidationErrors, setUploadedSheetValidationErrors] =
    useState("");
  const [quoteReviewForm, setQuoteReviewForm] = useState("");
  const [localCompanyID, setLocalCompanyID] = useState("");
  const [downloadSpreadsheet, setDownloadSpreadsheet] = useState("");
  const [downloadSpreadsheetBody, setDownloadSpreadsheetBody] = useState("");
  const [allPolicies, setAllPolicies] = useState("");
  const [fileName, setFileName] = useState("");
  const [memberDetailsFormLoading, setMemberDetailsFormLoading] = useState("");

  //functions

  async function fetchAllPolicies() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}`
      );
      if (
        response?.data?.data?.length > 0 &&
        Array.isArray(response?.data?.data)
      ) {
        setAllPolicies(response?.data?.data);
      }
      console.log(response, "fetch all policies");
    } catch (error) {
      console.log(error?.message, "fetch all policies error");
    }
  }

  async function getDataFillsAndValidate(document) {
    try {
      let url =
        quoteReviewForm?.enrollmentType === "Employee validates the data"
          ? "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate-with-dependents"
          : `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate`;

      const formData = new FormData();
      formData.append("file", document);

      let response = await Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (
        Array?.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setUploadedSheetValidationErrors(response?.data?.data[0]);
      }

      console.log(response?.data?.data, "getDataFills response");
    } catch (error) {
      console.log(error?.message, "Employee fills data error");
    }
  }

  function uploadBulkAssignPoliciesToMembers() {
    setMemberDetailsFormLoading(true);
    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append("file", quoteReviewForm?.document);

    Axios.post(
      "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/add-employee/quote/spreadsheet",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        console.log(response, "Uploaded endorsements via pre enrolment bulk");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setMemberDetailsFormLoading(false);
          setState("");
        } else {
          setMemberDetailsFormLoading(false);
          predefinedToast(response?.data?.message);
          setState("");
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded endorsements via pre enrolment error"
        );
        // setState("PreEnrollmentDownloads");
        setMemberDetailsFormLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  // async function getcompanyId() {
  //   let id = await getCompanyIDGlobal(selectedRow?.lead_email);
  //   setLocalCompanyID(id);
  // }

  //rendering

  useEffect(() => {
    fetchAllPolicies();
    // getcompanyId();
  }, []);

  useEffect(() => {
    if (downloadSpreadsheet?._id) {
      let filterSelected = allPolicies?.filter((eachPolicy) => {
        return eachPolicy?._id == downloadSpreadsheet?._id;
      });
      if (filterSelected?.length > 0) {
        setDownloadSpreadsheetBody(filterSelected[0]);
        console.log(stateValue, filterSelected, "");
      }
    }
  }, [downloadSpreadsheet]);

  // useEffect(() => {
  //   if (quoteReviewForm?.document) {
  //     setQuoteReviewForm((prev) => {
  //       return { ...prev, document: "" };
  //     });
  //     setUploadedSheetValidationErrors("");
  //     setFileName("");
  //   }
  // }, [quoteReviewForm?.enrollmentType]);

  return (
    <div className={classNames.bulkUpload}>
      {memberDetailsFormLoading && (
        <div className={classNames.loadingImage}>
          <img
            src="https://talltree.coverfi.app/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
            alt="loadingImage"
          />
        </div>
      )}
      <div
        className={classNames.title}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Bulk assign policies to members{" "}
        <span style={{ cursor: "pointer" }} onClick={() => setState("")}>
          X
        </span>
      </div>
      <div className={classNames.fieldsContainer}>
        <div className={classNames.singleInput}>
          <EachCustomDropdown
            title="Select policy"
            dropdown={allPolicies}
            name="_id"
            stateValue={downloadSpreadsheet}
            setState={setDownloadSpreadsheet}
            typee="objVal"
            mapName={"policy_number"}
            objName={"_id"}
          />
        </div>
      </div>
      <div className={classNames.bulkDocuments}>
        <div
          onClick={() => {
            if (
              downloadSpreadsheetBody?.policy_number &&
              downloadSpreadsheetBody?.quote_id &&
              globalCompanyUnderscoreId
            ) {
              console.log(
                `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/download/quote/${globalCompanyUnderscoreId}?policy_number=${downloadSpreadsheetBody?.policy_number}&quote_id=${downloadSpreadsheetBody?.quote_id}`,
                "_blank",
                "download sheet"
              );
              window?.open(
                `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/download/quote/${globalCompanyUnderscoreId}?policy_number=${downloadSpreadsheetBody?.policy_number}&quote_id=${downloadSpreadsheetBody?.quote_id}`,
                "_blank"
              );
            }

            // if (
            //   quoteReviewForm?.enrollmentType === "Employee fills the data" &&
            //   downloadSpreadsheet?.employee_pre_enrollment
            // ) {
            //   window.open(
            //     downloadSpreadsheet?.employee_pre_enrollment,
            //     "_blank"
            //   );
            // } else if (
            //   quoteReviewForm?.enrollmentType ===
            //     "Employee validates the data" &&
            //   downloadSpreadsheet?.employee_and_dependent_pre_enrollment
            // ) {
            //   window.open(
            //     downloadSpreadsheet?.employee_and_dependent_pre_enrollment,
            //     "_blank"
            //   );
            // }
          }}
        >
          <DownloadIcon />
          <span>Download Template File</span>
        </div>

        <div
          style={{
            pointerEvents: downloadSpreadsheetBody?.policy_number ? "" : "none",
          }}
        >
          <label
            htmlFor={"profileUpdateImgPlanBdocument"}
            className={classNames.uploadFileDiv}
            style={{ maxWidth: "100%" }}
          >
            <img src={uploadFileIcon} alt="uploadFileIcon" />
            <div
              style={{
                paddingTop: "1rem",
                textOverflow: "ellipse",
                overflow: "hidden",
              }}
            >
              {fileName ? fileName : "Upload Completed File"}
            </div>
            <div className={classNames.uploadFile}>
              <input
                className={classNames.uploadNewPicPlanB}
                type="file"
                style={{ display: "none" }}
                onChange={(event) => {
                  console.log(event?.target?.files, "files list");
                  if (event?.target?.files?.length > 0) {
                    setFileName(event?.target?.files[0]?.name);
                    setQuoteReviewForm({
                      ...quoteReviewForm,
                      document: event?.target?.files[0],
                    });
                    // if (
                    //   quoteReviewForm?.enrollmentType ===
                    //   "Employee fills the data"
                    // ) {
                    //   getDataFillsAndValidate(event?.target?.files[0]);
                    // } else if (
                    //   quoteReviewForm?.enrollmentType ===
                    //   "Employee validates the data"
                    // ) {
                    //   getDataFillsAndValidate(event?.target?.files[0]);
                    // }
                  }
                }}
                accept="*/*"
                id={"profileUpdateImgPlanBdocument"}
              />
            </div>
          </label>
        </div>
      </div>
      {uploadedSheetValidationErrors && (
        <ValidateSpreadsheetComponent data={uploadedSheetValidationErrors} />
      )}
      <div className={classNames.btnsContainer}>
        {/* <button onClick={() => setState("")}>Back</button> */}
        <button
          className={classNames.coloredBtn}
          style={{
            pointerEvents: quoteReviewForm?.document ? "" : "none",
            opacity: quoteReviewForm?.document ? "" : "0.5",
          }}
          onClick={uploadBulkAssignPoliciesToMembers}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export const BulkAssignPoliciesEmployeeList = ({
  stateValue,
  setState,
  companyId,
  policyNum,
  hrId,
  allPolicies,
}) => {
  const { globalHrId } = useContext(BankContext);
  const [uploadedSheetValidationErrors, setUploadedSheetValidationErrors] =
    useState("");
  const [quoteReviewForm, setQuoteReviewForm] = useState("");
  const [downloadSpreadsheet, setDownloadSpreadsheet] = useState("");
  const [downloadSpreadsheetBody, setDownloadSpreadsheetBody] = useState("");
  const [fileName, setFileName] = useState("");
  const [memberDetailsFormLoading, setMemberDetailsFormLoading] = useState("");

  //functions

  function uploadBulkAssignPoliciesToMembers() {
    setMemberDetailsFormLoading(true);
    const formData = new FormData();
    formData.append("hr_id", hrId);
    formData.append("file", quoteReviewForm?.document);

    Axios.post(
      "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/add-employee/spreadsheet",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        console.log(response, "Bulk assign employees list");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setMemberDetailsFormLoading(false);
          setState("");
        } else {
          setMemberDetailsFormLoading(false);
          predefinedToast(response?.data?.message);
          setState("");
        }
      })
      .catch((error) => {
        console.log(error?.message, "Bulk assign employees list error");
        // setState("PreEnrollmentDownloads");
        setMemberDetailsFormLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  //rendering

  useEffect(() => {
    if (downloadSpreadsheet?._id) {
      let filterSelected = allPolicies?.filter((eachPolicy) => {
        return eachPolicy?._id == downloadSpreadsheet?._id;
      });
      if (filterSelected?.length > 0) {
        setDownloadSpreadsheetBody(filterSelected[0]);
        console.log(stateValue, filterSelected, "");
      }
    }
  }, [downloadSpreadsheet]);

  return (
    <div className={classNames.bulkUpload} style={{ padding: "1rem 0" }}>
      {memberDetailsFormLoading && (
        <div className={classNames.loadingImage}>
          <img
            src="https://talltree.coverfi.app/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
            alt="loadingImage"
          />
        </div>
      )}

      <div className={classNames.bulkDocuments}>
        <div
          onClick={() => {
            if (companyId && policyNum) {
              let downloadURL = `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/download/employee/${companyId}?policy_number=${policyNum}`;
              window?.open(downloadURL, "_blank");
            }
          }}
        >
          <DownloadIcon />
          <span>Download Template File</span>
        </div>

        <div
          style={{
            pointerEvents: companyId && policyNum ? "" : "none",
          }}
        >
          <label
            htmlFor={"profileUpdateImgPlanBdocument"}
            className={classNames.uploadFileDiv}
            style={{ maxWidth: "100%" }}
          >
            <img src={uploadFileIcon} alt="uploadFileIcon" />
            <div
              style={{
                paddingTop: "1rem",
                textOverflow: "ellipse",
                overflow: "hidden",
              }}
            >
              {fileName ? fileName : "Upload Completed File"}
            </div>
            <div className={classNames.uploadFile}>
              <input
                className={classNames.uploadNewPicPlanB}
                type="file"
                style={{ display: "none" }}
                onChange={(event) => {
                  console.log(event?.target?.files, "files list");
                  if (event?.target?.files?.length > 0) {
                    setFileName(event?.target?.files[0]?.name);
                    setQuoteReviewForm({
                      ...quoteReviewForm,
                      document: event?.target?.files[0],
                    });
                  }
                }}
                accept="*/*"
                id={"profileUpdateImgPlanBdocument"}
              />
            </div>
          </label>
        </div>
      </div>
      {uploadedSheetValidationErrors && (
        <ValidateSpreadsheetComponent data={uploadedSheetValidationErrors} />
      )}
      <div className={classNames.btnsContainer}>
        {/* <button onClick={() => setState("")}>Back</button> */}
        <button
          className={classNames.coloredBtn}
          style={{
            pointerEvents: quoteReviewForm?.document ? "" : "none",
            opacity: quoteReviewForm?.document ? "" : "0.5",
          }}
          onClick={uploadBulkAssignPoliciesToMembers}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

//custom inputs

export const EachInputFileUpload = ({
  title,
  icon,
  stateValue,
  setState,
  setName,
}) => {
  const handleFileChange = (event) => {
    if (event?.target?.files?.length > 0) {
      setName(event?.target?.files[0]?.name);
      setState(event?.target?.files[0]);
    }
  };

  return (
    <div className={classNames.eachInput}>
      <label
        className={classNames.fileAction}
        htmlFor="fileUpload"
        onClick={handleFileChange}
      >
        <img src={icon} alt="downloadIcon" />
        <span>{title}</span>
      </label>
      <input
        type="file"
        id="fileUpload"
        accept=".xls,.xlsx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};
