import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./claims.module.scss";
import { BankContext } from "../../../context/Context";

import {
  CopyBtn,
  EachCustomNormalDropdown,
  EachCustomPincodeDropdown,
} from "../../../components/Custom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import flatpickr from "flatpickr";
import Skeleton from "react-loading-skeleton";
import NavbarV2 from "../../../components/NavbarV2";
import { CiFileOn } from "react-icons/ci";
import { PiDownloadSimpleBold } from "react-icons/pi";

import calenderIcon from "../../../assest/images/icons/calender.svg";
import asteriskIcon from "../../../assest/images/icons/asterisk.svg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { ReactComponent as CompleteIcon } from "../../../assest/images/icons/complete.svg";
import { ReactComponent as TrashcanIcon } from "../../../assest/images/icons/trashcan.svg";
import { ReactComponent as SortArrows } from "../../../assest/images/allicons/sort.svg";
import { ReactComponent as FilterIcon } from "../../../assest/images/allicons/filter.svg";
import { FormUpdateCompleted } from "../HR/forms/MembersForms";
import NavbarV3 from "../../../components/NavbarV3";
import { BsThreeDotsVertical } from "react-icons/bs";
import { logoutToSameLogin } from "../../../assest/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ClaimsAssociates = () => {
  const history = useHistory();
  const {
    name,
    email,
    globalUserId,
    globalSelectedPolicy,
    actionBtns,
    setActionBtns,
    setGlobalSelectedPolicy,
    globalHrId,
  } = useContext(BankContext);
  const [selectedProfileNav, setSelectedProfileNav] = useState("Claims");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [claimsApi, setClaimsApi] = useState("");
  const [allClaimsPolicies, setAllClaimsPolicies] = useState([]);

  function isAnyObjectEmpty(arrayOfObjects) {
    for (const obj of arrayOfObjects) {
      if (Object.keys(obj).length === 0) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (globalUserId) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/lead/getleadprofilebyhr?lead_email=${email}`
      )
        .then((response) => {
          let inputObject = response?.data?.data;
          let gradedOne = [];
          const arrayOfObjects = Object?.keys(inputObject)?.map((key) => {
            if (
              Array.isArray(inputObject[key]) &&
              inputObject[key]?.length > 0
            ) {
              // console.log(inputObject[key], "checkingg");
              gradedOne.push(...inputObject[key]);
              return {};
            } else {
              return { ...inputObject[key], id: key };
            }
          });
          if (isAnyObjectEmpty(arrayOfObjects)) {
            // console.log("arrayOfObjects claims", gradedOne);
            setClaimsApi(gradedOne);
          } else {
            // console.log(arrayOfObjects, "arrayOfObjects claims");
            setClaimsApi(arrayOfObjects);
          }
        })
        .catch((error) => {
          console.log(error?.message, "claims API error");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }, []);

  const [allSelectedPolicyClaims, setSelectedPolicyClaims] = useState([]);
  const [allSelectedPolicyClaimsLoading, setSelectedPolicyClaimsLoading] =
    useState(true);
  const [filterOpen, setFilterOpen] = useState(false);

  const filterDropdownRef = useRef(null);
  const filterItems = [
    "Active",
    "Invitation Send",
    "Invitation Expired",
    "Pending",
    "Disabled",
    "Deleted",
  ];

  useEffect(() => {
    setSelectedPolicyClaims([]);
    setSelectedPolicyClaimsLoading(true);

    console.log(globalSelectedPolicy, "policyNumber");

    if (globalSelectedPolicy) {
      let policyNumber = globalSelectedPolicy?.replace(/\//g, "%2F");
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/tpa/getClaimStatus/${policyNumber}?page=${
          (currentPage - 1) * 10
        }&limit=10&search=${searchQuery ? searchQuery?.toLowerCase() : ""}`
      )
        .then((response) => {
          if (response?.data?.data?.length > 0) {
            setSelectedPolicyClaims(response?.data?.data);
          } else {
            setSelectedPolicyClaims([]);
          }
          setSelectedPolicyClaimsLoading(false);
        })
        .catch((error) => {
          console.log(
            error?.message,
            "Get all claims for selected policy error"
          );
          setSelectedPolicyClaims([]);
          setSelectedPolicyClaimsLoading(false);
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }, [globalSelectedPolicy, currentPage, searchQuery]);

  const [openNotification, setOpenNotification] = useState(false);

  const notificationRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //form values

  const [formDetails, setFormDetails] = useState("");
  const [formDate, setFormDate] = useState("");
  const [seeDetailsForm, setSeeDetailsForm] = useState(false);

  const [insuranceCompaniesDropdown, setInsuranceCompaniesDropdown] =
    useState("");

  //functions

  function getGlobalHealthPolicies() {
    setSelectedPolicyClaimsLoading(true);
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}&policy_type=Health`
    )
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          setAllClaimsPolicies(response?.data?.data);
          setGlobalSelectedPolicy(response?.data?.data[0]?.policy_number);
          localStorage.setItem(
            "globalSelectedPolicy",
            response?.data?.data[0]?.policy_number
          );
          setSelectedPolicyClaimsLoading(false);
        } else {
          setAllClaimsPolicies([]);
          setGlobalSelectedPolicy("");
          localStorage.removeItem("globalSelectedPolicy");
          setSelectedPolicyClaimsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "get polic details error");
        setSelectedPolicyClaimsLoading(false);
      });
  }

  //useeffects

  useEffect(() => {
    if (globalHrId) {
      getGlobalHealthPolicies();
    }
  }, [globalHrId]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={[]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
        removeItem={["Policy"]}
        specificPolicies={allClaimsPolicies}
      />
      <div className={classNames.topMenuOptions}>
        <div className={classNames.filterDiv}>
          <div className={classNames.filterBtn} ref={filterDropdownRef}>
            <FilterIcon
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            />
            {filterOpen && (
              <div className={classNames.dropdownContent}>
                {filterItems?.map((eachItem, index) => {
                  return (
                    <div
                      className={classNames.eachFilterItem}
                      key={eachItem + index}
                    >
                      {eachItem}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <input
              type="text"
              name=""
              placeholder="Search"
              onChange={(event) => {
                setSearchQuery(event?.target?.value);
              }}
            />
          </div>
        </div>
        {/* <div className={classNames.pincodeDropdown}>
          <span>Filter By:</span>
          <EachCustomPincodeDropdown
            title="filterBy"
            dropdown={filterByDropdown}
            stateValue={queriedFilter}
            setState={setQueriedFilter}
          />
        </div> */}
        <div className={classNames.buttonsDiv}>
          <div className={classNames.coloredBtn}>
            <div onClick={() => setOpenNotification((prev) => !prev)}>
              Initiate Claim
            </div>
            {openNotification && (
              <div
                className={classNames.dropdownContent}
                style={{ left: openNotification !== true ? "-50%" : "" }}
              >
                {openNotification === "InitateClaimCompleted" ? (
                  <FormUpdateCompleted setState={setOpenNotification} />
                ) : openNotification == "Group Health Insurance" ? (
                  <div className={classNames.allClaimsForm}>
                    <div className={classNames.title}>
                      <div>Group Health Insurance</div>
                      <div onClick={() => setOpenNotification(false)}>X</div>
                    </div>
                    <div className={classNames.formContainer}>
                      <div className={classNames.flexDivThree}>
                        <EachInput
                          placeholder="Employee Name"
                          name="employee_name"
                          stateValue={formDetails}
                          setState={setFormDetails}
                        />
                        <EachInput
                          placeholder="Phone number"
                          name="phone_number"
                          stateValue={formDetails}
                          setState={setFormDetails}
                        />
                        <EachInput
                          placeholder="Email ID"
                          name="email"
                          stateValue={formDetails}
                          setState={setFormDetails}
                        />
                      </div>
                      <div className={classNames.flexDivTwo}>
                        <EachCustomDropdown
                          placeholder="Patient Full Name"
                          dropdown={insuranceCompaniesDropdown}
                          name="insuranceCompany_id"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          mandatory={
                            formDetails?.policy_number_T ? true : false
                          }
                        />
                        <EachInput
                          placeholder="Relationship"
                          name="premium_amount"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          mandatory={
                            formDetails?.policy_number_T ? true : false
                          }
                        />
                      </div>
                      <EachInput
                        placeholder="MA ID"
                        name="premium_amount"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        mandatory={formDetails?.policy_number_T ? true : false}
                      />
                      <EachProfileTextArea
                        placeholder="Diagnosis / Ailment"
                        name="description"
                        dropdown=""
                        stateValue={formDetails}
                        setState={setFormDetails}
                      />
                      <EachInputDatePicker
                        placeholder="Date of Admission"
                        // dropdown={usersDropdown}
                        stateValue={formDate}
                        setState={setFormDate}
                      />
                      <EachCustomDropdown
                        placeholder="Hospital Name"
                        dropdown={insuranceCompaniesDropdown}
                        name="insuranceCompany_id"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        mandatory={formDetails?.policy_number_T ? true : false}
                      />
                      <EachInput
                        placeholder="Location of Hospital"
                        name="premium_amount"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        mandatory={formDetails?.policy_number_T ? true : false}
                      />
                      <EachInputDatePicker
                        placeholder="Date of Intimation"
                        // dropdown={usersDropdown}
                        stateValue={formDate}
                        setState={setFormDate}
                      />
                      <div
                        className={classNames.submitButton}
                        onClick={() =>
                          setOpenNotification("InitateClaimCompleted")
                        }
                      >
                        Submit
                      </div>
                      <div className={classNames.noteMessage}>
                        <span>Note: </span>
                        Please inform to us Before Admission or with in 7 days
                        from the date of Admission
                      </div>
                    </div>
                  </div>
                ) : openNotification == "Group Personal Accident Insurance" ? (
                  <div className={classNames.allClaimsForm}>
                    <div className={classNames.title}>
                      <div>Group Personal Accident Insurance</div>
                      <div onClick={() => setOpenNotification(false)}>X</div>
                    </div>
                    <div className={classNames.formContainer}>
                      <div className={classNames.flexDivTwoSame}>
                        <EachInput
                          placeholder="Employee Name"
                          name="premium_amount"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          mandatory={
                            formDetails?.policy_number_T ? true : false
                          }
                        />
                        <EachInput
                          placeholder="Department"
                          name="premium_amount"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          mandatory={
                            formDetails?.policy_number_T ? true : false
                          }
                        />
                      </div>
                      <EachInput
                        placeholder="Designation"
                        name="premium_amount"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        mandatory={formDetails?.policy_number_T ? true : false}
                      />
                      <EachInputDatePicker
                        placeholder="Date of Joining"
                        // dropdown={usersDropdown}
                        stateValue={formDate}
                        setState={setFormDate}
                      />
                      <div className={classNames.flexDivTwoSame}>
                        <EachInputDatePicker
                          placeholder="Date of Accident"
                          // dropdown={usersDropdown}
                          stateValue={formDate}
                          setState={setFormDate}
                        />
                        <EachInput
                          placeholder="Place of Accident"
                          name="premium_amount"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          mandatory={
                            formDetails?.policy_number_T ? true : false
                          }
                        />
                      </div>
                      <div className={classNames.flexDivTwoSame}>
                        <EachCustomDropdown
                          placeholder="Type of Injury"
                          dropdown={insuranceCompaniesDropdown}
                          name="insuranceCompany_id"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          mandatory={
                            formDetails?.policy_number_T ? true : false
                          }
                        />
                        <EachInputDatePicker
                          placeholder="Date of Death"
                          // dropdown={usersDropdown}
                          stateValue={formDate}
                          setState={setFormDate}
                        />
                      </div>
                      <EachCustomDropdown
                        placeholder="Hospital Name"
                        dropdown={insuranceCompaniesDropdown}
                        name="insuranceCompany_id"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        mandatory={formDetails?.policy_number_T ? true : false}
                      />
                      <EachProfileTextArea
                        placeholder="Brief Description of Accident"
                        name="description"
                        dropdown=""
                        stateValue={formDetails}
                        setState={setFormDetails}
                      />
                      <div
                        className={classNames.submitButton}
                        onClick={() =>
                          setOpenNotification("InitateClaimCompleted")
                        }
                      >
                        Submit
                      </div>
                    </div>
                  </div>
                ) : openNotification == "Group Term Life Insurance" ? (
                  <div className={classNames.allClaimsForm}>
                    <div className={classNames.title}>
                      <div>Group Term Life Insurance</div>
                      <div onClick={() => setOpenNotification(false)}>X</div>
                    </div>
                    <div className={classNames.formContainer}>
                      <EachInput
                        placeholder="Employee Name"
                        name="premium_amount"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        mandatory={formDetails?.policy_number_T ? true : false}
                      />
                      <EachInputDatePicker
                        placeholder="Date of Joining"
                        // dropdown={usersDropdown}
                        stateValue={formDate}
                        setState={setFormDate}
                      />
                      <EachInputDatePicker
                        placeholder="Date of Death"
                        // dropdown={usersDropdown}
                        stateValue={formDate}
                        setState={setFormDate}
                      />
                      <EachProfileTextArea
                        placeholder="Cause of Death"
                        name="description"
                        dropdown=""
                        stateValue={formDetails}
                        setState={setFormDetails}
                      />
                      <div
                        className={classNames.submitButton}
                        onClick={() =>
                          setOpenNotification("InitateClaimCompleted")
                        }
                      >
                        Submit
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classNames.menuDropdown}>
                    <div
                      onClick={(event) =>
                        setOpenNotification(event?.target?.innerText)
                      }
                    >
                      Group Health Insurance
                    </div>
                    <div
                      onClick={(event) =>
                        setOpenNotification(event?.target?.innerText)
                      }
                    >
                      Group Personal Accident Insurance
                    </div>
                    <div
                      onClick={(event) =>
                        setOpenNotification(event?.target?.innerText)
                      }
                    >
                      Group Term Life Insurance
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <EachCustomNormalDropdown
            title="Download Claim Form"
            dropdown={[
              {
                name: "Health Insurance Claim",
                link: "https://drivetest.globalxchange.io/gxsharepublic/?full_link=raha.brain.stream/09504a62780cf93e839100d8223fde9c",
              },
              {
                name: "Personal Accident Claim",
                link: "https://drivetest.globalxchange.io/gxsharepublic/?full_link=raha.brain.stream/cc31b71f9600a914399dab20f215c8e7",
              },
            ]}
          />
          <EachCustomNormalDropdown
            title="Download Claim Checklist"
            dropdown={[
              {
                name: "Health Insurance Claim Checklist",
                link: "https://drivetest.globalxchange.io/gxsharepublic/?full_link=raha.brain.stream/aece4120b7854f875ec96dbb9b730e1c",
              },
              {
                name: "Personal Accident Claim Checklist",
                link: "https://drivetest.globalxchange.io/gxsharepublic/?full_link=raha.brain.stream/4ea8b6f8ef38a7c531e8326540f30953",
              },
            ]}
          />
        </div>
      </div>
      <div className={classNames.proactiveDetails}>
        <div className={classNames.allLeads}>
          <TableEmployeesSimplified
            allLeads={allSelectedPolicyClaims}
            allBrokeragesSearch={searchQuery}
            actionBtns={actionBtns}
            setActionBtns={setActionBtns}
            allLeadsLoading={allSelectedPolicyClaimsLoading}
            seeDetailsForm={seeDetailsForm}
            setSeeDetailsForm={setSeeDetailsForm}
          />
        </div>
        <div className={classNames.paginationBar}>
          <div className={classNames.paginationMsg}>
            Showing {allSelectedPolicyClaims?.length} entries
          </div>
          <div className={classNames.allPagination}>
            <div
              key={"pagebtnleft"}
              className={`${classNames.eachBtn} `}
              style={{
                pointerEvents: currentPage <= 1 ? "none" : "",
                opacity: currentPage <= 1 ? "0.5" : "",
              }}
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            >
              <FaAngleLeft />
            </div>
            {currentPage == 1
              ? Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index);
                      }}
                      style={{
                        pointerEvents:
                          allSelectedPolicyClaims?.length < 10 &&
                          index > currentPage - 1
                            ? "none"
                            : "",
                        opacity:
                          allSelectedPolicyClaims?.length < 10 &&
                          index > currentPage - 1
                            ? "0.5"
                            : "",
                      }}
                    >
                      {currentPage + index}
                    </div>
                  );
                })
              : Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index - 1 == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index - 1);
                      }}
                      style={{
                        pointerEvents:
                          allSelectedPolicyClaims?.length < 10 &&
                          index > currentPage - 1
                            ? "none"
                            : "",
                        opacity:
                          allSelectedPolicyClaims?.length < 10 &&
                          index > currentPage - 1
                            ? "0.5"
                            : "",
                      }}
                    >
                      {currentPage + index - 1}
                    </div>
                  );
                })}
            <div
              key={"pagebtnright"}
              className={`${classNames.eachBtn} `}
              style={{
                pointerEvents:
                  allSelectedPolicyClaims?.length < 10
                    ? "none"
                    : currentPage >= 1
                    ? ""
                    : "none",
                opacity:
                  allSelectedPolicyClaims?.length < 10
                    ? "0.5"
                    : currentPage >= 1
                    ? ""
                    : "0.5",
              }}
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              <FaAngleRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimsAssociates;

const EachInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
}) => {
  return (
    <div className={classNames.eachInput}>
      {title == "Select currency" || title == "Select Country" ? (
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          value={
            title == "Select currency" || title == "Select Country"
              ? stateValue
              : ""
          }
          style={{
            pointerEvents:
              title == "Select currency" || title == "Select Country"
                ? "none"
                : "",
          }}
        />
      ) : title == "Enter sum insured amounts" ? (
        <div className={classNames.inputDiv}>
          <input
            className={classNames.inputContainer}
            placeholder={placeholder}
            name={name}
            onChange={(event) => {
              setState({ ...stateValue, [name]: [event?.target?.value] });
            }}
            style={{ pointerEvents: stateValue?.length > 0 ? "none" : "" }}
          />
          <div
            className={classNames.selectedBanner}
            // style={{
            //   background: stateValue?.length > 0 ? "var(--main-color)" : "",
            //   color: "white",
            // }}
          >
            Flat
          </div>
        </div>
      ) : name == "sum_insured" ? (
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          onChange={(event) => {
            setState({
              ...stateValue,
              [name]: [...stateValue[name], event?.target?.value],
            });
          }}
        />
      ) : (
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          onChange={(event) => {
            setState({ ...stateValue, [name]: event?.target?.value });
          }}
        />
      )}
    </div>
  );
};

const EachProfileTextArea = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
  name,
  mandatory,
}) => {
  return (
    <div className={classNames.eachProfileInputTextarea}>
      <div className={classNames.title}>
        {title}
        {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <div className={classNames.inputParent}>
        <textarea
          type="text"
          name=""
          placeholder={placeholder}
          value={stateValue[name]}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
          onChange={(event) => {
            setState({ ...stateValue, [name]: event?.target?.value });
          }}
        >
          {stateValue}
        </textarea>
        {status == "secondary" ? (
          <div
            className={classNames.trashCan}
            onClick={() => {
              removeState((prev) => prev - 1);
            }}
          >
            <TrashcanIcon />
          </div>
        ) : (
          ""
        )}
        {status == "primary" ? (
          <div className={classNames.status}>
            <CompleteIcon />
            <span>Primary</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {dropdown && <IoMdArrowDropdown />} */}
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  mandatory,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div className={classNames.eachCustomDropdown}>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          background: isOpen ? "#e5e5e5" : "",
          zIndex: isOpen ? 4 : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : placeholder
              ? placeholder
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "Lead Source"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select State"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : title == "Insurance company"
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.displayName);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.insuranceCompany_id,
                          });
                        }}
                        key={eachitem?.displayName + index}
                      >
                        {eachitem?.displayName}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        {eachitem}
                      </li>
                    );
                  })}
          </ul>
        )}
      </div>
    </div>
  );
};

const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (
      (title?.toLowerCase()?.includes("birth") ||
        placeholder?.toLowerCase()?.includes("birth")) &&
      datepickerRef.current
    ) {
      const currentDate = new Date();

      const minDate = new Date(currentDate);
      const maxDate = new Date(currentDate);

      // Calculate minDate for a 80-year-old person
      minDate.setFullYear(currentDate.getFullYear() - 80);

      // Calculate maxDate for a 25-year-old person
      maxDate.setFullYear(currentDate.getFullYear() - 25);

      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        minDate: minDate,
        maxDate: maxDate,
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef.current);
          setState(`${dateStr}`);
        },
      };

      flatpickr(datepickerRef.current, options);
    } else if (datepickerRef.current) {
      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef.current);
          setState(`${dateStr}`);
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker}>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={stateValue}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
      <img
        src={calenderIcon}
        alt="calenderIcon"
        className={classNames.calendarIcon}
      />
    </div>
  );
};

const TableEmployeesSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
  seeDetailsForm,
  setSeeDetailsForm,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(7, 1fr)" }}>
            <th></th>
            <th>Claim No.</th>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Status</th>
            <th>Patient Name</th>
            <th>Claim Type</th>
            <th>Processed Amt</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(7, 1fr)" }}
            >
              <td>
                <BsThreeDotsVertical />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>

              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(7, 1fr)" }}
                >
                  <td>
                    {actionBtns?.includes(row?._id) ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActionBtns([]);
                        }}
                      >
                        X
                      </span>
                    ) : (
                      <BsThreeDotsVertical
                        onClick={() => {
                          if (actionBtns?.includes(row?._id)) {
                            setActionBtns([]);
                          } else {
                            setActionBtns([row?._id]);
                          }
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {row?.claim_number ? row?.claim_number : ""}
                    {/* {row?.claim_number ? (
                      <CopyBtn
                        copyText={row?.claim_number ? row?.claim_number : ""}
                      />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {row?.company_employee_id ? row?.company_employee_id : ""}
                  </td>
                  <td>
                    {row?.employee_name ? row?.employee_name : ""}
                    {/* {row?.employee_name ? (
                      <CopyBtn
                        copyText={row?.employee_name ? row?.employee_name : ""}
                      />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {row?.claim_status ===
                    "Information Awaited Final Reminder" ? (
                      <span className={classNames.processing}>In Process</span>
                    ) : row?.claim_status === "Claim Paid" ? (
                      <span className={classNames.processed}>Processed</span>
                    ) : row?.claim_status === "Denied" ? (
                      <span className={classNames.rejected}>Rejected</span>
                    ) : (
                      ""
                    )}

                    {/* {row?.claim_status ? (
                      <CopyBtn
                        copyText={row?.claim_status ? row?.claim_status : ""}
                      />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {row?.patient_name ? row?.patient_name : ""}
                    {/* {row?.patient_name ? (
                      <CopyBtn
                        copyText={row?.patient_name ? row?.patient_name : ""}
                      />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {row?.typE_OF_CLAIM ? row?.typE_OF_CLAIM : "-"}
                    {/* {row?.typE_OF_CLAIM ? (
                      <CopyBtn
                        copyText={row?.typE_OF_CLAIM ? row?.typE_OF_CLAIM : ""}
                      />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    <div>
                      {row?.claiM_APPROVED_AMOUNT
                        ? row?.claiM_APPROVED_AMOUNT
                        : "-"}
                      {/* {row?.claiM_APPROVED_AMOUNT ? (
                        <CopyBtn
                          copyText={
                            row?.claiM_APPROVED_AMOUNT
                              ? row?.claiM_APPROVED_AMOUNT
                              : ""
                          }
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
          {actionBtns?.length > 0 && (
            <BasicDetailsClaims
              wholeData={allLeads}
              setState={setActionBtns}
              stateValue={actionBtns}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

const BasicDetailsClaims = ({ stateValue, setState, wholeData }) => {
  const [selectedSubDiv, setSelectedSubDiv] = useState("Claim History");
  const [selectedUser, setSelectedUser] = useState("");

  //rendering

  useEffect(() => {
    if (
      Array.isArray(wholeData) &&
      wholeData?.length > 0 &&
      stateValue?.length > 0
    ) {
      let filterData = wholeData?.filter((eachItem, index) => {
        return eachItem?._id == stateValue[0];
      });

      if (filterData?.length > 0) {
        setSelectedUser(filterData[0]);
      }
      console.log(filterData, "filterData");
    }
  }, []);

  return (
    <div className={classNames.basicDetails}>
      <div className={classNames.title}>Basic Details</div>
      <div className={classNames.detailsContainer}>
        <div className={classNames.basicDisplayContainer}>
          <div className={classNames.eachDisplayDetails}>
            <span>Patient Name</span>
            <span>
              {selectedUser?.patient_name ? selectedUser?.patient_name : "-"}
            </span>
          </div>
          <div className={classNames.eachDisplayDetails}>
            <span>Member ID</span>
            <span>
              {selectedUser?.company_employee_id
                ? selectedUser?.company_employee_id
                : "-"}
            </span>
          </div>
          <div className={classNames.eachDisplayDetails}>
            <span>Date of Admisssion</span>
            <span>
              {selectedUser?.date_of_admission
                ? selectedUser?.date_of_admission
                : "-"}
            </span>
          </div>
          <div className={classNames.eachDisplayDetails}>
            <span>Date of Discharge</span>
            <span>
              {selectedUser?.date_of_discharge
                ? selectedUser?.date_of_discharge
                : ""}
            </span>
          </div>
          <div className={classNames.eachDisplayDetails}>
            <span>Ailment</span>
            <span>{selectedUser?.ailment ? selectedUser?.ailment : ""}</span>
          </div>
          <div className={classNames.eachDisplayDetails}>
            <span>Policy Holder Name</span>
            <span>
              {selectedUser?.employee_company_name
                ? selectedUser?.employee_company_name
                : ""}
            </span>
          </div>
          <div className={classNames.eachDisplayDetails}>
            <span>Policy Number</span>
            <span>
              {selectedUser?.policy_number ? selectedUser?.policy_number : ""}
            </span>
          </div>
        </div>
      </div>
      <div className={classNames.title} style={{ marginTop: "2rem" }}>
        Claim Details
      </div>
      <div className={classNames.detailsContainer}>
        <div
          className={classNames.flexContainer}
          style={{ marginBottom: "2rem" }}
        >
          <div
            className={
              selectedSubDiv === "Documents" ? classNames.selectedSection : ""
            }
            onClick={(event) => setSelectedSubDiv(event?.target?.innerText)}
          >
            Documents
          </div>
          <div
            className={
              selectedSubDiv === "Claim Bills" ? classNames.selectedSection : ""
            }
            onClick={(event) => setSelectedSubDiv(event?.target?.innerText)}
          >
            Claim Bills
          </div>
          <div
            className={
              selectedSubDiv === "Claim History"
                ? classNames.selectedSection
                : ""
            }
            onClick={(event) => setSelectedSubDiv(event?.target?.innerText)}
          >
            Claim History
          </div>
          <div
            className={
              selectedSubDiv === "Missing Documents"
                ? classNames.selectedSection
                : ""
            }
            style={{ pointerEvents: "none", opacity: "0.5" }}
            onClick={(event) => setSelectedSubDiv(event?.target?.innerText)}
          >
            Missing Documents
          </div>
        </div>
        <div className={classNames.switchingContainer}>
          {selectedSubDiv === "Missing Documents" ? (
            <>
              <div className={classNames.subHeading}>
                Kindly Upload the below mentioned documents
              </div>
              <div className={classNames.uploadDocument}>
                <div className={classNames.title}>
                  Medico Legal Certificte (MCL)
                </div>
                <div className={classNames.uploadBtn}>
                  <span>
                    <CiFileOn />
                    No file chosen
                  </span>
                  <button>Upload</button>
                </div>
              </div>
              <div className={classNames.uploadDocument}>
                <div className={classNames.title}>
                  First Information Report (FIR)
                </div>
                <div className={classNames.uploadBtn}>
                  <span>
                    <CiFileOn />
                    No file chosen
                  </span>
                  <button>Upload</button>
                </div>
              </div>
              <div className={classNames.noteDiv}>
                <span>Note:</span> You cannot add files other than jpg, jpeg,
                tiff, tif, png, gif, bmp, pdf, doc, docx. Maximum file size
                allowed is 15 MB
              </div>
            </>
          ) : selectedSubDiv === "Claim History" ? (
            <>
              <div className={classNames.tableContainer}>
                <div className={classNames.tableHead}>
                  <div>Date</div>
                  <div>Status</div>
                </div>
                <div className={classNames.tableBody}>
                  <div>
                    <div>
                      {selectedUser?.claim_last_updated
                        ? selectedUser?.claim_last_updated
                        : "-"}
                    </div>
                    <div>Latest update by insurance company</div>
                  </div>
                  <div>
                    <div>
                      {selectedUser?.date_of_discharge
                        ? selectedUser?.date_of_discharge
                        : "-"}
                    </div>
                    <div>Discharge</div>
                  </div>
                  <div>
                    <div>
                      {selectedUser?.date_of_admission
                        ? selectedUser?.date_of_admission
                        : "-"}
                    </div>
                    <div>Admission</div>
                  </div>
                </div>
              </div>
            </>
          ) : selectedSubDiv === "Documents" ? (
            <>
              <div className={classNames.tableContainer}>
                <div className={classNames.tableHead}>
                  <div>Name</div>
                  <div>Date</div>
                  <div>Actions</div>
                  <div>ID</div>
                </div>
                <div className={classNames.tableBody}>
                  {Array?.isArray(selectedUser?.claim_documents) &&
                  selectedUser?.claim_documents?.length > 0
                    ? selectedUser?.claim_documents?.map((eachItem, index) => {
                        return (
                          <div key={"document" + index}>
                            <div>
                              {eachItem?.document_name
                                ? eachItem?.document_name
                                : ""}
                            </div>
                            <div>
                              {eachItem?.document_date
                                ? eachItem?.document_date
                                : ""}
                            </div>
                            <div style={{ fontSize: "1rem" }}>
                              <PiDownloadSimpleBold
                                onClick={() => {
                                  if (eachItem?.document_url) {
                                    window?.open(
                                      eachItem?.document_url,
                                      "_blank"
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div>{eachItem?._id ? eachItem?._id : ""}</div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </>
          ) : selectedSubDiv === "Claim Bills" ? (
            <>
              <div className={classNames.tableContainer}>
                <div
                  className={classNames.tableHead}
                  style={{
                    gridTemplateColumns: "0.6fr 1fr 0.8fr 0.5fr 0.7fr",
                  }}
                >
                  <div>Bill Number</div>
                  <div>Description</div>
                  <div>Bill Date</div>
                  <div>Amount</div>
                  <div>Payable Amount</div>
                </div>
                <div className={classNames.tableBody}>
                  {Array?.isArray(selectedUser?.claim_billing) &&
                  selectedUser?.claim_billing?.length > 0
                    ? selectedUser?.claim_billing?.map((eachItem, index) => {
                        return (
                          <div
                            key={"claimbilling" + index}
                            style={{
                              gridTemplateColumns:
                                "0.6fr 1fr 0.8fr 0.5fr 0.7fr",
                            }}
                          >
                            <div>
                              {eachItem?.bill_id ? eachItem?.bill_id : "-"}
                            </div>
                            <div>
                              {eachItem?.bill_for ? eachItem?.bill_for : "-"}
                            </div>
                            <div>
                              {eachItem?.bill_date ? eachItem?.bill_date : "-"}
                            </div>
                            <div>
                              {eachItem?.bill_amount
                                ? eachItem?.bill_amount
                                : "-"}
                            </div>
                            <div>
                              {eachItem?.bill_payable_amount
                                ? eachItem?.bill_payable_amount
                                : "-"}
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={classNames.closeBtn} onClick={() => setState([])}>
        X
      </div>
    </div>
  );
};
