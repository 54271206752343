import React, { useState } from "react";
import classNames from "./dropdownui.module.scss";

import eyeIcon from "../../../../../assest/images/allicons/tableaction/eye.svg";
import downloadIcon from "../../../../../assest/images/allicons/tableaction/download.svg";
import addIcon from "../../../../../assest/images/allicons/tableaction/add.svg";
import TransferFromEmployee from "./TransferFromEmployee";

const DropdownUi = ({
  showMenu,
  setShowMenu,
  selectedRow,
  setSelectedRow,
  balanceObj,
  ownerId,
  initiatedById,
  getEmpBalances,
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedMainMenu, setSelectedMainMenu] = useState(null);
  const dropItems = [
    {
      icon: eyeIcon,
      text: "See transaction history",
    },
    {
      icon: addIcon,
      text: "Fund employee wallet",
    },
    {
      icon: downloadIcon,
      text: "Transfer from employee wallet",
    },
  ];

  return (
    <>
      <div className={classNames.dropdown}>
        {dropItems.map((item) => (
          <div
            className={classNames.dropItem}
            onClick={(e) => {
              setSelectedMainMenu(item?.text);
            }}
          >
            <img
              src={item?.icon}
              alt=""
              style={{ width: "14px", height: "14px" }}
            />
            <div className={classNames.dropText}>{item?.text}</div>
          </div>
        ))}
      </div>
      {/* {showSubMenu && (
        <div className={classNames.dropdown1}>
          {selectedMainMenu?.subMenu?.map((item1) => (
            <div className={classNames.dropItem}>
              <div className={classNames.dropText1}>{item1?.text}</div>
            </div>
          ))}
        </div>
      )} */}
      {selectedMainMenu === "Transfer from employee wallet" && (
        <TransferFromEmployee
          selectedMainMenu={selectedMainMenu}
          setSelectedMainMenu={setSelectedMainMenu}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          balanceObj={balanceObj}
          ownerId={ownerId}
          initiatedById={initiatedById}
          getEmpBalances={getEmpBalances}
        />
      )}
    </>
  );
};

export default DropdownUi;
