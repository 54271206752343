import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./newprofile.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import uploading from "../../../assest/images/icons/uploading.svg";
import dummyProfile from "../../../assest/images/icons/dummyProfile.svg";
import { AppContext } from "../../../context/AppContext";

import { uploadImageFunc } from "../../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import { Redirect } from "react-router-dom";

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_green.css";
import { logoutToSameLogin } from "../../../assest/functions";

const NewProfile = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    setUpdateLoading,
  } = useContext(BankContext);
  const [userLeadId, setUserLeadId] = useState("");
  const [userDOB, setUserDOB] = useState("");
  const [userCreated, setUserCreated] = useState("");

  const [martialStatusDropdown, setMartialStatusDropdown] = useState([
    "Single",
    "Married",
  ]);
  const [noOfChildrenDropdown, setNoOfChildrenDropdown] = useState([
    "0",
    "1",
    "2",
    "3 or more",
  ]);
  const [noOfParentsDropdown, setNoOfParentsDropdown] = useState([
    "0",
    "1",
    "2",
  ]);

  const [leadSourceDropdown, setLeadSourceDropdown] = useState([
    "Just Dial",
    "Instagram",
    "Google",
    "Offline Ads",
    "Referral",
    "Outbound In Person",
  ]);
  const [indianStatesDropdown, setIndianStatesDropdown] = useState("");
  const [usersDropdown, setUsersDropdown] = useState("");
  const [associateDropdown, setAssociateDropdown] = useState("");
  const [corporateLead, setCorporateLead] = useState({
    user_email: email,
    first_name: "",
    last_name: "",
    primary_phone_number: "",
    secondary_phone_numbers: [],
    address: "",
    date_of_birth: "00/00/0000",
    city: "",
    country: "India",
    state: "",
    pin_code: "",
    martial_status: "",
    profile_picture: "",
    lead_id: userLeadId,
    no_of_parents: "",
    no_of_children: "",
    no_of_parents_in_law: "",
  });
  const [selectBrokerage, setSelectBrokerage] = useState("");
  const [selectAssociate, setSelectAssociate] = useState("");

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/general/getstates`
    )
      .then((response) => {
        console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setIndianStatesDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/lead/get?lead_email=${email}`
    )
      .then((response) => {
        console.log(response?.data?.data, "user lead id response");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          setUserLeadId(response?.data?.data[0]?.lead_id);
        } else {
          setUserCreated("nolead");
        }
      })
      .catch((error) => {
        console.log(error?.message, "user lead id error");
      });
  }, [email]);

  function createCorporateLead() {
    setUpdateLoading(true);
    let finalObj = {
      ...corporateLead,
      lead_id: userLeadId,
      date_of_birth: userDOB,
    };
    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/user/create",
      finalObj
    )
      .then((response) => {
        // console.log(response, "create user profile API called");
        setUpdateLoading(false);
        if (response?.data?.status) {
          // predefinedToast(response?.data?.message);
          setTimeout(() => {
            setUserCreated(false);
            history.push("/app/coverfi/myprofile");
          }, 3000);
          setUserCreated(true);
        } else {
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "brokerage create error error");
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });

    console.log("create user profile API", finalObj);
  }

  // useEffect(() => {
  //   if (selectBrokerage) {
  //     Axios.get(
  //       `https://insurancecoverfi.apimachine.com/insurance/agent/get?brokerage_username=${selectBrokerage}`
  //     )
  //       .then((response) => {
  //         console.log(
  //           response,
  //           "Get all agents for selected brokerage response"
  //         );
  //         if (response?.data?.status) {
  //           setAssociateDropdown(response?.data?.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           error?.message,
  //           "Get all Associates for selected brokerage error"
  //         );
  //       });
  //   }
  //   console.log(selectBrokerage, "selectBrokerage selected");
  // }, [selectBrokerage]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newBrokerageParent}>
      <div className={classNames.newBrokerage}>
        {userCreated ? (
          <div className={classNames.successfulMessage}>
            {userCreated == "nolead" ? (
              <div>Lead ID not found, can't create user profile on own</div>
            ) : (
              <>
                <div>You have successfully updated your Tall Tree profile.</div>
                <div>You will be redirected to your profile page</div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className={classNames.title}>Complete My Profile</div>
            <div className={classNames.sectionTitle}>Identity Details</div>
            <div className={classNames.inputContainers}>
              <EachInput
                title="First Name"
                placeholder="Enter first name..."
                name="first_name"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="Last Name"
                placeholder="Enter last name..."
                name="last_name"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInputDatePicker
                title="Date Of Birth"
                placeholder="Select Date Of Birth"
                dropdown={usersDropdown}
                name="date_of_birth"
                stateValue={userDOB}
                setState={setUserDOB}
              />
              <EachCustomDropdown
                title="Martial Status"
                dropdown={martialStatusDropdown}
                name="martial_status"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachCustomDropdown
                title="Number Of Children"
                dropdown={noOfChildrenDropdown}
                name="no_of_children"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachCustomDropdown
                title="Number Of Living Parents"
                dropdown={noOfParentsDropdown}
                name="no_of_parents"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachCustomDropdown
                title="Number Of Living In-Laws"
                dropdown={noOfParentsDropdown}
                name="no_of_parents_in_law"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              {/* <EachInput
          title="Employee's Company Email"
          placeholder="Enter email.."
          name="company_email"
          stateValue={corporateLead}
          setState={setCorporateLead}
        />
        <EachInput
          title="Employee ID"
          placeholder="Enter id.."
          name="empid"
          stateValue={corporateLead}
          setState={setCorporateLead}
        /> */}
            </div>
            <div
              className={classNames.sectionTitle}
              style={{ marginTop: "2rem" }}
            >
              Address Details
            </div>
            <div className={classNames.inputContainers}>
              <EachInput
                title="Street Address"
                placeholder="Enter your address.."
                name="address"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="City"
                placeholder="Enter city.."
                name="city"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachCustomDropdown
                title="State"
                dropdown={indianStatesDropdown}
                name="state"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="Postal Code"
                placeholder="Enter Postal Code..."
                name="pin_code"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
            </div>
            <div
              className={classNames.sectionTitle}
              style={{ marginTop: "2rem" }}
            >
              Profile Details
            </div>
            <div className={classNames.inputContainers}>
              <ImageUploadDivIcon
                heading="Upload a profile image"
                name="profile_picture"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="Phone Number"
                placeholder="Enter Phone Number.."
                name="primary_phone_number"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
            </div>
          </>
        )}
      </div>
      {userCreated ? (
        ""
      ) : (
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => {
              if (popularAction) {
                setPopularAction(false);
              } else {
                setDashboardHam(!dashboardHam);
              }
              history.push("/app/coverfi/myprofile");
            }}
          >
            Close Form
          </div>
          <div onClick={createCorporateLead}>Submit Profile</div>
        </div>
      )}
    </div>
  );
};

export default NewProfile;

export const EachInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
}) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      // style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          zIndex: isOpen ? "5" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "State"
              ? "Select state.."
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : title == "Job Type" || title == "Select User"
              ? "Click To Select"
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "State"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : title == "Number Of Living In-Laws" ||
                title == "Number Of Living Parents" ||
                title == "Number Of Children" ||
                title == "Martial Status"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select Brokerage"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.brokerage_name,
                            eachitem?.brokerage_icon
                          );
                          setState(eachitem?.brokerage_username);
                        }}
                        key={eachitem?.brokerage_name + index}
                        style={{
                          display:
                            eachitem?.brokerage_username === stateValue
                              ? "none"
                              : "",
                        }}
                      >
                        <img
                          src={eachitem?.brokerage_icon}
                          alt={eachitem?.brokerage_name}
                        />
                        {eachitem?.brokerage_name}
                      </li>
                    );
                  })
              : title == "Select User"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.first_name + eachitem?.last_name,
                            eachitem?.profile_picture
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem?.user_email,
                          });
                        }}
                        key={eachitem?.first_name + index}
                      >
                        <img
                          src={eachitem?.profile_picture}
                          alt={eachitem?.first_name}
                        />
                        <div>
                          <div>
                            {eachitem?.first_name} &nbsp; {eachitem?.last_name}
                          </div>
                          <div>{eachitem?.user_email}</div>
                        </div>
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name, eachitem?.app_icon);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"Select Brokerage"}
              >
                {title == "Select Brokerage"
                  ? "Search one of your brokerage.."
                  : "Select an option"}
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanB"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePic = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDivProfilePic}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanBProfilePic"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanBProfilePic"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanBProfilePic"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await", name);
          }}
          accept="image/*"
          id="profileUpdateImgPlanBProfilePic"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (
      (title?.toLowerCase()?.includes("birth") ||
        placeholder?.toLowerCase()?.includes("birth")) &&
      datepickerRef.current
    ) {
      const currentDate = new Date();

      const minDate = new Date(currentDate);
      const maxDate = new Date(currentDate);

      // Calculate minDate for a 80-year-old person
      minDate.setFullYear(currentDate.getFullYear() - 80);

      // Calculate maxDate for a 25-year-old person
      maxDate.setFullYear(currentDate.getFullYear() - 25);

      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        minDate: minDate,
        maxDate: maxDate,
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected date:", dateStr);
          setState(dateStr);
        },
      };

      flatpickr(datepickerRef.current, options);
    } else if (datepickerRef.current) {
      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected date:", dateStr);
          setState(dateStr);
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker}>
      <div className={classNames.title}>{title}</div>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
    </div>
  );
};
