import React, { useContext, useState } from "react";
import "./toggle.scss";
import { BankContext } from "../../../context/Context";

function ToggleButton({ stateValue, stateFunc, loading, typee }) {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    if (
      stateValue == "HR Profile" &&
      window?.location?.pathname?.includes("/coverfi")
    ) {
      stateFunc("Company");
    } else if (
      stateValue == "Company" &&
      window?.location?.pathname?.includes("/coverfi")
    ) {
      stateFunc("HR Profile");
    } else if (
      stateValue == "Personal" &&
      window?.location?.pathname?.includes("/coverfi")
    ) {
      stateFunc("Company");
    } else if (
      stateValue == "Personal" &&
      window?.location?.pathname?.includes("/rahax")
    ) {
      stateFunc("Employee");
    } else if (stateValue == "Company") {
      stateFunc("Personal");
    } else if (stateValue == "Quote Manager") {
      stateFunc("Policy Manager");
    } else if (stateValue == "Policy Manager") {
      stateFunc("Quote Manager");
    } else if (stateValue == "Employee") {
      stateFunc("Personal");
    } else if (stateValue == "Employer") {
      stateFunc("Employee");
    } else if (stateValue == "Employee") {
      stateFunc("Employer");
    } else if (stateValue == "Simplified") {
      stateFunc("Advanced");
    } else if (stateValue == "Advanced") {
      stateFunc("Simplified");
    }
    setIsToggled(!isToggled);
  };

  return (
    <div
      className="toggleParent"
      style={{
        pointerEvents: loading ? "none" : "",
        color: typee == "main" ? "white" : "",
      }}
    >
      <div
        className="toggleName"
        style={{
          display:
            stateValue == "Personal" ||
            stateValue == "Company" ||
            stateValue == "Employee" ||
            stateValue == "Policy Manager" ||
            stateValue == "Quote Manager" ||
            stateValue ||
            "HR Profile"
              ? "none"
              : "",
        }}
      >
        {typee == "main" ? "Employer" : "Simplified"}
      </div>
      <div
        className={`toggle-container`}
        onClick={handleToggle}
        style={{ background: typee == "main" ? "white" : "" }}
      >
        <div className="cylinder">
          <div className={`circle ${isToggled ? "toggled" : ""}`} />
        </div>
      </div>
      <div
        className="toggleName"
        style={{
          display:
            stateValue == "Personal" ||
            stateValue == "Company" ||
            stateValue == "Employee" ||
            stateValue == "Policy Manager" ||
            stateValue == "Quote Manager" ||
            stateValue ||
            "HR Profile"
              ? "none"
              : "",
        }}
      >
        {typee == "main" ? "Employee" : "Advanced"}
      </div>
    </div>
  );
}

export default ToggleButton;

export function ToggleButtonDashboard({ loading, typee }) {
  const {
    dashboardToggleMain,
    dashboardToggle: stateValue,
    setDashboardToggle: stateFunc,
    designation,
    setDesignation,
  } = useContext(BankContext);
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    if (stateValue == "Employer") {
      stateFunc("Employee");
      localStorage.setItem("dashboardToggle", "Employee");
    } else if (stateValue == "Employee") {
      stateFunc("Employer");
      localStorage.setItem("dashboardToggle", "Employer");
    } else if (stateValue == "Simplified") {
      stateFunc("Advanced");
    } else if (stateValue == "Advanced") {
      stateFunc("Simplified");
    }
    setIsToggled(!isToggled);
  };

  return (
    <div
      className="toggleParent toggleParentDashboard"
      style={{
        pointerEvents: loading
          ? "none"
          : dashboardToggleMain == "Employer" || designation?.is_hr
          ? ""
          : "none",
        color: typee == "main" ? "white" : "",
      }}
    >
      <div className="toggleName">
        {typee == "main" ? "Employee" : "Advanced"}
      </div>
      <div
        className={`toggle-container`}
        onClick={handleToggle}
        style={{ background: typee == "main" ? "white" : "" }}
      >
        <div className="cylinder">
          <div
            className={`circle ${stateValue == "Employer" ? "toggled" : ""}`}
          />
        </div>
      </div>
      <div className="toggleName">
        {typee == "main" ? "Employer" : "Simplified"}
      </div>
      {/* <div className="toggleNameDashboard">
        {stateValue == "Employee" ? "Employee" : "Employer"}
      </div> */}
    </div>
  );
}
