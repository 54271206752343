import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./engage.module.scss";
import { BankContext } from "../../context/Context";

import { ReactComponent as SearchIcon } from "../../assest/images/icons/search.svg";

import { Redirect } from "react-router-dom";

import EducationContainer from "../React_Education";
import NavbarV2 from "../../components/NavbarV2";
import NavbarV3 from "../../components/NavbarV3";
import { logoutToSameLogin } from "../../assest/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Engage = () => {
  const history = useHistory();
  const { email } = useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("Training");
  const [allRahaStoreSearch, setAllRahaStoreSearch] = useState("");

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={["Training", "Support", "Promote"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setAllRahaStoreSearch}
      />
      <div className={classNames.rahastoreDetails}>
        <EducationContainer />
      </div>
    </div>
  );
};

export default Engage;
