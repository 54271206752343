import docterIcon from "../../images/employer/wellness/docter.svg";
import labTestIcon from "../../images/employer/wellness/labtest.svg";
import scanIcon from "../../images/employer/wellness/scan.svg";
import bedsideAssistantIcon from "../../images/employer/wellness/bedassistant.svg";
import nursingIcon from "../../images/employer/wellness/nursing.svg";
import physiotheraphyIcon from "../../images/employer/wellness/physiotheraphy.svg";
import wellnessIcon from "../../images/employer/wellness/wellness.svg";
import medicineIcon from "../../images/employer/wellness/medicines.svg";
import diagnosticIcon from "../../images/employer/wellness/diagnostic.svg";
import familyDocterIcon from "../../images/employer/wellness/familydocter.svg";
import headspace from "../../images/employer/wellness/headspace.svg";
import cultfit from "../../images/employer/wellness/cultfit.svg";
import callhealth from "../../images/employer/wellness/callhealth.svg";
import callhealthlogo from "../../images/employer/wellness/callhealthlogo.svg";
import oroglee from "../../images/employer/wellness/callhealth.svg";
import redcliff from "../../images/employer/wellness/redcliff.svg";

export const allTests = [
  { title: "Doctor Consultation", image: docterIcon },
  { title: "Lab Tests", image: labTestIcon },
  { title: "X-Ray and Scans", image: scanIcon },
  { title: "Bedside Attendant", image: bedsideAssistantIcon },
  { title: "Nursing", image: nursingIcon },
  { title: "Physiotheraphy at Home / Virtual", image: physiotheraphyIcon },
  { title: "Wellness", image: wellnessIcon },
  { title: "Medicines", image: medicineIcon },
  { title: "Diagnostic - Others", image: diagnosticIcon },
  { title: "Family Doctor", image: familyDocterIcon },
];

export const allCompanies = [
  { title: "Call Health", image: callhealthlogo },
  { title: "Orgoglee", image: oroglee },
  { title: "RedCliffe Labs", image: redcliff },
];

export const allBrands = [
  {
    image: headspace,
    companyName: "Headspace",
    category: "Mental Health",
    letter: "H",
  },
  { image: cultfit, companyName: "Cult Fit", category: "Health", letter: "C" },
  {
    image: callhealth,
    companyName: "Call Health",
    category: "Healthcare",
    letter: "C",
  },
];

export const subCategory = [
  "Gastroenterology",
  "Internal Medicine",
  "Obstetrics & Gynaecology",
  "Neurology",
  "General Physician - GP",
  "Psychiatry",
  "General Surgery",
  "ENT",
  "Pulmonology",
  "Dentistry",
  "Covid Consultation",
  "Paediatrics",
  "Psychology",
  "Nutrition & Dietetics",
  "Cardiology",
  "Dermatology",
];

export const mainMenuItems = [
  { name: "Lab tests", subMenuItem: "Lab tests" },
  { name: "Doctor Consultation", subMenuItem: "FinACE’ured" },
  {
    name: "Physiotherapy",
    subMenuItem: "FinACE’ured",
  },
  { name: "Bedside Attendant", subMenuItem: "FinACE’ured" },
  { name: "Wellness", subMenuItem: "CRM" },
  { name: "COVID Solutions", subMenuItem: "CRM" },
];

export const subMenuItemsLabTests = [
  { name: "X-Ray and Scans" },
  { name: "Nursing" },
  { name: "Medicines" },
  { name: "Medical Equipment" },
  { name: "Family Doctor" },
  { name: "Diagnostics - Others" },
];

export const cartmembers = [
  {
    name: "betty thomas",
    assignedUnit: "HR Manager",
    date: "01/01/2024",
    location: "Hyderabad",
    service: "HR Manager",
  },
];

export const allService = [
  {
    wellness_service_name: "Arthritis Screening Package",
    wellness_service_description:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    wellness_service_price: "4563",
    wellness_service_logo_url: headspace,
    wellness_partner_name: "Partner",
  },
  {
    wellness_service_name: "2Arthritis Screening",
    wellness_service_description:
      "2Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    wellness_service_price: "7896",
    wellness_service_logo_url: cultfit,
    wellness_partner_name: "Partner2",
  },
];

export const wellnessTypeMenu = [
  { name: "Services" },
  { name: "Categories" },
  { name: "Brands" },
];

export const ordersTypeMenu = [{ name: "All Time" }];
