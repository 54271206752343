import React, { useContext, useState } from "react";

import "./custom.scss";
import classNames from "../../pages/MyProfile/myprofile.module.scss";

import { ReactComponent as CopyIcon } from "../../assest/images/icons/copy.svg";
import { predefinedToast } from "../../utils/toast";
import { BankContext } from "../../context/Context";

import { TiTick } from "react-icons/ti";
import { useRef } from "react";
import { useEffect } from "react";
import { ReactComponent as CompleteIcon } from "../../assest/images/icons/complete.svg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Axios from "axios";

import fullLogo from "../../assest/images/fullLogo.svg";

const CustomCheckbox = ({ actionBtns, setActionBtns, id, name, type }) => {
  const { setOverlayDeleteItemName, setGlobalSelectedCheckbox } =
    useContext(BankContext);

  const handleCheckboxChange = () => {
    // console.log(id, " selected id");
    // if (type == "single") {
    //   setActionBtns([id]);
    // } else if (Array.isArray(actionBtns) && actionBtns?.includes(id)) {
    //   // If the ID is already in the array, remove it
    //   setActionBtns(actionBtns.filter((item) => item !== id));
    // } else {
    //   // If the ID is not in the array, add it
    //   setActionBtns([...actionBtns, id]);
    // }

    if (type === "array" && actionBtns?.includes(id)) {
      setGlobalSelectedCheckbox(id);
      setActionBtns((prev) => {
        return prev?.filter((eachID) => eachID !== id);
      });
    } else if (type === "array") {
      setGlobalSelectedCheckbox(id);
      setActionBtns((prev) => [...prev, id]);
    } else if (actionBtns?.includes(id)) {
      setGlobalSelectedCheckbox("");
      setActionBtns([]);
    } else {
      setGlobalSelectedCheckbox(id);
      setActionBtns([id]);
    }

    setOverlayDeleteItemName(name);
  };

  // Check if the current checkbox's ID is present in the actionBtns array
  const isChecked = actionBtns?.includes(id);

  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        id="myCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span className={`checkmark ${isChecked ? "checked" : ""}`}>
        {isChecked && (
          // <TiTick />
          <div className="checkedBox"></div>
        )}
      </span>
    </label>
  );
};

export default CustomCheckbox;

export const CopyBtn = ({ copyText }) => {
  return (
    <CopyIcon
      style={{
        width: "12px",
        height: "12px",
        cursor: "pointer",
        marginLeft: "7.5px",
        fontFamily: "Montserrat",
      }}
      onClick={() => {
        navigator.clipboard.writeText(copyText);
        predefinedToast("Copied");
      }}
      className="copy_icon"
    />
  );
};

export const EachCustomProfileDropdown = ({
  title,
  // dropdown,
  name,
  stateValue,
  setState,
  pic,
}) => {
  const { email, setProtectionScore, setProtectionScoreLoading } =
    useContext(BankContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [dropdown, setDropdown] = useState("");

  useEffect(() => {
    setProtectionScoreLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuser?user_email=${email}`
    )
      .then((response) => {
        // console.log(response, "user profile response dropdown");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          // setIsProfileFound(response?.data?.data[0]);
          setDropdown(response?.data?.data[0]);
          setProtectionScore(response?.data?.data[0]);
        } else {
          // setIsProfileFound(false);
        }
        setProtectionScoreLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
        setProtectionScoreLoading(false);
      });
  }, [email]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: isOpen ? "250px" : "" }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          padding: isOpen ? "0.6rem 1.75rem" : "",
        }}
        ref={dropdownRef}
      >
        <span style={{ display: isOpen ? "none" : "" }}>
          <span>
            <img
              src={
                dropdown?.profile_picture
                  ? dropdown?.profile_picture
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
              }
              alt=""
            />
            <span>
              {selectedOption
                ? selectedOption
                : dropdown?.user_name
                ? dropdown?.user_name
                : ""}
            </span>
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul
            className="dropdown-list"
            style={{ marginTop: isOpen ? "0" : "" }}
          >
            <li style={{ position: "relative" }}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
              <IoMdArrowDropup
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "-7px",
                  transform: "translate(-50%,-50%)",
                }}
              />
            </li>
            {dropdown?.profile_picture && dropdown?.user_name ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption(dropdown?.user_name);
                  // setState(eachitem);
                }}
                key={"primary"}
                // style={{
                //   display: eachitem === stateValue ? "none" : "",
                // }}
              >
                <img
                  src={
                    dropdown?.profile_picture
                      ? dropdown?.profile_picture
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                  }
                  alt=""
                />
                {dropdown?.user_name ? dropdown?.user_name : ""}
                <CompleteIcon />
              </li>
            ) : (
              ""
            )}
            {title === "selectprofile"
              ? dropdown?.dependents?.length > 0 &&
                dropdown?.dependents
                  ?.filter((row) => {
                    console.log(row, "select profile row");
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.user_name
                      ?.toLowerCase()
                      ?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.user_name);
                          // setState(eachitem);
                        }}
                        key={eachitem?.user_name + index}
                        // style={{
                        //   display: eachitem === stateValue ? "none" : "",
                        // }}
                      >
                        <img
                          src={
                            eachitem?.profile_picture
                              ? eachitem?.profile_picture
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                          }
                          alt=""
                        />
                        {eachitem?.user_name}
                        {/* {selectedOption == eachitem && <CompleteIcon />} */}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"All Countries"}
              >
                All Countries
              </li>
            ) : (
              ""
            )}
            <li className={classNames.addBtn}>Add Family Member</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const EachCustomPolicyNumberDropdown = ({
  title,
  // dropdown,
  name,
  stateValue,
  setState,
  pic,
  specificPolicies,
  noposition,
}) => {
  const {
    email,
    setProtectionScore,
    setProtectionScoreLoading,
    globalHrId,
    globalSelectedPolicy,
    setGlobalSelectedPolicy,
    dashboardToggle,
    setGlobalUserEmployeeId,
    setGlobalUserEmployeeDetails,
    selectedGlobalLead,
  } = useContext(BankContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [localSelected, setLocalSelected] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [dropdown, setDropdown] = useState("");

  function isAnyObjectEmpty(arrayOfObjects) {
    for (const obj of arrayOfObjects) {
      if (Object.keys(obj).length === 0) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    setDropdown("");
    setProtectionScoreLoading(true);
    if (window?.location?.pathname?.includes("/app/rahax/")) {
      Axios.post(
        `https://insurancecoverfi.apimachine.com/insurance/lead/get/projected?lead_email=${email}`,
        {
          project: {
            health_policy_number: 1,
            accidental_policy_number: 1,
            life_policy_number: 1,
          },
        }
      )
        .then((response) => {
          console.log(
            response,
            "policy numbers response employees dropdown /app/rahax/"
          );
          if (response?.data?.status && response?.data?.data?.length > 0) {
            // setIsProfileFound(response?.data?.data[0]);
            let obj = [];

            if (
              Array.isArray(response?.data?.data[0]?.health_policy_number) &&
              response?.data?.data[0]?.health_policy_number?.length > 0
            ) {
              response?.data?.data[0]?.health_policy_number?.forEach(
                (eachItem) => {
                  obj.push({
                    name: "Health Policy :",
                    value: eachItem,
                  });
                }
              );
            } else if (response?.data?.data[0]?.health_policy_number) {
              obj.push({
                name: "Health Policy :",
                value: response?.data?.data[0]?.health_policy_number,
              });
            }

            if (
              Array.isArray(
                response?.data?.data[0]?.accidental_policy_number
              ) &&
              response?.data?.data[0]?.accidental_policy_number?.length > 0
            ) {
              response?.data?.data[0]?.accidental_policy_number?.forEach(
                (eachItem) => {
                  obj.push({
                    name: "Accident Policy :",
                    value: eachItem,
                  });
                }
              );
            } else if (response?.data?.data[0]?.accidental_policy_number) {
              obj.push({
                name: "Accident Policy :",
                value: response?.data?.data[0]?.accidental_policy_number,
              });
            }

            if (
              Array.isArray(response?.data?.data[0]?.life_policy_number) &&
              response?.data?.data[0]?.life_policy_number?.length > 0
            ) {
              response?.data?.data[0]?.life_policy_number?.forEach(
                (eachItem) => {
                  obj.push({
                    name: "Life Policy :",
                    value: eachItem,
                  });
                }
              );
            } else if (response?.data?.data[0]?.life_policy_number) {
              obj.push({
                name: "Life Policy :",
                value: response?.data?.data[0]?.life_policy_number,
              });
            }

            if (localStorage.getItem("globalSelectedPolicy")) {
              setGlobalSelectedPolicy(
                localStorage.getItem("globalSelectedPolicy")
              );
            } else if (globalSelectedPolicy) {
              setGlobalSelectedPolicy(globalSelectedPolicy);
            } else {
              if (obj?.length > 0) {
                setGlobalSelectedPolicy(obj[0]?.value);
              }
            }
            // console.log(obj, "obj from policy number");
            setDropdown(obj);
            setProtectionScore(response?.data?.data[0]);
          } else {
            // setIsProfileFound(false);
          }
          setProtectionScoreLoading(false);
        })
        .catch((error) => {
          console.log(error?.message, "policy numbers employees error");
          setProtectionScoreLoading(false);
        });
    } else {
      if (dashboardToggle == "Employee") {
        Axios.post(
          `https://insurancecoverfi.apimachine.com/insurance/lead/get/projected?lead_email=${email}`,
          {
            project: {
              first_name: 1,
              last_name: 1,
              lead_email: 1,
              phone_number: 1,
              follow_up_status: 1,
              lead_id: 1,
              hr_id: 1,
              employee: 1,
              agent_id: 1,
              user_employee_id: 1,
              health_policy_number: 1,
              accidental_policy_number: 1,
              life_policy_number: 1,
            },
          }
        )
          .then((response) => {
            console.log(
              response,
              "policy numbers response employers dropdown (FOr Employee Toggle)"
            );
            if (response?.data?.status && response?.data?.data?.length > 0) {
              let isProfileFromEmployee =
                response?.data?.data?.length > 0
                  ? response?.data?.data?.filter(
                      (eachItem) => eachItem?.employee?.toLowerCase() == "yes"
                    )
                  : [];
              setGlobalUserEmployeeId(
                isProfileFromEmployee[0]?.user_employee_id
              );
              setGlobalUserEmployeeDetails(response?.data?.data);
              localStorage.setItem(
                "globalUserEmployeeId",
                isProfileFromEmployee[0]?.user_employee_id
              );
              // setIsProfileFound(isProfileFromEmployee[0]);
              let obj = [];
              if (
                Array.isArray(isProfileFromEmployee[0]?.health_policy_number) &&
                isProfileFromEmployee[0]?.health_policy_number?.length > 0
              ) {
                isProfileFromEmployee[0]?.health_policy_number?.forEach(
                  (eachItem) => {
                    obj.push({
                      name: "Health Policy :",
                      value: eachItem,
                    });
                  }
                );
              } else if (isProfileFromEmployee[0]?.health_policy_number) {
                obj.push({
                  name: "Health Policy :",
                  value: isProfileFromEmployee[0]?.health_policy_number,
                });
              }

              if (
                Array.isArray(
                  isProfileFromEmployee[0]?.accidental_policy_number
                ) &&
                isProfileFromEmployee[0]?.accidental_policy_number?.length > 0
              ) {
                isProfileFromEmployee[0]?.accidental_policy_number?.forEach(
                  (eachItem) => {
                    obj.push({
                      name: "Accident Policy :",
                      value: eachItem,
                    });
                  }
                );
              } else if (isProfileFromEmployee[0]?.accidental_policy_number) {
                obj.push({
                  name: "Accident Policy :",
                  value: isProfileFromEmployee[0]?.accidental_policy_number,
                });
              }

              if (
                Array.isArray(isProfileFromEmployee[0]?.life_policy_number) &&
                isProfileFromEmployee[0]?.life_policy_number?.length > 0
              ) {
                isProfileFromEmployee[0]?.life_policy_number?.forEach(
                  (eachItem) => {
                    obj.push({
                      name: "Life Policy :",
                      value: eachItem,
                    });
                  }
                );
              } else if (isProfileFromEmployee[0]?.life_policy_number) {
                obj.push({
                  name: "Life Policy :",
                  value: isProfileFromEmployee[0]?.life_policy_number,
                });
              }

              if (localStorage.getItem("globalSelectedPolicy")) {
                setGlobalSelectedPolicy(
                  localStorage.getItem("globalSelectedPolicy")
                );
              } else if (globalSelectedPolicy) {
                setGlobalSelectedPolicy(globalSelectedPolicy);
              } else {
                if (obj?.length > 0) {
                  setGlobalSelectedPolicy(obj[0]?.value);
                }
              }
              console.log(obj, "obj from policy number");
              setDropdown(obj);
              setProtectionScore(isProfileFromEmployee[0]);
            } else {
              // setIsProfileFound(false);
            }
            setProtectionScoreLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "policy numbers employers error");
            setProtectionScoreLoading(false);
          });
      } else if (globalHrId) {
        Axios.get(
          `https://insurancecoverfi.apimachine.com/insurance/hr/get?hr_id=${globalHrId}`
        )
          .then((response) => {
            if (response?.data?.status && response?.data?.data?.length > 0) {
              // setIsProfileFound(response?.data?.data[0]);
              let obj = [];

              if (
                Array.isArray(response?.data?.data[0]?.health_policy_number) &&
                response?.data?.data[0]?.health_policy_number?.length > 0
              ) {
                response?.data?.data[0]?.health_policy_number?.forEach(
                  (eachItem) => {
                    obj.push({
                      name: "Health Policy :",
                      value: eachItem,
                    });
                  }
                );
              } else if (response?.data?.data[0]?.health_policy_number) {
                obj.push({
                  name: "Health Policy :",
                  value: response?.data?.data[0]?.health_policy_number,
                });
              }

              if (
                Array.isArray(
                  response?.data?.data[0]?.accidental_policy_number
                ) &&
                response?.data?.data[0]?.accidental_policy_number?.length > 0
              ) {
                response?.data?.data[0]?.accidental_policy_number?.forEach(
                  (eachItem) => {
                    obj.push({
                      name: "Accident Policy :",
                      value: eachItem,
                    });
                  }
                );
              } else if (response?.data?.data[0]?.accidental_policy_number) {
                obj.push({
                  name: "Accident Policy :",
                  value: response?.data?.data[0]?.accidental_policy_number,
                });
              }

              if (
                Array.isArray(response?.data?.data[0]?.life_policy_number) &&
                response?.data?.data[0]?.life_policy_number?.length > 0
              ) {
                response?.data?.data[0]?.life_policy_number?.forEach(
                  (eachItem) => {
                    obj.push({
                      name: "Life Policy :",
                      value: eachItem,
                    });
                  }
                );
              } else if (response?.data?.data[0]?.life_policy_number) {
                obj.push({
                  name: "Life Policy :",
                  value: response?.data?.data[0]?.life_policy_number,
                });
              }
              if (localStorage.getItem("globalSelectedPolicy")) {
                setGlobalSelectedPolicy(
                  localStorage.getItem("globalSelectedPolicy")
                );
              } else if (globalSelectedPolicy) {
                setGlobalSelectedPolicy(globalSelectedPolicy);
              } else {
                if (obj?.length > 0) {
                  setGlobalSelectedPolicy(obj[0]?.value);
                }
              }
              // console.log(obj, "obj from policy number");
              setDropdown(obj);
              setProtectionScore(response?.data?.data[0]);
            } else {
              // setIsProfileFound(false);
            }
            setProtectionScoreLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "policy numbers employers error");
            setProtectionScoreLoading(false);
          });
      }
    }
  }, [globalHrId, dashboardToggle]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    getLocalSelected();

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    let value = option?.split(":");
    console.log(value, "inside selectOption");
    setSelectedOption(option);
    if (value?.length > 0) {
      localStorage.setItem("globalSelectedPolicy", value[1]);
      setGlobalSelectedPolicy(value[1]);
    }
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  function getLocalSelected() {
    let res;
    if (dropdown?.length > 0) {
      res = dropdown?.filter((eachPolicy) => {
        return (
          eachPolicy?.value == globalSelectedPolicy ||
          localStorage.getItem("globalSelectedPolicy") == eachPolicy?.value
        );
      });
      setLocalSelected(res);
    }
    if (specificPolicies?.length > 0) {
      res = specificPolicies?.filter((eachPolicy) => {
        return (
          eachPolicy?.policy_number == globalSelectedPolicy ||
          localStorage.getItem("globalSelectedPolicy") ==
            eachPolicy?.policy_number
        );
      });
      setLocalSelected(res);
      console.log("Coming inside specific option:", specificPolicies);

      selectOption("Policy Number :" + specificPolicies[0]?.policy_number);
    }
  }

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "270px" : "",
        pointerEvents:
          noposition == "true"
            ? selectedGlobalLead
              ? ""
              : "none"
            : dropdown?.length > 0 || specificPolicies?.length > 0
            ? ""
            : "none",
        position: noposition ? "unset" : "relative",
        background: "white",
      }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents:
            dropdown?.length > 0 || specificPolicies?.length > 0 ? "" : "none",
          // padding: isOpen ? "0.6rem 1.75rem" : "",
          position: "unset",
          width: "100%",
          maxWidth: "none",
        }}
        ref={dropdownRef}
      >
        <span
          style={{
            gap: "10px",
            padding: "1rem",
            justifyContent: "space-between",
            width: "100%",
            background: "white",
            position: "relative",
            zIndex: "5",
          }}
        >
          <span>
            <span>
              {noposition == "true"
                ? selectedGlobalLead
                  ? localStorage?.getItem("globalSelectedPolicy")
                    ? `Policy Number :${localStorage?.getItem(
                        "globalSelectedPolicy"
                      )}`
                    : "No policies"
                  : "Select policy"
                : localStorage?.getItem("globalSelectedPolicy")
                ? `Policy Number :${localStorage?.getItem(
                    "globalSelectedPolicy"
                  )}`
                : localSelected?.length > 0
                ? localSelected[0]?.name + localSelected[0]?.value
                : localSelected
                ? `Policy Number :${localSelected}`
                : selectedOption && !specificPolicies
                ? selectedOption
                : dropdown?.length > 0
                ? dropdown[0]?.name + dropdown[0]?.value
                : specificPolicies && specificPolicies?.length > 0
                ? "Select policy"
                : "Policy Number :"}
            </span>
          </span>
          <span
            style={{
              fontSize: "1.2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>

        <ul
          className={classNames.dropdownContent}
          style={{
            marginTop: isOpen ? "10px" : "",
            top: isOpen ? "" : "-800px",
            borderRadius: "20px",
            border: "1px solid #e7e7e7",
          }}
        >
          {specificPolicies && specificPolicies?.length > 0
            ? specificPolicies?.map((eachItem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption("Policy Number :" + eachItem?.policy_number);
                      // setState(eachItem);
                    }}
                    key={"Policy Number :" + index}
                    // style={{
                    //   display: eachItem === statepolicy_number ? "none" : "",
                    // }}
                  >
                    {"Policy Number :" + eachItem?.policy_number}
                    {/* {selectedOption == eachItem && <CompleteIcon />} */}
                    <CopyBtn
                      copyText={
                        eachItem?.policy_number ? eachItem?.policy_number : ""
                      }
                    />
                  </li>
                );
              })
            : dropdown?.length > 0
            ? dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem?.name + eachitem?.value);
                      // setState(eachitem);
                    }}
                    key={eachitem?.name + index}
                    // style={{
                    //   display: eachitem === stateValue ? "none" : "",
                    // }}
                  >
                    {eachitem?.name + eachitem?.value}
                    {/* {selectedOption == eachitem && <CompleteIcon />} */}
                    <CopyBtn
                      copyText={eachitem?.value ? eachitem?.value : ""}
                    />
                  </li>
                );
              })
            : ""}
        </ul>
      </div>
    </div>
  );
};

export const EachCustomPincodeDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  pic,
  title2,
}) => {
  const {
    email,
    setProtectionScore,
    setProtectionScoreLoading,
    globalHrId,
    globalSelectedPolicy,
    setGlobalSelectedPolicy,
    dashboardToggle,
  } = useContext(BankContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, key) => {
    setSelectedOption(option);
    setIsOpen(false);
    setState(key);
    // console.log(setState, "selected pincode");
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        minWidth: "160px",
        pointerEvents: dropdown?.length > 0 ? "" : "none",
        maxHeight: "50px",
      }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          padding: isOpen ? "0.6rem 1.75rem" : "",
          borderBottom: isOpen ? "0" : "",
          borderRadius: isOpen ? "5px 5px 0 0" : "",
          border: "1px solid #e7e7e7",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ fontWeight: "500" }}>
            <span>
              {selectedOption
                ? selectedOption
                : title2 == "filterByHospital"
                ? "Name"
                : "Patient"}
            </span>
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul
            className={`dropdown-list ${classNames.dropdownList}`}
            style={{ marginTop: isOpen ? "0" : "" }}
          >
            {/* <li style={{ position: "relative" }}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
              <IoMdArrowDropup
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "-7px",
                  transform: "translate(-50%,-50%)",
                }}
              />
            </li> */}
            {/* {dropdown?.profile_picture && dropdown?.user_name ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption(dropdown?.user_name);
                  // setState(eachitem);
                }}
                key={"primary"}
                // style={{
                //   display: eachitem === stateValue ? "none" : "",
                // }}
              >
                <img
                  src={
                    dropdown?.profile_picture
                      ? dropdown?.profile_picture
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                  }
                  alt=""
                />
                {dropdown?.user_name ? dropdown?.user_name : ""}
                <CompleteIcon />
              </li>
            ) : (
              ""
            )} */}
            {title == "filterBy" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem?.name, eachitem?.key);
                      }}
                      key={eachitem?.key + index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {eachitem?.name}
                      {eachitem == "Name" && <IoMdArrowDropup />}
                      {/* {selectedOption == eachitem && <CompleteIcon />} */}
                    </li>
                  );
                })
              : dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem?.piN_CODE);
                      }}
                      key={eachitem?.piN_CODE + index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {eachitem?.piN_CODE}

                      {eachitem == "Name" && <IoMdArrowDropup />}
                      {/* {selectedOption == eachitem && <CompleteIcon />} */}
                    </li>
                  );
                })
              : ""}
            {title == "filterBy" ? (
              ""
            ) : stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"All Countries"}
              >
                All Countries
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const HRIDDropdown = ({ title, name, stateValue, setState, pic }) => {
  const {
    loggedInUserId,
    loggedInUserRoleId,
    setGlobalHrId,
    setSelectedGlobalLead,
  } = useContext(BankContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    (localStorage.getItem("HRIDDropdown") &&
      JSON.parse(localStorage.getItem("HRIDDropdown"))) ||
      ""
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdown, setDropdown] = useState([]);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    localStorage.setItem("HRIDDropdown", JSON.stringify(option));
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  async function getHRIDAssociates() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/project/?role_id=${loggedInUserRoleId}&user_id=${loggedInUserId}&policy_exits=true`
      );
      if (response?.data?.data?.length > 0) {
        setDropdown(response?.data?.data);
      }
      console.log(response, "response from getHRIDAssociates");
    } catch (error) {
      console.log(error?.message, "getHRIDAssociates");
    }
  }

  useEffect(() => {
    if (loggedInUserRoleId && loggedInUserId) {
      getHRIDAssociates();
    }
  }, [loggedInUserRoleId, loggedInUserId]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        minWidth: "max-content",
        border: "none",
        position: "unset",
        width: "135px",
      }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          // position: "relative",
          padding: "0",
          borderRight: "1px solid #e1e1e1",
          borderRadius: "35px 0 0 35px",
        }}
        ref={dropdownRef}
      >
        <span
          style={{
            gap: "10px",
            border: "1px solid #e7e7e7",
            padding: "1rem",
            border: "none",
            // borderBottom: isOpen ? "0" : "1px solid #e7e7e7",
          }}
        >
          <span style={{ width: "100px", display: "flex", gap: "8px" }}>
            {selectedOption ? (
              <>
                {selectedOption?.company_icon && (
                  <img
                    src={selectedOption?.company_icon}
                    alt={selectedOption?.company_name}
                    style={{ height: "1.25rem", width: "1.25rem" }}
                  />
                )}
                <div style={{ display: "grid" }}>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "520",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {selectedOption?.company_name}
                  </div>
                  {/* <div
                    style={{
                      fontSize: "0.6rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {selectedOption?.hr_id}
                  </div> */}
                </div>
              </>
            ) : (
              "Select Lead"
            )}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>

        {isOpen && (
          <ul
            className={classNames.dropdownContent}
            style={{
              marginTop: isOpen ? "10px" : "",
              left: "0px",
              // right: "unset",
              width: "100%",
              borderRadius: "20px",
              border: "1px solid #e7e7e7",
            }}
          >
            <li
              style={{
                position: "relative",
                height: "3rem",
                minHeight: "3rem",
                padding: "1rem",
                borderBottom: "1px solid #e1e1e1",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
              {/* <IoMdArrowDropup
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "-7px",
                  transform: "translate(-50%,-50%)",
                }}
              /> */}
            </li>
            {/* {dropdown?.profile_picture && dropdown?.user_name ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption(dropdown?.user_name);
                  // setState(eachitem);
                }}
                key={"primary"}
                // style={{
                //   display: eachitem === stateValue ? "none" : "",
                // }}
              >
                <img
                  src={
                    dropdown?.profile_picture
                      ? dropdown?.profile_picture
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                  }
                  alt=""
                />
                {dropdown?.user_name ? dropdown?.user_name : ""}
                <CompleteIcon />
              </li>
            ) : (
              ""
            )} */}
            {dropdown?.length > 0 &&
              dropdown
                ?.filter((row) => {
                  // Convert the row object values to an array and check if any value matches the search query
                  const values = Object?.values(row);
                  const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                  return values?.some((value) => {
                    if (typeof value === "string") {
                      return value?.toLowerCase()?.includes(searchQueryy);
                    }
                    return false;
                  });
                })
                ?.map((eachitem, index) => {
                  return (
                    <li
                      className={classNames.associatesEachHr}
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem);
                        setGlobalHrId(eachitem?.hr_id);
                        localStorage.setItem(
                          "globalUserHrIdRaha",
                          eachitem?.hr_id
                        );
                        setSelectedGlobalLead(eachitem?.hr_id);
                        localStorage.setItem(
                          "globalLeadDropdown",
                          eachitem?.hr_id
                        );
                      }}
                      key={eachitem?.company_name + index}
                    >
                      {eachitem?.company_icon ? (
                        <img
                          src={eachitem?.company_icon}
                          alt={eachitem?.company_name + index}
                        />
                      ) : (
                        ""
                      )}
                      <div>
                        <div>{eachitem?.company_name}</div>
                        {/* <div>{eachitem?.hr_id}</div> */}
                      </div>
                    </li>
                  );
                })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const EachCustomNormalDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  pic,
}) => {
  const { email, setProtectionScore, setProtectionScoreLoading } =
    useContext(BankContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: "max-content" }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          position: "relative",
          padding: "0",
        }}
        ref={dropdownRef}
      >
        <span
          style={{
            gap: "10px",
            border: "1px solid #e7e7e7",
            padding: "1rem",
            borderBottom: isOpen ? "0" : "1px solid #e7e7e7",
          }}
        >
          <span style={{ width: "100%" }}>
            <span>{selectedOption ? selectedOption : title ? title : ""}</span>
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>

        {isOpen && (
          <ul
            className={classNames.dropdownContent}
            style={{
              marginTop: isOpen ? "0" : "",
              left: "0px",
              right: "unset",
              width: "100%",
            }}
          >
            {dropdown?.profile_picture && dropdown?.user_name ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption(dropdown?.user_name);
                  // setState(eachitem);
                }}
                key={"primary"}
                // style={{
                //   display: eachitem === stateValue ? "none" : "",
                // }}
              >
                <img
                  src={
                    dropdown?.profile_picture
                      ? dropdown?.profile_picture
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                  }
                  alt=""
                />
                {dropdown?.user_name ? dropdown?.user_name : ""}
                <CompleteIcon />
              </li>
            ) : (
              ""
            )}
            {dropdown?.length > 0 &&
              dropdown
                ?.filter((row) => {
                  // Convert the row object values to an array and check if any value matches the search query
                  const values = Object?.values(row);
                  const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                  return values?.some((value) => {
                    if (typeof value === "string") {
                      return value?.toLowerCase()?.includes(searchQueryy);
                    }
                    return false;
                  });
                })
                ?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem?.name);
                        window.open(eachitem?.link, "_blank");
                      }}
                      key={eachitem?.name + index}
                    >
                      {eachitem?.name}
                    </li>
                  );
                })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"All Countries"}
              >
                All Countries
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const LoadingAnimationRaha = ({
  height,
  position,
  opacity,
  zIndex,
  inset,
}) => {
  return (
    <div
      className="loadinganim_raha"
      style={{
        height: height ? height : "",
        position: position ? position : "",
        opacity: opacity ? opacity : "",
        pointerEvents: opacity ? "none" : "",
        zIndex: opacity || zIndex ? "5" : "",
        inset: inset ? inset : "",
      }}
    >
      <span class="loading__anim"></span>
    </div>
  );
};

export const LoadingAnimationRahaLogo = ({
  height,
  position,
  opacity,
  form,
  inset,
}) => {
  return (
    <div
      className="loadinganim_raha"
      style={{
        height: height ? height : "",
        position: position ? position : "",
        pointerEvents: opacity ? "none" : "",
        zIndex: opacity ? "5" : "",
        inset: inset ? inset : "",
        // border: "1px solid red",
      }}
    >
      <img src={fullLogo} alt="fullLogo" className="rahaLogoloading" />
    </div>
  );
};

export const LoadingAnimationRahaLogoFixed = ({
  height,
  position,
  opacity,
  form,
}) => {
  return (
    <div
      className="loadinganim_raha"
      style={{
        height: "100vh",
        width: "100vw",
        inset: 0,
        position: "fixed",
        pointerEvents: "none",
        zIndex: 50,
      }}
    >
      <img src={fullLogo} alt="fullLogo" className="rahaLogoloading" />
    </div>
  );
};

export const CustomInputVisit = ({ title, placeholder, setState }) => {
  return (
    <div
      className="visitInput"
      style={{ flexDirection: title == "Phone" ? "column" : "" }}
    >
      <label htmlFor={"visitInput" + title} className="title">
        {title}
      </label>
      <input
        type="text"
        name={"visitInput" + title}
        className="inputContainer"
        placeholder={placeholder}
        onChange={(event) => {
          setState(event?.target?.value);
        }}
      />
    </div>
  );
};
