import React, { useContext } from "react";
import classNames from "./prelogin.module.scss";

import { Link } from "react-router-dom";

import rahaxFullLogo from "../../assest/images/fullLogo.svg";

import employeesLogo from "../../assest/images/login/prelogin/employees.svg";
import employersLogo from "../../assest/images/login/prelogin/employers.svg";
import associatesLogo from "../../assest/images/login/prelogin/associates.svg";
import adminLogo from "../../assest/images/login/prelogin/admin.svg";
import { BankContext } from "../../context/Context";

const PreLogin = () => {
  const { setGlobalHrId, setGlobalUserId, setGlobalCompanyId } =
    useContext(BankContext);

  const allApps = [
    { logo: employeesLogo, name: "For Employees", path: "/employee" },
    { logo: employersLogo, name: "For Employers", path: "/employer" },
    { logo: associatesLogo, name: "For Associates", path: "/associates" },
    { logo: adminLogo, name: "For Admin", path: "/admin" },
  ];

  function logoutFunction() {
    setGlobalUserId("");
    setGlobalHrId("");
    setGlobalCompanyId("");
    localStorage.clear();
  }
  return (
    <div className={classNames.prelogin}>
      <img
        src={rahaxFullLogo}
        alt="rahaxFullLogo"
        className={classNames.logo}
      />
      <div className={classNames.message}>
        Welcome To Tall Tree. Which Of Our Apps Do You Want To Use?
      </div>
      <div className={classNames.allApps}>
        {allApps?.length > 0 &&
          allApps?.map(({ name, logo, path }, index) => {
            return (
              <Link
                className={classNames.eachApp}
                key={name + index}
                onClick={logoutFunction}
                to={`${path}/login`}
              >
                <div className={classNames.appLogo}>
                  <img src={logo} alt={name} />
                </div>
                <div className={classNames.appName}>{name}</div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default PreLogin;
