import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./hr.module.scss";

import moment from "moment";
import fullLogo from "../../../assest/images/fullLogo.svg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, { CopyBtn } from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV2 from "../../../components/NavbarV2";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";

import { ReactComponent as FilterIcon } from "../../../assest/images/allicons/filter.svg";
import { ReactComponent as SortArrows } from "../../../assest/images/allicons/sort.svg";

//action buttons icons
import { ReactComponent as EyeIcon } from "../../../assest/images/allicons/tableaction/eye.svg";
import { ReactComponent as EditIcon } from "../../../assest/images/allicons/tableaction/edit.svg";
import { ReactComponent as DownloadIcon } from "../../../assest/images/allicons/tableaction/download.svg";
import { ReactComponent as EmailIcon } from "../../../assest/images/allicons/tableaction/email.svg";
import { ReactComponent as BandaidIcon } from "../../../assest/images/allicons/tableaction/bandaid.svg";
import { ReactComponent as LockIcon } from "../../../assest/images/allicons/tableaction/lock.svg";
import { ReactComponent as DependentsIcon } from "../../../assest/images/allicons/tableaction/dependents.svg";
import { ReactComponent as AddIcon } from "../../../assest/images/allicons/tableaction/add.svg";
import { ReactComponent as DeleteIcon } from "../../../assest/images/allicons/tableaction/delete.svg";
import { ReactComponent as BulkUploadIcon } from "../../../assest/images/icons/bulkupload.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomDropdownInitiateMultiple,
  EachCustomDropdownInitiateMultipleHealth,
  EachCustomInput,
  EachCustomSelectOne,
} from "../../../components/Custom/Input";
import { GoDownload } from "react-icons/go";
import { ReactComponent as ManuallyIcon } from "../../../assest/images/employer/members/manually.svg";
import { ReactComponent as EnrollmentIcon } from "../../../assest/images/employer/members/enrollmentform.svg";
import { ReactComponent as SingleAddIcon } from "../../../assest/images/allicons/tableaction/singleadd.svg";
import { ReactComponent as BulkAddIcon } from "../../../assest/images/allicons/tableaction/bulkadd.svg";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import MembersForms, {
  BulkAssignPoliciesMembers,
  BulkEntryEnrollmentForm,
  BulkUpload,
  BulkUploadMemberUpdated,
  MemberDetailsAddDependent,
  MembersDetailsForm,
  MembersDetailsFormEnrollmentForm,
  MembersFormsEnrollmentForm,
  MembersReviewCompleted,
  MembersReviewDetails,
  MembersReviewDetailsEnrollmentForm,
  PreEnrollmentDownloads,
  PreEnrollmentForm,
  PreEnrollmentUpdated,
} from "./forms/MembersForms";
import NavbarV3 from "../../../components/NavbarV3";
import { BsThreeDotsVertical } from "react-icons/bs";
import { predefinedToast } from "../../../utils/toast";
import {
  base64ToBlob,
  calculateAge,
  calculateAgeDash,
  downloadFile,
  getCurrentDate,
  logoutToSameLogin,
} from "../../../assest/functions";

const fetchUsersAllCompaniesDropdown = (setUsersAllCompany, email) => {
  Axios.get(
    `https://insurancecoverfi.apimachine.com/insurance/corporate/get?owner_email=${email}`
  )
    .then((response) => {
      // console.log(response, "Users allCompanies dropdown response");
      if (response?.data?.status) {
        setUsersAllCompany(response?.data?.data);
      }
    })
    .catch((error) => {
      console.log(error?.message, "UsersAllCompanies dropdown error");
    });
};

const HR = () => {
  const {
    email,
    popularAction,
    setPopularAction,
    actionBtns,
    setActionBtns,
    setUpdateLoading,
    globalSelectedPolicy,
    addDependentSidebar,
    setAddDependentSidebar,
    lastSelectedEmployee,
    setLastSelectedEmployee,
    onLoginFormEmployerProfile,
    globalCompanyId,
    globalHrId,
  } = useContext(BankContext);

  const selectedItemTable = useRef(null);
  const history = useHistory();

  const [selectedProfileNav, setSelectedProfileNav] =
    useState("Internal Members");

  const [filterOpen, setFilterOpen] = useState(false);
  const [expandDetails, setExpandDetails] = useState([]);
  const [hoveringForm, setHoveringForm] = useState("");
  const [hoveringFormClick, setHoveringFormClick] = useState("");

  const [usersAllCompany, setUsersAllCompany] = useState("");
  const [usersAllCompanySelected, setUsersAllCompanySelected] = useState("");

  const [usersAllStates, setUsersAllStates] = useState("");
  const [usersAllStatesSelected, setUsersAllStatesSelected] = useState("");
  const [toggleSelectedTable, setToggleSelectedTable] = useState("Simplified");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");
  const [allLeadsAPI, setAllLeadsAPI] = useState("");
  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");
  const [selectedFilterOption, setSelectedFilterOption] = useState("");
  const [selectedFilterOptionActive, setSelectedFilterOptionActive] =
    useState("no");
  const [currentPage, setCurrentPage] = useState(1);

  //single enrollment states
  const [enrollmentFormSingle, setEnrollmentFormSingle] = useState({});
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [latestSelectedTypes, setLatestSelectedTypes] = useState("");

  useEffect(() => {
    // console.log("API is being called");
    setAllLeads("");
    setAllLeadsAPI("");
    setAllBrokeragesTotalProfile("");
    setAllLeadsLoading(true);

    const fetchAllBrokerages = () => {
      let URL = "";
      let body = {
        project: {
          lead_email: 1,
          lead_id: 1,
          lead_type: 1,
          follow_up_status: 1,
          first_name: 1,
          last_name: 1,
          phone_number: 1,
          user_employee_id: 1,
        },
      };

      if (
        selectedProfileNav == "Internal Members" &&
        toggleSelectedTable == "Simplified" &&
        onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails?._id
      ) {
        URL = `https://coverfi.apimachine.com/api/v1/client-user/employee/${
          onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails?._id
        }${
          selectedFilterOptionActive == "no"
            ? ""
            : selectedFilterOptionActive == true
            ? "?policy_exists=true"
            : selectedFilterOptionActive == false
            ? "?policy_exists=false"
            : ""
        }?skip=${
          currentPage === 1 ? 0 : (currentPage - 1) * 20
        }&limit=20&search=${
          allBrokeragesSearch ? allBrokeragesSearch?.toLowerCase() : ""
        }`;
      } else if (
        selectedProfileNav == "External Members" &&
        toggleSelectedTable == "Simplified" &&
        onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails?._id
      ) {
        URL = `https://coverfi.apimachine.com/api/v1/client-user/employee/${
          onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails?._id
        }${
          selectedFilterOptionActive == "no"
            ? ""
            : selectedFilterOptionActive == true
            ? "?policy_exists=true"
            : selectedFilterOptionActive == false
            ? "?policy_exists=false"
            : ""
        }?skip=${
          currentPage === 1 ? 0 : (currentPage - 1) * 20
        }&limit=20&search=${
          allBrokeragesSearch ? allBrokeragesSearch?.toLowerCase() : ""
        }`;
      } else if (
        selectedProfileNav == "Internal Members" &&
        globalHrId &&
        toggleSelectedTable == "Advanced"
      ) {
        URL = `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/get?hr_id=${globalHrId}&profile_status=Active`;
      } else if (
        selectedProfileNav == "External Members" &&
        globalHrId &&
        toggleSelectedTable == "Advanced"
      ) {
        URL = `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/get?hr_id=${globalHrId}&profile_status=Active`;
      } else if (
        selectedProfileNav == "Pending Members" &&
        globalHrId &&
        toggleSelectedTable == "Simplified"
      ) {
        URL = `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/get?hr_id=${globalHrId}&profile_status=Pending`;
      } else if (selectedProfileNav == "Pending Members" && globalHrId) {
        URL = `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/get?hr_id=${globalHrId}&profile_status=Pending`;
      } else {
      }

      if (toggleSelectedTable == "Simplified") {
        Axios.get(URL)
          .then((response) => {
            console.log(response, `${selectedProfileNav} response`);
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);

              if (
                selectedProfileNav == "Companies" &&
                response?.data?.data?.length > 0
              ) {
                const outputArray = response?.data?.data?.map(
                  ({ branchAddressState }) => ({
                    branchAddressState,
                  })
                );
                const uniqueArray = outputArray?.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (o) => o.branchAddressState === obj.branchAddressState
                    )
                );
                // console.log(outputArray, uniqueArray, "Simplified HR response");
                setUsersAllStates(uniqueArray);
              }
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "Simplified HR error");
            setAllLeadsLoading(false);
          });
      } else {
        Axios.get(URL)
          .then((response) => {
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);

              if (
                selectedProfileNav == "Companies" &&
                response?.data?.data?.length > 0
              ) {
                const outputArray = response?.data?.data?.map(
                  ({ branchAddressState }) => ({
                    branchAddressState,
                  })
                );
                const uniqueArray = outputArray?.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (o) => o.branchAddressState === obj.branchAddressState
                    )
                );
                // console.log(outputArray, uniqueArray, "dropdown");
                setUsersAllStates(uniqueArray);
              }
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "Advanced HR error");
            setAllLeadsLoading(false);
          });
      }
    };

    fetchAllBrokerages();
  }, [
    selectedProfileNav,
    email,
    toggleSelectedTable,
    selectedFilterOptionActive,
    currentPage,
    allBrokeragesSearch,
  ]);

  useEffect(() => {
    fetchUsersAllCompaniesDropdown(setUsersAllCompany, email);
    // setSelectedFilterOptionActive("no");
    // setFilterOpen(false);
    // setSelectedFilterOption("");
  }, [selectedProfileNav]);

  useEffect(() => {
    if (allLeadsAPI?.length > 0 && Array.isArray(allLeadsAPI)) {
      setAllBrokeragesTotalProfile("");
      let res = allLeadsAPI?.filter((row) => {
        const { displayName, branchAddressState, ...newObject } = row;

        // Creating a new object with the extracted properties
        if (selectedProfileNav == "Companies") {
          const separateObject = { branchAddressState };
          // Convert the row object values to an array and check if any value matches the search query
          const values = Object?.values(separateObject);
          const searchQuery = usersAllStatesSelected?.toLowerCase();

          // Check if the selected country matches the object's country (if selected)
          const countryMatches =
            !searchQuery ||
            values.some(
              (value) =>
                typeof value === "string" &&
                searchQuery?.toLowerCase().includes(value?.toLowerCase())
            );

          return countryMatches;
          //  && brokerageMatches;
        } else {
        }
      });
      setAllBrokeragesTotalProfile(res.length);
      setAllLeads(res);
    }
  }, [usersAllCompanySelected, usersAllStatesSelected]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  // functions

  async function getActiveMembersSpreadsheet(companyID, hrID) {
    window?.open(
      `https://insurancecoverfi.apimachine.com/insurance/hr/share?hr_id=${hrID}&company_id=${companyID}`,
      "_blank"
    );
  }
  async function getPendingMembersSpreadsheet(hrID) {
    window?.open(
      `https://insurancecoverfi.apimachine.com/insurance/hr/share/pending?hr_id=${hrID}`,
      "_blank"
    );
  }

  function onCloseSidebar() {
    if (sidebarOpen) {
      setSidebarOpen((prev) => !prev);
    }
  }

  useEffect(() => {
    if (actionBtns?.length > 0 && Array.isArray(actionBtns)) {
      let response = allLeads?.filter(
        (eachLead) => eachLead?.id == actionBtns[actionBtns?.length - 1]
      );
      // console.log(response, "see dependents");
      if (response?.length > 0) {
        setLastSelectedEmployee(response[0]);
      }
    }
  }, [actionBtns, addDependentSidebar]);

  const filterItems = [
    { title: "All", name: "policies" },
    { title: "With policies", name: "policies" },
    { title: "Without policies", name: "policies" },
  ];
  const filterItemsPending = [
    { title: "Employee ID", name: "company_employee_id" },
    { title: "Employee Name", name: "employee_name" },
    { title: "Status", name: "profile_status" },
    { title: "Phone Number", name: "phone_number" },
    { title: "Email ID", name: "email_id" },
  ];

  function hoveringFormSingleEntry() {
    setHoveringForm("singleentry");
  }
  function hoveringFormBulkEntry() {
    setHoveringForm("bulkentry");
  }
  function hoveringFormSingleEntryEnrollmentForm() {
    setHoveringForm("singleentryenrollmentform");
  }
  function hoveringFormBulkEntryEnrollmentForm() {
    setHoveringForm("bulkentryenrollmentform");
  }
  function hoveringFormPreEnrollment() {
    setHoveringForm("PreEnrollmentForm");
  }

  const filterItemsAddEmployee = [
    {
      title: "Single Entry",
      icon: SingleAddIcon,
      clickable: true,
      subMenu: "",
      function: hoveringFormSingleEntryEnrollmentForm,
      // link: "/app/coverfi/claims",
    },
    {
      title: "Bulk Upload",
      icon: BulkAddIcon,
      clickable: true,
      subMenu: "",
      function: hoveringFormBulkEntryEnrollmentForm,
      // link: "/app/coverfi/claims",
    },
  ];
  // const filterItemsAddEmployee = [
  //   {
  //     title: "Manually",
  //     icon: ManuallyIcon,
  //     clickable: true,
  //     subMenu: [
  //       {
  //         title: "Single Entry",
  //         icon: SingleAddIcon,
  //         clickable: true,
  //         subMenu: "",
  //         func: hoveringFormSingleEntry,
  //         // link: "/app/coverfi/claims",
  //       },
  //       {
  //         title: "Bulk Upload",
  //         icon: BulkAddIcon,
  //         clickable: true,
  //         subMenu: "",
  //         func: hoveringFormBulkEntry,
  //         // link: "/app/coverfi/claims",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Via Enrolment Form",
  //     icon: EnrollmentIcon,
  //     clickable: true,
  //     // function: hoveringFormPreEnrollment,
  //     subMenu: [
  //       {
  //         title: "Single Entry",
  //         icon: SingleAddIcon,
  //         clickable: true,
  //         subMenu: "",
  //         func: hoveringFormSingleEntryEnrollmentForm,
  //         // link: "/app/coverfi/claims",
  //       },
  //       {
  //         title: "Bulk Upload",
  //         icon: BulkAddIcon,
  //         clickable: true,
  //         subMenu: "",
  //         func: hoveringFormBulkEntryEnrollmentForm,
  //         // link: "/app/coverfi/claims",
  //       },
  //     ],
  //   },
  // ];

  const filterDropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectedItemTable.current &&
        !selectedItemTable.current.contains(event.target) &&
        hoveringForm
      ) {
        // Click outside the component, close the dropdown

        setHoveringForm(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Add dependencies if needed

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div
      className={classNames.profile}
      onClick={(event) => {
        if (
          addDependentSidebar &&
          !event.target.closest("#addDependentSidebar")
        ) {
          console.log(event.target, ": clicked id");
          setAddDependentSidebar(false);
        }
      }}
    >
      <NavbarV3
        dropdownItems={[
          { title: "Internal Members", clickable: true },
          { title: "External Members", clickable: true },
          { title: "Pending Members", clickable: true },
        ]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setAllBrokeragesSearch}
        typee="v3"
      />
      <div className={classNames.brokerageDetails}>
        <div className={classNames.dropdownItems}>
          <div className={classNames.filterDiv}>
            <div className={classNames.filterBtn} ref={filterDropdownRef}>
              <FilterIcon
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              />
              {/* hided filter dropdown */}
              {filterOpen && (
                <div className={classNames.dropdownContent}>
                  {(selectedProfileNav === "Internal Members"
                    ? [...filterItems]
                    : [...filterItemsPending]
                  )?.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachFilterItem}
                        key={eachItem?.title + index}
                        onClick={() => {
                          setSelectedFilterOption(eachItem?.name);
                          if (
                            eachItem?.title === "With policies" &&
                            selectedProfileNav === "Internal Members"
                          ) {
                            setSelectedFilterOptionActive(true);
                            setFilterOpen(false);
                          } else if (
                            eachItem?.title === "Without policies" &&
                            selectedProfileNav === "Internal Members"
                          ) {
                            setSelectedFilterOptionActive(false);
                            setFilterOpen(false);
                          } else if (
                            eachItem?.title === "All" &&
                            selectedProfileNav === "Internal Members"
                          ) {
                            setSelectedFilterOptionActive("no");
                            setFilterOpen(false);
                          }
                        }}
                      >
                        {eachItem?.title}
                      </div>
                    );
                  })}
                </div>
              )}
              {filterOpen && (
                <div className={classNames.dropdownContent}>
                  {(selectedProfileNav === "External Members"
                    ? [...filterItems]
                    : [...filterItemsPending]
                  )?.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachFilterItem}
                        key={eachItem?.title + index}
                        onClick={() => {
                          setSelectedFilterOption(eachItem?.name);
                          if (
                            eachItem?.title === "With policies" &&
                            selectedProfileNav === "External Members"
                          ) {
                            setSelectedFilterOptionActive(true);
                            setFilterOpen(false);
                          } else if (
                            eachItem?.title === "Without policies" &&
                            selectedProfileNav === "External Members"
                          ) {
                            setSelectedFilterOptionActive(false);
                            setFilterOpen(false);
                          } else if (
                            eachItem?.title === "All" &&
                            selectedProfileNav === "External Members"
                          ) {
                            setSelectedFilterOptionActive("no");
                            setFilterOpen(false);
                          }
                        }}
                      >
                        {eachItem?.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div>
              <input
                type="text"
                name=""
                placeholder="Search"
                onChange={(event) => {
                  setAllBrokeragesSearch(event?.target?.value);
                }}
              />
            </div>
          </div>
          {selectedProfileNav == "Pending Members" ||
          selectedProfileNav == "Internal Members" ||
          selectedProfileNav == "External Members" ? (
            <>
              <div>
                <div
                  className={classNames.inviteBtn}
                  onClick={() => {
                    if (
                      selectedProfileNav == "Internal Members" ||
                      selectedProfileNav == "External Members"
                    ) {
                      getActiveMembersSpreadsheet(globalCompanyId, globalHrId);
                    } else if (selectedProfileNav == "Pending Members") {
                      getPendingMembersSpreadsheet(globalHrId);
                    }
                  }}
                >
                  <GoDownload />
                  <span> Download</span>
                </div>
                <div
                  className={classNames.downloadListBtn}
                  // onClick={() => {
                  //   setPopularAction(true);
                  // }}
                  ref={selectedItemTable}
                >
                  Add Employee(s)
                  <div
                    className={classNames.dropdownContent}
                    style={{
                      display: hoveringForm ? "block" : "",
                      top: hoveringForm ? "100%" : "",
                      transform: "none",
                    }}
                  >
                    {hoveringForm === "bulkentry" ? (
                      <BulkUpload setState={setHoveringForm} />
                    ) : hoveringForm === "bulkentryupdated" ? (
                      <BulkUploadMemberUpdated setState={setHoveringForm} />
                    ) : hoveringForm === "PreEnrollmentForm" ? (
                      <PreEnrollmentForm setState={setHoveringForm} />
                    ) : hoveringForm === "PreEnrollmentDownloads" ? (
                      <PreEnrollmentDownloads setState={setHoveringForm} />
                    ) : hoveringForm === "PreEnrollmentUpdated" ? (
                      <PreEnrollmentUpdated setState={setHoveringForm} />
                    ) : hoveringForm === "singleentry" ? (
                      <MembersForms setState={setHoveringForm} />
                    ) : hoveringForm === "singleentrymemberdetails" ? (
                      <MembersDetailsForm setState={setHoveringForm} />
                    ) : hoveringForm === "bulkentryenrollmentform" ? (
                      <BulkEntryEnrollmentForm setState={setHoveringForm} />
                    ) : hoveringForm === "singleentryenrollmentform" ? (
                      <MembersFormsEnrollmentForm
                        setState={setHoveringForm}
                        enrollmentFormSingle={enrollmentFormSingle}
                        setEnrollmentFormSingle={setEnrollmentFormSingle}
                        selectedTypesPreEnrollmentSingle={selectedTypes}
                        setSelectedTypesPreEnrollmentSingle={setSelectedTypes}
                        latestSelectedTypes={latestSelectedTypes}
                        setLatestSelectedTypes={setLatestSelectedTypes}
                      />
                    ) : hoveringForm ===
                      "singleentrymemberdetailsenrollmentform" ? (
                      <MembersDetailsFormEnrollmentForm
                        setState={setHoveringForm}
                        enrollmentFormSingle={enrollmentFormSingle}
                        setEnrollmentFormSingle={setEnrollmentFormSingle}
                      />
                    ) : hoveringForm ===
                      "singleentrymemberreviewenrollmentform" ? (
                      <MembersReviewDetailsEnrollmentForm
                        setState={setHoveringForm}
                        enrollmentFormSingle={enrollmentFormSingle}
                        setEnrollmentFormSingle={setEnrollmentFormSingle}
                      />
                    ) : hoveringForm === "MemberDetailsAddDependent" ? (
                      <MemberDetailsAddDependent setState={setHoveringForm} />
                    ) : hoveringForm === "MembersReviewDetails" ? (
                      <MembersReviewDetails setState={setHoveringForm} />
                    ) : hoveringForm === "MembersReviewCompleted" ? (
                      <MembersReviewCompleted setState={setHoveringForm} />
                    ) : (
                      <>
                        <div
                          style={{
                            fontSize: "1rem",
                            fontWeight: "520",
                            marginBottom: "10px",
                            textAlign: "left",
                            padding: "0 1.5rem",
                          }}
                        >
                          Via Enrolment Form
                        </div>
                        {filterItemsAddEmployee?.map((eachItem, index) => {
                          return (
                            <div
                              className={classNames.eachFilterItem}
                              key={eachItem?.title + index}
                              style={{
                                pointerEvents: eachItem?.clickable
                                  ? ""
                                  : "none",
                                opacity: eachItem?.clickable ? "" : "0.5",
                              }}
                              onClick={() => {
                                if (eachItem?.clickable && eachItem?.function) {
                                  eachItem?.function();
                                }
                                setActionBtns([]);
                              }}
                            >
                              <span>
                                {eachItem.icon && <eachItem.icon />}
                                {eachItem?.title}
                              </span>{" "}
                              {eachItem?.subMenu && <IoMdArrowDropdown />}
                              {eachItem?.subMenu?.length > 0 &&
                                Array.isArray(eachItem?.subMenu) && (
                                  <div
                                    className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                  >
                                    <div className={classNames.subMenuTitle}>
                                      {eachItem?.title}
                                    </div>
                                    {eachItem?.subMenu?.map(
                                      (eachItemSub, index) => {
                                        return (
                                          <div
                                            className={
                                              classNames.eachFilterItem
                                            }
                                            key={eachItemSub?.title + index}
                                            style={{
                                              pointerEvents:
                                                eachItemSub?.clickable
                                                  ? ""
                                                  : "none",
                                              opacity: eachItemSub?.clickable
                                                ? ""
                                                : "0.5",
                                              justifyContent: "flex-start",
                                              gap: "10px",
                                            }}
                                            onClick={() => {
                                              if (eachItemSub?.func) {
                                                eachItemSub?.func();
                                              } else {
                                                history.push(eachItemSub?.link);
                                              }
                                            }}
                                          >
                                            <span>
                                              {eachItemSub.icon && (
                                                <eachItemSub.icon />
                                              )}
                                            </span>
                                            {eachItemSub?.title}{" "}
                                            {eachItemSub?.subMenu && (
                                              <IoMdArrowDropdown />
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                {/* <div className={classNames.deleteBtn}>Remove Employee(s)</div> */}
              </div>
            </>
          ) : selectedProfileNav == "Internal Members" ||
            selectedProfileNav == "External Members" ? (
            <></>
          ) : (
            <></>
          )}
        </div>
        <div className={classNames.extraBtns}>
          <div
            className={classNames.dropdownContent}
            style={{ display: hoveringFormClick ? "" : "none" }}
          >
            {hoveringFormClick === "BulkAssignPoliciesMembers" ? (
              <BulkAssignPoliciesMembers
                stateValue={hoveringFormClick}
                setState={setHoveringFormClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={classNames.allLeads}
          style={{
            height: "calc(100% - 7.9rem)",

            marginTop: "1rem",
          }}
        >
          {selectedProfileNav == "Internal Members" &&
          toggleSelectedTable == "Simplified" ? (
            <TableActiveEmployeesSimplified
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              expandDetails={expandDetails}
              setExpandDetails={setExpandDetails}
              selectedFilterOption={selectedFilterOption}
            />
          ) : selectedProfileNav == "External Members" &&
            toggleSelectedTable == "Simplified" ? (
            <TableActiveEmployeesSimplified
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              expandDetails={expandDetails}
              setExpandDetails={setExpandDetails}
              selectedFilterOption={selectedFilterOption}
            />
          ) : selectedProfileNav == "Pending Members" &&
            toggleSelectedTable == "Simplified" ? (
            <TablePendingEmployeesSimplified
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              expandDetails={expandDetails}
              setExpandDetails={setExpandDetails}
              selectedFilterOption={selectedFilterOption}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className={classNames.paginationBar}
          style={{
            display:
              selectedProfileNav == "Internal Members" ||
              selectedProfileNav == "External Members"
                ? ""
                : "none",
          }}
        >
          <div className={classNames.paginationMsg}>
            Showing {allLeads?.length} entries
          </div>
          <div className={classNames.allPagination}>
            <div
              key={"pagebtnleft"}
              className={`${classNames.eachBtn} `}
              style={{
                pointerEvents: currentPage <= 1 ? "none" : "",
                opacity: currentPage <= 1 ? "0.5" : "",
              }}
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            >
              <FaAngleLeft />
            </div>
            {currentPage == 1
              ? Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index);
                      }}
                      style={{
                        pointerEvents:
                          allLeads?.length < 10 && index > currentPage - 1
                            ? "none"
                            : "",
                        opacity:
                          allLeads?.length < 10 && index > currentPage - 1
                            ? "0.5"
                            : "",
                      }}
                    >
                      {currentPage + index}
                    </div>
                  );
                })
              : Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index - 1 == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index - 1);
                      }}
                      style={{
                        pointerEvents:
                          allLeads?.length < 20 && index > currentPage - 2
                            ? "none"
                            : "",
                        opacity:
                          allLeads?.length < 20 && index > currentPage - 2
                            ? "0.5"
                            : "",
                      }}
                    >
                      {currentPage + index - 1}
                    </div>
                  );
                })}
            <div
              key={"pagebtnright"}
              className={`${classNames.eachBtn} `}
              style={{
                pointerEvents:
                  allLeads?.length < 20
                    ? "none"
                    : currentPage >= 1
                    ? ""
                    : "none",
                opacity:
                  allLeads?.length < 20 ? "0.5" : currentPage >= 1 ? "" : "0.5",
              }}
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              <FaAngleRight />
            </div>
          </div>
        </div>
        <SidebarMenu
          position={sidebarOpen ? "0" : ""}
          onClose={onCloseSidebar}
          openedItem={sidebarOpen}
          selectedItemMain={lastSelectedEmployee}
        />
      </div>
    </div>
  );
};

export default HR;

const TablePendingEmployeesSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
  sidebarOpen,
  setSidebarOpen,
  expandDetails,
  setExpandDetails,
  selectedFilterOption,
}) => {
  const { setUpdateLoading, globalSelectedPolicy, setAddDependentSidebar } =
    useContext(BankContext);
  const selectedItemTable = useRef(null);
  const history = useHistory();

  const [selectedDetails, setSelectedDetails] = useState("addDependents");

  function expandDetailsFunc(actionID) {
    setExpandDetails([actionID]);
    setSelectedDetails("expandProfileDetailsFunc");
  }

  function expandDependenciesFunc(actionID) {
    setExpandDetails([actionID]);
    setSelectedDetails("expandDependenciesFunc");
  }

  const [popupContainer, setPopupContainer] = useState("");
  const [popupValueMain, setPopupValueMain] = useState("");

  const filterItems = [
    {
      title: "See Details",
      icon: EyeIcon,
      clickable: true,
      subMenu: [
        {
          title: "Profile Details",
          icon: "",
          clickable: true,
          subMenu: "",
          function: expandDetailsFunc,
        },
        {
          title: "Policy Details",
          icon: "",
          clickable: true,
          subMenu: "",
          link: "#",
        },
        {
          title: "Dependents",
          icon: "",
          clickable: true,
          subMenu: "",
          function: expandDependenciesFunc,
        },
      ],
    },
    // { title: "Edit Details", icon: EditIcon, clickable: false, subMenu: "" },
    {
      title: "See Pre-Enrolment Link",
      icon: DownloadIcon,
      clickable: true,
      subMenu: "",
      function: seePreEnrollmentFunc,
    },
    // {
    //   title: "Re-Initiate Pre-Enrolment",
    //   icon: EmailIcon,
    //   clickable: false,
    //   subMenu: "",
    // },
    // {
    //   title: "Manually Validate",
    //   icon: LockIcon,
    //   clickable: false,
    //   subMenu: "",
    // },
    // {
    //   title: "Delete invitation",
    //   icon: TrashIcon,
    //   clickable: false,
    //   subMenu: "",
    // },
  ];

  function seePreEnrollmentFunc(email) {
    setPopupContainer("See Pre-Enrolment Link");
    setPopupValueMain(email);
  }

  const [filterOrderly, setFilterOrderly] = useState("");

  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(6, 1fr)" }}>
            <th></th>
            <th>
              Employee ID{" "}
              <SortArrows
                onClick={() => {
                  if (filterOrderly === "Pending Employee ID") {
                    setFilterOrderly("Pending Employee ID Reverse");
                  } else {
                    setFilterOrderly("Pending Employee ID");
                  }
                }}
              />
            </th>
            <th>
              Employee Name{" "}
              <SortArrows
                onClick={() => {
                  if (filterOrderly === "Pending Employee Name") {
                    setFilterOrderly("Pending Employee Name Reverse");
                  } else {
                    setFilterOrderly("Pending Employee Name");
                  }
                }}
              />
            </th>
            <th>Status</th>
            <th>Phone Number</th>
            <th>
              Email{" "}
              <SortArrows
                onClick={() => {
                  if (filterOrderly === "Pending Email") {
                    setFilterOrderly("Pending Email Reverse");
                  } else {
                    setFilterOrderly("Pending Email");
                  }
                }}
              />
            </th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(6, 1fr)" }}
            >
              <td>
                {/* <CustomCheckbox /> */}
                <BsThreeDotsVertical />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                const searchQuery = allBrokeragesSearch?.toLowerCase();
                if (
                  selectedFilterOption &&
                  row[selectedFilterOption] &&
                  typeof row[selectedFilterOption] == "string"
                ) {
                  console.log(row, "checking value", selectedFilterOption);
                  return row[selectedFilterOption]
                    ?.toLowerCase()
                    ?.includes(searchQuery);
                } else {
                  // Convert the row object values to an array and check if any value matches the search query
                  const values = Object?.values(row);
                  const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                  return values?.some((value) => {
                    if (typeof value === "string") {
                      return value?.toLowerCase()?.includes(searchQuery);
                    }
                    return false;
                  });
                }
              })
              ?.sort((a, b) => {
                if (filterOrderly === "Pending Employee ID") {
                  return a?.company_employee_id?.localeCompare(
                    b?.company_employee_id
                  );
                } else if (filterOrderly === "Pending Employee ID Reverse") {
                  return b?.company_employee_id?.localeCompare(
                    a?.company_employee_id
                  );
                } else if (filterOrderly === "Pending Employee Name") {
                  return a?.employee_name?.localeCompare(b?.employee_name);
                } else if (filterOrderly === "Pending Employee Name Reverse") {
                  return b?.employee_name?.localeCompare(a?.employee_name);
                } else if (filterOrderly === "Pending Email") {
                  return a?.email_id?.localeCompare(b?.email_id);
                } else if (filterOrderly === "Pending Email Reverse") {
                  return b?.email_id?.localeCompare(a?.email_id);
                }
              })
              ?.map((row, index) => (
                <>
                  <tr
                    key={row?.brokerage_name + index}
                    style={{
                      gridTemplateColumns: "min-content repeat(6, 1fr)",
                      pointerEvents: sidebarOpen ? "none" : "",
                    }}
                  >
                    <td>
                      {actionBtns?.includes(row?._id) ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (expandDetails?.includes(row?._id)) {
                              setExpandDetails([]);
                            }
                            setActionBtns([]);
                          }}
                        >
                          X
                        </span>
                      ) : (
                        <BsThreeDotsVertical
                          onClick={() => {
                            if (actionBtns?.includes(row?._id)) {
                              setActionBtns([]);
                            } else {
                              setActionBtns([row?._id]);
                            }
                          }}
                        />
                      )}
                      {/* <CustomCheckbox
                        actionBtns={actionBtns}
                        setActionBtns={setActionBtns}
                        id={row?._id}
                        name={row?.first_name + " " + row?.last_name}
                      /> */}
                      {popupContainer === "See Pre-Enrolment Link" &&
                      actionBtns?.includes(row?._id) ? (
                        <div
                          className={classNames.dropdownContent}
                          ref={selectedItemTable}
                        >
                          <div className={classNames.seePreEnrollment}>
                            <span>
                              {`https://talltree.coverfi.app/employees/external/${popupValueMain}`}
                            </span>
                            <button
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://talltree.coverfi.app/employees/external/${popupValueMain}`
                                );
                                predefinedToast("Copied");
                                setPopupContainer("");
                                setPopupValueMain("");
                                setActionBtns([]);
                              }}
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                      ) : (
                        actionBtns?.includes(row?._id) && (
                          <div
                            className={classNames.dropdownContent}
                            ref={selectedItemTable}
                          >
                            {filterItems?.map((eachItem, index) => {
                              return (
                                <div
                                  className={classNames.eachFilterItem}
                                  key={eachItem?.title + index}
                                  style={{
                                    pointerEvents: eachItem?.clickable
                                      ? ""
                                      : "none",
                                    opacity: eachItem?.clickable ? "" : "0.5",
                                  }}
                                  onClick={() => {
                                    if (
                                      eachItem?.clickable &&
                                      eachItem?.function
                                    ) {
                                      if (eachItem?.title === "See Details") {
                                        eachItem?.function(row?.id);
                                      } else if (
                                        eachItem?.title ===
                                        "See Pre-Enrolment Link"
                                      ) {
                                        eachItem?.function(row?.email_id);
                                      } else {
                                        eachItem?.function(row?.id);
                                      }
                                    } else {
                                      setActionBtns([]);
                                    }
                                  }}
                                >
                                  <span>
                                    {eachItem.icon && <eachItem.icon />}
                                    {eachItem?.title}
                                  </span>{" "}
                                  {eachItem?.subMenu && <IoMdArrowDropdown />}
                                  {eachItem?.subMenu?.length > 0 &&
                                    Array.isArray(eachItem?.subMenu) && (
                                      <div
                                        className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                      >
                                        {eachItem?.subMenu?.map(
                                          (eachItemSub, index) => {
                                            return (
                                              <div
                                                className={
                                                  classNames.eachFilterItem
                                                }
                                                key={eachItemSub?.title + index}
                                                style={{
                                                  pointerEvents:
                                                    eachItemSub?.clickable
                                                      ? ""
                                                      : "none",
                                                  opacity:
                                                    eachItemSub?.clickable
                                                      ? ""
                                                      : "0.5",
                                                }}
                                                onClick={() => {
                                                  if (eachItemSub?.function) {
                                                    console.log(
                                                      eachItemSub,
                                                      "eachItemSub clicked"
                                                    );
                                                    eachItemSub?.function(
                                                      row?.id
                                                    );
                                                  } else {
                                                    history.push(
                                                      eachItemSub?.link
                                                    );
                                                  }
                                                }}
                                              >
                                                {eachItemSub?.title}{" "}
                                                {eachItemSub?.subMenu && (
                                                  <IoMdArrowDropdown />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </div>
                        )
                      )}
                    </td>
                    <td>
                      {row?.company_employee_id ? row?.company_employee_id : ""}
                    </td>
                    <td>
                      <div>{row?.employee_name ? row?.employee_name : ""}</div>
                    </td>
                    <td>
                      <div className={classNames.statusBtn}>
                        {row?.profile_status == "Accepted"
                          ? "Invitation Accepted"
                          : row?.profile_status == "Pending"
                          ? "Invitation Sent"
                          : ""}
                      </div>
                    </td>
                    <td>
                      <div>{row?.phone_number ? row?.phone_number : ""}</div>
                    </td>
                    <td>
                      <div>{row?.email_id ? row?.email_id : ""}</div>
                    </td>
                    <td>
                      {row?.endorsement == true ||
                      row?.endorsement == "True" ||
                      row?.endorsement == "true"
                        ? "Endorsement"
                        : "Original"}
                    </td>
                  </tr>
                  {selectedDetails === "expandDependenciesFunc" &&
                  expandDetails?.includes(row?.id) ? (
                    <tr
                      style={{
                        // height: "8rem",
                        gridTemplateColumns: "unset",
                        // background: "red",
                      }}
                    >
                      <DependentsTable
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                        type="pending"
                      />
                    </tr>
                  ) : selectedDetails === "expandProfileDetailsFunc" &&
                    expandDetails?.includes(row?.id) ? (
                    <tr
                      style={{
                        // height: "8rem",
                        gridTemplateColumns: "unset",
                        // background: "red",
                      }}
                    >
                      <SeeDetails
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                        listFrom="pending"
                      />
                    </tr>
                  ) : (
                    ""
                  )}
                </>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableActiveEmployeesSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
  sidebarOpen,
  setSidebarOpen,
  expandDetails,
  setExpandDetails,
  selectedFilterOption,
}) => {
  const {
    setUpdateLoading,
    globalSelectedPolicy,
    setAddDependentSidebar,
    globalHrId,
  } = useContext(BankContext);
  const selectedItemTable = useRef(null);
  const history = useHistory();

  const [selectedDetails, setSelectedDetails] = useState("");

  const [popupContainer, setPopupContainer] = useState("");
  const [popupValueMain, setPopupValueMain] = useState("");

  function expandDetailsFunc(actionID) {
    setExpandDetails([actionID]);
    setSelectedDetails("expandProfileDetailsFunc");
  }

  function expandDependenciesFunc(actionID) {
    setExpandDetails([actionID]);
    setSelectedDetails("expandDependenciesFunc");

    setTimeout(() => {
      setActionBtns([]);
    }, 100);

    // if (expandDetails?.includes(row?._id)) {
    //   setExpandDetails([]);
    //   setPopupContainer("");
    //   setSelectedDetails("");
    // }

    // setActionBtns([]);
  }

  function getHealthCard() {
    setUpdateLoading(true);
    Axios.get(
      `https://comms.globalxchange.io/retired/app/ecardurl?employeeId=${
        actionBtns?.length > 0 && Array.isArray(actionBtns)
          ? actionBtns[actionBtns?.length - 1]
          : actionBtns
      }&policyNumber=${
        globalSelectedPolicy
          ? globalSelectedPolicy
          : localStorage.getItem("globalSelectedPolicy")
          ? localStorage.getItem("globalSelectedPolicy")
          : ""
      }`
    )
      .then((response) => {
        console.log(response, "Get health card response");
        if (response?.data?.status && response?.data?.data?.ecardUrl)
          window?.open(response?.data?.data?.ecardUrl, "_blank");
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get health card error");
        setUpdateLoading(false);
      });
  }

  function seeDependents() {
    if (actionBtns?.length > 0 && Array.isArray(actionBtns)) {
      let response = allLeads?.filter(
        (eachLead) => eachLead?._id == actionBtns[actionBtns?.length - 1]
      );
      if (response?.length > 0) {
        setSidebarOpen(response[0]?.lead_email);
      }
    }
  }

  function addDependent(id) {
    setPopupContainer("addDependents");
    setActionBtns([id]);
  }
  function initiateAdditionalEndorsement(id) {
    setPopupContainer("initiateAdditionalEndorsement");
    setActionBtns([id]);
  }
  function initiateDeletingEndorsement(id) {
    setPopupContainer("initiateDeletingEndorsement");
    setActionBtns([id]);
  }

  async function addPolicy(id) {
    if (globalHrId) {
      try {
        let response = await Axios.get(
          `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}`
        );
        console.log("Add policy step 1", response);
        setSelectedDetails("addPoliciesStep1");
        setExpandDetails([id]);
        if (actionBtns?.includes(id)) {
          setActionBtns([]);
        } else {
          setActionBtns([id]);
        }
      } catch (error) {
        predefinedToast("Add policy error");
        console.log(error?.message, "Add policy step 1 error");
      }
    }
  }

  function getAllPoliciesForUser(id) {
    setSelectedDetails("allPoliciesForUser");
    setExpandDetails([id]);
    if (actionBtns?.includes(id)) {
      setActionBtns([]);
    } else {
      setActionBtns([id]);
    }
  }
  function deletePolicyEmployee(id) {
    setSelectedDetails("allPoliciesForUserDelete");
    setExpandDetails([id]);
    if (actionBtns?.includes(id)) {
      setActionBtns([]);
    } else {
      setActionBtns([id]);
    }
  }

  function getAllPoliciesHealthCard(id) {
    setSelectedDetails("AllPoliciesHealthcard");
    setExpandDetails([id]);
    if (actionBtns?.includes(id)) {
      setActionBtns([]);
    } else {
      setActionBtns([id]);
    }
  }

  function initiateInsuranceHealth(id, type) {
    setPopupContainer(type);
    setActionBtns([id]);
  }

  const filterItems = [
    {
      title: "See Details",
      icon: EyeIcon,
      clickable: true,
      subMenu: [
        {
          title: "Profile Details",
          icon: "",
          clickable: true,
          subMenu: "",
          function: expandDetailsFunc,
        },
        {
          title: "Policy Details",
          icon: "",
          clickable: true,
          subMenu: "",
          function: getAllPoliciesForUser,
        },
        {
          title: "Dependents",
          icon: "",
          clickable: true,
          subMenu: "",
          function: expandDependenciesFunc,
        },
      ],
    },
    // { title: "Edit Details", icon: EditIcon, clickable: false, subMenu: "" },
    // {
    //   title: "Add policy",
    //   icon: AddIcon,
    //   clickable: true,
    //   subMenu: "",
    //   function: addPolicy,
    // },
    {
      title: "Download Health Cards",
      icon: DownloadIcon,
      clickable: true,
      subMenu: "",
      function: getAllPoliciesHealthCard,
    },
    // {
    //   title: "Email Health Cards",
    //   icon: EmailIcon,
    //   clickable: false,
    //   subMenu: "",
    // },
    // {
    //   title: "Initiate a Claim",
    //   icon: BandaidIcon,
    //   clickable: true,
    //   subMenu: [
    //     {
    //       title: "Group Health Insurance",
    //       icon: "",
    //       clickable: true,
    //       subMenu: "",
    //       function: initiateInsuranceHealth,
    //     },
    //     {
    //       title: "Group Personal Accident Insurance",
    //       icon: "",
    //       clickable: true,
    //       subMenu: "",
    //       link: "/app/coverfi/claims",
    //     },
    //     {
    //       title: "Group Term Life Insurance",
    //       icon: "",
    //       clickable: true,
    //       subMenu: "",
    //       link: "/app/coverfi/claims",
    //     },
    //   ],
    // },
    // {
    //   title: "Enable/Disable Account",
    //   icon: LockIcon,
    //   clickable: false,
    //   subMenu: "",
    // },
    // {
    //   title: "See Dependents",
    //   icon: DependentsIcon,
    //   clickable: true,
    //   subMenu: "",
    //   // function: seeDependents,
    //   function: expandDependenciesFunc,
    // },
    {
      title: "Add Dependents",
      icon: AddIcon,
      clickable: true,
      subMenu: "",
      function: addDependent,
    },
    // {
    //   title: "Remove a policy",
    //   icon: DeleteIcon,
    //   clickable: true,
    //   subMenu: "",
    //   function: deletePolicyEmployee,
    // },
    {
      title: "Initiate addition endorsement request",
      icon: AddIcon,
      clickable: true,
      subMenu: "",
      function: initiateAdditionalEndorsement,
    },
    {
      title: "Initiate deletion endorsement request",
      icon: DeleteIcon,
      clickable: true,
      subMenu: "",
      function: initiateDeletingEndorsement,
    },
    // { title: "Delete", icon: TrashIcon, clickable: false, subMenu: "" },
  ];

  const [filterOrderly, setFilterOrderly] = useState("");

  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(7, 1fr)" }}>
            <th></th>
            <th>
              <span>Employee ID</span>
              <SortArrows
                onClick={() => {
                  if (filterOrderly === "Active Employee ID") {
                    setFilterOrderly("Active Employee ID Reverse");
                  } else {
                    setFilterOrderly("Active Employee ID");
                  }
                }}
              />
            </th>
            <th>
              <span>Employee Name</span>
              <SortArrows
                onClick={() => {
                  if (filterOrderly === "Active Employee Name") {
                    setFilterOrderly("Active Employee Name Reverse");
                  } else {
                    setFilterOrderly("Active Employee Name");
                  }
                }}
              />
            </th>
            <th>Phone Number</th>
            <th>
              <span>Email</span>
              <SortArrows
                onClick={() => {
                  if (filterOrderly === "Active Email") {
                    setFilterOrderly("Active Email Reverse");
                  } else {
                    setFilterOrderly("Active Email");
                  }
                }}
              />
            </th>
            <th>
              <span>Policies</span>
              <SortArrows
                onClick={() => {
                  if (filterOrderly === "Active Policies") {
                    setFilterOrderly("Active Policies Reverse");
                  } else {
                    setFilterOrderly("Active Policies");
                  }
                }}
              />
            </th>
            <th>Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(7, 1fr)" }}
            >
              <td>
                {/* <CustomCheckbox /> */}
                <BsThreeDotsVertical />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                const searchQuery = allBrokeragesSearch?.toLowerCase();
                // if (selectedFilterOption) {
                //   return row[selectedFilterOption]
                //     ?.toLowerCase()
                //     ?.includes(searchQuery);
                // } else {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
                // }
              })
              ?.sort((a, b) => {
                if (filterOrderly === "Active Employee ID") {
                  return a?.user_employee_id?.localeCompare(
                    b?.user_employee_id
                  );
                } else if (filterOrderly === "Active Employee ID Reverse") {
                  return b?.user_employee_id?.localeCompare(
                    a?.user_employee_id
                  );
                } else if (filterOrderly === "Active Employee Name") {
                  return a?.name?.localeCompare(b?.name);
                } else if (filterOrderly === "Active Employee Name Reverse") {
                  return b?.name?.localeCompare(a?.name);
                } else if (filterOrderly === "Active Email") {
                  return a?.user_company_email?.localeCompare(
                    b?.user_company_email
                  );
                } else if (filterOrderly === "Active Email Reverse") {
                  return b?.user_company_email?.localeCompare(
                    a?.user_company_email
                  );
                } else if (filterOrderly === "Active Policies") {
                  return Number(a?.policies_count) - Number(b?.policies_count);
                } else if (filterOrderly === "Active Policies Reverse") {
                  return Number(b?.policies_count) - Number(a?.policies_count);
                }
              })
              ?.map((row, index) => (
                <>
                  <tr
                    key={row?.brokerage_name + index}
                    style={{
                      gridTemplateColumns: "min-content repeat(7, 1fr)",
                      pointerEvents: sidebarOpen ? "none" : "",
                    }}
                  >
                    <td>
                      {expandDetails?.includes(row?._id) ||
                      actionBtns?.includes(row?._id) ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              expandDetails?.includes(row?._id) ||
                              actionBtns?.includes(row?._id)
                            ) {
                              setExpandDetails([]);
                              setPopupContainer("");
                              setSelectedDetails("");
                            }

                            setActionBtns([]);
                          }}
                        >
                          X
                        </span>
                      ) : (
                        <BsThreeDotsVertical
                          onClick={() => {
                            if (actionBtns?.includes(row?._id)) {
                              setActionBtns([]);
                            } else {
                              setActionBtns([row?._id]);
                            }
                          }}
                        />
                      )}
                      {/* <CustomCheckbox
                        actionBtns={actionBtns}
                        setActionBtns={setActionBtns}
                        id={row?._id}
                        name={row?.first_name + " " + row?.last_name}
                      /> */}
                      {popupContainer === "Group Health Insurance" &&
                      actionBtns?.includes(row?._id) ? (
                        <InitiateInsuranceHealth
                          from="dependent_user_company_email"
                          employeeName={
                            row?.name ? row?.name : row?.employee_name
                          }
                          setPopupContainer={setPopupContainer}
                          employeeEmail={
                            row?.user_company_email
                              ? row?.user_company_email
                              : row?.email_id
                          }
                        />
                      ) : popupContainer === "addDependents" &&
                        actionBtns?.includes(row?._id) ? (
                        <AddDependentsSidebar
                          from="dependent_user_company_email"
                          employeeName={
                            row?.name ? row?.name : row?.employee_name
                          }
                          setPopupContainer={setPopupContainer}
                          employeeEmail={
                            row?.user_company_email
                              ? row?.user_company_email
                              : row?.email_id
                          }
                        />
                      ) : popupContainer === "initiateAdditionalEndorsement" &&
                        actionBtns?.includes(row?._id) ? (
                        <InitiateAddionalEndorsements
                          setPopupContainer={setPopupContainer}
                          employeeDetails={row}
                        />
                      ) : popupContainer === "initiateDeletingEndorsement" &&
                        actionBtns?.includes(row?._id) ? (
                        <InitiateDeletingEndorsement
                          setPopupContainer={setPopupContainer}
                          employeeDetails={row}
                        />
                      ) : (
                        actionBtns?.includes(row?._id) && (
                          <div
                            className={classNames.dropdownContent}
                            ref={selectedItemTable}
                          >
                            {filterItems?.map((eachItem, index) => {
                              return (
                                <div
                                  className={classNames.eachFilterItem}
                                  key={eachItem?.title + index}
                                  style={{
                                    pointerEvents: eachItem?.clickable
                                      ? ""
                                      : "none",
                                    opacity: eachItem?.clickable ? "" : "0.5",
                                  }}
                                  onClick={() => {
                                    if (
                                      eachItem?.title === "Initiate a Claim"
                                    ) {
                                    } else if (
                                      eachItem?.clickable &&
                                      eachItem?.function
                                    ) {
                                      if (eachItem?.title === "See Details") {
                                        eachItem?.function(row?._id);
                                      } else {
                                        eachItem?.function(row?._id);
                                      }
                                    } else {
                                      setActionBtns([]);
                                    }
                                  }}
                                >
                                  <span>
                                    {eachItem.icon && <eachItem.icon />}
                                    {eachItem?.title}
                                  </span>{" "}
                                  {eachItem?.subMenu && <IoMdArrowDropdown />}
                                  {eachItem?.subMenu?.length > 0 &&
                                    Array.isArray(eachItem?.subMenu) && (
                                      <div
                                        className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                      >
                                        {eachItem?.subMenu?.map(
                                          (eachItemSub, index) => {
                                            return (
                                              <div
                                                className={
                                                  classNames.eachFilterItem
                                                }
                                                key={eachItemSub?.title + index}
                                                style={{
                                                  pointerEvents:
                                                    eachItemSub?.clickable
                                                      ? ""
                                                      : "none",
                                                  opacity:
                                                    eachItemSub?.clickable
                                                      ? ""
                                                      : "0.5",
                                                }}
                                                onClick={() => {
                                                  if (eachItemSub?.function) {
                                                    console.log(
                                                      eachItemSub,
                                                      "eachItemSub clicked",
                                                      eachItemSub?.title
                                                    );
                                                    eachItemSub?.function(
                                                      row?._id,
                                                      eachItemSub?.title
                                                    );
                                                  } else {
                                                    history.push(
                                                      eachItemSub?.link
                                                    );
                                                  }
                                                }}
                                              >
                                                {eachItemSub?.title}{" "}
                                                {eachItemSub?.subMenu && (
                                                  <IoMdArrowDropdown />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </div>
                        )
                      )}
                    </td>
                    <td>
                      {row?.user_employee_id ? row?.user_employee_id : "-"}
                    </td>
                    <td>{row?.name ? row?.name : "-"}</td>

                    <td>{row?.phone_number ? row?.phone_number : "-"}</td>
                    <td>
                      {row?.user_company_email ? row?.user_company_email : "-"}
                    </td>
                    <td>{row?.policies_count ? row?.policies_count : "-"}</td>
                    <td>
                      {row?.endorsement == true ||
                      row?.endorsement == "True" ||
                      row?.endorsement == "true"
                        ? "Endorsement"
                        : "Original"}
                    </td>
                    <td>
                      <div
                        className={classNames.statusBtn}
                        style={{
                          background:
                            row?.working_status?.toLowerCase() == "active"
                              ? "var(--main-color)"
                              : "",
                          color:
                            row?.working_status?.toLowerCase() == "active"
                              ? "white"
                              : "",
                        }}
                      >
                        {row?.working_status?.toLowerCase() == "active"
                          ? "Active"
                          : row?.working_status == "Pending"
                          ? "Invitation Sent"
                          : ""}
                      </div>
                    </td>
                  </tr>
                  {selectedDetails === "AllPoliciesHealthcard" &&
                  expandDetails?.includes(row?._id) ? (
                    <tr
                      style={{
                        gridTemplateColumns: "unset",
                      }}
                    >
                      <AllPoliciesHealthcard
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                      />
                    </tr>
                  ) : selectedDetails === "allPoliciesForUser" &&
                    expandDetails?.includes(row?._id) ? (
                    <tr
                      style={{
                        gridTemplateColumns: "unset",
                      }}
                    >
                      <AllPoliciesForUser
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                      />
                    </tr>
                  ) : selectedDetails === "allPoliciesForUserDelete" &&
                    expandDetails?.includes(row?._id) ? (
                    <tr
                      style={{
                        gridTemplateColumns: "unset",
                      }}
                    >
                      <AllPoliciesForUser
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                        type="removePolicy"
                      />
                    </tr>
                  ) : selectedDetails === "addPoliciesStep1" &&
                    expandDetails?.includes(row?._id) ? (
                    <tr
                      style={{
                        gridTemplateColumns: "unset",
                      }}
                    >
                      <AllPoliciesForUserAdd
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                      />
                    </tr>
                  ) : selectedDetails === "expandDependenciesFunc" &&
                    expandDetails?.includes(row?._id) ? (
                    <tr
                      style={{
                        gridTemplateColumns: "unset",
                      }}
                    >
                      <DependentsTable
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                      />
                    </tr>
                  ) : selectedDetails === "expandProfileDetailsFunc" &&
                    expandDetails?.includes(row?._id) ? (
                    <tr
                      style={{
                        // height: "8rem",
                        gridTemplateColumns: "unset",
                        // background: "red",
                      }}
                    >
                      <SeeDetails
                        allLeads={allLeads}
                        expandDetails={expandDetails}
                        setExpandDetails={setExpandDetails}
                      />
                    </tr>
                  ) : (
                    ""
                  )}
                </>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

export const SidebarMenu = ({
  position,
  onClose,
  openedItem,
  selectedItemMain,
}) => {
  const sidebarRef = useRef(null);
  const { popularAction } = useContext(BankContext);

  const [allDependents, setAllDependents] = useState([]);
  const [allDependentsLoading, setAllDependentsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    setAllDependentsLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${openedItem}`
    )
      .then((response) => {
        // console.log(
        //   response?.data?.data,
        //   "get all dependents response",
        //   openedItem
        // );
        setAllDependents(response?.data?.data);
        setAllDependentsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get all dependents error");
        setAllDependentsLoading(false);
      });
  }, [popularAction, openedItem]);

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      <div className={classNames.dependentsContainer}>
        <div className={classNames.title}>
          Dependents For {selectedItemMain?.first_name}
        </div>
        <div className={classNames.allDependents}>
          {allDependentsLoading ? (
            <div className={classNames.eachDependent}>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
            </div>
          ) : allDependents?.length > 0 ? (
            allDependents?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachDependent}
                  key={eachItem?.dependent_name + index}
                >
                  <div>{eachItem?.dependent_name}</div>
                  <div>{eachItem?.relationship}</div>
                  <div>{eachItem?.primary_phone_number}</div>
                </div>
              );
            })
          ) : (
            <div>No items</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SeeDetails = ({
  allLeads,
  expandDetails,
  setExpandDetails,
  listFrom,
}) => {
  const [seeDetails, setDetails] = useState({});

  useEffect(() => {
    if (allLeads?.length > 0) {
      let filteredItem = allLeads?.filter(
        (eachitem, index) => eachitem?._id === expandDetails[0]
      );

      if (filteredItem?.length > 0) {
        setDetails({
          ...filteredItem[0],
          age: Number(calculateAgeDash(filteredItem[0]?.date_of_birth))
            ? Number(calculateAgeDash(filteredItem[0]?.date_of_birth))
            : "-",
        });
        console.log(
          filteredItem,
          "filteredItem see details",
          allLeads,
          expandDetails
        );
      }
    }
  }, []);
  return (
    <div className={classNames.seeDetails}>
      {/* <div className={classNames.title}>
        <span onClick={() => setExpandDetails([])}>X</span>
      </div> */}
      <div className={classNames.gridThree} style={{ pointerEvents: "none" }}>
        <EachCustomInput
          title="Gender"
          placeholder="Gender"
          name="gender"
          stateValue={seeDetails}
          setState={setDetails}
        />
        <EachCustomInput
          title="Date Of Birth"
          placeholder="Date Of Birth"
          name="date_of_birth"
          stateValue={seeDetails}
          setState={setDetails}
        />
        <EachCustomInput
          title="Age"
          placeholder="age"
          name="age"
          stateValue={seeDetails}
          setState={setDetails}
        />
      </div>
      <div className={classNames.gridThree} style={{ pointerEvents: "none" }}>
        <EachCustomInput
          title="Designation"
          placeholder="Designation"
          name={listFrom === "pending" ? "designation" : "role"}
          stateValue={seeDetails}
          setState={setDetails}
        />
        {/* <EachCustomInput
          title="Company Joining Date"
          placeholder="Company Joining Date"
          name="date_of_joining"
          stateValue={seeDetails}
          setState={setDetails}
        />
        <EachCustomInput
          title="CTC"
          placeholder="CTC"
          name="CTC"
          stateValue={seeDetails}
          setState={setDetails}
        /> */}
      </div>
    </div>
  );
};

export const DependentsTable = ({
  allLeads,
  expandDetails,
  setExpandDetails,
  type,
}) => {
  const { setUpdateLoading } = useContext(BankContext);
  const [seeDetails, setDetails] = useState({});
  const [allUserDependents, setAllUserDepedndents] = useState([]);
  const [refreshAPILocal, setRefreshAPILocal] = useState(false);

  useEffect(() => {
    if (allLeads?.length > 0) {
      let filteredItem = allLeads?.filter(
        (eachitem, index) => eachitem?._id === expandDetails[0]
      );
      if (filteredItem?.length > 0) {
        setDetails(filteredItem[0]);
        if (type == "pending") {
          setAllUserDepedndents(filteredItem[0]?.employee_dependents);
        } else {
          getDependentsForEmail(filteredItem[0]?.user_company_email);
        }
        console.log(filteredItem, "filteredItem for dependents");
      }
    }
  }, [refreshAPILocal]);

  //functions

  async function getDependentsForEmail(userEmail) {
    try {
      let response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${userEmail}`
      );
      if (response?.data?.status) {
        setAllUserDepedndents(response?.data?.data);
      }
      // console.log(response, "getDepedentForUser");
    } catch (error) {
      console.log("GetDependentUserError", error?.message);
    }
  }

  async function deleteDependent(dependentID) {
    setUpdateLoading(true);
    try {
      let response = await Axios.delete(
        `https://insurancecoverfi.apimachine.com/insurance/dependent/delete/?dependent_id=${dependentID}`
      );
      setUpdateLoading(false);
      if (response?.data?.status) {
        predefinedToast("Deleted dependent succesfully!");
        setRefreshAPILocal((prev) => !prev);
      } else {
        predefinedToast(response?.data?.message);
      }
    } catch (error) {
      predefinedToast("Error while deleting dependent");
      console.log("Delete dependent error", error?.message);
      setUpdateLoading(false);
    }
  }

  return (
    <div className={classNames.seeDetails}>
      {/* <div className={classNames.title}>
        <span onClick={() => setExpandDetails([])}>X</span>
      </div> */}
      <div className={classNames.tableContainer}>
        <table className={classNames.tableParent}>
          <thead>
            <tr>
              <th></th>
              <th>Dependent Name</th>
              <th>Relationship</th>
              <th>Date of birth</th>
              <th>Gender</th>
              <th></th>
            </tr>
          </thead>
          <tbody className={classNames.tableBody}>
            {allUserDependents?.length > 0 ? (
              allUserDependents?.map((eachDependent, index) => {
                return (
                  <tr className={classNames.tableRow} key={eachDependent?._id}>
                    <td>
                      <MdEdit />
                    </td>
                    <td>
                      {eachDependent?.dependent_name
                        ? eachDependent?.dependent_name
                        : eachDependent?.dependant_name
                        ? eachDependent?.dependant_name
                        : "-"}
                    </td>
                    <td>
                      {eachDependent?.relationship
                        ? eachDependent?.relationship
                        : eachDependent?.relation
                        ? eachDependent?.relation
                        : "-"}
                    </td>
                    <td>
                      {eachDependent?.date_of_birth
                        ? eachDependent?.date_of_birth
                        : "-"}
                    </td>
                    <td>
                      {eachDependent?.gender ? eachDependent?.gender : "-"}
                    </td>
                    <td>
                      <RiDeleteBin5Line
                        onClick={() => {
                          if (eachDependent?.dependent_id) {
                            deleteDependent(eachDependent?.dependent_id);
                          } else {
                            predefinedToast("Dependent ID not found!");
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className={classNames.noDataMessage}>
                No dependent for this user!
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const AddDependentsSidebar = ({
  employeeName,
  employeeEmail,
  from,
  setPopupContainer,
}) => {
  const {
    email,
    addDependentSidebar: isOpen,
    setAddDependentSidebar: setIsOpen,
    setUpdateLoading,
    lastSelectedEmployee,
    setActionBtns,
  } = useContext(BankContext);
  const [dobDate, setDobDate] = useState("");
  const [dependentsDetails, setDependentsDetails] = useState({
    dependent_user_company_email: "",
    dependent_name: "",
    dependent_email: "",
    relationship: "",
    gender: "",
    date_of_birth: "",
    primary_phone_number: "",
  });

  function addDependent() {
    setUpdateLoading(true);

    if (from == "dependent_user_company_email") {
      dependentsDetails.dependent_user_company_email = employeeEmail;
    }
    console.log(dependentsDetails, "add dependent dependentsDetails");

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/dependent/create",
      dependentsDetails
    )
      .then((response) => {
        if (response?.data?.data?.status) {
          setIsOpen(false);
          setPopupContainer("");
          setActionBtns([]);
          predefinedToast(`Added new dependent for ${employeeName}`);
        }
        // console.log(response?.data?.data?.status, "add dependent response");
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Add dependent error");
        setUpdateLoading(false);
        predefinedToast("Error while adding new dependent");
      });
  }

  return (
    <div className={classNames.addDependents} id="addDependentSidebar">
      <div
        className={classNames.title}
      >{`Add dependent for ${employeeName}`}</div>
      <div className={classNames.allInputsParent}>
        <EachCustomDropdown
          dropdown={[
            "Spouse",
            "Children",
            "Father",
            "Mother",
            "Father-in-Law",
            "Mother-in-Law",
          ]}
          title="What is your relation to the dependent?"
          name="relationship"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          typee="single"
        />
        <EachCustomInput
          placeholder="Name.."
          name="dependent_name"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />
        <div className={classNames.twoInputContainer}>
          <EachCustomDropdown
            name="gender"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            dropdown={["Male", "Female"]}
            typee="single"
          />
          <EachCustomDatePicker
            placeholder="Select date of birth"
            name="date_of_birth"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="spreadObj"
            values={{ state: dependentsDetails }}
            validation={{ type: "relationship" }}
          />
        </div>
        <EachCustomInput
          placeholder="Enter number..."
          name="primary_phone_number"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          validation={{ type: "phone_validation" }}
        />
        <EachCustomInput
          placeholder="Enter email..."
          name="dependent_email"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          validation={{ type: "email" }}
        />
      </div>
      <div className={classNames.submitButton} onClick={addDependent}>
        Add
      </div>
    </div>
  );
};

export const InitiateInsuranceHealth = ({
  employeeName,
  employeeEmail,
  from,
  setPopupContainer,
}) => {
  const {
    addDependentSidebar: isOpen,
    setAddDependentSidebar: setIsOpen,
    setUpdateLoading,
    globalCompanyUnderscoreId,
    setActionBtns,
  } = useContext(BankContext);
  const [selectedType, setSelectedType] = useState("Employee");
  const [allActiveMembers, setAllActiveMembers] = useState([]);
  const [allPoliciesForOneEmployee, setAllPoliciesForOneEmployee] = useState(
    []
  );
  const [allHospitalsForOneEmployee, setAllHospitalsForOneEmployee] = useState(
    []
  );
  const [dependentsDetails, setDependentsDetails] = useState({
    insurance_type: "Health",
    relationship: "Self",
  });

  //functions

  async function getAllActiveMembers() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/client-user/employee/${globalCompanyUnderscoreId}`
      );
      setAllActiveMembers(response?.data?.data);
      console.log(response, "getAllActiveMembers");
    } catch (error) {
      console.log(error?.message, "getAllActiveMembers");
    }
  }
  async function getAllHospitalsForEmployee(tpaID, insuranceCompanyID) {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/tpa/getNetworkHospital/${tpaID}/${insuranceCompanyID}`
      );
      setAllHospitalsForOneEmployee(response?.data?.data);
      console.log(response, "getAllHospitalsForEmployee");
    } catch (error) {
      console.log(error?.message, "getAllHospitalsForEmployee");
    }
  }

  async function getAllPoliciesOfEmployee(employeeEmail) {
    try {
      let response = await Axios.get(
        // `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=fanawe7659@trackden.com`
        `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=${employeeEmail}`
      );
      if (response?.data?.data?.length > 0) {
        setAllPoliciesForOneEmployee(response?.data?.data[0]?.policies);
      }
      console.log(response, "getAllPoliciesOfEmployee");
    } catch (error) {
      console.log(error?.message, "getAllPoliciesOfEmployee");
    }
  }

  function submitHealthClaim() {
    setUpdateLoading(true);
    let url;

    if (selectedType === "Employee") {
      url = "https://coverfi.apimachine.com/api/v1/raha-user/claim/create";
      console.log(dependentsDetails, "submitHealthClaim dependentsDetails");

      Axios.post(url, dependentsDetails)
        .then((response) => {
          if (response?.data?.status) {
            setPopupContainer("");
            setActionBtns([]);
            predefinedToast(response?.data?.message);
          } else {
            predefinedToast("Error while submitting health claim");
          }
          console.log(response, "submitHealthClaim response");
          setUpdateLoading(false);
        })
        .catch((error) => {
          console.log(error?.message, "submitHealthClaim error");
          setUpdateLoading(false);
          predefinedToast("Error while submitting health claim");
        });
    }
  }

  //renderings

  useEffect(() => {
    getAllActiveMembers();
  }, []);

  useEffect(() => {
    if (dependentsDetails?.employee_id) {
      let filteredUser = allActiveMembers?.filter((eachUser, index) => {
        return eachUser?._id == dependentsDetails?.employee_id;
      });
      if (Array.isArray(filteredUser) && filteredUser?.length > 0) {
        getAllPoliciesOfEmployee(filteredUser[0]?.user_company_email);
        setDependentsDetails((prev) => {
          return {
            ...prev,
            company_id: globalCompanyUnderscoreId,
            employee_name: filteredUser[0]?.name,
            patient_name: filteredUser[0]?.name,
            company_employee_id: filteredUser[0]?.user_employee_id,
          };
        });
      }
      console.log(filteredUser, "filteredUser");
    }
  }, [dependentsDetails?.employee_id]);

  useEffect(() => {
    console.log(dependentsDetails, "checking dependents details");
    if (dependentsDetails?.policy_id) {
      let filteredPolicy = allPoliciesForOneEmployee?.filter(
        (eachPolicy, index) => {
          return eachPolicy?.policy_id?._id == dependentsDetails?.policy_id;
        }
      );
      console.log(filteredPolicy, "filteredPolicy");

      if (Array.isArray(filteredPolicy) && filteredPolicy?.length > 0)
        getAllHospitalsForEmployee(
          filteredPolicy[0]?.policy_id?.tpa_id?._id,
          filteredPolicy[0]?.policy_id?.insurance_company_id?._id
        );
      setDependentsDetails((prev) => {
        return {
          ...prev,
          policy_number: filteredPolicy[0]?.policy_number,
          insurance_company_id:
            filteredPolicy[0]?.policy_id?.insurance_company_id?._id,
        };
      });
    }
  }, [dependentsDetails?.policy_id]);

  return (
    <div
      className={`${classNames.addDependents} ${classNames.initiateInsurance}`}
      id="addDependentSidebar"
    >
      <div className={classNames.title}>
        Group Health Insurance{" "}
        <span
          onClick={() => {
            setPopupContainer("");
          }}
        >
          X
        </span>
      </div>
      <div className={classNames.allInputsParent}>
        <div className={classNames.smallDrodown}>
          <EachCustomDropdown
            dropdown={allActiveMembers}
            title="Select employee"
            name="employee_id"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="objVal"
            objName="_id"
            mapName="name"
          />
        </div>
        <div className={classNames.smallDrodown}>
          <EachCustomDropdownInitiateMultipleHealth
            dropdown={allPoliciesForOneEmployee}
            title="Select policy"
            name="policy_id"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="objVal"
            objName="_id"
          />
        </div>
        <EachCustomSelectOne
          title="Is this claim for the employee or for one of their dependents ?"
          placeholder="claim"
          name="selected_claim"
          stateValue={selectedType}
          setState={setSelectedType}
          dropdown={["Employee", "Dependent"]}
        />
        <div className={classNames.smallDrodown}>
          <EachCustomDropdown
            dropdown={allHospitalsForOneEmployee}
            title="Select hospital"
            name="hospital_name"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="objVal"
            objName="hospital_name"
          />
        </div>
        <EachCustomInput
          title="What is the ailment?"
          placeholder="Ex. Fever..."
          name="ailment"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />
        <EachCustomInput
          title="What is the claim number"
          placeholder="Ex. CLM123..."
          name="claim_number"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />

        <div className={classNames.twoInputContainer}>
          <EachCustomDatePicker
            placeholder="Date of intimation"
            name="date_of_intimation"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="spreadObj"
          />
          <EachCustomDatePicker
            placeholder="Date of admission"
            name="date_of_admission"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="spreadObj"
          />
        </div>
      </div>
      <div className={classNames.submitButton} onClick={submitHealthClaim}>
        Submit
      </div>
    </div>
  );
};

const AllPoliciesForUserAdd = ({
  allLeads,
  expandDetails,
  setExpandDetails,
}) => {
  const history = useHistory();
  const { globalHrId } = useContext(BankContext);
  const [seeDetails, setSeeDetails] = useState({});
  const [allPolicies, setAllPolicies] = useState([]);
  const [selectedUserLocal, setSelectedUserLocal] = useState("");
  const [submitAPIResponse, setSubmitAPIResponse] = useState("");

  //functions

  async function addPolicyToEmployee(
    companyEmail,
    employeeID,
    policyGrade,
    companyEmpID,
    policyID,
    policyNumber
  ) {
    let obj;
    let URL;

    if (
      selectedUserLocal?.endorsement == true ||
      selectedUserLocal?.endorsement == "true" ||
      selectedUserLocal?.endorsement == "True"
    ) {
      URL =
        "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/add-employee/endorsement";
      obj = {
        hr_id: globalHrId,
        employee_data: [
          {
            employee_id: employeeID,
            company_employee_id: companyEmpID,
            date_of_birth: selectedUserLocal?.date_of_birth,
            date_of_joining: getCurrentDate(),
            employee_email_id: companyEmail,
            policy_number: policyNumber,
            Grade: policyGrade,
          },
        ],
      };
    } else {
      URL = `https://coverfi.apimachine.com/api/v1/policy/policy-employee-map/create/bulk`;
      obj = {
        hr_id: globalHrId,
        employee_data: [
          {
            employee_id: employeeID,
            company_employee_id: companyEmpID,
            employee_email_id: companyEmail,
            policy_number: policyNumber,
            Grade: policyGrade,
          },
        ],
      };
    }

    // console.log("Add policy to employee working", obj);
    try {
      const response = await Axios.post(URL, obj);
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        if (
          selectedUserLocal?.endorsement == true ||
          selectedUserLocal?.endorsement == "true" ||
          (selectedUserLocal?.endorsement == "True" &&
            response?.data?.data?.length > 0)
        ) {
          setSubmitAPIResponse(response?.data?.data[0]);
        } else {
          setTimeout(() => {
            window?.location?.reload();
          }, 1500);
        }
      } else {
        predefinedToast(response?.data?.message);
      }
      console.log(response, "Add policy to employee response");
    } catch (error) {
      predefinedToast("Error while adding policy to employee");
      console.log(error?.message, "Add policy to employee error");
    }
  }
  async function getAllPolicies() {
    try {
      const response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}`
      );
      if (response?.data?.status && response?.data?.data?.length > 0) {
        setAllPolicies(response?.data?.data);
        console.log(response?.data?.data, "All policies response");
      } else {
        setAllPolicies("noPolicies");
      }
    } catch (error) {
      predefinedToast("Error while fetching all policies");
      console.log(error?.message, "All policies error");
      setAllPolicies("noPolicies");
    }
  }

  function filterUser() {
    if (allLeads?.length > 0) {
      let filteredItem = allLeads?.filter(
        (eachitem, index) => eachitem?._id === expandDetails[0]
      );
      if (filteredItem?.length > 0) {
        setSeeDetails(filteredItem[0]);
        if (filteredItem[0]?.user_company_email && globalHrId) {
          // console.log(filteredItem, "filteredItem value");
          setSelectedUserLocal(filteredItem[0]);
          getAllPolicies(filteredItem[0]?.user_company_email);
        }
      }
    }
  }

  useEffect(() => {
    filterUser();
  }, []);

  return (
    <div className={classNames.addPolicies}>
      <div className={classNames.allPolicies}>
        {Array?.isArray(allPolicies) && allPolicies?.length > 0 ? (
          allPolicies?.map((eachPolicy, index) => {
            return (
              <div
                className={`${classNames.eachPolicy}`}
                // onClick={() => {
                //   addPolicyToEmployee(
                //     seeDetails?.user_company_email,
                //     seeDetails?._id,
                //     eachPolicy?.policy_variant
                //       ? eachPolicy?.policy_variant
                //       : "",
                //     seeDetails?.user_employee_id,
                //     eachPolicy?._id
                //   );
                // }}
              >
                <div className={classNames.headDiv}>
                  <div className={classNames.imageDiv}>
                    <img
                      src={
                        eachPolicy?.insurance_company_id
                          ? eachPolicy?.insurance_company_id
                              ?.insurance_company_logo_url
                          : "-"
                      }
                      alt="iciciIcon"
                    />
                  </div>
                  <div className={classNames.companyDetails}>
                    <div className={classNames.title}>
                      {eachPolicy?.policy_type ? eachPolicy?.policy_type : "-"}
                    </div>
                    <div className={classNames.company}>
                      Policy By :{" "}
                      <span style={{ fontWeight: "600" }}>
                        {eachPolicy
                          ? eachPolicy?.insurance_company_id
                              ?.insurance_company_name
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={classNames.desc}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {eachPolicy?.description}
                </div> */}
                <div
                  className={classNames.desc}
                  style={{
                    paddingTop: "0",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <div>
                      Start Date:{" "}
                      {eachPolicy?.start_date ? eachPolicy?.start_date : "-"}
                    </div>
                    <div>
                      Valid Till:{" "}
                      {eachPolicy?.end_date ? eachPolicy?.end_date : "-"}
                    </div>
                  </div>
                  <div>
                    Policy Number:{" "}
                    {eachPolicy?.policy_number
                      ? eachPolicy?.policy_number
                      : "-"}
                  </div>
                </div>
                <div className={classNames.moreDetails}>
                  <div className={classNames.amount}>
                    <div>
                      <div>Grade</div>
                      <div>Sum Insured</div>
                    </div>
                    {/* {eachPolicy?.policy_variant &&
                    eachPolicy?.policy_variant === "Flat" ? (
                      <div className={classNames.flexDiv}>
                        <div>{eachPolicy?.policy_variant}</div>
                        <div>
                          {eachPolicy?.sum_insured?.length > 0
                            ? eachPolicy?.sum_insured[0]
                            : "-"}
                        </div>
                      </div>
                    ) : eachPolicy?.sum_insured?.length > 0 ? (
                      eachPolicy?.sum_insured?.map((eachItem, indexx) => {
                        return (
                          <div className={classNames.flexDiv}>
                            <div>
                              {String.fromCharCode("A".charCodeAt(0) + indexx)}
                            </div>
                            <div>{eachItem}</div>
                          </div>
                        );
                      })
                    ) : (
                      ""
                    )} */}
                    {eachPolicy?.policy_rate_card?.length > 0
                      ? eachPolicy?.policy_rate_card?.map((eachItem, index) => {
                          return (
                            <div
                              className={`${classNames.flexDiv} ${classNames.hoverDiv}`}
                              onClick={() => {
                                console.log(
                                  eachPolicy,
                                  "eachPolicy selected clicked"
                                );
                                addPolicyToEmployee(
                                  seeDetails?.user_company_email,
                                  seeDetails?._id,
                                  eachItem?.policy_grade
                                    ? eachItem?.policy_grade
                                    : "",
                                  seeDetails?.user_employee_id,
                                  eachPolicy?._id,
                                  eachItem?.policy_number
                                );
                              }}
                            >
                              <div>{eachItem?.policy_grade}</div>
                              <div>{eachItem?.sum_insured}</div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            );
          })
        ) : allPolicies === "noPolicies" ? (
          <div className={classNames.messageContainer}>No Polices found!</div>
        ) : (
          ""
        )}
      </div>
      {submitAPIResponse && (
        <div className={classNames.shadowDiv}>
          <div className={classNames.balanceDiv}>
            <img src={fullLogo} alt="" className={classNames.logo} />
            <div
              className={classNames.desc}
            >{`Your CD balance has been debited ${submitAPIResponse?.cd_balance_debited?.toFixed(
              2
            )} USD for this endorsement`}</div>
            <div className={classNames.btnsContainer}>
              <button
                className={classNames.coloredBtn}
                onClick={() => {
                  history.push("/app/coverfi/overview", {
                    from: history.location,
                  });
                }}
              >
                Go To CD Balance
              </button>
              <button
                className={classNames.transparentBtn}
                onClick={() => {
                  setSubmitAPIResponse("");
                  window?.location?.reload();
                }}
              >
                Stay On This Page
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const AllPoliciesForUser = ({
  allLeads,
  expandDetails,
  setExpandDetails,
  type,
}) => {
  const { globalHrId } = useContext(BankContext);
  const [seeDetails, setSeeDetails] = useState({});
  const [allPolicies, setAllPolicies] = useState([]);
  const [selectedUserLocal, setSelectedUserLocal] = useState("");

  //functions

  async function getAllPolicies(userEmail) {
    try {
      const response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=${userEmail}`
      );
      if (response?.data?.status && response?.data?.data?.length > 0) {
        if (response?.data?.data?.length > 0) {
          setAllPolicies(response?.data?.data[0]?.policies);
        } else {
          setAllPolicies("noPolicies");
        }

        console.log(
          response?.data?.data,
          `All policies response see policies for ${userEmail}`
        );
      } else {
        setAllPolicies("noPolicies");
      }
    } catch (error) {
      predefinedToast("Error while fetching all policies");
      console.log(error?.message, "All policies error");
      setAllPolicies("noPolicies");
    }
  }

  function filterUser() {
    if (allLeads?.length > 0) {
      let filteredItem = allLeads?.filter(
        (eachitem, index) => eachitem?._id === expandDetails[0]
      );
      if (filteredItem?.length > 0) {
        setSeeDetails(filteredItem[0]);
        if (filteredItem[0]?.user_company_email && globalHrId) {
          console.log(filteredItem, "filteredItem value");
          getAllPolicies(filteredItem[0]?.user_company_email);
          setSelectedUserLocal(filteredItem[0]);
        }
      }
    }
  }

  async function removePolicyForEmployee(
    companyEmail,
    employeeID,
    policyGrade,
    companyEmpID,
    policyID,
    policyNumber
  ) {
    let obj = {
      hr_id: globalHrId,
      employee_data: [
        {
          employee_id: employeeID,
          date_of_birth: selectedUserLocal?.date_of_birth,
          date_of_leaving: getCurrentDate(),
          policy_number: policyNumber,
          Grade: policyGrade,
        },
      ],
    };
    try {
      let response = await Axios.post(
        `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/remove-employee/endorsement`,
        obj
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
      }
      setTimeout(() => {
        window?.location?.reload();
      }, 1500);
    } catch (error) {
      console.log(error?.message, "Remove policy for employee");
    }
  }

  useEffect(() => {
    filterUser();
  }, []);

  return (
    <div className={classNames.addPolicies}>
      <div className={classNames.allPolicies}>
        {Array?.isArray(allPolicies) && allPolicies?.length > 0 ? (
          allPolicies?.map((eachPolicy, index) => {
            return (
              <div className={`${classNames.eachPolicy}`}>
                <div className={classNames.headDiv}>
                  {/* <div className={classNames.imageDiv}>
                    <img
                      src={
                        eachPolicy?.insurance_company_id
                          ? eachPolicy?.insurance_company_id
                              ?.insurance_company_logo_url
                          : "-"
                      }
                      alt="iciciIcon"
                    />
                  </div> */}
                  <div
                    className={classNames.companyDetails}
                    style={{ paddingLeft: "0" }}
                  >
                    <div className={classNames.title}>
                      {eachPolicy?.policy_type ? eachPolicy?.policy_type : "-"}
                    </div>
                    <div className={classNames.company}>
                      Policy By :{" "}
                      <span style={{ fontWeight: "600" }}>
                        {eachPolicy?.policy_id?.insurance_company_id
                          ?.insurance_company_display_name
                          ? eachPolicy?.policy_id?.insurance_company_id
                              ?.insurance_company_display_name
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={classNames.desc}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {eachPolicy?.description}
                </div> */}
                <div
                  className={classNames.desc}
                  style={{
                    paddingTop: "0",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <div>
                      Start Date:{" "}
                      {eachPolicy?.policy_id?.start_date
                        ? eachPolicy?.policy_id?.start_date
                        : "-"}
                    </div>
                    <div>
                      Valid Till:{" "}
                      {eachPolicy?.policy_id?.end_date
                        ? eachPolicy?.policy_id?.end_date
                        : "-"}
                    </div>
                  </div>
                  <div>
                    Policy Number:{" "}
                    {eachPolicy?.policy_number
                      ? eachPolicy?.policy_number
                      : "-"}
                  </div>
                </div>
                <div className={classNames.moreDetails}>
                  <div className={classNames.moreDetailsTitle}>
                    <div>Grade</div>
                    <div>Sum Insured</div>
                  </div>
                  <div className={classNames.allPolicyVarients}>
                    <div
                      className={classNames.eachVarient}
                      onClick={() => {
                        if (type == "removePolicy") {
                          removePolicyForEmployee(
                            seeDetails?.user_company_email,
                            seeDetails?._id,
                            eachPolicy?.policy_grade,
                            seeDetails?.user_employee_id,
                            eachPolicy?._id,
                            eachPolicy?.policy_number
                          );
                        }
                      }}
                    >
                      <div>
                        {eachPolicy?.policy_grade
                          ? eachPolicy?.policy_grade
                          : ""}
                      </div>
                      <div>
                        {Array.isArray(eachPolicy?.sum_insured) &&
                        eachPolicy?.sum_insured?.length > 0
                          ? eachPolicy?.sum_insured[0]
                          : eachPolicy?.sum_insured
                          ? eachPolicy?.sum_insured
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : allPolicies === "noPolicies" ? (
          <div className={classNames.messageContainer}>No Polices found!</div>
        ) : (
          <div className={classNames.messageContainer}>No Polices found!</div>
        )}
      </div>
    </div>
  );
};

const AllPoliciesHealthcard = ({
  allLeads,
  expandDetails,
  setExpandDetails,
}) => {
  const { globalHrId, setUpdateLoading } = useContext(BankContext);
  const [seeDetails, setSeeDetails] = useState({});
  const [allPolicies, setAllPolicies] = useState([]);

  //functions

  async function getAllPolicies(userEmail) {
    try {
      const response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy/policy-employee-map?employee_email_id=${userEmail}&policy_type=Health`
      );
      if (response?.data?.status && response?.data?.data?.length > 0) {
        setAllPolicies(response?.data?.data);
      }
      console.log(response, "All policies response");
    } catch (error) {
      predefinedToast("Error while fetching all policies");
      console.log(error?.message, "All policies error");
    }
  }

  function filterUser() {
    if (allLeads?.length > 0) {
      let filteredItem = allLeads?.filter(
        (eachitem, index) => eachitem?._id === expandDetails[0]
      );
      if (filteredItem?.length > 0) {
        setSeeDetails(filteredItem[0]);
        if (filteredItem[0]?.user_company_email) {
          getAllPolicies(filteredItem[0]?.user_company_email);
        }
      }
    }
  }

  async function downloadHealthCard(policyNumber, empID, tpaID) {
    setUpdateLoading(true);
    try {
      if (empID && tpaID && policyNumber) {
        const response = await Axios.get(
          `https://coverfi.apimachine.com/api/v1/tpa/getEcard/${tpaID}/${encodeURIComponent(
            policyNumber
          )}/${encodeURIComponent(empID)}`
        );

        console.log(response, "downloadHealthCard");
        let res = response?.data;

        if (res?.status && res?.data?.length > 0) {
          predefinedToast(res?.message);
          // window?.open(res?.data[0]?.ecard_url, "_blank");
        }

        //new method start
        if (
          Array.isArray(response?.data?.data) &&
          response?.data?.data?.length > 0 &&
          response?.data?.data[0]?.type == "browser"
        ) {
          window?.open(
            response?.data?.data[0]?.ecard_url
              ? response?.data?.data[0]?.ecard_url
              : response?.data?.data[0]["e-card"],
            "_blank"
          );
        } else if (
          Array.isArray(response?.data?.data) &&
          response?.data?.data?.length > 0 &&
          response?.data?.data[0]?.type == "base64"
        ) {
          const blob = base64ToBlob(
            response?.data?.data[0]["e-card"],
            "application/pdf"
          );
          downloadFile(blob);
        } else {
          predefinedToast(response?.data?.message);
        }
      } else {
        predefinedToast("Some fields are missing!");
      }
      setUpdateLoading(false);
    } catch (error) {
      predefinedToast("Error while fetching health cards");
      console.log(error?.message, "downloadHealthCard error");
      setUpdateLoading(false);
    }
  }

  useEffect(() => {
    filterUser();
  }, []);

  return (
    <div className={classNames.addPolicies}>
      <div className={classNames.allPolicies}>
        {Array?.isArray(allPolicies) && allPolicies?.length > 0 ? (
          allPolicies?.map((eachPolicy, index) => {
            return (
              <div className={`${classNames.eachPolicy}`}>
                <div className={classNames.headDiv}>
                  {/* <div className={classNames.imageDiv}>
                    <img
                      src={
                        eachPolicy?.insurance_company_id
                          ?.insurance_company_logo_url
                          ? eachPolicy?.insurance_company_id
                              ?.insurance_company_logo_url
                          : ""
                      }
                      alt="iciciIcon"
                    />
                  </div> */}
                  <div className={classNames.companyDetails}>
                    <div className={classNames.title}>
                      {eachPolicy?.policy_type}
                    </div>
                    <div className={classNames.company}>
                      Policy By :{" "}
                      <span style={{ fontWeight: "600" }}>
                        {eachPolicy?.insurance_company_id
                          ?.insurance_company_name
                          ? eachPolicy?.insurance_company_id
                              ?.insurance_company_name
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.desc}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {eachPolicy?.description}
                </div>
                <div
                  className={classNames.desc}
                  style={{
                    paddingTop: "0",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <div>Start Date: {eachPolicy?.policy_id?.start_date}</div>
                    <div>Valid Till: {eachPolicy?.policy_id?.end_date}</div>
                  </div>
                  <div>Policy Number: {eachPolicy?.policy_number}</div>
                </div>
                <div className={classNames.moreDetails}>
                  <div className={classNames.amount}>
                    <div>Coverage (USD)</div>
                    <div>
                      {eachPolicy?.policy_id?.sum_insured?.length > 0 &&
                        eachPolicy?.policy_id?.sum_insured[0]}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        className={classNames.btn}
                        onClick={() => {
                          let policyNumber = eachPolicy?.policy_number?.replace(
                            /\//g,
                            "%2F"
                          );
                          downloadHealthCard(
                            policyNumber,
                            seeDetails?.user_employee_id,
                            eachPolicy?.policy_id?.tpa_id
                          );
                        }}
                      >
                        Download Health Card
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={classNames.messageContainer}>No Polices found!</div>
        )}
      </div>
    </div>
  );
};

const InitiateAddionalEndorsements = ({
  employeeDetails,
  setPopupContainer,
}) => {
  const {
    policiesApiGlobal,
    addDependentSidebar: isOpen,
    setAddDependentSidebar: setIsOpen,
    setUpdateLoading,
    setActionBtns,
    onLoginFormEmployerProfile,
  } = useContext(BankContext);
  const [dependentsDetails, setDependentsDetails] = useState({});

  function initiateAdditionalEndorsementRequest() {
    let finalObj = {
      hr_id: onLoginFormEmployerProfile?.hrDetails?.hr_id,
      company_id: onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails?._id,
      employee_id: employeeDetails?._id,
      policy_id: dependentsDetails?.policy_id,
      date_of_joining: dependentsDetails?.date_of_joining,
      action_type: "Addition",
    };
    // console.log(onLoginFormEmployerProfile, "employeeDetails");

    setUpdateLoading(true);
    Axios.post(
      "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/hr-associate-request/create",
      finalObj
    )
      .then((response) => {
        if (response?.data?.status) {
          setIsOpen(false);
          setPopupContainer("");
          setActionBtns([]);
          predefinedToast("Initiated addition endorsement");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        // console.log(response, "add dependent response");
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Initiate endorsement error");
        setUpdateLoading(false);
        predefinedToast("Error while initializing endorsement");
      });
  }

  return (
    <div className={classNames.addDependents}>
      <div className={classNames.title}>
        Initiate addition endorsement request
      </div>
      <div className={classNames.allInputsParent}>
        <EachCustomDropdown
          dropdown={policiesApiGlobal}
          title="Select policy"
          name="policy_id"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          typee="objVal"
          mapName="policy_number"
          objName="_id"
        />

        <div className={classNames.twoInputContainer}>
          <EachCustomDatePicker
            placeholder="Date of joining"
            name="date_of_joining"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="spreadObj"
            // values={{ state: dependentsDetails }}
          />
        </div>
      </div>
      <div
        className={classNames.submitButton}
        onClick={initiateAdditionalEndorsementRequest}
      >
        Submit
      </div>
    </div>
  );
};

const InitiateDeletingEndorsement = ({
  employeeDetails,
  setPopupContainer,
}) => {
  const {
    policiesApiGlobal,
    addDependentSidebar: isOpen,
    setAddDependentSidebar: setIsOpen,
    setUpdateLoading,
    setActionBtns,
    onLoginFormEmployerProfile,
  } = useContext(BankContext);
  const [dependentsDetails, setDependentsDetails] = useState({});

  function initiateDeletionEndorsementRequest() {
    let finalObj = {
      hr_id: onLoginFormEmployerProfile?.hrDetails?.hr_id,
      company_id: onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails?._id,
      employee_id: employeeDetails?._id,
      policy_id: dependentsDetails?.policy_id,
      date_of_leaving: dependentsDetails?.date_of_leaving,
      action_type: "Removal",
    };
    // console.log(onLoginFormEmployerProfile, "employeeDetails");

    setUpdateLoading(true);
    Axios.post(
      "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/hr-associate-request/create",
      finalObj
    )
      .then((response) => {
        if (response?.data?.status) {
          setIsOpen(false);
          setPopupContainer("");
          setActionBtns([]);
          predefinedToast("Initiated deletion endorsement");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        // console.log(response, "add dependent response");
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Initiate endorsement error");
        setUpdateLoading(false);
        predefinedToast("Error while deleting endorsement");
      });
  }

  return (
    <div className={classNames.addDependents}>
      <div className={classNames.title}>
        Initiate deletion endorsement request
      </div>
      <div className={classNames.allInputsParent}>
        <EachCustomDropdown
          dropdown={policiesApiGlobal}
          title="Select policy"
          name="policy_id"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          typee="objVal"
          mapName="policy_number"
          objName="_id"
        />

        <div className={classNames.twoInputContainer}>
          <EachCustomDatePicker
            placeholder="Date of exit"
            name="date_of_leaving"
            stateValue={dependentsDetails}
            setState={setDependentsDetails}
            typee="spreadObj"
            // values={{ state: dependentsDetails }}
          />
        </div>
      </div>
      <div
        className={classNames.submitButton}
        onClick={initiateDeletionEndorsementRequest}
      >
        Submit
      </div>
    </div>
  );
};
