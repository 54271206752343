import React, { useEffect, useRef, useState } from "react";
import classNames from "./enrollmentform.module.scss";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import fullLogo from "../../assest/images/fullLogo.svg";
import rahaWhiteLogo from "../../assest/images/logos/rahawhite.svg";
import calenderIcon from "../../assest/images/icons/calender.svg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import useWindowDimensions from "../../utils/WindowSize";
import Axios from "axios";
import { predefinedToast } from "../../utils/toast";
import { useContext } from "react";
import { BankContext } from "../../context/Context";
import { LoadingAnimationRahaLogoFixed } from "../../components/Custom";

// import premiumTable from "../../assest/images/prelogin/premiumtable.jpg";
import premiumTableRaha from "../../assest/images/prelogin/premiumtableraha.svg";

import powerByVisista from "../../assest/images/logos/poweredbyvisista.svg";
import { useHistory, useParams, Link } from "react-router-dom";
import { formatIndianRupees } from "../../assest/data/functions";
import asteriskIcon from "../../assest/images/icons/asterisk.svg";

const EnrollmentForm = () => {
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const { status } = useParams();
  const { reqValue, setreqValue } = useContext(BankContext);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [totalPremiumAmount, setTotalPremiumAmount] = useState(0);
  const [totalPremiumAmountDisplay, setTotalPremiumAmountDisplay] =
    useState("");
  const [baseAmount, setBaseAmount] = useState("");
  const [baseAmountSpouse, setBaseAmountSpouse] = useState("");
  const [redirectPage, setRedirectPage] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [indianStatesDropdown, setIndianStatesDropdown] = useState("");

  const [userAge, setUserAge] = useState("");
  const [dependentAge, setDependentAge] = useState("");

  const [selfPremiumAmount, setSelfPremiumAmount] = useState("");
  const [dependentPremiumAmount, setDependentPremiumAmount] = useState("");

  const [dependentsProfile, setDependentsProfile] = useState({
    name: "",
    date_of_birth: "",
    gender: "",
    relationship: "",
    age: "",
    roll_number: "",
    sum_insured: "",
    // email_id: "",
    // phone_number: "",
    // association_id: "",
    // sum_insured: "",
  });

  const [enrollmentData, setEnrollmentData] = useState({
    policy_type: "Base Policy",
    member_type: "Self Only",
    sum_insured: "",
    name: "",
    date_of_birth: "MM-DD-YYYY",
    gender: "",
    relationship: "",
    email_id: "",
    phone_number: "",
    // association_id: "",
    insurance_premium: "",
    dependents_data: [],
    age: "",
  });

  useEffect(() => {
    // console.log(selfPremiumAmount, dependentPremiumAmount, "Checking premium");
    if (selfPremiumAmount & dependentPremiumAmount) {
      setTotalPremiumAmount(selfPremiumAmount + dependentPremiumAmount);
      setTotalPremiumAmountDisplay(
        formatIndianRupees(selfPremiumAmount + dependentPremiumAmount)
      );
    } else if (selfPremiumAmount) {
      setTotalPremiumAmount(selfPremiumAmount);
      setTotalPremiumAmountDisplay(formatIndianRupees(selfPremiumAmount));
    } else if (dependentPremiumAmount) {
      setTotalPremiumAmount(dependentPremiumAmount);
      setTotalPremiumAmountDisplay(formatIndianRupees(dependentPremiumAmount));
    } else {
      setTotalPremiumAmount(0);
      setTotalPremiumAmountDisplay(0);
      // setTotalPremiumAmount(0)
    }
  }, [selfPremiumAmount, dependentPremiumAmount]);

  async function calculatePremium(age, premiumAmount, setPremium) {
    let totalAmount;
    let sumInsured;
    if (premiumAmount === "3,00,000.00") {
      sumInsured = [5506, 8511, 13878, 18134, 21325, 25173, 29006];
    } else if (premiumAmount === "4,00,000.00") {
      sumInsured = [6512, 10080, 16435, 21411, 25213, 29762, 34294];
    } else if (premiumAmount === "5,00,000.00") {
      sumInsured = [7523, 11649, 18134, 24689, 29099, 34352, 39582];
    } else {
      // Handle cases where the premium amount is not in the specified list
      // throw new Error("Invalid premium amount");
    }

    // // Parse DOB string to a Date object
    // const dobDate = new Date(dob);
    // const today = new Date();

    // // Calculate age
    // const age =
    //   today.getFullYear() -
    //   dobDate.getFullYear() -
    //   (today.getMonth() < dobDate.getMonth() ||
    //   (today.getMonth() === dobDate.getMonth() &&
    //     today.getDate() < dobDate.getDate())
    //     ? 1
    //     : 0);

    // Determine the premium based on the age and sum insured
    if (36 <= age && age <= 45) {
      totalAmount = sumInsured[0];
    } else if (46 <= age && age <= 55) {
      totalAmount = sumInsured[1];
    } else if (56 <= age && age <= 60) {
      totalAmount = sumInsured[2];
    } else if (61 <= age && age <= 65) {
      totalAmount = sumInsured[3];
    } else if (66 <= age && age <= 70) {
      totalAmount = sumInsured[4];
    } else if (71 <= age && age <= 75) {
      totalAmount = sumInsured[5];
    } else if (76 <= age && age <= 80) {
      totalAmount = sumInsured[6];
    } else {
      totalAmount = 0;
      // Handle cases where age is outside the defined ranges
      // throw new Error("Invalid age range");
    }

    setPremium(totalAmount);

    console.log(totalAmount, "totalAmount for premium");
  }

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/general/getstates`
    )
      .then((response) => {
        console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setIndianStatesDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  useEffect(() => {
    // getPremiumAmount();
    if (userAge) {
      calculatePremium(
        userAge,
        baseAmount?.base_policy_amount,
        setSelfPremiumAmount
      );
    }

    if (dependentAge) {
      calculatePremium(
        dependentAge,
        baseAmountSpouse?.base_policy_amount,
        setDependentPremiumAmount
      );
    }
  }, [
    enrollmentData,
    baseAmount,
    baseAmountSpouse,
    dependentsProfile,
    userAge,
    dependentAge,
  ]);

  function updateAge(selectedState, seperateState) {
    const dobParts = selectedState?.date_of_birth?.split("/");
    console.log(dobParts, "dobParts", selectedState?.date_of_birth);
    if (dobParts?.length === 3) {
      const dobDate = new Date(dobParts[2], dobParts[1] - 1, dobParts[0]); // Year, month, day
      const today = new Date();
      const age = today.getFullYear() - dobDate.getFullYear();

      // Adjust age if the birthday hasn't occurred yet this year
      // if (
      //   today <
      //   new Date(today.getFullYear(), dobDate.getMonth(), dobDate.getDate())
      // ) {
      //   age--;
      // }
      // console.log(age, "Selected age");
      selectedState.age = age;
      seperateState(age);
    }
  }

  async function submitIOB() {
    //check phone number shoud be 10 digits and should not start with 0 and no special characters
    if (
      !/^[0-9]{10}$/.test(enrollmentData.phone_number) ||
      enrollmentData.phone_number.startsWith("0") ||
      /[+\-\s]/.test(enrollmentData.phone_number)
    ) {
      predefinedToast(
        "Please enter a valid phone number without special characters or spaces"
      );
      return;
    }
    setUpdateLoading(true);
    enrollmentData.insurance_premium = totalPremiumAmount;
    enrollmentData.sum_insured = baseAmount?.base_policy_amount;

    dependentsProfile.sum_insured = baseAmountSpouse?.base_policy_amount;

    let selfObj = {
      name: enrollmentData?.name,
      date_of_birth: enrollmentData?.date_of_birth,
      gender: enrollmentData?.gender,
      relationship: enrollmentData?.relationship,
      email_id: enrollmentData?.email_id,
      phone_number: enrollmentData?.phone_number,
      // association_id: enrollmentData?.association_id,
      sum_insured: baseAmount?.base_policy_amount,
      age: enrollmentData?.age,
      roll_number: enrollmentData?.roll_number,
    };

    if (enrollmentData?.member_type == "Self + Spouse") {
      enrollmentData.dependents_data = [selfObj, dependentsProfile];
    } else {
      enrollmentData.dependents_data = [selfObj];
    }

    await Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/general/iob/add",
      enrollmentData
    )
      .then((response) => {
        // console.log(response?.data, "submitIOB response");
        if (response?.data?.status) {
          // setUpdateLoading(false);
          predefinedToast(response?.data?.message);
          callPaymentAPI(response?.data?.iob_id);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        }
      })
      .catch((error) => {
        console.log(error?.message, "submitIOB error");
        predefinedToast(error?.message);
        // setUpdateLoading(false);
      });

    async function callPaymentAPI(iobId) {
      console.log(iobId, "IOBID from payment API");
      await Axios.post(
        "https://insurancecoverfi.apimachine.com/insurance/iob/transaction/initiate",
        {
          totalamt: totalPremiumAmount,
          phone_number: enrollmentData?.phone_number,
          primary_member_details: enrollmentData?.name,
          roll_number: enrollmentData?.roll_number,
          iob_id: iobId,
          // user_id: enrollmentData?.association_id,
          // user_email: enrollmentData?.email_id,
        }
      )
        .then((response) => {
          console.log(response?.data, "submitIOB payment gateway response");
          // setUpdateLoading(false);
          setreqValue(response?.data?.data);

          if (response?.data?.data) {
            // predefinedToast(response?.data?.message);
            setRedirectPage(true);
          } else {
            predefinedToast(response?.data?.message);
          }
          setUpdateLoading(false);
        })
        .catch((error) => {
          console.log(error?.message, "submitIOB payment gateway error");
          predefinedToast(error?.message);
          setUpdateLoading(false);
        });
    }
    console.log(enrollmentData, "Enrollment data final");
  }

  useEffect(() => {
    if (dependentsProfile?.date_of_birth) {
      dependentsProfile.age = null;
      updateAge(dependentsProfile, setDependentAge);
    }
    if (enrollmentData?.date_of_birth) {
      enrollmentData.age = null;
      updateAge(enrollmentData, setUserAge);
    }
  }, [dependentsProfile?.date_of_birth, enrollmentData?.date_of_birth]);

  useEffect(() => {
    if (redirectPage) {
      autoFormSubmit();
    }
  }, [redirectPage]); // Run once on component mount

  const autoFormSubmit = () => {
    const reqjsonValue = JSON.stringify(reqValue);

    if (reqjsonValue.trim().length > 0) {
      document.getElementById("msg").innerHTML =
        "Processing your transaction. Please Wait.....";
      document.getElementById("form1").submit();
    } else {
      document.getElementById("msg").innerHTML =
        "Server Error. Please contact your website administrator!";
    }
  };

  const txn_initiation_url = "https://www.iobnet.co.in/iobpay/apitxninit.do";

  useEffect(() => {
    enrollmentData.insurance_premium = totalPremiumAmount;
    enrollmentData.sum_insured = baseAmount?.base_policy_amount;

    dependentsProfile.sum_insured = baseAmountSpouse?.base_policy_amount;

    let selfObj = {
      name: enrollmentData?.name,
      date_of_birth: enrollmentData?.date_of_birth,
      gender: enrollmentData?.gender,
      relationship: enrollmentData?.relationship,
      email_id: enrollmentData?.email_id,
      phone_number: enrollmentData?.phone_number,
      // association_id: enrollmentData?.association_id,
      sum_insured: baseAmount?.base_policy_amount,
      age: enrollmentData?.age,
      roll_number: enrollmentData?.roll_number,
    };

    if (enrollmentData?.member_type == "Self + Spouse") {
      enrollmentData.dependents_data = [selfObj, dependentsProfile];
    } else {
      enrollmentData.dependents_data = [selfObj];
    }
  }, [
    enrollmentData,
    dependentsProfile,
    baseAmountSpouse,
    baseAmount,
    totalPremiumAmount,
    totalPremiumAmountDisplay,
  ]);

  return (
    <div className={classNames.enrollmentForm}>
      {window?.location?.pathname?.includes("/enrollment/success") &&
      status == "success" ? (
        <>
          <div
            className={classNames.leftPanel}
            style={{ display: width < 800 ? "none" : "" }}
          >
            <div className={classNames.headPanel}>
              <img src={fullLogo} alt="fullLogo" />
            </div>
            <img src={powerByVisista} alt="powerByVisista" />
          </div>
          <div
            className={classNames.rightPanel}
            style={{
              width: width < 800 ? "100%" : "",
              borderRadius: width < 800 ? "0" : "",
              padding: width < 800 ? "10rem 1rem" : "",
              paddingTop: width < 800 ? "0" : "",
              paddingBottom: "2.5rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames.successMessage}>
              <div>Congratulations</div>
              <div>You have successfully made your payment</div>
            </div>
            <Link
              className={classNames.receiptBtn}
              to="/iob/enrollment/transactionhistory"
            >
              View Transactions
            </Link>
          </div>
        </>
      ) : window?.location?.pathname?.includes("/enrollment/failure") &&
        status == "failure" ? (
        <>
          <div
            className={classNames.leftPanel}
            style={{ display: width < 800 ? "none" : "" }}
          >
            <div className={classNames.headPanel}>
              <img src={fullLogo} alt="fullLogo" />
            </div>
            <img src={powerByVisista} alt="powerByVisista" />
          </div>
          <div
            className={classNames.rightPanel}
            style={{
              width: width < 800 ? "100%" : "",
              borderRadius: width < 800 ? "0" : "",
              padding: width < 800 ? "10rem 1rem" : "",
              paddingTop: width < 800 ? "0" : "",
              paddingBottom: "2.5rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames.successMessage}>
              <div>Payment Failed</div>
              <div>We are unable to process the payment</div>
            </div>
            <Link
              className={classNames.receiptBtn}
              to="/iob/enrollment/transactionhistory"
            >
              View Transactions
            </Link>
          </div>
        </>
      ) : window?.location?.pathname?.includes("/enrollment/awaited") &&
        status == "awaited" ? (
        <>
          <div
            className={classNames.leftPanel}
            style={{ display: width < 800 ? "none" : "" }}
          >
            <div className={classNames.headPanel}>
              <img src={fullLogo} alt="fullLogo" />
            </div>
            <img src={powerByVisista} alt="powerByVisista" />
          </div>
          <div
            className={classNames.rightPanel}
            style={{
              width: width < 800 ? "100%" : "",
              borderRadius: width < 800 ? "0" : "",
              padding: width < 800 ? "10rem 1rem" : "",
              paddingTop: width < 800 ? "0" : "",
              paddingBottom: "2.5rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames.successMessage}>
              <div>Pending</div>
              <div>
                Your payment is under process.please check after sometime.
              </div>
            </div>
            <Link
              className={classNames.receiptBtn}
              to="/iob/enrollment/transactionhistory"
            >
              View Transactions
            </Link>
          </div>
        </>
      ) : window?.location?.pathname?.includes("/enrollment/paylater") ? (
        <>
          <div
            className={classNames.leftPanel}
            style={{ display: width < 800 ? "none" : "" }}
          >
            <div className={classNames.headPanel}>
              <img src={fullLogo} alt="fullLogo" />
            </div>
            <img src={powerByVisista} alt="powerByVisista" />
          </div>

          <div
            className={classNames.rightPanel}
            style={{
              width: width < 800 ? "100%" : "",
              borderRadius: width < 800 ? "0" : "",
              padding: width < 800 ? "10rem 1rem" : "",
              paddingTop: width < 800 ? "0" : "",
              paddingBottom: "2.5rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {width < 800 ? (
              <div
                className={classNames.headPanel}
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <img src={rahaWhiteLogo} alt="rahaWhiteLogo" />
                <img
                  src={powerByVisista}
                  alt="powerByVisista"
                  style={{ height: "1.7rem", objectFit: "contain" }}
                />
              </div>
            ) : (
              ""
            )}
            <div className={classNames.successMessage}>
              <div>Congratulations</div>
              <div style={{ paddingTop: "1.2rem" }}>
                You have successfully confirmed your order. You will be emailed
                the next steps so you can complete the payment.
              </div>
            </div>
          </div>
        </>
      ) : redirectPage ? (
        <>
          <div className={classNames.redirectingMessage}>
            <form action={txn_initiation_url} method="post" id="form1">
              <p id="msg"></p>
              <input
                type="hidden"
                name="reqjson"
                value={JSON.stringify(reqValue)}
              />
            </form>
          </div>
        </>
      ) : (
        <>
          <div
            className={classNames.leftPanel}
            style={{ display: width < 800 ? "none" : "" }}
          >
            <div className={classNames.headPanel}>
              <img src={fullLogo} alt="fullLogo" />
            </div>
            <img src={powerByVisista} alt="powerByVisista" />
          </div>

          <div
            className={classNames.rightPanel}
            style={{
              width: width < 800 ? "100%" : "",
              borderRadius: width < 800 ? "0" : "",
              padding: width < 800 ? "10rem 1rem" : "",
              paddingTop: width < 800 ? "0" : "",
            }}
          >
            {updateLoading ? (
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  pointerEvents: "none",
                  position: "fixed",
                  background: "#00000080",
                  inset: 0,
                  zIndex: 6,
                }}
              >
                <LoadingAnimationRahaLogoFixed />
              </div>
            ) : (
              ""
            )}
            {width < 800 ? (
              <div
                className={classNames.headPanel}
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <img src={rahaWhiteLogo} alt="rahaWhiteLogo" />
                <img
                  src={powerByVisista}
                  alt="powerByVisista"
                  style={{ height: "1.7rem", objectFit: "contain" }}
                />
              </div>
            ) : (
              ""
            )}
            <div className={classNames.headerWrapper}>
              <div className={classNames.headerTitle}>
                Enrollment Form for ARISE (IOB Retirees)
              </div>
              <div
                className={classNames.txnLink}
                onClick={(e) => {
                  window.open(
                    "https://talltree.coverfi.app/iob/enrollment/transactionhistory",
                    "_blank"
                  );
                }}
              >
                Transaction Tracker
              </div>
            </div>

            <img src={premiumTableRaha} alt="premiumTableRaha" />

            <SelectTag
              title="Member Type"
              name="member_type"
              allItems={["Self Only", "Self + Spouse"]}
              selectedValue={enrollmentData}
              setSelectedValue={setEnrollmentData}
              mandatory="true"
            />

            <div className={classNames.multipleInputsWithTitle}>
              <div className={classNames.title}>Sum Insured</div>
              <div
                className={`${classNames.multipleInputs} ${
                  width < 800 ? classNames.multipleInputsMobile : ""
                }`}
                style={{
                  flexDirection: width < 800 ? "column" : "",
                  alignItems: width < 800 ? "flex-start" : "",
                }}
              >
                {enrollmentData?.policy_type == "Base Policy" ||
                enrollmentData?.policy_type == "Both" ? (
                  <CustomDropdown
                    dropdown={["3,00,000.00", "4,00,000.00", "5,00,000.00"]}
                    name="base_policy_amount"
                    stateValue={baseAmount}
                    setState={setBaseAmount}
                    title={"Self"}
                    mandatory="true"
                  />
                ) : (
                  ""
                )}
                {enrollmentData?.policy_type == "Base Policy" &&
                enrollmentData?.member_type == "Self + Spouse" ? (
                  <CustomDropdown
                    dropdown={["3,00,000.00", "4,00,000.00", "5,00,000.00"]}
                    name="base_policy_amount"
                    stateValue={baseAmountSpouse}
                    setState={setBaseAmountSpouse}
                    title={"Spouse"}
                    mandatory="true"
                  />
                ) : (
                  ""
                )}
                {enrollmentData?.policy_type == "Super Top-up Policy" ||
                enrollmentData?.policy_type == "Both" ? (
                  <CustomDropdown
                    dropdown={[""]}
                    name="allbrokerages"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    title={"Super Top-up policy"}
                    mandatory="true"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className={classNames.personalDetails}>
              {enrollmentData?.member_type == "Self Only" ||
              enrollmentData?.member_type == "Self + Spouse" ? (
                <>
                  <div className={classNames.title}>Primary Member Details</div>
                  <div
                    className={`${classNames.multipleInputs} ${
                      width < 800 ? classNames.multipleInputsMobile : ""
                    }`}
                    style={{ marginTop: "1rem" }}
                  >
                    <EachInput
                      title="Name"
                      placeholder="Enter Name.."
                      name="name"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      mandatory="true"
                    />
                    <EachInputDatePicker
                      title="Date of Birth"
                      placeholder="Click To Select"
                      name="date_of_birth"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      mandatory="yes"
                    />
                    <CustomDropdown
                      dropdown={["Male", "Female"]}
                      name="gender"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      title={"Gender"}
                      mandatory="true"
                    />
                    <CustomDropdown
                      dropdown={["Self", "Spouse"]}
                      name="relationship"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      title={"Relationship"}
                      mandatory="true"
                    />
                  </div>
                  <div
                    className={`${classNames.multipleInputs} ${
                      width < 800 ? classNames.multipleInputsMobile : ""
                    }`}
                    style={{ marginTop: "1rem" }}
                  >
                    <EachInput
                      title="Roll Number"
                      placeholder="Enter Roll Number.."
                      name="roll_number"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      mandatory="true"
                    />
                    <EachInput
                      title="Age"
                      placeholder="Enter Age.."
                      name="age"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      valuee={userAge}
                      // mandatory="true"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {enrollmentData?.member_type == "Self + Spouse" ? (
                <>
                  <div
                    className={classNames.title}
                    style={{ marginTop: "3rem" }}
                  >
                    Dependent Details
                  </div>
                  <div
                    className={`${classNames.multipleInputs} ${
                      width < 800 ? classNames.multipleInputsMobile : ""
                    }`}
                    style={{ marginTop: "1rem" }}
                  >
                    <EachInput
                      title="Name"
                      placeholder="Enter Name.."
                      name="name"
                      stateValue={dependentsProfile}
                      setState={setDependentsProfile}
                      mandatory="true"
                    />
                    <EachInputDatePicker
                      title="Date of Birth"
                      placeholder="Click To Select"
                      name="date_of_birth"
                      stateValue={dependentsProfile}
                      setState={setDependentsProfile}
                      mandatory="yes"
                    />
                    <CustomDropdown
                      dropdown={["Male", "Female"]}
                      name="gender"
                      stateValue={dependentsProfile}
                      setState={setDependentsProfile}
                      title={"Gender"}
                      mandatory="true"
                    />
                    <CustomDropdown
                      dropdown={["Self", "Spouse"]}
                      name="relationship"
                      stateValue={dependentsProfile}
                      setState={setDependentsProfile}
                      title={"Relationship"}
                      mandatory="true"
                    />
                  </div>
                  <div
                    className={`${classNames.multipleInputs} ${
                      width < 800 ? classNames.multipleInputsMobile : ""
                    }`}
                    style={{ marginTop: "1rem" }}
                  >
                    <EachInput
                      title="Roll Number"
                      placeholder="Enter Roll Number.."
                      name="roll_number"
                      stateValue={dependentsProfile}
                      setState={setDependentsProfile}
                      mandatory="true"
                    />
                    <EachInput
                      title="Age"
                      placeholder="Enter age.."
                      name="age"
                      stateValue={dependentsProfile}
                      setState={setDependentsProfile}
                      valuee={dependentAge}
                      // mandatory="true"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              <div style={{ margin: "2rem 0" }}>
                <div className={classNames.visistaInputs}>
                  <div className={classNames.title}>
                    Address 1 <img src={asteriskIcon} alt="asteriskIcon" />
                  </div>
                  <EachInput
                    placeholder="Enter Address 1.."
                    name="address1"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    type="talltree"
                  />
                </div>
                <div className={classNames.visistaInputs}>
                  <div className={classNames.title}>
                    Address 2 <img src={asteriskIcon} alt="asteriskIcon" />
                  </div>
                  <EachInput
                    placeholder="Enter Address 2.."
                    name="address2"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    type="talltree"
                  />
                </div>
                <div className={classNames.visistaInputs1}>
                  <div className={classNames.visistaInputs}>
                    <div className={classNames.title}>
                      City <img src={asteriskIcon} alt="asteriskIcon" />
                    </div>
                    <EachInput
                      placeholder="Enter City.."
                      name="city"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      type="talltree"
                    />
                  </div>
                  <div className={classNames.visistaInputs}>
                    <div className={classNames.title}>
                      State <img src={asteriskIcon} alt="asteriskIcon" />
                    </div>
                    <CustomDropdown
                      dropdown={indianStatesDropdown}
                      name="state"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      // mandatory="true"
                    />
                  </div>
                </div>
                <div className={classNames.visistaInputs}>
                  <div className={classNames.title}>
                    Pincode <img src={asteriskIcon} alt="asteriskIcon" />
                  </div>
                  <EachInput
                    placeholder="Enter Pincode.."
                    name="pincode"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    type="talltree"
                  />
                </div>
                <div className={classNames.visistaInputs}>
                  <div className={classNames.title}>
                    Email <img src={asteriskIcon} alt="asteriskIcon" />
                  </div>
                  <EachInput
                    placeholder="Enter Email.."
                    name="email_id"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    type="talltree"
                  />
                </div>
                <div className={classNames.visistaInputs}>
                  <div className={classNames.title}>
                    Mobile <img src={asteriskIcon} alt="asteriskIcon" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    <EachInput
                      placeholder="+91"
                      name="countrycode"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                      widthh="maxwidth"
                      style={{ width: "4rem" }}
                    />
                    <EachInput
                      placeholder="Enter Mobile Number.."
                      name="phone_number"
                      stateValue={enrollmentData}
                      setState={setEnrollmentData}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames.checkBoxParent}>
                <input
                  type="checkbox"
                  checked={agreedTerms}
                  onChange={(event) => setAgreedTerms(event.target.checked)}
                />
                <span>I Agree To RahaInsure’s Terms & Conditions</span>
              </div>
            </div>
            <div
              className={classNames.bottomBanner}
              style={{
                width: width < 800 ? "100%" : "",
                padding: width < 800 ? "1rem" : "",
                borderRadius: width < 800 ? "0" : "",
              }}
            >
              <div className={classNames.priceDiv}>
                <div>
                  USD{" "}
                  {totalPremiumAmountDisplay
                    ? totalPremiumAmountDisplay
                    : "0.00"}
                  /-
                </div>
                <div>Insurance Premium (Including GST)</div>
              </div>
              <div className={classNames.buttonWrapper}>
                <div
                  className={classNames.submitBtn1}
                  style={{
                    pointerEvents:
                      enrollmentData?.member_type == "Self Only" &&
                      Object.values(enrollmentData).every((value) => !!value)
                        ? ""
                        : enrollmentData?.member_type == "Self + Spouse" &&
                          Object.values(enrollmentData).every(
                            (value) => !!value
                          ) &&
                          Object.values(dependentsProfile).every(
                            (value) => !!value
                          )
                        ? ""
                        : "none",
                    opacity:
                      enrollmentData?.member_type == "Self Only" &&
                      Object.values(enrollmentData).every((value) => !!value)
                        ? ""
                        : enrollmentData?.member_type == "Self + Spouse" &&
                          Object.values(enrollmentData).every(
                            (value) => !!value
                          ) &&
                          Object.values(dependentsProfile).every(
                            (value) => !!value
                          )
                        ? ""
                        : "0.5",
                  }}
                  onClick={() => {
                    if (!agreedTerms) {
                      predefinedToast("Please agree to the terms & condtions");
                    } else {
                      if (
                        userAge &&
                        dependentAge &&
                        userAge >= 36 &&
                        userAge <= 80 &&
                        dependentAge >= 36 &&
                        dependentAge <= 80
                      ) {
                        submitIOB();
                      } else if (
                        userAge &&
                        userAge >= 36 &&
                        userAge <= 80 &&
                        !dependentAge
                      ) {
                        submitIOB();
                      } else if (
                        dependentAge &&
                        dependentAge >= 36 &&
                        dependentAge <= 80 &&
                        !userAge
                      ) {
                        submitIOB();
                      } else {
                        predefinedToast(
                          "Members in form are not eligible under age criteria!"
                        );
                      }
                    }
                  }}
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EnrollmentForm;

const SelectTag = ({
  title,
  name,
  allItems,
  selectedValue,
  setSelectedValue,
  mandatory,
}) => {
  return (
    <div className={classNames.customRadio}>
      <div className={classNames.title}>
        {title} {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <div className={classNames.radioParent}>
        {allItems?.map((eachItem, index) => (
          <div key={eachItem + index} className={classNames.eachRadioBtn}>
            <input
              type="radio"
              id={eachItem}
              name={name}
              value={eachItem}
              checked={selectedValue[name] == eachItem}
              onChange={() => {
                setSelectedValue((prev) => {
                  return { ...prev, [name]: eachItem };
                });
                console.log(selectedValue, "selectedValue");
              }}
            />
            <label htmlFor={eachItem}>{eachItem}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomDropdown = ({
  title,
  subtitle,
  dropdown,
  name,
  stateValue,
  setState,
  mandatory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: isOpen ? "250px" : "", zIndex: isOpen ? "4" : "" }}
    >
      <div className={classNames.title}>
        {title} {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{ pointerEvents: dropdown ? "" : "none" }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOption
              ? selectedOption
              : title == "Employee" || title == "Spouse"
              ? "Select Coverage"
              : title == "Spouse" || title == "Self"
              ? "Select Coverage"
              : title
              ? "Select " + title
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {title === "All Countries"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState(eachitem);
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : name === "state" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                          // selectOption(eachitem?.state);
                          // setState(eachitem?.state);
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        {eachitem}
                      </li>
                    );
                  })}
          </ul>
        )}
      </div>
    </div>
  );
};

const EachInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  widthh,
  valuee,
  type,
  mandatory,
}) => {
  return (
    <div
      className={classNames.eachInput}
      style={{ pointerEvents: title == "Age" ? "none" : "" }}
    >
      <div className={classNames.title}>
        {title} {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={title == "Age" ? valuee : stateValue[name]}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
        style={{
          width: widthh ? "75px" : "",
          pointerEvents: placeholder == "+91" ? "none" : "",
          maxWidth: type == "talltree" ? "100%" : "",
        }}
      />
    </div>
  );
};

const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (
      (title?.toLowerCase()?.includes("birth") ||
        placeholder?.toLowerCase()?.includes("birth")) &&
      datepickerRef.current
    ) {
      const currentDate = new Date();

      const minDate = new Date(currentDate);
      const maxDate = new Date(currentDate);

      // Calculate minDate for a 80-year-old person
      minDate.setFullYear(currentDate.getFullYear() - 80);

      // Calculate maxDate for a 25-year-old person
      maxDate.setFullYear(currentDate.getFullYear() - 25);

      const options = {
        minDate: minDate,
        maxDate: maxDate,
        // maxDate:"01-01-1943",
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          setState({ ...stateValue, [name]: dateStr });
        },
      };

      flatpickr(datepickerRef.current, options);
    } else if (datepickerRef.current) {
      const options = {
        minDate: "01-01-1943",
        // maxDate:"01-01-1943",
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          setState({ ...stateValue, [name]: dateStr });
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, [datepickerRef, name, stateValue, setState]);

  return (
    <div className={classNames.eachInputDatePicker}>
      <div className={classNames.title}>
        {title} {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={stateValue[name]}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event.target.value });
        }}
      />
      <img
        src={calenderIcon}
        alt="calenderIcon"
        className={classNames.calendarIcon}
      />
    </div>
  );
};
