import { ReactComponent as BagIcon } from "../../images/associates/crm/bag.svg";
import { ReactComponent as MoneyIcon } from "../../images/associates/crm/money.svg";
import { ReactComponent as PurseIcon } from "../../images/associates/crm/purse.svg";
import { ReactComponent as ComputerIcon } from "../../images/associates/crm/computer.svg";

export const crmMainScore = [
  {
    title: "Total Premium",
    icon: <BagIcon />,
    score: "$108,560.93",
    growth: true,
  },
  {
    title: "Conversion Rate",
    icon: <MoneyIcon />,
    score: "86.52%",
    growth: true,
  },
  {
    title: "Booked Revenue",
    icon: <PurseIcon />,
    score: "$108,560.93",
    growth: true,
  },
  {
    title: "Wellness Sales",
    icon: <ComputerIcon />,
    score: "$108,560.93",
    growth: false,
  },
];

export const crmAffiliateMainScore = [
  {
    title: "Total Premium",
    icon: <BagIcon />,
    score: "$108,560.93",
    growth: true,
  },
  {
    title: "Affiliate Commissions",
    icon: <MoneyIcon />,
    score: "$108,560.93",
    growth: true,
  },
  {
    title: "Booked Revenue",
    icon: <PurseIcon />,
    score: "$108,560.93",
    growth: true,
  },
  {
    title: "Wellness Sales",
    icon: <ComputerIcon />,
    score: "$108,560.93",
    growth: false,
  },
];
