import React, { useState } from "react";
import classNames from "./dropdownui.module.scss";

import eyeIcon from "../../../../assest/images/allicons/tableaction/eye.svg";
import qrcIcon from "../../../../assest/images/allicons/tableaction/qrc.svg";
import keyIcon from "../../../../assest/images/allicons/tableaction/key1.svg";
import uploadIcon from "../../../../assest/images/allicons/tableaction/upload.svg";
import editIcon from "../../../../assest/images/allicons/tableaction/edit.svg";
import downloadIcon from "../../../../assest/images/allicons/tableaction/download.svg";
import emailIcon from "../../../../assest/images/allicons/tableaction/email.svg";
import peoples from "../../../../assest/images/allicons/tableaction/peoples.svg";
import lockIcon from "../../../../assest/images/allicons/tableaction/lock.svg";
import dependentsIcon from "../../../../assest/images/allicons/tableaction/dependents.svg";
import addIcon from "../../../../assest/images/allicons/tableaction/add.svg";
import arrowRight from "../../../../assest/images/allicons/tableaction/arrowRight.svg";

const DropdownUi = ({
  selectedLead,
  setSelectedQuote,
  setSelectedLead,
  setSelectedMenu,
  setShowUploadQRC,
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedMainMenu, setSelectedMainMenu] = useState(null);
  const dropItems = [
    {
      icon: eyeIcon,
      text: "See Quotes",
      // subMenu: [
      //   {
      //     text: "See Quotes",
      //   },
      //   {
      //     text: "See Profile Details",
      //   },
      // ],
    },
    // {
    //   icon: editIcon,
    //   text: "Edit Client Details",
    // },
    // {
    //   icon: peoples,
    //   text: "Initiate Pre-Enrollment",
    // },
    {
      icon: qrcIcon,
      text: "Upload QCR",
    },
    // {
    //   icon: uploadIcon,
    //   text: "Upload policy",
    // },
    {
      icon: keyIcon,
      text: "Reset Password",
    },
  ];

  return (
    <>
      <div className={classNames.dropdown}>
        {dropItems.map((item) => (
          <div
            className={classNames.dropItem}
            onMouseEnter={(e) => {
              if (item.subMenu) {
                setSelectedMainMenu(item);
                setShowSubMenu(true);
              } else {
                setShowSubMenu(false);
              }
            }}
            onClick={(e) => {
              if (item.text === "See Quotes") {
                setSelectedQuote(selectedLead);
                setSelectedLead(null);
                setSelectedMenu(item.text);
              } else if (item.text === "Upload QCR") {
                setShowUploadQRC(selectedLead);
                setSelectedLead(null);
                setSelectedMenu(item.text);
              }
            }}
          >
            <img
              src={item?.icon}
              alt=""
              style={{ width: "14px", height: "14px" }}
            />
            <div className={classNames.dropText}>{item?.text}</div>
            {item.subMenu && (
              <img
                src={arrowRight}
                alt=""
                style={{ position: "absolute", right: 30 }}
              />
            )}
          </div>
        ))}
      </div>
      {showSubMenu && (
        <div className={classNames.dropdown1}>
          {selectedMainMenu?.subMenu?.map((item1) => (
            <div className={classNames.dropItem}>
              <div className={classNames.dropText1}>{item1?.text}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DropdownUi;
