import React, { useEffect, useState } from "react";
import styles from "./clientDetails.module.scss";
import Axios from "axios";
import { BsArrowBarDown, BsArrowDown, BsCheck } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa6";

import { ReactComponent as CopyIcon } from "../../../../assest/images/allicons/tableaction/copy.svg";
import { MdDelete, MdEdit } from "react-icons/md";

const ClientDetails = ({ selectedEmployee }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [selectedTab, setSelectedTab] = useState({
    tabName: "Lead Details",
    enabled: true,
  });
  const [copyLoading, setCopyLoading] = useState(null);
  const tabs = [
    {
      tabName: "Lead Details",
      enabled: true,
    },
    {
      tabName: "User Details",
      enabled: true,
    },
    {
      tabName: "Employee",
      enabled: true,
    },
    {
      tabName: "Wellness Wallet",
      enabled: true,
    },
    {
      tabName: "Wellness Cart",
      enabled: true,
    },
    {
      tabName: "Insurance Dependents",
      enabled: true,
    },
    {
      tabName: "Policies",
      enabled: true,
    },
    {
      tabName: "Wellness Dependents",
      enabled: true,
    },
  ];

  const [showTxnIds, setShowTxnIds] = useState(false);
  const [selectedTnx, setSelectedTnx] = useState(null);

  useEffect(() => {
    if (copyLoading) {
      setTimeout(() => {
        setCopyLoading(null);
      }, 1000);
    }
  }, [copyLoading]);

  useEffect(() => {
    if (selectedEmployee) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${selectedEmployee?.user_company_email}`
      ).then(({ data }) => {
        if (data.status) {
          setUserInfo(data.data);
        }
      });
    }
  }, [selectedEmployee]);

  const [dependents, setDependents] = useState([]);

  const getDependents = () => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${selectedEmployee?.user_company_email}`
    ).then(({ data }) => {
      if (data.status) {
        setDependents(data.data);
      }
    });
  };
  const [policies, setPolicies] = useState([]);
  const getPolicies = () => {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=${selectedEmployee?.user_company_email}`
      // `https://coverfi.apimachine.com/api/v1/client-user/employee/?employee_email_id=fanawe7659@trackden.com`
    ).then(({ data }) => {
      if (data.status) {
        console.log(data.data[0].policies, "jwqdjkhqkdwdqd");
        setPolicies(data.data[0].policies);
        setSelectedPolicyTab(data.data[0].policies[0]);
      }
    });
  };

  useEffect(() => {
    if (selectedTab.tabName === "Insurance Dependents") {
      getDependents();
    } else if (selectedTab.tabName === "Policies") {
      getPolicies();
    }
  }, [selectedTab]);

  const [selectedPolicyTab, setSelectedPolicyTab] = useState(null);

  const conditionalTabData = () => {
    if (selectedTab.tabName === "Lead Details") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?._id}</div>
                {copyLoading === userInfo?.leadDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?._id);
                      navigator.clipboard.writeText(userInfo?.leadDetails?._id);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>First name</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.first_name}</div>
                {copyLoading === userInfo?.leadDetails?.first_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.first_name);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.first_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.last_name !== null
                    ? userInfo?.leadDetails?.last_name
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.last_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.last_name);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.last_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Reference email</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.reference_email}</div>
                {copyLoading === userInfo?.leadDetails?.reference_email ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.reference_email);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.reference_email
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Company Employee ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.user_employee_id
                    ? userInfo?.leadDetails?.user_employee_id
                    : "null"}
                </div>
                {copyLoading ===
                userInfo?.leadDetails?.userInfo?.leadDetails
                  ?.user_employee_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.leadDetails?.userInfo?.leadDetails
                          ?.user_employee_id
                      );
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.userInfo?.leadDetails
                          ?.user_employee_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Email</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.lead_email
                    ? userInfo?.leadDetails?.lead_email
                    : "null"}
                </div>
                {copyLoading ===
                userInfo?.leadDetails?.userInfo?.leadDetails?.lead_email ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.leadDetails?.userInfo?.leadDetails?.lead_email
                      );
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.userInfo?.leadDetails?.lead_email
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Phone number</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.phone_number !== null
                    ? userInfo?.leadDetails?.phone_number
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.phone_number ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.phone_number);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.phone_number
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Gender</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.gender !== null
                    ? userInfo?.leadDetails?.gender
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.gender ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.gender);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.gender
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Address</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.address !== null
                    ? userInfo?.leadDetails?.address
                    : "null"}
                </div>
                {userInfo?.leadDetails?.address !== null &&
                copyLoading === userInfo?.leadDetails?.address ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.address);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.address
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Lead Source</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.lead_source !== null
                    ? userInfo?.leadDetails?.lead_source
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.lead_source ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.lead_source);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.lead_source
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Lead Type</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.lead_type !== null
                    ? userInfo?.leadDetails?.lead_type
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.lead_type ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.lead_type);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.lead_type
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Added by</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.addedBy !== null
                    ? userInfo?.leadDetails?.addedBy
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.addedBy ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.addedBy);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.addedBy
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>HR ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.hr_id !== null
                    ? userInfo?.leadDetails?.hr_id
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.hr_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.hr_id);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.hr_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>External lead</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.external_lead !== null
                    ? userInfo?.leadDetails?.external_lead
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.external_lead ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.external_lead);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.external_lead
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Lead ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.lead_id !== null
                    ? userInfo?.leadDetails?.lead_id
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.lead_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.lead_id);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.lead_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Date of birth</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.date_of_birth !== null
                    ? userInfo?.leadDetails?.date_of_birth
                    : "null"}
                </div>
                {copyLoading === userInfo?.leadDetails?.date_of_birth ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.date_of_birth);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.date_of_birth
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>City</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.city !== null
                    ? userInfo?.leadDetails?.city
                    : "null"}
                </div>
                {userInfo?.leadDetails?.city !== null &&
                copyLoading === userInfo?.leadDetails?.city ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.city);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.city
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>State</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.state !== null
                    ? userInfo?.leadDetails?.state
                    : "null"}
                </div>
                {userInfo?.leadDetails?.state !== null &&
                copyLoading === userInfo?.leadDetails?.state ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.state);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.state
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Country</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.country !== null
                    ? userInfo?.leadDetails?.country
                    : "null"}
                </div>
                {userInfo?.leadDetails?.country !== null &&
                copyLoading === userInfo?.leadDetails?.country ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.country);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.country
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Pin code</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.pin_code !== null
                    ? userInfo?.leadDetails?.pin_code
                    : "null"}
                </div>
                {userInfo?.leadDetails?.pin_code !== null &&
                copyLoading === userInfo?.leadDetails?.pin_code ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.pin_code);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.pin_code
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Martial status</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.martial_status !== null
                    ? userInfo?.leadDetails?.martial_status
                    : "null"}
                </div>
                {userInfo?.leadDetails?.martial_status !== null &&
                copyLoading === userInfo?.leadDetails?.martial_status ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.martial_status);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.martial_status
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Follow up status</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.follow_up_status !== null
                    ? userInfo?.leadDetails?.follow_up_status
                    : "null"}
                </div>
                {userInfo?.leadDetails?.follow_up_status !== null &&
                copyLoading === userInfo?.leadDetails?.follow_up_status ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.follow_up_status);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.follow_up_status
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Pin code</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.pin_code !== null
                    ? userInfo?.leadDetails?.pin_code
                    : "null"}
                </div>
                {userInfo?.leadDetails?.pin_code !== null &&
                copyLoading === userInfo?.leadDetails?.pin_code ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.pin_code);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.pin_code
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Martial status</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.martial_status !== null
                    ? userInfo?.leadDetails?.martial_status
                    : "null"}
                </div>
                {userInfo?.leadDetails?.martial_status !== null &&
                copyLoading === userInfo?.leadDetails?.martial_status ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.martial_status);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.martial_status
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Icon</div>
              <div
                className={styles.userDetailValue}
                style={{ width: "104px", height: "104px" }}
              >
                <img src={userInfo?.leadDetails?.profile_picture} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "User Details") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.userDetails?._id}</div>
                {copyLoading === userInfo?.userDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?._id);
                      navigator.clipboard.writeText(userInfo?.userDetails?._id);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>User ID</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.userDetails?.user_id}</div>
                {copyLoading === userInfo?.userDetails?.user_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.user_id);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.user_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>First name</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.userDetails?.first_name}</div>
                {copyLoading === userInfo?.userDetails?.first_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.first_name);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.first_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.last_name !== null
                    ? userInfo?.userDetails?.last_name
                    : "null"}
                </div>
                {userInfo?.userDetails?.last_name !== null &&
                copyLoading === userInfo?.userDetails?.last_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.last_name);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.last_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}># of parents</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.no_of_parents !== null
                    ? userInfo?.userDetails?.no_of_parents
                    : "null"}
                </div>
                {userInfo?.userDetails?.no_of_parents !== null &&
                copyLoading === userInfo?.userDetails?.no_of_parents ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.no_of_parents);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.no_of_parents
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}># of children</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.no_of_children !== null
                    ? userInfo?.userDetails?.no_of_children
                    : "null"}
                </div>
                {userInfo?.userDetails?.no_of_children !== null &&
                copyLoading === userInfo?.userDetails?.no_of_children ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.no_of_children);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.no_of_children
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}># of parents in law</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.no_of_parents_in_law !== null
                    ? userInfo?.userDetails?.no_of_parents_in_law
                    : "null"}
                </div>
                {userInfo?.userDetails?.no_of_parents_in_law !== null &&
                copyLoading === userInfo?.userDetails?.no_of_parents_in_law ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.userDetails?.no_of_parents_in_law
                      );
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.no_of_parents_in_law
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Primary phone number</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.primary_phone_number !== null
                    ? userInfo?.userDetails?.primary_phone_number
                    : "null"}
                </div>
                {userInfo?.userDetails?.primary_phone_number !== null &&
                copyLoading === userInfo?.userDetails?.primary_phone_number ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.userDetails?.primary_phone_number
                      );
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.primary_phone_number
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Address</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.address !== null
                    ? userInfo?.userDetails?.address
                    : "null"}
                </div>
                {userInfo?.userDetails?.address !== null &&
                copyLoading === userInfo?.userDetails?.address ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.address);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.address
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Date of birth</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.date_of_birth !== null
                    ? userInfo?.userDetails?.date_of_birth
                    : "null"}
                </div>
                {userInfo?.userDetails?.date_of_birth !== null &&
                copyLoading === userInfo?.userDetails?.date_of_birth ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.date_of_birth);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.date_of_birth
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>City</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.city !== null
                    ? userInfo?.userDetails?.city
                    : "null"}
                </div>
                {userInfo?.userDetails?.city !== null &&
                copyLoading === userInfo?.userDetails?.city ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.city);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.city
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Country</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.country !== null
                    ? userInfo?.userDetails?.country
                    : "null"}
                </div>
                {userInfo?.userDetails?.country !== null &&
                copyLoading === userInfo?.userDetails?.country ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.country);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.country
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Gender</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.gender !== null
                    ? userInfo?.userDetails?.gender
                    : "null"}
                </div>
                {userInfo?.userDetails?.gender !== null &&
                copyLoading === userInfo?.userDetails?.gender ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.gender);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.gender
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>State</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.state !== null
                    ? userInfo?.userDetails?.state
                    : "null"}
                </div>
                {userInfo?.userDetails?.state !== null &&
                copyLoading === userInfo?.userDetails?.state ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.state);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.state
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Pincode</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.pin_code !== null
                    ? userInfo?.userDetails?.pin_code
                    : "null"}
                </div>
                {userInfo?.userDetails?.pin_code !== null &&
                copyLoading === userInfo?.userDetails?.pin_code ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.pin_code);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.pin_code
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Martial status</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.martial_status !== null
                    ? userInfo?.userDetails?.martial_status
                    : "null"}
                </div>
                {userInfo?.userDetails?.martial_status !== null &&
                copyLoading === userInfo?.userDetails?.martial_status ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.martial_status);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.martial_status
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Designation</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.designation !== null
                    ? userInfo?.userDetails?.designation
                    : "null"}
                </div>
                {userInfo?.userDetails?.designation !== null &&
                copyLoading === userInfo?.userDetails?.designation ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.designation);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.designation
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Profile picture</div>
              <div
                className={styles.userDetailValue}
                style={{ width: "104px", height: "104px" }}
              >
                <img src={userInfo?.userDetails?.profile_picture} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "Employee") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?._id !== null
                    ? userInfo?.employeeDetails[0]?._id
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?._id !== null &&
                copyLoading === userInfo?.employeeDetails[0]?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?._id);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?._id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Employee ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.employee_id !== null
                    ? userInfo?.employeeDetails[0]?.employee_id
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.employee_id !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.employee_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.employee_id);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.employee_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.name !== null
                    ? userInfo?.employeeDetails[0]?.name
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.name !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.name);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Email</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.user_company_email !== null
                    ? userInfo?.employeeDetails[0]?.user_company_email
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.user_company_email !== null &&
                copyLoading ===
                  userInfo?.employeeDetails[0]?.user_company_email ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.user_company_email
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.user_company_email
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Company Employee ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.user_employee_id !== null
                    ? userInfo?.employeeDetails[0]?.user_employee_id
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.user_employee_id !== null &&
                copyLoading ===
                  userInfo?.employeeDetails[0]?.user_employee_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.user_employee_id
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.user_employee_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Company ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.company_id !== null
                    ? userInfo?.employeeDetails[0]?.company_id
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.company_id !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.company_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.company_id);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.company_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>User ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.user_id !== null
                    ? userInfo?.employeeDetails[0]?.user_id
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.user_id !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.user_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.user_id);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.user_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Work location</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.work_location !== null
                    ? userInfo?.employeeDetails[0]?.work_location
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.work_location !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.work_location ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.work_location
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.work_location
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Role</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.role !== null
                    ? userInfo?.employeeDetails[0]?.role
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.role !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.role ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.role);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.role
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>CTC</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.CTC !== null
                    ? userInfo?.employeeDetails[0]?.CTC
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.CTC !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.CTC ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.CTC);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.CTC
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Phone number</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.phone_number !== null
                    ? userInfo?.employeeDetails[0]?.phone_number
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.phone_number !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.phone_number ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.phone_number
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.phone_number
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Date of birth</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.date_of_birth !== null
                    ? userInfo?.employeeDetails[0]?.date_of_birth
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.date_of_birth !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.date_of_birth ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.date_of_birth
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.date_of_birth
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Date of joining</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.date_of_joining !== null
                    ? userInfo?.employeeDetails[0]?.date_of_joining
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.date_of_joining !== null &&
                copyLoading ===
                  userInfo?.employeeDetails[0]?.date_of_joining ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.date_of_joining
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.date_of_joining
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Date of leaving</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.date_of_leaving !== null
                    ? userInfo?.employeeDetails[0]?.date_of_leaving
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.date_of_leaving !== null &&
                copyLoading ===
                  userInfo?.employeeDetails[0]?.date_of_leaving ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.date_of_leaving
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.date_of_leaving
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Job type</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.job_type !== null
                    ? userInfo?.employeeDetails[0]?.job_type
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.job_type !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.job_type ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.job_type);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.job_type
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Working status</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.working_status !== null
                    ? userInfo?.employeeDetails[0]?.working_status
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.working_status !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.working_status ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.employeeDetails[0]?.working_status
                      );
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.working_status
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>HR ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.employeeDetails[0]?.hr_id !== null
                    ? userInfo?.employeeDetails[0]?.hr_id
                    : "null"}
                </div>
                {userInfo?.employeeDetails[0]?.hr_id !== null &&
                copyLoading === userInfo?.employeeDetails[0]?.hr_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.employeeDetails[0]?.hr_id);
                      navigator.clipboard.writeText(
                        userInfo?.employeeDetails[0]?.hr_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "Wellness Wallet") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?._id !== null
                    ? userInfo?.companyWalletDetails?._id
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?._id !== null &&
                copyLoading === userInfo?.companyWalletDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyWalletDetails?._id);
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?._id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Wallet Type</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.type_of_wallet !== null
                    ? userInfo?.companyWalletDetails?.type_of_wallet
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.type_of_wallet !== null &&
                copyLoading ===
                  userInfo?.companyWalletDetails?.type_of_wallet ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.companyWalletDetails?.type_of_wallet
                      );
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.type_of_wallet
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Balance</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.balance !== null
                    ? userInfo?.companyWalletDetails?.balance
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.balance !== null &&
                copyLoading === userInfo?.companyWalletDetails?.balance ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyWalletDetails?.balance);
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.balance
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Active</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.is_active !== null
                    ? userInfo?.companyWalletDetails?.is_active
                      ? "True"
                      : "False"
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.is_active !== null &&
                copyLoading === userInfo?.companyWalletDetails?.is_active ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyWalletDetails?.is_active);
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.is_active
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Created on</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.created_at !== null
                    ? userInfo?.companyWalletDetails?.created_at
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.created_at !== null &&
                copyLoading === userInfo?.companyWalletDetails?.created_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.companyWalletDetails?.created_at
                      );
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.created_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last updated</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.updated_at !== null
                    ? userInfo?.companyWalletDetails?.updated_at
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.updated_at !== null &&
                copyLoading === userInfo?.companyWalletDetails?.updated_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.companyWalletDetails?.updated_at
                      );
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.updated_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Transaction ID’s</div>
              {userInfo?.companyWalletDetails?.transaction_id?.length > 0 ? (
                showTxnIds ? (
                  <div
                    className={styles.userDetailValue}
                    style={{
                      height: "100px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "scroll",
                    }}
                  >
                    {userInfo?.companyWalletDetails?.transaction_id.map(
                      (item) => (
                        <div
                          onClick={(e) => {
                            setShowTxnIds(!showTxnIds);
                            setSelectedTnx(item);
                          }}
                        >
                          {item}
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div
                    className={styles.userDetailValue}
                    onClick={(e) => setShowTxnIds(!showTxnIds)}
                  >
                    {selectedTnx ? selectedTnx : "Click to select"}
                    <FaChevronDown />
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "Wellness Cart") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?._id !== null
                    ? userInfo?.companyCartDetails?._id
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?._id !== null &&
                copyLoading === userInfo?.companyCartDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?._id);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?._id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Address ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.address_id !== null
                    ? userInfo?.companyCartDetails?.address_id
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.address_id !== null &&
                copyLoading === userInfo?.companyCartDetails?.address_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.address_id);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.address_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Is Active</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.is_active !== null
                    ? userInfo?.companyCartDetails?.is_active
                      ? "True"
                      : "False"
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.is_active !== null &&
                copyLoading === userInfo?.companyCartDetails?.is_active ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.is_active);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.is_active
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Created on </div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.created_at !== null
                    ? userInfo?.companyCartDetails?.created_at
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.created_at !== null &&
                copyLoading === userInfo?.companyCartDetails?.created_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.created_at);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.created_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last updated</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.updated_at !== null
                    ? userInfo?.companyCartDetails?.updated_at
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.updated_at !== null &&
                copyLoading === userInfo?.companyCartDetails?.updated_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.updated_at);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.updated_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "Insurance Dependents") {
      return (
        <div>
          <div className={styles.tableHeader}>
            <div></div>
            <div>Dependent Name</div>
            <div>Relation</div>
            <div>Date of birth</div>
            <div>Gender</div>
            <div></div>
          </div>
          <div style={{ height: "20vh", overflowY: "scroll" }}>
            {dependents.length > 0 ? (
              dependents?.map((item) => (
                <div className={styles.tableRow}>
                  <div>
                    <MdEdit />
                  </div>
                  <div>
                    {item?.dependent_name !== null
                      ? item?.dependent_name
                      : "null"}
                  </div>
                  <div>{item?.relation !== null ? item?.relation : "null"}</div>
                  <div>
                    {item?.date_of_birth !== null
                      ? item?.date_of_birth
                      : "null"}
                  </div>
                  <div>{item?.gender !== null ? item?.gender : "null"}</div>
                  <div>
                    <MdDelete />
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "10vh",
                }}
              >
                No Dependents
              </div>
            )}
            {/* {Array(15)
              .fill("")
              ?.map((item) => (
                <div className={styles.tableRow}>
                  <div>
                    <MdEdit />
                  </div>
                  <div>Name</div>
                  <div>Son</div>
                  <div>20/06/90</div>
                  <div>Male</div>
                  <div>
                    <MdDelete />
                  </div>
                </div>
              ))} */}
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "Policies") {
      return (
        <>
          <div className={styles.tabWrapper}>
            {policies?.map((item, index) => {
              return (
                <div
                  className={
                    selectedPolicyTab?._id === item?._id
                      ? styles.selectedTabItem
                      : styles.tabItem
                  }
                  onClick={(e) => {
                    setSelectedPolicyTab(item);
                  }}
                >
                  Policy {index + 1}
                </div>
              );
            })}
          </div>
          <div>
            <div className={styles.rowStyle}>
              <div>
                <div className={styles.userDetailTitle}>_id</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?._id !== null
                      ? selectedPolicyTab?._id
                      : "null"}
                  </div>
                  {selectedPolicyTab?._id !== null &&
                  copyLoading === selectedPolicyTab?._id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?._id);
                        navigator.clipboard.writeText(selectedPolicyTab?._id);
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Company employee ID
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.company_employee_id !== null
                      ? selectedPolicyTab?.company_employee_id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.company_employee_id !== null &&
                  copyLoading === selectedPolicyTab?.company_employee_id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.company_employee_id);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.company_employee_id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Employee ID</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.employee_id !== null
                      ? selectedPolicyTab?.employee_id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.employee_id !== null &&
                  copyLoading === selectedPolicyTab?.employee_id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.employee_id);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.employee_id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Policy _id</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?._id !== null
                      ? selectedPolicyTab?.policy_id?._id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?._id !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?._id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.policy_id?._id);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?._id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>HR ID</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.hr_id !== null
                      ? selectedPolicyTab?.policy_id?.hr_id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.hr_id !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.hr_id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.policy_id?.hr_id);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.hr_id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>CD Balance ID</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.cdBalance_id !== null
                      ? selectedPolicyTab?.policy_id?.cdBalance_id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.cdBalance_id !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.cdBalance_id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.cdBalance_id
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.cdBalance_id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Quote ID</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.quote_id !== null
                      ? selectedPolicyTab?.policy_id?.quote_id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.quote_id !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.quote_id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.policy_id?.quote_id);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.quote_id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Policy Number</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.policy_number !== null
                      ? selectedPolicyTab?.policy_id?.policy_number
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.policy_number !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.policy_number ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.policy_number
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.policy_number
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Policy type</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.policy_type !== null
                      ? selectedPolicyTab?.policy_id?.policy_type
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.policy_type !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.policy_type ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.policy_type
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.policy_type
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Policy pdf link</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.policy_pdf_link !== null
                      ? selectedPolicyTab?.policy_id?.policy_pdf_link?.substring(
                          0,
                          20
                        ) + "..."
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.policy_pdf_link !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.policy_pdf_link ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.policy_pdf_link
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.policy_pdf_link
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Premium amount</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.premium_amount !== null
                      ? selectedPolicyTab?.policy_id?.premium_amount
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.premium_amount !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.premium_amount ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.premium_amount
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.premium_amount
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Policy variant</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.policy_variant !== null
                      ? selectedPolicyTab?.policy_id?.policy_variant
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.policy_variant !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.policy_variant ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.policy_variant
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.policy_variant
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              {selectedPolicyTab?.policy_id?.sum_insured?.map((item, index) => (
                <div>
                  <div className={styles.userDetailTitle}>
                    Sum Insured{" "}
                    {selectedPolicyTab?.policy_id?.policy_variant === "Graded"
                      ? `Policy Grade ${index + 1}`
                      : ""}
                  </div>
                  <div className={styles.userDetailValue}>
                    <div>{item}</div>
                    {item !== null && copyLoading === item ? (
                      <BsCheck />
                    ) : (
                      <CopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setCopyLoading(item);
                          navigator.clipboard.writeText(item);
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
              <div>
                <div className={styles.userDetailTitle}>Family definition</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.covered_members !== null
                      ? selectedPolicyTab?.policy_id?.covered_members
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.covered_members !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.covered_members ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.covered_members
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.covered_members
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Start date</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.start_date !== null
                      ? selectedPolicyTab?.policy_id?.start_date
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.start_date !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.start_date ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.start_date
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.start_date
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>End date</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.end_date !== null
                      ? selectedPolicyTab?.policy_id?.end_date
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.end_date !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.end_date ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.policy_id?.end_date);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.end_date
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Rater card</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.rate_card !== null
                      ? selectedPolicyTab?.policy_id?.rate_card
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.rate_card !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.rate_card ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.policy_id?.rate_card);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.rate_card
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Rater cart amount</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.rate_card_amount !== null
                      ? selectedPolicyTab?.policy_id?.rate_card_amount
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.rate_card_amount !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.rate_card_amount ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.rate_card_amount
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.rate_card_amount
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Initial employees</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.total_employees !== null
                      ? selectedPolicyTab?.policy_id?.total_employees
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.total_employees !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.total_employees ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.total_employees
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.total_employees
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Initial dependents</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.total_dependants !== null
                      ? selectedPolicyTab?.policy_id?.total_dependants
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.total_dependants !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.total_dependants ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.total_dependants
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.total_dependants
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Active employees</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.active_employees !== null
                      ? selectedPolicyTab?.policy_id?.active_employees
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.active_employees !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.active_employees ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.active_employees
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.active_employees
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Active dependents</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.active_dependants !== null
                      ? selectedPolicyTab?.policy_id?.active_dependants
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.active_dependants !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.active_dependants ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.active_dependants
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.active_dependants
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Endorsements (employees add)
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.employees_joined !== null
                      ? selectedPolicyTab?.policy_id?.employees_joined
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.employees_joined !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.employees_joined ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.employees_joined
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.employees_joined
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Endorsements (employees remove)
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.employees_left !== null
                      ? selectedPolicyTab?.policy_id?.employees_left
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.employees_left !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.employees_left ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.employees_left
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.employees_left
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Endorsements (dependents add)
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.dependants_joined !== null
                      ? selectedPolicyTab?.policy_id?.dependants_joined
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.dependants_joined !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.dependants_joined ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.dependants_joined
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.dependants_joined
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Endorsements (dependents remove)
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.dependants_left !== null
                      ? selectedPolicyTab?.policy_id?.dependants_left
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.dependants_left !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.dependants_left ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.dependants_left
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.dependants_left
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>TPA name</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.tpa_id?.tpa_business_name !==
                    null
                      ? selectedPolicyTab?.policy_id?.tpa_id?.tpa_business_name
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.tpa_id?.tpa_business_name !==
                    null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.tpa_id?.tpa_business_name ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.tpa_id
                            ?.tpa_business_name
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.tpa_id
                            ?.tpa_business_name
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>TPA id</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.tpa_id?._id !== null
                      ? selectedPolicyTab?.policy_id?.tpa_id?._id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.tpa_id?._id !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.tpa_id?._id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.tpa_id?._id
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.tpa_id?._id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Insurance company name
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.insurance_company_id
                      ?.insurance_company_display_name !== null
                      ? selectedPolicyTab?.policy_id?.insurance_company_id
                          ?.insurance_company_display_name
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.insurance_company_id
                    ?.insurance_company_display_name !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.insurance_company_id
                      ?.insurance_company_display_name ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.insurance_company_id
                            ?.insurance_company_display_name
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.insurance_company_id
                            ?.insurance_company_display_name
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Insurance company id
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.insurance_company_id?._id !==
                    null
                      ? selectedPolicyTab?.policy_id?.insurance_company_id?._id
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.insurance_company_id?._id !==
                    null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.insurance_company_id?._id ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.insurance_company_id
                            ?._id
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.insurance_company_id
                            ?._id
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Brokerage commissions
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.broker_commission !== null
                      ? selectedPolicyTab?.policy_id?.broker_commission
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.broker_commission !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.broker_commission ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.broker_commission
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.broker_commission
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Affiliate commissions
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.affiliate_commission !== null
                      ? selectedPolicyTab?.policy_id?.affiliate_commission
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.affiliate_commission !==
                    null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.affiliate_commission ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.affiliate_commission
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.affiliate_commission
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Preliminary claim status
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.preliminary_claim_status !==
                    null
                      ? selectedPolicyTab?.policy_id?.preliminary_claim_status
                        ? "True"
                        : "False"
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.preliminary_claim_status !==
                    null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.preliminary_claim_status ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.preliminary_claim_status
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.preliminary_claim_status
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>
                  Claim continuity status
                </div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.claim_continuity_status !==
                    null
                      ? selectedPolicyTab?.policy_id?.claim_continuity_status
                        ? "True"
                        : "False"
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.claim_continuity_status !==
                    null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.claim_continuity_status ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.claim_continuity_status
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.claim_continuity_status
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Is active</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.is_active !== null
                      ? selectedPolicyTab?.policy_id?.is_active
                        ? "True"
                        : "False"
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.is_active !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.is_active ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(selectedPolicyTab?.policy_id?.is_active);
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.is_active
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Policy added date</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.policy_added_date !== null
                      ? selectedPolicyTab?.policy_id?.policy_added_date
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.policy_added_date !== null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.policy_added_date ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.policy_added_date
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.policy_added_date
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Created at</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.created_at !== null
                      ? selectedPolicyTab?.policy_id?.created_at
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.created_at !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.created_at ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.created_at
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.created_at
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Updated at</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.updated_at !== null
                      ? selectedPolicyTab?.policy_id?.updated_at
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.updated_at !== null &&
                  copyLoading === selectedPolicyTab?.policy_id?.updated_at ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id?.updated_at
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id?.updated_at
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.userDetailTitle}>Updated at</div>
                <div className={styles.userDetailValue}>
                  <div>
                    {selectedPolicyTab?.policy_id?.policy_endorsement_status !==
                    null
                      ? selectedPolicyTab?.policy_id?.policy_endorsement_status
                        ? "True"
                        : "False"
                      : "null"}
                  </div>
                  {selectedPolicyTab?.policy_id?.policy_endorsement_status !==
                    null &&
                  copyLoading ===
                    selectedPolicyTab?.policy_id?.policy_endorsement_status ? (
                    <BsCheck />
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCopyLoading(
                          selectedPolicyTab?.policy_id
                            ?.policy_endorsement_status
                        );
                        navigator.clipboard.writeText(
                          selectedPolicyTab?.policy_id
                            ?.policy_endorsement_status
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <div className={styles.tabWrapper}>
        {tabs.map((item) => (
          <div
            className={
              selectedTab.tabName === item.tabName
                ? styles.selectedTabItem
                : styles.tabItem
            }
            style={{ opacity: item.enabled ? 1 : 0.4 }}
            onClick={(e) => {
              if (item.enabled) {
                setSelectedTab(item);
              }
            }}
          >
            {item?.tabName}
          </div>
        ))}
      </div>
      {conditionalTabData()}
    </div>
  );
};

export default ClientDetails;
