import React from "react";
import classNames from "./wellness.module.scss";

//assets
import wellnessComingSoon from "../../assest/images/wellnesscomingsoon.png";

const WellnessComingSoon = () => {
  return (
    <div className={classNames.wellnessComingSoon}>
      <img src={wellnessComingSoon} alt="wellnessComingSoon" />
      <div className={classNames.title}>
        All Good things come to those who Wait...
      </div>
      <div className={classNames.desc}>
        Get notified when we launch when we launch new wellness program.{" "}
      </div>
    </div>
  );
};

export default WellnessComingSoon;
