import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./wallet.module.scss";
import { BankContext } from "../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Axios from "axios";

import Skeleton from "react-loading-skeleton";

import { Redirect } from "react-router-dom";

import moment from "moment";

import debitIcon from "../../assest/images/icons/debit.svg";
import creditIcon from "../../assest/images/icons/credit.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import NavbarV2 from "../../components/NavbarV2";
import CustomCheckbox, { CopyBtn } from "../../components/Custom";
import { ReactComponent as SortArrows } from "../../assest/images/allicons/sort.svg";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
  EachCustomTextArea,
} from "../../components/Custom/Input";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutToSameLogin } from "../../assest/functions";

const fetchTransactionBalanceAPI = (
  email,
  setVaultBalance,
  setVaultBalanceLoading
) => {
  setVaultBalanceLoading(true);
  Axios.post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
    app_code: "raha",
    email: email,
    include_coins: ["USD"],
  })
    .then((response) => {
      console.log(response, "vault balance response");
      if (response?.data?.status && response?.data?.coins_data?.length > 0) {
        setVaultBalance(response?.data?.coins_data[0]);
        setVaultBalanceLoading(false);
      }
    })
    .catch((error) => {
      console.log(error?.message, "vault balance error");
      setVaultBalanceLoading(false);
    });
};

const Wallet = () => {
  const history = useHistory();
  const {
    email,
    name,
    globalSelectedPolicy,
    actionBtns,
    setActionBtns,
    globalHrId,
  } = useContext(BankContext);
  const [selectedProfileNav, setSelectedProfileNav] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const [bondsPagination, setBondsPagination] = useState(0);
  const [bondsTxnsPerPage, setBondsTxnsPerPage] = useState(25);

  const [brokerageDropdownSelected, setBrokerageDropdownSelected] =
    useState("");
  const [countryDropdownSelected, setCountryDropdownSelected] = useState("");
  const [ownersDropdown, setOwnersDropdown] = useState("");
  const [countryDropdown, setCountryDropdown] = useState("");

  const [vaultBalance, setVaultBalance] = useState("");
  const [vaultBalanceLoading, setVaultBalanceLoading] = useState("");

  const [allWalletSearch, setAllWalletSearch] = useState("");

  useEffect(() => {
    fetchTransactionBalanceAPI(email, setVaultBalance, setVaultBalanceLoading);
  }, [email]);

  const [vaultHeaderBalanceLoading, setVaultHeaderBalanceLoading] =
    useState(false);
  const [cdBalanceDetails, setCdBalanceDetails] = useState([]);
  const [cdBalanceLive, setCdBalanceLive] = useState([]);
  const [cdTransactionsLive, setCdTransactionsLive] = useState([]);
  const [viewRaterCard, setViewRaterCard] = useState(false);
  const [totalCdBalance, setTotalCdBalance] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addTransactionForm, setAddTransactionForm] = useState("");
  const [raterCardSelected, setRaterCardSelected] = useState("");
  const [raterCardSelectedForm, setRaterCardSelectedForm] = useState("");
  const [allSelectedPolicyClaims, setAllSelectedPolicyClaims] = useState([]);
  const [allRaterCardDetails, setAllRaterCardDetails] = useState([]);
  const [allSelectedPolicyClaimsLoading, setAllSelectedPolicyClaimsLoading] =
    useState(false);

  async function getTotalCDBalance() {
    let response = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/cdBalance/hr/get?hr_id=${globalHrId}`
    );

    let res = response?.data?.data;

    if (res?.length > 0) {
      setTotalCdBalance(res[0]);
      getAllCDBalanceTransactions(res[0]?.cdBalance_id);
    }

    console.log(res, "get total CD balance response");
  }

  async function getAllCDBalanceTransactions(cdBalanceID) {
    let response = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/cdBalance/hr/get/transaction?cdBalance_id=${cdBalanceID}&skip=${
        (currentPage - 1) * 10
      }&limit=10`
    );

    let res = response?.data?.data;

    if (res?.length > 0) {
      setAllSelectedPolicyClaims(res);
    } else {
      setAllSelectedPolicyClaims([]);
    }

    console.log(res, "getAllCDBalanceTransactions response");
  }

  async function getRaterCardDetails() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}`
      );

      let res = response?.data?.data;

      if (res?.length > 0) {
        setAllRaterCardDetails(res);

        // if (res?.length === 1) {
        //   setRaterCardSelectedForm({ policy_number: res[0]?.policy_number });
        // }
      }

      console.log(res, "getRaterCardDetails response");
    } catch (error) {
      // Handle the error here, you can log it or perform any necessary actions
      console.error("Error in getRaterCardDetails:", error);
      setAllRaterCardDetails([]);
    }
  }

  useEffect(() => {
    setVaultHeaderBalanceLoading(true);
    setCdBalanceDetails([]);
    setCdBalanceLive([]);
    getBalance();
    getLiveBalancePolicy();
    getTransactionOnePolicy();
    setVaultHeaderBalanceLoading(false);

    //API definitions
    async function getBalance() {
      let response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/cdBalance/get?policy_number=${globalSelectedPolicy}`
      );

      let res = response?.data?.data;

      if (res?.length > 0) {
        setCdBalanceDetails(res[0]);
      }

      // console.log(res, "get CD balance response", globalSelectedPolicy);
    }

    async function getLiveBalancePolicy() {
      let response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/cdBalance/getLive?policy_number=${globalSelectedPolicy}`
      );

      let res = response?.data?.data;

      if (res?.length > 0) {
        setCdBalanceLive(res[0]);
      }

      // console.log(res, "get CD balance Live response", globalSelectedPolicy);
    }

    async function getTransactionOnePolicy() {
      setVaultBalanceLoading(true);
      let response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/cdBalance/get/transaction?policy_number=${globalSelectedPolicy}`
      );

      let res = response?.data?.data;

      setCdTransactionsLive(res);

      setVaultBalanceLoading(false);

      console.log(res, "cd transactions response", globalSelectedPolicy);
    }
  }, [globalSelectedPolicy]);

  useEffect(() => {
    getTotalCDBalance();

    getRaterCardDetails();
  }, [email, globalHrId]);

  useEffect(() => {
    if (allRaterCardDetails?.length > 0) {
      let policyNum = raterCardSelectedForm?.policy_number
        ? raterCardSelectedForm?.policy_number
        : allRaterCardDetails[0]?.policy_number;
      let filteredItem = allRaterCardDetails?.filter(
        (eachItem, index) => eachItem?.policy_number === policyNum
      );
      if (filteredItem?.length > 0) {
        setRaterCardSelected(filteredItem[0]);
      }
      console.log(filteredItem, "selected rater card");
    }
  }, [raterCardSelectedForm?.policy_number]);

  useEffect(() => {
    if (totalCdBalance?.cdBalance_id) {
      getAllCDBalanceTransactions(totalCdBalance?.cdBalance_id);
    }
  }, [currentPage]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.walletDetails}>
      <div className={classNames.overviewDetails}>
        <div className={classNames.eachDetails}>
          <div className={classNames.value}>
            $
            {totalCdBalance?.cd_balance
              ? Number(totalCdBalance?.cd_balance)?.toFixed(2)
              : "0.00"}
          </div>
          <div className={classNames.title}>Total CD Balance</div>
        </div>
      </div>
      <div className={classNames.allLeads}>
        <TableEmployeesSimplified
          allLeads={allSelectedPolicyClaims}
          allBrokeragesSearch={searchQuery}
          actionBtns={actionBtns}
          setActionBtns={setActionBtns}
          allLeadsLoading={allSelectedPolicyClaimsLoading}
        />
        <div className={classNames.paginationBar}>
          <div className={classNames.paginationMsg}>
            Showing{" "}
            {allSelectedPolicyClaims?.length > 0
              ? allSelectedPolicyClaims?.length
              : 0}{" "}
            entries
          </div>
          <div className={classNames.allPagination}>
            {currentPage == 1
              ? Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index);
                      }}
                    >
                      {currentPage + index}
                    </div>
                  );
                })
              : Array.from({ length: 4 })?.map((eachItem, index) => {
                  return (
                    <div
                      key={"pagebtn" + index + currentPage}
                      className={`${classNames.eachBtn} ${
                        currentPage + index - 1 == currentPage
                          ? classNames.eachBtnCurrent
                          : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(currentPage + index - 1);
                      }}
                    >
                      {currentPage + index - 1}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;

const TableEmployeesSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
  seeDetailsForm,
  setSeeDetailsForm,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "repeat(7, 1fr)" }}>
            {/* <th></th> */}
            <th>Date</th>
            <th>Transaction ID</th>
            <th>Particulars</th>
            <th>Cheque/EFT. No.</th>
            <th>Credit</th>
            <th>Debit</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "repeat(7, 1fr)" }}
            >
              {/* <td>
                <CustomCheckbox />
              </td> */}
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "repeat(7, 1fr)" }}
                >
                  <td>{row?.date ? row?.date : "-"}</td>
                  <td>{row?.transaction_id ? row?.transaction_id : "-"}</td>
                  <td>{row?.transacted_for ? row?.transacted_for : "-"}</td>
                  <td>
                    {row?.payment_reference_number
                      ? row?.payment_reference_number
                      : "-"}
                  </td>
                  <td>
                    {row?.credit?.toLowerCase() === "yes" && row?.amount
                      ? `$${Number(row?.amount)?.toFixed(2)}`
                      : "-"}
                  </td>
                  <td>
                    {row?.debit?.toLowerCase() === "yes" && row?.amount
                      ? `$${Number(row?.amount)?.toFixed(2)}`
                      : "-"}
                  </td>
                  <td>
                    $
                    {row?.updated_cd_balance
                      ? Number(row?.updated_cd_balance)?.toFixed(2)
                      : "-"}
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};
