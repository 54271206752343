import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./feedbackform.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import uploading from "../../../assest/images/icons/uploading.svg";
import dummyProfile from "../../../assest/images/icons/dummyProfile.svg";
import downloadIcon from "../../../assest/images/icons/download.svg";
import uploadIcon from "../../../assest/images/icons/upload.svg";
import { AppContext } from "../../../context/AppContext";

import { uploadImageFunc } from "../../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import { Redirect } from "react-router-dom";
import { event } from "react-ga";
import { logoutToSameLogin } from "../../../assest/functions";

const FeebackForm = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    addNewEmployeeType,
    setAddNewEmployeeType,
    globalUserId,
    globalHrId,
    setGlobalHrId,
    setUpdateLoading,
  } = useContext(BankContext);

  const [successMessage, setSuccessMessage] = useState("");

  const [leadSourceDropdown, setLeadSourceDropdown] = useState([
    "Just Dial",
    "Instagram",
    "Google",
    "Offline Ads",
    "Referral",
    "Outbound In Person",
  ]);
  const [usersDropdown, setUsersDropdown] = useState("");
  const [associateDropdown, setAssociateDropdown] = useState("");
  const [corporateLead, setCorporateLead] = useState({
    email: email,
    satisfaction: "",
    services_used: [],
    usability: "",
    improvement: "",
    excel: "",
    recommend: "",
    additional_feedback: "",
  });
  const [selectBrokerage, setSelectBrokerage] = useState("");
  const [uploadFileSelected, setUploadFileSelected] = useState("");
  const [uploadFileSelectedName, setUploadFileSelectedName] = useState("");

  const [createEmployeeEmail, setCreateEmployeeEmail] = useState([]);

  function submitFeedbackForm() {
    setUpdateLoading(true);
    let obj = corporateLead;
    delete obj?.customerservice;

    obj = {
      ...corporateLead,
      services_used: [corporateLead?.services_used],
    };

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/feedback/create",
      corporateLead
    )
      .then((response) => {
        // console.log(response, "Updated feedback form successfully");
        setUpdateLoading(false);
        predefinedToast(response?.data?.message);
        history.push("/app/coverfi/overview");
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded Endorsements  form via spreadsheet error"
        );
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(obj, "submit feebackback form obj");
  }

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newBrokerageParent}>
      {successMessage ? (
        <div className={classNames.successfulMessage}>
          {successMessage == "addedEmployeeBySpreadsheet" ? (
            <>
              <div>
                You have successfully added new employees via spreadsheet
              </div>
              <div>You will be redirected to your pending employees list</div>
            </>
          ) : (
            <>
              <div>
                You have successfully invited
                {" " +
                  createEmployeeEmail?.filter(
                    (obj) => Object.keys(obj).length > 0
                  )?.length +
                  " "}
                new employees
              </div>
              <div>You will be redirected to your pending employees list</div>
            </>
          )}
        </div>
      ) : (
        <div className={classNames.newBrokerage}>
          <div className={classNames.title}>Feedback Form</div>
          <EachInputSelectOneCircle
            title="How satisfied were you with your experience using Tall Tree's services?"
            name="satisfaction"
            stateValue={corporateLead}
            setState={setCorporateLead}
            options={["1", "2", "3", "4", "5"]}
          />
          <EachInputSelectOne
            title="Please specify the service you used"
            name="services_used"
            stateValue={corporateLead}
            setState={setCorporateLead}
            options={["Policy Management", "Claims Management"]}
          />
          <EachInputSelectOneCircle
            title="How easy was it to navigate and use our platform?"
            name="usability"
            stateValue={corporateLead}
            setState={setCorporateLead}
            options={["1", "2", "3", "4", "5"]}
          />
          <EachInputSelectOne
            title="Did you find the information and support you needed from Tall Tree's customer service team?"
            name="customerservice"
            stateValue={corporateLead}
            setState={setCorporateLead}
            options={[
              "Yes, the support was excellent",
              "Yes, the support was sufficient",
              "No, I encountered issues with customer support",
            ]}
          />
          <EachInput
            title="What aspects of our services do you feel we excel at?"
            placeholder="Enter.."
            name="excel"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
          <EachInput
            title="What aspects of our services do you believe need improvement?"
            placeholder="Enter.."
            name="improvement"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
          <EachInputSelectOneCircle
            title="How likely are you to recommend Tall Tree's services to others?"
            name="recommend"
            stateValue={corporateLead}
            setState={setCorporateLead}
            options={["1", "2", "3", "4", "5"]}
          />
          <EachInput
            title="Do you have any additional comments, suggestions, or feedback for us?"
            placeholder="Enter.."
            name="additional_feedback"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
          <div onClick={submitFeedbackForm} className={classNames.submitBtn}>
            Submit
          </div>
        </div>
      )}
    </div>
  );
};

export default FeebackForm;

const EachInput = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      // style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : title == "Job Type" || title == "Select User"
              ? "Click To Select"
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "Lead Source"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select Brokerage"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.brokerage_name,
                            eachitem?.brokerage_icon
                          );
                          setState(eachitem?.brokerage_username);
                        }}
                        key={eachitem?.brokerage_name + index}
                        style={{
                          display:
                            eachitem?.brokerage_username === stateValue
                              ? "none"
                              : "",
                        }}
                      >
                        <img
                          src={eachitem?.brokerage_icon}
                          alt={eachitem?.brokerage_name}
                        />
                        {eachitem?.brokerage_name}
                      </li>
                    );
                  })
              : title == "Select User"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.first_name + eachitem?.last_name,
                            eachitem?.profile_picture
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem?.user_email,
                          });
                        }}
                        key={eachitem?.first_name + index}
                      >
                        <img
                          src={eachitem?.profile_picture}
                          alt={eachitem?.first_name}
                        />
                        <div>
                          <div>
                            {eachitem?.first_name} &nbsp; {eachitem?.last_name}
                          </div>
                          <div>{eachitem?.user_email}</div>
                        </div>
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name, eachitem?.app_icon);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"Select Brokerage"}
              >
                {title == "Select Brokerage"
                  ? "Search one of your brokerage.."
                  : "Select an option"}
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanB"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePic = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDivProfilePic}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanBProfilePic"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanBProfilePic"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanBProfilePic"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await", name);
          }}
          accept="image/*"
          id="profileUpdateImgPlanBProfilePic"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

const EachProfileInput = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
}) => {
  const [numberOfEmails, setNumberOfEmails] = useState(1);

  const handleEmailChange = (index, key, value) => {
    const updatedStateValue = [...stateValue];
    if (!updatedStateValue[index]) {
      updatedStateValue[index] = {};
    }
    updatedStateValue[index][key] = value;
    setState(updatedStateValue);
  };

  const handleAddEmail = () => {
    setNumberOfEmails((prev) => prev + 1);
    setState([...stateValue, {}]); // Add an empty object to the array
  };

  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      {Array.from({ length: numberOfEmails })?.map((email, index) => (
        <div className={classNames.addByEmail} key={index}>
          <div className={classNames.inputParent}>
            <input
              type="text"
              name="employee_email"
              placeholder={placeholder}
              value={stateValue[index]?.employee_email || ""}
              className={classNames.inputContainer}
              onChange={(event) =>
                handleEmailChange(index, "employee_email", event.target.value)
              }
            />
            <div
              className={classNames.addButton}
              onClick={handleAddEmail}
              style={{ display: numberOfEmails - 1 === index ? "" : "none" }}
            >
              +
            </div>
          </div>
          <div className={classNames.optionalThings}>
            <input
              type="text"
              className={classNames.inputContainer}
              placeholder="Enter First Name (Optional)"
              name="first_name"
              value={stateValue[index]?.first_name || ""}
              onChange={(event) =>
                handleEmailChange(index, "first_name", event.target.value)
              }
            />
            <input
              type="text"
              className={classNames.inputContainer}
              placeholder="Enter Last Name (Optional)"
              name="last_name"
              value={stateValue[index]?.last_name || ""}
              onChange={(event) =>
                handleEmailChange(index, "last_name", event.target.value)
              }
            />
            <input
              type="text"
              className={classNames.inputContainer}
              placeholder="Phone Number (Optional)"
              name="phone_number"
              value={stateValue[index]?.phone_number || ""}
              onChange={(event) =>
                handleEmailChange(index, "phone_number", event.target.value)
              }
            />
          </div>
        </div>
      ))}

      {dropdown && <IoMdArrowDropdown />}
    </div>
  );
};

const EachInputFileDownload = ({ title, icon, stateValue, setState }) => {
  const handleDownloadClick = () => {
    // You can replace 'FILE_URL' with the actual URL of your file
    const fileUrl = stateValue;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "employeespreadsheettemplate.xlsx"; // Specify the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classNames.eachInput}>
      <div className={classNames.fileAction} onClick={handleDownloadClick}>
        <img src={icon} alt="downloadIcon" />
        <span>{title}</span>
      </div>
    </div>
  );
};

const EachInputFileUpload = ({
  title,
  icon,
  stateValue,
  setState,
  setName,
}) => {
  const handleFileChange = (event) => {
    if (event?.target?.files?.length > 0) {
      setName(event?.target?.files[0]?.name);
      setState(event?.target?.files[0]);
    }
  };

  return (
    <div className={classNames.eachInput}>
      <label
        className={classNames.fileAction}
        htmlFor="fileUpload"
        onClick={handleFileChange}
      >
        <img src={icon} alt="downloadIcon" />
        <span>{title}</span>
      </label>
      <input
        type="file"
        id="fileUpload"
        accept=".xls,.xlsx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

const EachInputSelectOne = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  return (
    <div className={classNames.eachInputPickOne}>
      <div className={classNames.title}>{title}</div>
      <div
        classNames={classNames.selectOneAllOptions}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          fontSize: "1rem",
          marginTop: "1rem",
        }}
      >
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    setSelectedValue(eachOption);
                    setState({ ...stateValue, [name]: eachOption });
                  }}
                  style={{
                    textAlign: "center",
                    width: "max-content",
                    padding: "1rem 2rem",
                    cursor: "pointer",
                    fontSize: "0.9rem",
                    border:
                      selectedValue == eachOption
                        ? "1px solid var(--main-color)"
                        : "1px solid #e5e5e5",
                    borderRadius: "35px",
                    transition: "all linear 0.3s",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const EachInputSelectOneCircle = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  return (
    <div className={classNames.eachInputPickOneCircle}>
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.selectOneAllOptionsCircle}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          fontSize: "1rem",
          marginTop: "1rem",
        }}
      >
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    setSelectedValue(eachOption);
                    setState({ ...stateValue, [name]: eachOption });
                  }}
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    fontSize: "0.9rem",
                    border:
                      selectedValue == eachOption
                        ? "1px solid var(--main-color)"
                        : "1px solid #e5e5e5",
                    transition: "all linear 0.3s",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};
