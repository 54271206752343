import React, { useContext } from "react";
import classNames from "./editaddcompany.module.scss";
import HealthFlatCompanyProfile from "../Employer/HealthFlatCompanyProfile";
import { BankContext } from "../../context/Context";
import AccidentCompanyProfile from "../Employer/AccidentCompanyProfile";
import LifeCompanyProfile from "../Employer/LifeCompanyProfile";

const EditAddCompanyProfile = () => {
  const { popularAction } = useContext(BankContext);
  return (
    <div className={classNames.editAddCompany}>
      {popularAction == "healthflat" ? (
        <HealthFlatCompanyProfile />
      ) : popularAction == "accidentpolicy" ? (
        <AccidentCompanyProfile />
      ) : popularAction == "lifepolicy" ? (
        <LifeCompanyProfile />
      ) : (
        ""
      )}
    </div>
  );
};

export default EditAddCompanyProfile;
