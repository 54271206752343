import React, { useEffect, useRef, useState } from "react";
import classNames from "./transactionhistory.module.scss";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import fullLogo from "../../assest/images/fullLogo.svg";
import powerByVisista from "../../assest/images/logos/poweredbyvisista.svg";
import rahaWhiteLogo from "../../assest/images/logos/rahawhite.svg";
import calenderIcon from "../../assest/images/icons/calender.svg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import useWindowDimensions from "../../utils/WindowSize";
import Axios from "axios";
import { predefinedToast } from "../../utils/toast";
import { useContext } from "react";
import { BankContext } from "../../context/Context";
import { LoadingAnimationRahaLogo } from "../../components/Custom";

import iobLogo from "../../assest/images/logos/iob.svg";
import { useHistory, useParams, useLocation, Redirect } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import EReceiptPDF from "./PDF";

const TransactionHistory = () => {
  const { width, height } = useWindowDimensions();
  const { history } = useHistory();
  const {
    updateLoading,
    setUpdateLoading,
    reqValue,
    setreqValue,
    generatePDFData,
    setGeneratePDFData,
  } = useContext(BankContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [allTransactionHistory, setAllTransactionHistory] = useState("");
  const [allTransactionHistoryLoading, setAllTransactionHistoryLoading] =
    useState(false);

  function formatDateToDDMMYYYY(inputDate) {
    // const date = new Date(inputDate);

    // const day = String(date.getDate()).padStart(2, "0");
    // const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    // const year = date.getFullYear();
    let datee = inputDate?.split(" ");
    if (datee?.length > 0) {
      return datee[0];
    } else {
      return inputDate;
    }
  }

  const [refreshStatus, setRefreshStatus] = useState(false);

  useEffect(() => {
    if (refreshStatus) {
      getTransactionHistory();
      setRefreshStatus(false);
    }
  }, [refreshStatus]);

  function getTransactionHistory() {
    // setUpdateLoading(true);
    setAllTransactionHistoryLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/iob/transaction/history?phone_number=${searchQuery}`
    )
      .then((response) => {
        console.log(response, "getTransactionHistory response");
        setAllTransactionHistory(response?.data?.data);
        // setUpdateLoading(false);
        setAllTransactionHistoryLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "getTransactionHistory error");
        // setUpdateLoading(false);
        setAllTransactionHistoryLoading(false);
      });
  }
  function getEReceiptForTrackId(phone, trackId) {
    // setUpdateLoading(true);
    setAllTransactionHistoryLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/iob/transaction/receipt?phone_number=${phone}&trackid=${trackId}`
    )
      .then((response) => {
        console.log(response, "getEReceiptForTrackId response");
        // setAllTransactionHistory(response?.data?.data);
        // setUpdateLoading(false);
        setAllTransactionHistoryLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "getEReceiptForTrackId error");
        // setUpdateLoading(false);
        setAllTransactionHistoryLoading(false);
      });
  }
  function refreshAwaitedTransaction(totalAmt, tokenId) {
    // setUpdateLoading(true);
    setAllTransactionHistoryLoading(true);
    Axios.post(`https://rahabank.marketsverse.com/api/txn-status`, {
      totalamt: totalAmt,
      tokenid: tokenId,
    })
      .then((response) => {
        console.log(response, "refreshAwaitedTransaction response");
        predefinedToast(response?.data?.message);
        // setAllTransactionHistory(response?.data?.data);
        // setUpdateLoading(false);
        // setAllTransactionHistoryLoading(false);
        setRefreshStatus(true);
      })
      .catch((error) => {
        console.log(error?.message, "refreshAwaitedTransaction error");
        // setUpdateLoading(false);
        setAllTransactionHistoryLoading(false);
      });
  }

  function convertTimestampToCustomFormat(originalTimestamp) {
    // Parse the original timestamp
    const parsedTimestamp = moment(originalTimestamp, "DD-MM-YYYY HH:mm:ss");

    let date = moment(parsedTimestamp).format("MMM Do YY");
    // Create a new moment object with the desired date (Jan 1, 2023) and the same time
    const newDate = moment({
      year: parsedTimestamp.year(),
      month: 0,
      day: 1,
      hour: parsedTimestamp.hours(),
      minute: parsedTimestamp.minutes(),
      second: parsedTimestamp.seconds(),
    });

    // Format the new moment object as "Jan 1st 2023" and return it
    return date;
  }

  function formatTimeIn12HourClock(originalTimestamp) {
    // Parse the original timestamp
    const parsedTimestamp = moment(originalTimestamp, "DD-MM-YYYY HH:mm:ss");

    // Format the time in 12-hour clock format (hh:mm A)
    return parsedTimestamp.format("hh:mm A");
  }

  const generatePDF = (trackid) => {
    const pdf = new jsPDF("p", "mm", "a4");

    const content = document.getElementById("pdfDownload");

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 277);
      pdf.save(`${trackid}_document.pdf`);
    });
  };

  useEffect(() => {
    if (generatePDFData) {
      generatePDF(generatePDFData?.trackid);
    }
  }, [generatePDFData]);

  return (
    <div className={classNames.enrollmentForm}>
      <div
        className={classNames.leftPanel}
        style={{ display: width < 800 ? "none" : "" }}
      >
        <div className={classNames.headPanel}>
          <img src={fullLogo} alt="fullLogo" />
        </div>
        <img src={powerByVisista} alt="powerByVisista" />
        <EReceiptPDF />
      </div>
      <div
        className={classNames.rightPanel}
        style={{
          width: width < 800 ? "100%" : "",
          borderRadius: width < 800 ? "0" : "",
          padding: width < 800 ? "10rem 1rem" : "",
          paddingTop: width < 800 ? "0" : "",
        }}
      >
        {updateLoading ? (
          <LoadingAnimationRahaLogo
            height={"80%"}
            position="absolute"
            opacity="0.6"
          />
        ) : (
          ""
        )}
        {width < 800 ? (
          <div className={classNames.headPanel}>
            <img src={rahaWhiteLogo} alt="rahaWhiteLogo" />
          </div>
        ) : (
          ""
        )}

        <div className={classNames.title}>Transaction History</div>

        <div className={classNames.multipleInputsWithTitle}>
          <input
            type="text"
            className={classNames.searchDiv}
            placeholder="Enter phone number..."
            onChange={(event) => setSearchQuery(event?.target?.value)}
          />
          <button
            className={classNames.transactionBtn}
            onClick={getTransactionHistory}
            style={{
              pointerEvents: allTransactionHistoryLoading
                ? "none"
                : searchQuery && !allTransactionHistoryLoading
                ? ""
                : "none",
              opacity: allTransactionHistoryLoading
                ? "0.5"
                : searchQuery && !allTransactionHistoryLoading
                ? ""
                : "0.5",
            }}
          >
            Get Transactions
          </button>
        </div>
        <div className={classNames.tableParent}>
          <div className={classNames.tableTitles}>
            <div>Date</div>
            <div>Time</div>
            <div>Amount</div>
            <div>ID</div>
            <div>Status</div>
            <div>Actions</div>
          </div>
          <div className={classNames.tableBody}>
            {allTransactionHistoryLoading ? (
              <div className={classNames.eachItem}>
                <div className={classNames.singleFields}>
                  <Skeleton height={15} width={80} />
                </div>
                <div className={classNames.singleFields}>
                  <Skeleton height={15} width={80} />
                </div>
                <div className={classNames.singleFields}>
                  <Skeleton height={15} width={80} />
                </div>
                <div className={classNames.singleFields}>
                  <Skeleton height={15} width={80} />
                </div>
                <div className={classNames.singleFields}>
                  <Skeleton height={15} width={80} />
                </div>
                <div className={classNames.singleFields}>
                  <Skeleton height={15} width={80} />
                </div>
              </div>
            ) : allTransactionHistory?.length > 0 ? (
              allTransactionHistory.map((eachItem, index) => {
                return (
                  <div
                    className={classNames.eachItem}
                    key={eachItem?._id + index + eachItem?.timestamp}
                  >
                    <div className={classNames.singleFields}>
                      {convertTimestampToCustomFormat(eachItem?.timestamp)}
                    </div>
                    <div className={classNames.singleFields}>
                      {formatTimeIn12HourClock(eachItem?.timestamp)}
                    </div>
                    <div className={classNames.singleFields}>
                      {eachItem?.totalamt}
                    </div>
                    <div className={classNames.singleFields}>
                      {eachItem?.trackid}
                    </div>
                    <div className={classNames.singleFields}>
                      {eachItem?.txnstatus}
                    </div>

                    {eachItem?.txnstatus?.toLowerCase() == "success" ? (
                      <div
                        className={classNames.singleFieldsBtn}
                        onClick={() => {
                          // getEReceiptForTrackId(
                          //   eachItem?.phone_number,
                          //   eachItem?.trackid
                          // );
                          setGeneratePDFData({
                            date: convertTimestampToCustomFormat(
                              eachItem?.timestamp
                            ),
                            time: formatTimeIn12HourClock(eachItem?.timestamp),
                            totalamt: eachItem?.totalamt,
                            trackid: eachItem?.trackid,
                            txnstatus: eachItem?.txnstatus,
                            primary_member_details:
                              eachItem?.primary_member_details,
                            roll_number: eachItem?.roll_number,
                            sum_insured: eachItem?.sum_insured,
                            phone_number: eachItem?.phone_number,
                          });
                        }}
                      >
                        Download
                      </div>
                    ) : eachItem?.txnstatus?.toLowerCase() == "awaited" ||
                      eachItem?.txnstatus?.toLowerCase() == "failure" ||
                      eachItem?.txnstatus?.toLowerCase() == "initiated" ? (
                      <div
                        className={classNames.singleFieldsBtn}
                        onClick={() => {
                          refreshAwaitedTransaction(
                            eachItem?.totalamt,
                            eachItem?.tokenid
                          );
                        }}
                      >
                        Refresh
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;

const SelectTag = ({
  title,
  name,
  allItems,
  selectedValue,
  setSelectedValue,
}) => {
  return (
    <div className={classNames.customRadio}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.radioParent}>
        {allItems?.map((eachItem, index) => (
          <div key={eachItem + index} className={classNames.eachRadioBtn}>
            <input
              type="radio"
              id={eachItem}
              name={name}
              value={eachItem}
              checked={selectedValue[name] == eachItem}
              onChange={() => {
                setSelectedValue((prev) => {
                  return { ...prev, [name]: eachItem };
                });
                console.log(selectedValue, "selectedValue");
              }}
            />
            <label htmlFor={eachItem}>{eachItem}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomDropdown = ({
  title,
  subtitle,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: isOpen ? "250px" : "", zIndex: isOpen ? "4" : "" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{ pointerEvents: dropdown ? "" : "none" }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOption
              ? selectedOption
              : title == "Employee" || title == "Spouse"
              ? "Select Coverage"
              : title
              ? "Select " + title
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {/* <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li> */}
            {title === "All Countries"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState(eachitem);
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        {eachitem}
                      </li>
                    );
                  })}
          </ul>
        )}
      </div>
    </div>
  );
};

const EachInput = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};
