import React, { useContext, useEffect, useState } from "react";
import classNames from "./verifyemployee.module.scss";
import { BankContext } from "../../context/Context";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
} from "../Custom/Input";
import {
  EachCustomDatePickerEditable,
  EachCustomDropdownEditable,
  EachCustomInputEditable,
} from "../../pages/Login/TempLoginEmployees";
import Axios from "axios";
import { predefinedToast } from "../../utils/toast";
import {
  calculateAge,
  calculateAgeDash,
  convertDateFormatToUnderscore,
  filterObjectsWithAllTruthy,
} from "../../assest/functions";
import CustomCheckbox from "../Custom";
import fullLogo from "../../assest/images/fullLogo.svg";
import { useHistory } from "react-router-dom";

const VerifyEmployee = () => {
  const { email, employeeVerified } = useContext(BankContext);
  const history = useHistory();

  //local values
  const [currentStep, setCurrentStep] = useState(2); // 2 is default
  const [confirmStep, setConfirmStep] = useState([]);
  const [tempPasswordStep, setTempPasswordStep] = useState(4); //4 is default
  const [formDetails, setFormDetails] = useState(
    (localStorage.getItem("formDetailsPreLogin") &&
      JSON.parse(localStorage.getItem("formDetailsPreLogin"))) ||
      ""
  );
  const [isProfileFound, setIsProfileFound] = useState("");
  const [isProfileFoundDependents, setIsProfileFoundDependents] = useState([]);
  const [dependentsUpdate, setDependentsUpdate] = useState([]);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [companyProfileDetails, setCompanyProfileDetails] = useState("");
  const [formDetailsArrayNumber, setFormDetailsArrayNumber] = useState(0); // 0 is default
  const [updatedDependentArray, setUpdatedDependentArray] = useState("");

  //validations
  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);

  const [formDetailsArray, setFormDetailsArray] = useState(
    Array.from({ length: formDetailsArrayNumber }, () => ({}))
  );

  const updateFormDetailsAtIndex = (index, newValue) => {
    setUpdatedDependentArray(true);
    setFormDetailsArray((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = { ...updatedDetails[index], ...newValue };
      return updatedDetails;
    });
  };

  const updateFormDetailsAtIndexIsProfile = (index, newValue) => {
    setUpdatedDependentArray(true);
    setIsProfileFoundDependents((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = { ...updatedDetails[index], ...newValue };
      return updatedDetails;
    });
  };

  const updateFormDetailsAtIndexUpdateDependents = (index, newValue) => {
    setUpdatedDependentArray(true);
    setDependentsUpdate((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = { ...updatedDetails[index], ...newValue };
      return updatedDetails;
    });
  };

  //editable data
  const [editEnabled, setEditEnabled] = useState(false);

  async function updateEmployeeDetails() {
    try {
      let objj = {
        name: formDetails?.employee_name,
        date_of_birth: formDetails?.date_of_birth,
        phone_number: formDetails?.phone_number,
        gender: formDetails?.gender,
        role: formDetails?.designation,
        family_definition: isProfileFound?.family_definition
          ? isProfileFound?.family_definition
          : formDetails?.family_definition
          ? formDetails?.family_definition
          : "",
      };

      let employeeID = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
      );
      let empID = employeeID?.data?.data?.employeeDetails;
      if (employeeID?.data?.data?.employeeDetails?.length > 0) {
        empID = employeeID?.data?.data?.employeeDetails[0]?.employee_id;
      }
      console.log(empID, "employeeID ");

      let response = await Axios.put(
        `https://insurancecoverfi.apimachine.com/insurance/employee/update?employee_id=${empID}`,
        objj
      );
      console.log(response, "response from formDetails updateEmployeeDetails");
      predefinedToast(response?.data?.message);
      setEditEnabled(false);
    } catch (error) {
      console.log(error?.message);
    }
  }

  //functions

  function getConfirmEmployees() {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/get?email_id=${email}`
    )
      .then((response) => {
        console.log(response, "Confirm employees response");
        if (response?.data?.data?.length > 0) {
          let res = response?.data?.data[0];
          res.age = formDetails?.date_of_birth?.includes("-")
            ? calculateAgeDash(res?.date_of_birth)
            : calculateAge(res?.date_of_birth);
          localStorage.setItem("formDetailsPreLogin", JSON.stringify(res));
          setFormDetails(res);

          if (res?.family_definition == "Employee + Spouse") {
            setFormDetailsArrayNumber(1);
          } else if (
            res?.family_definition == "Employee + Spouse + 2 Children"
          ) {
            setFormDetailsArrayNumber(3);
          } else if (
            res?.family_definition ==
            "Employee + Spouse + 2 Children + 2 Parents"
          ) {
            setFormDetailsArrayNumber(5);
          } else if (
            res?.family_definition ==
            "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
          ) {
            setFormDetailsArrayNumber(7);
          }
        }
      })
      .catch((error) => {
        console.log(error?.message, "Confirm employees error");
        // setProtectionScoreLoading(false);
      });
  }

  function getCompanyProfile() {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/lead/get?lead_email=${email}`
    )
      .then((response) => {
        console.log(response, "Company profile response");
        if (response?.data?.data?.length > 0) {
          setCompanyProfileDetails(response?.data?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Company profile error");
        // setProtectionScoreLoading(false);
      });
  }

  function transformObject(originalObject) {
    return {
      employee_id: originalObject.employee_id,
      dependent_user_company_email: email,
      dependent_name: originalObject.dependant_name
        ? originalObject.dependant_name
        : originalObject.dependent_name
        ? originalObject.dependent_name
        : "",
      dependent_email: originalObject.email_id,
      relationship: originalObject?.relation,
      gender: originalObject?.gender,
      date_of_birth: originalObject?.date_of_birth,
      primary_phone_number: originalObject?.phone_number,
      secondary_phone_numbers: [],
    };
  }

  async function addDependents() {
    console.log(formDetailsArray, "formDetailsArray in add dependent");
    let employeeID = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
    );
    let empID = employeeID?.data?.data?.employeeDetails;
    if (employeeID?.data?.data?.employeeDetails?.length > 0) {
      empID = employeeID?.data?.data?.employeeDetails[0]?._id;
    }

    let finalArray;

    let newArrayWithoutAge = formDetailsArray?.map((obj, index) => {
      // Create a new object without the age field if it exists
      const { date_of_birth, age, ...rest } = obj;
      rest.dependent_user_company_email = email;
      rest.date_of_birth = convertDateFormatToUnderscore(date_of_birth);
      rest.employee_id = empID;
      rest.primary_phone_number = formDetails?.phone_number;
      rest.dependent_name = obj?.dependent_name
        ? obj?.dependent_name
        : isProfileFoundDependents[index]?.dependent_name
        ? isProfileFoundDependents[index]?.dependent_name
        : "";
      return rest;
    });
    console.log(newArrayWithoutAge, "newArrayWithoutAge before removing empty");
    newArrayWithoutAge = filterObjectsWithAllTruthy(newArrayWithoutAge);
    console.log(newArrayWithoutAge, "newArrayWithoutAge after removing empty");

    if (
      isProfileFoundDependents?.length > 0 &&
      (isProfileFoundDependents[0]?.hr_id ||
        isProfileFoundDependents[0]?.phone_number ||
        isProfileFoundDependents[0]?.relation ||
        isProfileFoundDependents[0]?.lead_id)
    ) {
      finalArray = isProfileFoundDependents?.map(transformObject);
      console.log(finalArray, "predone before removing empty");
      // finalArray = filterObjectsWithAllTruthy(
      //   isProfileFoundDependents?.map(transformObject)
      // );
      console.log(finalArray, "predone after removing empty");
    } else {
      finalArray = newArrayWithoutAge;
      console.log(finalArray, "postdone before removing empty");
      finalArray = filterObjectsWithAllTruthy(newArrayWithoutAge);
      console.log(finalArray, "postdone after removing empty");
    }

    console.log(finalArray, "finalObject");

    Axios.post(
      // `https://insurancecoverfi.apimachine.com/insurance/dependent/create/bulktemp`,
      `https://insurancecoverfi.apimachine.com/insurance/dependent/create/bulk`,
      {
        dependents_details:
          // isProfileFoundDependents?.length > 0
          //   ? isProfileFoundDependents
          //   : newArrayWithoutAge,
          Array.isArray(isProfileFoundDependents) &&
          isProfileFoundDependents?.length > 0
            ? finalArray
            : formDetailsArray?.length > 0
            ? newArrayWithoutAge
            : finalArray,
      }
    )
      .then((response) => {
        console.log(response?.data, "Added dependents response");
        if (response?.data?.status) {
          localStorage.removeItem("employeeVerified");
          window?.location.reload();
          predefinedToast("Added dependent successfully!");
        } else {
          predefinedToast("Error while adding dependents");
        }
      })
      .catch((error) => {
        console.log(error?.message, "Added dependents error");
        predefinedToast(error?.message);
      });
  }

  function updateFieldsDependents(array) {
    // Loop through each object in the array
    array.forEach((obj) => {
      // Check if the object has the fields dependent_name and relationship
      if (
        obj.hasOwnProperty("dependent_name") &&
        obj.hasOwnProperty("relationship")
      ) {
        // Update the fields to dependant_name and relation
        obj.dependant_name = obj.dependent_name;
        obj.relation = obj.relationship;
        // Delete the old fields if needed
        delete obj.dependent_name;
        delete obj.relationship;
      }
    });
    return array;
  }
  function updateDependentInfoFinal(data) {
    return data.map((dependent) => {
      return {
        dependent_name: dependent.dependant_name || "",
        dependent_email: dependent.dependent_email || "",
        relationship: dependent.relation || "",
        gender: dependent.gender || "",
        date_of_birth: dependent.date_of_birth || "",
        dependent_id: dependent.dependent_id || "",
        primary_phone_number: dependent.primary_phone_number || "",
        secondary_phone_numbers: dependent.secondary_phone_numbers || [],
      };
    });
  }
  async function updateDependents() {
    const finalData = updateDependentInfoFinal(dependentsUpdate);
    console.log(finalData, "Update dependents API called");
    try {
      let response = await Axios.put(
        `https://insurancecoverfi.apimachine.com/insurance/dependent/update/bulk`,
        {
          dependents_details: finalData,
        }
      );
      if (response?.data?.status) {
        localStorage.removeItem("employeeVerified");
        window?.location.reload();
        predefinedToast("Added dependent successfully!");
      } else {
        predefinedToast("Error while adding dependents");
      }
      console.log(response, "updateDependents response");
    } catch (error) {
      console.log(error, "updateDependents error");
      predefinedToast(error?.message);
    }
  }

  async function getUpdateDependents() {
    try {
      let response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${email}`
      );
      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        const updatedArray = updateFieldsDependents(response?.data?.data);
        setDependentsUpdate(updatedArray);
      } else {
        setDependentsUpdate("");
      }
      console.log(response, "getUpdateDependents response");
    } catch (error) {
      console.log(error, "getUpdateDependents response");
    }
  }

  //renderings

  useEffect(() => {
    getCompanyProfile();
    getConfirmEmployees();

    setIsLoggingIn(true);
    getUpdateDependents();
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/get?email_id=${email}`
    )
      .then((response) => {
        console.log(response, "Get profile data for lead email response");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          setIsProfileFound(response?.data?.data[0]);
          setIsProfileFoundDependents(
            response?.data?.data[0]?.employee_dependents
          );
        } else {
          setIsProfileFound(false);
          setIsProfileFoundDependents(false);
          // history.push("/employee/login");
        }
        setIsLoggingIn(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get profile data for lead email error");
        setIsLoggingIn(false);
      });
  }, []);

  useEffect(() => {
    setFormDetailsArray(
      Array.from({ length: formDetailsArrayNumber }, () => ({}))
    );
  }, [formDetailsArrayNumber]);

  return (
    <div
      className={classNames.verifyEmployee}
      style={{
        display:
          employeeVerified == "false" &&
          window?.location?.pathname?.includes("rahax")
            ? ""
            : "none",
      }}
    >
      <div className={classNames.overlay}></div>
      <div className={classNames.verifyBox}>
        <div className={classNames.leftContainer}>
          <div className={classNames.headerContainer}>
            <div className={classNames.title}>Verify Data</div>
            <div className={classNames.stepTracker}>
              <div className={currentStep === 1 ? classNames.currentStep : ""}>
                1<div className={classNames.stepTitle}>Reset Password</div>
              </div>
              <div className={currentStep === 2 ? classNames.currentStep : ""}>
                2 <div className={classNames.stepTitle}>Confirm Details</div>
              </div>
              <div className={currentStep === 3 ? classNames.currentStep : ""}>
                3<div className={classNames.stepTitle}>Confirm Dependents</div>
              </div>
            </div>
          </div>
          {tempPasswordStep == 4 ? (
            <>
              <div
                className={classNames.userDetailsContainer}
                style={
                  {
                    // marginTop: currentStep === 1 ? "" : "10rem",
                  }
                }
              >
                <div className={classNames.title}>
                  <span>Employee Details</span>
                  {/* <span>
                  <MdOutlineEdit />
                </span> */}
                </div>
                <div
                  className={classNames.eachDetails}
                  // style={{
                  //   pointerEvents: "none",
                  // }}
                >
                  <div className={classNames.threeDivContainer}>
                    <EachCustomInputEditable
                      placeholder="-"
                      name="employee_name"
                      stateValue={formDetails}
                      setState={setFormDetails}
                      title="Name"
                      editEnabled={editEnabled}
                      setEditEnabled={setEditEnabled}
                    />
                    {/* <EachCustomInputEditable
                    placeholder="-"
                    name="gender"
                    stateValue={formDetails}
                    setState={setFormDetails}
                    title="Gender"
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                  /> */}
                    <EachCustomDropdownEditable
                      title="Gender"
                      placeholder="-"
                      name="gender"
                      dropdown={["Male", "Female"]}
                      stateValue={formDetails}
                      setState={setFormDetails}
                      typee="single"
                      editEnabled={editEnabled}
                      setEditEnabled={setEditEnabled}
                      option={"alreadySet"}
                    />
                    <EachCustomDatePickerEditable
                      //   title="Policy valid until"
                      placeholder="Click To Select"
                      // dropdown={usersDropdown}
                      name="date_of_birth"
                      stateValue={formDetails}
                      setState={setFormDetails}
                      //   mandatory={clientOnboardingForm ? true : false}
                      title="Date of birth"
                      editEnabled={editEnabled}
                      setEditEnabled={setEditEnabled}
                      typee="spreadObj"
                    />
                  </div>
                  <div className={classNames.twoDivContainer}>
                    <EachCustomInputEditable
                      placeholder=""
                      name="phone_number"
                      stateValue={formDetails}
                      setState={setFormDetails}
                      title="Phone number"
                      editEnabled={editEnabled}
                      setEditEnabled={setEditEnabled}
                      validation={{ length: 10, type: "length" }}
                    />
                    <EachCustomInput
                      placeholder="-"
                      name="email_id"
                      stateValue={formDetails}
                      setState={setFormDetails}
                      title="Email"
                    />
                  </div>
                  <div className={classNames.twoDivContainer}>
                    <EachCustomInput
                      placeholder="-"
                      name="designation"
                      stateValue={formDetails}
                      setState={setFormDetails}
                      title="Designation"
                    />

                    <EachCustomInput
                      placeholder="-"
                      name="age"
                      stateValue={{
                        age:
                          formDetails?.date_of_birth?.includes("-") &&
                          formDetails?.date_of_birth
                            ? calculateAgeDash(formDetails?.date_of_birth)
                            : formDetails?.date_of_birth
                            ? calculateAge(formDetails?.date_of_birth)
                            : "-",
                      }}
                      setState={setFormDetails}
                      title="Age"
                    />
                    {/* <div className={classNames.ageBox}>
                    {formDetails?.date_of_birth
                      ? calculateAgeDash(formDetails?.date_of_birth)
                      : "-"}
                  </div> */}
                  </div>
                </div>
                {editEnabled ? (
                  ""
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ position: "relative", width: "40px" }}>
                      <CustomCheckbox
                        actionBtns={confirmStep}
                        setActionBtns={setConfirmStep}
                        id={"temppasswordstep4"}
                        name={"temppasswordstep4 name"}
                      />
                    </div>
                    <span>
                      I hereby acknowledge that my personal information is
                      presented correctly
                    </span>
                  </div>
                )}
              </div>
              {editEnabled ? (
                <div
                  className={classNames.submitBtn}
                  onClick={updateEmployeeDetails}
                >
                  Save changes
                </div>
              ) : (
                <div
                  className={classNames.submitBtn}
                  style={{
                    pointerEvents: confirmStep?.includes("temppasswordstep4")
                      ? ""
                      : "none",
                    opacity: confirmStep?.includes("temppasswordstep4")
                      ? ""
                      : "0.5",
                  }}
                  onClick={() => {
                    updateEmployeeDetails();
                    if (
                      formDetails?.family_definition === "Only The Employee"
                    ) {
                      localStorage.removeItem("employeeVerified");
                      window?.location.reload();
                    } else {
                      setCurrentStep(3);
                      setTempPasswordStep(5);
                    }
                  }}
                >
                  Next Step
                </div>
              )}
            </>
          ) : tempPasswordStep === 5 ? (
            <>
              <div
                className={classNames.userDetailsContainer}
                style={{
                  marginTop: currentStep === 1 ? "" : "5rem",
                }}
              >
                <div className={classNames.title}>
                  <span>Dependent Details</span>
                </div>
                {dependentsUpdate?.length > 0
                  ? dependentsUpdate?.map((formDetails, index) => {
                      return (
                        <div
                          className={classNames.eachDetails}
                          key={`eachDependent${index}`}
                          // style={{ pointerEvents: "none" }}
                        >
                          <div className={classNames.threeDivContainer}>
                            <EachCustomInput
                              placeholder="Enter dependent name"
                              name="dependant_name"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexUpdateDependents(
                                  index,
                                  newValue
                                )
                              }
                              // asteriskIconn="true"
                            />
                            <div>
                              <EachCustomDatePicker
                                //   title="Policy valid until"
                                placeholder="Select date of birth"
                                // dropdown={usersDropdown}
                                name="date_of_birth"
                                stateValue={formDetails}
                                setState={(newValue) =>
                                  updateFormDetailsAtIndexUpdateDependents(
                                    index,
                                    newValue
                                  )
                                }
                                // asteriskIconn="true"
                                //   mandatory={clientOnboardingForm ? true : false}
                                values={{
                                  state: formDetails,
                                  index: index,
                                }}
                                validation={{ type: "relationship" }}
                              />
                            </div>
                            <div className={classNames.ageBox}>
                              {formDetails?.date_of_birth
                                ? calculateAgeDash(formDetails?.date_of_birth)
                                : "-"}
                            </div>
                          </div>
                          <div className={classNames.twoDivContainer}>
                            {/* <EachCustomInput
                              placeholder="Relation"
                              name="relation"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexUpdateDependents(index, newValue)
                              }
                            /> */}
                            <EachCustomDropdown
                              placeholder="Relation"
                              dropdown={
                                isProfileFound?.family_definition ==
                                "Employee + Spouse"
                                  ? ["Spouse"]
                                  : isProfileFound?.family_definition ==
                                    "Employee + Spouse + 2 Children"
                                  ? ["Spouse", "Son", "Daughter"]
                                  : isProfileFound?.family_definition ==
                                    "Employee + Spouse + 2 Children + 2 Parents"
                                  ? [
                                      "Spouse",
                                      "Son",
                                      "Daughter",
                                      "Father",
                                      "Mother",
                                    ]
                                  : isProfileFound?.family_definition ==
                                    "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
                                  ? [
                                      "Spouse",
                                      "Son",
                                      "Daughter",
                                      "Father",
                                      "Mother",
                                      "Father-in-Law",
                                      "Mother-in-Law",
                                    ]
                                  : []
                              }
                              name="relation"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexUpdateDependents(
                                  index,
                                  newValue
                                )
                              }
                              typee="single"
                              asteriskIconn="true"
                              isAlreadyThere={formDetails?.relation}
                            />
                            <EachCustomInput
                              placeholder="Gender"
                              name="gender"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexUpdateDependents(
                                  index,
                                  newValue
                                )
                              }
                            />
                          </div>
                        </div>
                      );
                    })
                  : isProfileFoundDependents?.length > 0
                  ? isProfileFoundDependents?.map((formDetails, index) => {
                      return (
                        <div
                          className={classNames.eachDetails}
                          key={`eachDependent${index}`}
                          // style={{ pointerEvents: "none" }}
                        >
                          <div className={classNames.threeDivContainer}>
                            <EachCustomInput
                              placeholder="Enter dependent name"
                              name="dependant_name"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexIsProfile(
                                  index,
                                  newValue
                                )
                              }
                              // asteriskIconn="true"
                            />

                            <div>
                              <EachCustomDatePicker
                                //   title="Policy valid until"
                                placeholder="Select date of birth"
                                // dropdown={usersDropdown}
                                name="date_of_birth"
                                stateValue={formDetails}
                                setState={(newValue) =>
                                  updateFormDetailsAtIndexIsProfile(
                                    index,
                                    newValue
                                  )
                                }
                                // asteriskIconn="true"
                                //   mandatory={clientOnboardingForm ? true : false}
                                values={{
                                  state: formDetails,
                                  index: index,
                                }}
                                validation={{ type: "relationship" }}
                              />
                            </div>
                            <div className={classNames.ageBox}>
                              {formDetails?.date_of_birth
                                ? calculateAgeDash(formDetails?.date_of_birth)
                                : "-"}
                            </div>
                          </div>
                          <div className={classNames.twoDivContainer}>
                            {/* <EachCustomInput
                              placeholder="Relation"
                              name="relation"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexIsProfile(index, newValue)
                              }
                            /> */}
                            <EachCustomDropdown
                              placeholder="Relation"
                              dropdown={
                                isProfileFound?.family_definition ==
                                "Employee + Spouse"
                                  ? ["Spouse"]
                                  : isProfileFound?.family_definition ==
                                    "Employee + Spouse + 2 Children"
                                  ? ["Spouse", "Son", "Daughter"]
                                  : isProfileFound?.family_definition ==
                                    "Employee + Spouse + 2 Children + 2 Parents"
                                  ? [
                                      "Spouse",
                                      "Son",
                                      "Daughter",
                                      "Father",
                                      "Mother",
                                    ]
                                  : isProfileFound?.family_definition ==
                                    "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
                                  ? [
                                      "Spouse",
                                      "Son",
                                      "Daughter",
                                      "Father",
                                      "Mother",
                                      "Father-in-Law",
                                      "Mother-in-Law",
                                    ]
                                  : []
                              }
                              name="relation"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexIsProfile(
                                  index,
                                  newValue
                                )
                              }
                              typee="single"
                              asteriskIconn="true"
                              isAlreadyThere={formDetails?.relation}
                            />
                            <EachCustomInput
                              placeholder="Gender"
                              name="gender"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexIsProfile(
                                  index,
                                  newValue
                                )
                              }
                            />
                          </div>
                        </div>
                      );
                    })
                  : formDetailsArray.map((formDetails, index) => (
                      <div
                        className={classNames.eachDetails}
                        key={`eachDependent${index}`}
                      >
                        <div className={classNames.threeDivContainer}>
                          <EachCustomInput
                            placeholder="Enter dependent name"
                            name="dependent_name"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndex(index, newValue)
                            }
                            asteriskIconn="true"
                          />
                          <div>
                            <EachCustomDatePicker
                              //   title="Policy valid until"
                              placeholder="Select date of birth"
                              // dropdown={usersDropdown}
                              name="date_of_birth"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndex(index, newValue)
                              }
                              asteriskIconn="true"
                              values={{ state: formDetails, index: index }}
                              validation={{ type: "relationship" }}
                              //   mandatory={clientOnboardingForm ? true : false}
                            />
                          </div>
                          <div className={classNames.ageBox}>
                            {formDetails?.date_of_birth
                              ? calculateAgeDash(formDetails?.date_of_birth)
                              : "-"}
                          </div>
                        </div>
                        <div className={classNames.twoDivContainer}>
                          <EachCustomDropdown
                            placeholder="Relation"
                            dropdown={
                              isProfileFound?.family_definition ==
                              "Employee + Spouse"
                                ? ["Spouse"]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children"
                                ? ["Spouse", "Son", "Daughter"]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children + 2 Parents"
                                ? [
                                    "Spouse",
                                    "Son",
                                    "Daughter",
                                    "Father",
                                    "Mother",
                                  ]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
                                ? [
                                    "Spouse",
                                    "Son",
                                    "Daughter",
                                    "Father",
                                    "Mother",
                                    "Father-in-Law",
                                    "Mother-in-Law",
                                  ]
                                : []
                              // [
                              //   "Spouse",
                              //   "Son",
                              //   "Daughter",
                              //   "Father",
                              //   "Mother",
                              //   "Father-in-Law",
                              //   "Mother-in-Law",
                              // ]
                            }
                            name="relationship"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndex(index, newValue)
                            }
                            typee="single"
                            asteriskIconn="true"
                          />
                          <EachCustomDropdown
                            placeholder="Gender"
                            dropdown={["Male", "Female"]}
                            name="gender"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndex(index, newValue)
                            }
                            typee="single"
                            asteriskIconn="true"
                          />
                        </div>
                      </div>
                    ))}
                <div
                  className={classNames.submitBtn}
                  style={{ marginBottom: "1rem" }}
                  onClick={
                    Array.isArray(dependentsUpdate) &&
                    dependentsUpdate?.length > 0
                      ? updateDependents
                      : addDependents
                  }
                >
                  Save Dependents
                </div>
                <div
                  className={classNames.submitBtn}
                  style={{ background: "white", color: "var(--font-color)" }}
                  onClick={() => {
                    localStorage.removeItem("employeeVerified");
                    window?.location.reload();
                    predefinedToast("Successfully completed the registration!");
                  }}
                >
                  Skip, I have no dependents
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className={classNames.rightContainer}>
          {tempPasswordStep === 6 ? (
            <div className={classNames.successContainer}>
              <img src={fullLogo} alt="Full Logo" />
              <div className={classNames.title}>You have successfully</div>
              <ol>
                <li>Updated your password</li>
                <li>Verified your personal details</li>
                <li>Added your dependents</li>
              </ol>
              <div
                className={classNames.submitBtn}
                onClick={() => {
                  history.push("/employee/login");
                }}
              >
                Go To Login
              </div>
            </div>
          ) : (
            <div className={classNames.mainContainer}>
              <div className={classNames.title}>
                Great News {isProfileFound?.employee_name}!{" "}
              </div>
              <div className={classNames.desc}>
                {companyProfileDetails?.employee_company_name
                  ? companyProfileDetails.employee_company_name
                  : ""}{" "}
                is adding you to their Tall Tree Coverage Plan. In a couple of
                steps, you will have your own Tall Tree account where you can;
              </div>

              <div className={classNames.allInsurance}>
                <div
                  className={classNames.eachInsurance}
                  style={{
                    display:
                      isProfileFound?.gmc_grade?.toLowerCase() === "na"
                        ? "none"
                        : "",
                  }}
                >
                  <div className={classNames.title}>Health Insurance</div>
                  <div className={classNames.boxContainer}>
                    <div className={classNames.eachItem}>
                      <div className={classNames.title}>Family definition</div>
                      <div className={classNames.valueItem}>
                        {isProfileFound?.family_definition
                          ? isProfileFound?.family_definition
                          : ""}
                      </div>
                    </div>
                    <div className={classNames.eachItem}>
                      <div className={classNames.title}>Sum Insured</div>
                      <div className={classNames.valueItem}>
                        <span>
                          {isProfileFound?.gmc_grade
                            ? isProfileFound?.gmc_grade
                            : ""}
                        </span>
                        <span>
                          {isProfileFound?.gmc_si
                            ? `$${isProfileFound?.gmc_si}`
                            : "$"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.eachInsurance}
                  style={{
                    display:
                      isProfileFound?.gpa_grade?.toLowerCase() === "na"
                        ? "none"
                        : "",
                  }}
                >
                  <div className={classNames.title}>Accident Insurance</div>
                  <div className={classNames.boxContainer}>
                    <div className={classNames.eachItem}>
                      <div className={classNames.title}>Sum Insured</div>
                      <div className={classNames.valueItem}>
                        <span>
                          {isProfileFound?.gpa_grade
                            ? isProfileFound?.gpa_grade
                            : ""}
                        </span>
                        <span>
                          {isProfileFound?.gpa_si
                            ? `$${isProfileFound?.gpa_si}`
                            : "$"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.eachInsurance}
                  style={{
                    display:
                      isProfileFound?.gtli_grade?.toLowerCase() === "na"
                        ? "none"
                        : "",
                  }}
                >
                  <div className={classNames.title}>Life Insurance</div>
                  <div className={classNames.boxContainer}>
                    <div className={classNames.eachItem}>
                      <div className={classNames.title}>Sum Insured</div>
                      <div className={classNames.valueItem}>
                        <span>
                          {isProfileFound?.gtli_grade
                            ? isProfileFound?.gtli_grade
                            : ""}
                        </span>
                        <span>
                          {isProfileFound?.gtli_si
                            ? `$${isProfileFound?.gtli_si}`
                            : "$"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmployee;
