import axios from "axios";

let Youtube_API = "AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU";

export const videosInPlaylist = async (playListId) => {
  try {
    const data = await axios.get(
      `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&playlistId=${playListId}&key=${Youtube_API}`
    );
    return data.data;
  } catch (error) {
    console.error(error, "videosInPlaylist");
  }
};

//PLvM7O5s4wrN1tM5Y5FO3oTQCIJEMCmvKa -> playlistid
