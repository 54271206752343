import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./healthflatcompanyprofile.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import uploading from "../../../assest/images/icons/uploading.svg";
import dummyProfile from "../../../assest/images/icons/dummyProfile.svg";
import { AppContext } from "../../../context/AppContext";

//assets icon
import { ReactComponent as PropertyIcon } from "../../../assest/images/profile/property.svg";
import { ReactComponent as VehicleIcon } from "../../../assest/images/profile/vehicle.svg";
import { ReactComponent as WorldIcon } from "../../../assest/images/profile/world.svg";

import { uploadImageFunc } from "../../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import { Redirect } from "react-router-dom";

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_green.css";
import { EachCustomProfileDropdown } from "../../../components/Custom";
import NavbarV2 from "../../../components/NavbarV2";
import NavbarV3 from "../../../components/NavbarV3";
import { logoutToSameLogin } from "../../../assest/functions";

const HealthFlatCompanyProfile = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    globalUserId,
    globalCompanyId,
    globalHrId,
    setUpdateLoading,
  } = useContext(BankContext);
  const [userCreated, setUserCreated] = useState("");

  const [selectedProfileNav, setSelectedProfileNav] =
    useState("My Company Profile");
  const [searchQuery, setSearchQuery] = useState("");

  const [indianStatesDropdown, setIndianStatesDropdown] = useState("");
  const [createEmployeeSumInsured, setCreateEmployeeSumInsured] = useState("");
  const [corporateLead, setCorporateLead] = useState({
    hr_id: globalHrId
      ? globalHrId
      : localStorage.getItem("globalUserHrIdRaha")
      ? localStorage.getItem("globalUserHrIdRaha")
      : "",
    company_id: localStorage.getItem("globalCompanyIdRaha")
      ? localStorage.getItem("globalCompanyIdRaha")
      : globalCompanyId
      ? globalCompanyId
      : "",
    insurance_type: "Health",
    coverage_type: "Flat",
    covered_persons: [],
    sum_insured: [],
    currency: "USD",
  });

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/general/getstates`
    )
      .then((response) => {
        console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setIndianStatesDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  function createHealthConfigurations() {
    setUpdateLoading(true);
    let { covered_persons, ...finalObject } = corporateLead;

    const uniqueCoveredPersons = [...new Set(covered_persons)];

    let finalObj = { ...finalObject, covered_persons: uniqueCoveredPersons };

    if (!finalObj?.company_id) {
      finalObj = {
        ...finalObj,
        company_id: localStorage.getItem("globalCompanyIdRaha")
          ? localStorage.getItem("globalCompanyIdRaha")
          : globalCompanyId
          ? globalCompanyId
          : "",
      };
    }

    if (corporateLead?.coverage_type == "Flat") {
      finalObj = {
        ...finalObj,
        sum_insured: [finalObj?.sum_insured],
      };
    } else {
      finalObj = {
        ...finalObj,
        sum_insured: [...createEmployeeSumInsured],
      };
    }

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/policy/createQuotation",
      finalObj
    )
      .then((response) => {
        // console.log(response, "health configuration created");
        setUpdateLoading(false);
        if (response?.data?.status) {
          // predefinedToast(response?.data?.message);
          setTimeout(() => {
            setUserCreated(false);
            setPopularAction(false);
            history.push("/app/coverfi/policyrequirements");
            window.location.reload();
          }, 3000);
          setUserCreated(true);
        } else {
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "health configuration create error");
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
  }

  let healthOptions = [
    "Only The Employee",
    "Employee + Spouse",
    "Employee + Spouse + 2 Children",
    "Employee + Spouse + 2 Children + 2 Parents",
    "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws",
  ];

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newCompanyProfileStep2Parent}>
      {userCreated ? (
        <div className={classNames.successfulMessage}>
          <div>You have successfully updated your policy configurations</div>
          <div>You will be redirected to your policy requirments page</div>
        </div>
      ) : (
        <>
          <NavbarV3
            dropdownItems={[]}
            selectedTab={selectedProfileNav}
            setSelectedTab={setSelectedProfileNav}
            setSearchQuery={setSearchQuery}
          />
          <div className={classNames.contentContainer}>
            <div className={classNames.companyProfileStep2}>
              <div className={classNames.title}>
                Configuring Health Insurance Requirements
              </div>
              <div className={classNames.inputContainers}>
                <EachInputSelectOne
                  title="Coverage Type"
                  placeholder="Enter name..."
                  name="coverage_type"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                  options={["Flat", "Graded"]}
                />
                <EachInputSelectMultiples
                  title="Select who will be covered in the health insurance policy"
                  name="covered_persons"
                  stateValue={corporateLead}
                  setState={setCorporateLead}
                  options={healthOptions}
                />
                {corporateLead?.coverage_type == "Flat" ? (
                  <EachInput
                    title="How much coverage do you wish to offer to each employee?"
                    placeholder="Enter amount..."
                    name="sum_insured"
                    stateValue={corporateLead}
                    setState={setCorporateLead}
                    banner={"Flat"}
                  />
                ) : (
                  <EachProfileInputArray
                    title="How much coverage do you wish to offer to each employee?"
                    placeholder="Enter amount..."
                    dropdown=""
                    stateValue={createEmployeeSumInsured}
                    setState={setCreateEmployeeSumInsured}
                    banner={"Health"}
                    name="sum_insured"
                    stateValueMain={corporateLead}
                  />
                )}
              </div>
            </div>

            <div className={classNames.btnsContainer}>
              <div
                onClick={() => {
                  if (popularAction) {
                    setPopularAction(false);
                  } else {
                    setDashboardHam(!dashboardHam);
                  }
                  history.push("/app/coverfi/companyprofile");
                }}
              >
                Close Form
              </div>
              <div onClick={createHealthConfigurations}>
                Save Health Configurations
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HealthFlatCompanyProfile;

const EachInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  banner,
}) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputDiv}>
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          onChange={(event) => {
            setState({ ...stateValue, [name]: event?.target?.value });
          }}
        />
        <div className={classNames.selectedBanner}>{banner}</div>
      </div>
    </div>
  );
};
const EachInputSelectOne = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  return (
    <div className={classNames.eachInputPickOne}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.selectOneAllOptions}>
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    setSelectedValue(eachOption);
                    setState({ ...stateValue, [name]: eachOption });
                  }}
                  style={{
                    textAlign: "center",
                    width: "max-content",
                    padding: "1rem 2rem",
                    cursor: "pointer",
                    fontSize: "0.9rem",
                    border:
                      selectedValue == eachOption
                        ? "1.5px solid var(--main-color)"
                        : "1.5px solid #e5e5e5",
                    borderRadius: "35px",
                    transition: "all linear 0.3s",
                    marginTop: "1rem",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const EachInputSelectMultiples = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);
  return (
    <div className={classNames.eachInputPickOne}>
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.selectOneAllOptions}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          fontSize: "1rem",
          marginTop: "0.5rem",
        }}
      >
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    if (
                      selectedValue?.includes(eachOption) ||
                      stateValue[name]?.includes(eachOption)
                    ) {
                      setSelectedValue(
                        selectedValue?.filter((item) => item !== eachOption)
                      );
                      let { covered_persons, ...stateValuee } = stateValue;
                      let cv = covered_persons?.filter(
                        (item) => item !== eachOption
                      );
                      setState({ ...stateValuee, covered_persons: cv });
                    } else {
                      setSelectedValue([...selectedValue, eachOption]);
                      setState({
                        ...stateValue,
                        [name]: [...stateValue[name], eachOption],
                      });
                    }
                  }}
                  style={{
                    textAlign: "center",
                    width: "max-content",
                    padding: "1rem 2rem",
                    border: selectedValue?.includes(eachOption)
                      ? "1.5px solid var(--main-color)"
                      : "1.5px solid #e5e5e5",
                    borderRadius: "35px",
                    transition: "all linear 0.3s",
                    marginTop: "1rem",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const EachInputSelectMultiple = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.assetsContainer}>
        <div
          className={classNames.eachAsset}
          onClick={() => {
            if (selectedValue?.includes("health")) {
              setSelectedValue(
                selectedValue?.filter((item) => item !== "health")
              );
              let {
                health,
                health_sum_insured,
                health_coverage_people,
                ...stateValuee
              } = stateValue;
              setState({ ...stateValuee });
            } else {
              setSelectedValue([...selectedValue, "health"]);
              setState({ ...stateValue, health: "yes" });
            }
          }}
          style={{
            boxShadow: selectedValue?.includes("health")
              ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
              : "",
          }}
        >
          <div>Health</div>
          <div>
            <PropertyIcon />
            <div className={classNames.shadeArea}></div>
          </div>
        </div>
        <div
          className={classNames.eachAsset}
          onClick={() => {
            if (selectedValue?.includes("accident")) {
              setSelectedValue(
                selectedValue?.filter((item) => item !== "accident")
              );
              let {
                accident,
                accident_sum_insured,
                accident_coverage_type,
                ...stateValuee
              } = stateValue;
              setState({ ...stateValuee });
            } else {
              setSelectedValue([...selectedValue, "accident"]);
              setState({ ...stateValue, accident: "yes" });
            }
          }}
          style={{
            boxShadow: selectedValue?.includes("accident")
              ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
              : "",
          }}
        >
          <div>Accidental</div>
          <div>
            <VehicleIcon />
            <div className={classNames.shadeArea}></div>
          </div>
        </div>
        <div
          className={classNames.eachAsset}
          onClick={() => {
            if (selectedValue?.includes("life")) {
              setSelectedValue(
                selectedValue?.filter((item) => item !== "life")
              );
              let {
                life,
                life_sum_insured,
                life_coverage_type,
                ...stateValuee
              } = stateValue;
              setState({ ...stateValuee });
            } else {
              setSelectedValue([...selectedValue, "life"]);
              setState({ ...stateValue, life: "yes" });
            }
          }}
          style={{
            boxShadow: selectedValue?.includes("life")
              ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
              : "",
          }}
        >
          <div>Life</div>
          <div>
            <WorldIcon />
            <div className={classNames.shadeArea}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EachInputPara = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <textarea
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      ></textarea>
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      // style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          zIndex: isOpen ? "5" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "State"
              ? "Select state.."
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : title == "Job Type" || title == "Select User"
              ? "Click To Select"
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "State"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : title == "Number Of Living In-Laws" ||
                title == "Number Of Living Parents" ||
                title == "Number Of Children" ||
                title == "Martial Status"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select Brokerage"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.brokerage_name,
                            eachitem?.brokerage_icon
                          );
                          setState(eachitem?.brokerage_username);
                        }}
                        key={eachitem?.brokerage_name + index}
                        style={{
                          display:
                            eachitem?.brokerage_username === stateValue
                              ? "none"
                              : "",
                        }}
                      >
                        <img
                          src={eachitem?.brokerage_icon}
                          alt={eachitem?.brokerage_name}
                        />
                        {eachitem?.brokerage_name}
                      </li>
                    );
                  })
              : title == "Select User"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.first_name + eachitem?.last_name,
                            eachitem?.profile_picture
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem?.user_email,
                          });
                        }}
                        key={eachitem?.first_name + index}
                      >
                        <img
                          src={eachitem?.profile_picture}
                          alt={eachitem?.first_name}
                        />
                        <div>
                          <div>
                            {eachitem?.first_name} &nbsp; {eachitem?.last_name}
                          </div>
                          <div>{eachitem?.user_email}</div>
                        </div>
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name, eachitem?.app_icon);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"Select Brokerage"}
              >
                {title == "Select Brokerage"
                  ? "Search one of your brokerage.."
                  : "Select an option"}
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanB"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePic = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDivProfilePic}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanBProfilePic"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanBProfilePic"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanBProfilePic"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await", name);
          }}
          accept="image/*"
          id="profileUpdateImgPlanBProfilePic"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

const EachProfileInputArray = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
  banner,
  name,
  stateValueMain,
}) => {
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [allInputs, setAllInputs] = useState([]);
  const [numberOfEmails, setNumberOfEmails] = useState(1);

  // const handleEmailChange = (index, key, value) => {
  //   const updatedStateValue = [...stateValue];
  //   if (!updatedStateValue[index]) {
  //     updatedStateValue[index] = {};
  //   }
  //   updatedStateValue[index][key] = value;
  //   setState(updatedStateValue);
  // };

  const handleAddEmail = () => {
    setAllInputs((prev) => [...prev, currentInputValue]);
    setState((prev) => [...prev, currentInputValue]); // Add an empty object to the array

    setCurrentInputValue("");
    setNumberOfEmails((prev) => prev + 1);
  };

  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      {Array.from({ length: numberOfEmails })?.map((email, index) => (
        //    <div className={classNames.inputDiv} key={index}>
        //   <input
        //     className={classNames.inputContainer}
        //     placeholder={placeholder}
        //     name={name}
        //     onChange={(event) =>
        //     handleEmailChange(index, "employee_email", event.target.value)
        //     }
        //   />
        //   <div className={classNames.selectedBanner}>{banner}</div>
        // </div>
        <div className={classNames.addByEmail} key={index}>
          <div className={classNames.inputParent}>
            <div
              className={classNames.inputDiv}
              key={index}
              style={{
                pointerEvents: allInputs?.length == index ? "" : "none",
              }}
            >
              <input
                className={classNames.inputContainer}
                placeholder={placeholder}
                name={name}
                value={allInputs[index]}
                onChange={(event) =>
                  // handleEmailChange(index, "employee_email", event.target.value)
                  setCurrentInputValue(event.target.value)
                }
              />
              <div
                className={classNames.selectedBanner}
                style={{
                  background:
                    allInputs?.length == index ? "" : "var(--main-color)",
                  color: allInputs?.length == index ? "" : "white",
                }}
              >
                {"Grade " + (index + 1)}
              </div>
            </div>
            <div
              className={classNames.addButton}
              onClick={handleAddEmail}
              style={{
                pointerEvents: currentInputValue ? "" : "none",
                display:
                  stateValueMain?.coverage_type == "Flat"
                    ? "none"
                    : numberOfEmails - 1 === index
                    ? ""
                    : "none",
              }}
            >
              +
            </div>
          </div>
        </div>
      ))}

      {/* {allInputs?.length == 0 && (
        <div className={classNames.addByEmail}>
          <div className={classNames.inputParent}>
            <div className={classNames.inputDiv}>
              <input
                className={classNames.inputContainer}
                placeholder={placeholder}
                name={name}
                onChange={(event) =>
                  // handleEmailChange(index, "employee_email", event.target.value)
                  setCurrentInputValue(event.target.value)
                }
              />
              <div
                className={classNames.selectedBanner}
                style={{
                  background:
                    allInputs?.length - 1 > numberOfEmails
                      ? "var(--main-color)"
                      : "",
                }}
              >
                {"Grade 1"}
              </div>
            </div>
            <div
              className={classNames.addButton}
              style={{ pointerEvents: currentInputValue ? "" : "none" }}
              onClick={handleAddEmail}
            >
              +
            </div>
          </div>
        </div>
      )} */}

      {dropdown && <IoMdArrowDropdown />}
    </div>
  );
};
