import React, { useEffect, useState } from "react";
import styles from "./clientDetails.module.scss";
import Axios from "axios";
import { BsArrowBarDown, BsArrowDown, BsCheck } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa6";

import { ReactComponent as CopyIcon } from "../../../../assest/images/allicons/tableaction/copy.svg";

const ClientDetails = ({ selectedRow }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [selectedTab, setSelectedTab] = useState({
    tabName: "Lead Details",
    enabled: true,
  });
  const [copyLoading, setCopyLoading] = useState(null);
  const tabs = [
    {
      tabName: "Lead Details",
      enabled: true,
    },
    {
      tabName: "User Details",
      enabled: true,
    },
    {
      tabName: "HR Details",
      enabled: true,
    },
    {
      tabName: "HR Company Details",
      enabled: true,
    },
    {
      tabName: "Employee Details",
      enabled: false,
    },
    {
      tabName: "CD Balance",
      enabled: true,
    },
    {
      tabName: "Wellness Wallet",
      enabled: true,
    },
    {
      tabName: "Wellness Cart",
      enabled: true,
    },
  ];

  const [showTxnIds, setShowTxnIds] = useState(false);
  const [selectedTnx, setSelectedTnx] = useState(null);

  useEffect(() => {
    if (copyLoading) {
      setTimeout(() => {
        setCopyLoading(null);
      }, 1000);
    }
  }, [copyLoading]);

  useEffect(() => {
    if (selectedRow) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${selectedRow?.lead_email}`
      ).then(({ data }) => {
        if (data.status) {
          setUserInfo(data.data);
        }
      });
    }
  }, [selectedRow]);

  const conditionalTabData = () => {
    if (selectedTab.tabName === "Lead Details") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?._id}</div>
                {copyLoading === userInfo?.leadDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?._id);
                      navigator.clipboard.writeText(userInfo?.leadDetails?._id);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Lead ID</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.lead_id}</div>
                {copyLoading === userInfo?.leadDetails?.lead_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.lead_id);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.lead_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>External Lead</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.external_lead ? "True" : "False"}
                </div>
                {copyLoading === userInfo?.leadDetails?.external_lead ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.external_lead);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.external_lead
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>First name</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.first_name}</div>
                {copyLoading === userInfo?.leadDetails?.first_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.first_name);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.first_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last name</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.last_name}</div>
                {copyLoading === userInfo?.leadDetails?.last_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.last_name);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.last_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Lead Type</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.lead_type}</div>
                {copyLoading === userInfo?.leadDetails?.lead_type ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.lead_type);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.lead_type
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Lead source</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.lead_source}</div>
                {copyLoading === userInfo?.leadDetails?.lead_source ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.lead_source);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.lead_source
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Company name</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.company_name}</div>
                {copyLoading === userInfo?.leadDetails?.company_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.company_name);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.company_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Added by</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.addedBy}</div>
                {copyLoading === userInfo?.leadDetails?.addedBy ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.addedBy);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.addedBy
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Phone number</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.phone_number}</div>
                {copyLoading === userInfo?.leadDetails?.phone_number ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.phone_number);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.phone_number
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Company size</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.company_size}</div>
                {copyLoading === userInfo?.leadDetails?.company_size ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.company_size);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.company_size
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>State</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.state !== null
                    ? userInfo?.leadDetails?.state
                    : "null"}
                </div>
                {userInfo?.leadDetails?.state !== null &&
                copyLoading === userInfo?.leadDetails?.state ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.state);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.state
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Pin code</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.leadDetails?.pin_code !== null
                    ? userInfo?.leadDetails?.pin_code
                    : "null"}
                </div>
                {userInfo?.leadDetails?.pin_code !== null &&
                copyLoading === userInfo?.leadDetails?.pin_code ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.pin_code);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.pin_code
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Reference email</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.reference_email}</div>
                {copyLoading === userInfo?.leadDetails?.reference_email ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.reference_email);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.reference_email
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Agent ID</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.agent_id}</div>
                {copyLoading === userInfo?.leadDetails?.agent_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.agent_id);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.agent_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Agent affiliate ID</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.leadDetails?.agent_affiliate_id}</div>
                {copyLoading === userInfo?.leadDetails?.agent_affiliate_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.leadDetails?.agent_affiliate_id);
                      navigator.clipboard.writeText(
                        userInfo?.leadDetails?.agent_affiliate_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "User Details") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.userDetails?._id}</div>
                {copyLoading === userInfo?.userDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?._id);
                      navigator.clipboard.writeText(userInfo?.userDetails?._id);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>User ID</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.userDetails?.user_id}</div>
                {copyLoading === userInfo?.userDetails?.user_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.user_id);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.user_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>First name</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.userDetails?.first_name}</div>
                {copyLoading === userInfo?.userDetails?.first_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.first_name);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.first_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Last name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.last_name !== null
                    ? userInfo?.userDetails?.last_name
                    : "null"}
                </div>
                {userInfo?.userDetails?.last_name !== null &&
                copyLoading === userInfo?.userDetails?.last_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.last_name);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.last_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}># of parents</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.no_of_parents !== null
                    ? userInfo?.userDetails?.no_of_parents
                    : "null"}
                </div>
                {userInfo?.userDetails?.no_of_parents !== null &&
                copyLoading === userInfo?.userDetails?.no_of_parents ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.no_of_parents);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.no_of_parents
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}># of children</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.no_of_children !== null
                    ? userInfo?.userDetails?.no_of_children
                    : "null"}
                </div>
                {userInfo?.userDetails?.no_of_children !== null &&
                copyLoading === userInfo?.userDetails?.no_of_children ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.no_of_children);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.no_of_children
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}># of parents in law</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.no_of_parents_in_law !== null
                    ? userInfo?.userDetails?.no_of_parents_in_law
                    : "null"}
                </div>
                {userInfo?.userDetails?.no_of_parents_in_law !== null &&
                copyLoading === userInfo?.userDetails?.no_of_parents_in_law ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.userDetails?.no_of_parents_in_law
                      );
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.no_of_parents_in_law
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Primary phone number</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.primary_phone_number !== null
                    ? userInfo?.userDetails?.primary_phone_number
                    : "null"}
                </div>
                {userInfo?.userDetails?.primary_phone_number !== null &&
                copyLoading === userInfo?.userDetails?.primary_phone_number ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.userDetails?.primary_phone_number
                      );
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.primary_phone_number
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Address</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.address !== null
                    ? userInfo?.userDetails?.address
                    : "null"}
                </div>
                {userInfo?.userDetails?.address !== null &&
                copyLoading === userInfo?.userDetails?.address ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.address);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.address
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Date of birth</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.date_of_birth !== null
                    ? userInfo?.userDetails?.date_of_birth
                    : "null"}
                </div>
                {userInfo?.userDetails?.date_of_birth !== null &&
                copyLoading === userInfo?.userDetails?.date_of_birth ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.date_of_birth);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.date_of_birth
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>City</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.city !== null
                    ? userInfo?.userDetails?.city
                    : "null"}
                </div>
                {userInfo?.userDetails?.city !== null &&
                copyLoading === userInfo?.userDetails?.city ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.city);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.city
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Country</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.country !== null
                    ? userInfo?.userDetails?.country
                    : "null"}
                </div>
                {userInfo?.userDetails?.country !== null &&
                copyLoading === userInfo?.userDetails?.country ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.country);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.country
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Gender</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.gender !== null
                    ? userInfo?.userDetails?.gender
                    : "null"}
                </div>
                {userInfo?.userDetails?.gender !== null &&
                copyLoading === userInfo?.userDetails?.gender ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.gender);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.gender
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>State</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.state !== null
                    ? userInfo?.userDetails?.state
                    : "null"}
                </div>
                {userInfo?.userDetails?.state !== null &&
                copyLoading === userInfo?.userDetails?.state ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.state);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.state
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Pincode</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.pin_code !== null
                    ? userInfo?.userDetails?.pin_code
                    : "null"}
                </div>
                {userInfo?.userDetails?.pin_code !== null &&
                copyLoading === userInfo?.userDetails?.pin_code ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.pin_code);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.pin_code
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Martial status</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.martial_status !== null
                    ? userInfo?.userDetails?.martial_status
                    : "null"}
                </div>
                {userInfo?.userDetails?.martial_status !== null &&
                copyLoading === userInfo?.userDetails?.martial_status ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.martial_status);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.martial_status
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Designation</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.userDetails?.designation !== null
                    ? userInfo?.userDetails?.designation
                    : "null"}
                </div>
                {userInfo?.userDetails?.designation !== null &&
                copyLoading === userInfo?.userDetails?.designation ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.userDetails?.designation);
                      navigator.clipboard.writeText(
                        userInfo?.userDetails?.designation
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Profile picture</div>
              <div className={styles.userDetailValue}>profile pic</div>
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "HR Details") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.hrDetails?._id}</div>
                {copyLoading === userInfo?.hrDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?._id);
                      navigator.clipboard.writeText(userInfo?.hrDetails?._id);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>HR ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hr_id !== null
                    ? userInfo?.hrDetails?.hr_id
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hr_id !== null &&
                copyLoading === userInfo?.hrDetails?.hr_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.hr_id);
                      navigator.clipboard.writeText(userInfo?.hrDetails?.hr_id);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Company ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.company_id !== null
                    ? userInfo?.hrDetails?.company_id
                    : "null"}
                </div>
                {userInfo?.hrDetails?.company_id !== null &&
                copyLoading === userInfo?.hrDetails?.company_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.company_id);
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.company_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Date of joining</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.date_of_joining !== null
                    ? userInfo?.hrDetails?.date_of_joining
                    : "null"}
                </div>
                {userInfo?.hrDetails?.date_of_joining !== null &&
                copyLoading === userInfo?.hrDetails?.date_of_joining ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.date_of_joining);
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.date_of_joining
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Date of leaving</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.date_of_leaving !== null
                    ? userInfo?.hrDetails?.date_of_leaving
                    : "null"}
                </div>
                {userInfo?.hrDetails?.date_of_leaving !== null &&
                copyLoading === userInfo?.hrDetails?.date_of_leaving ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.date_of_leaving);
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.date_of_leaving
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Role name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.role_name !== null
                    ? userInfo?.hrDetails?.role_name
                    : "null"}
                </div>
                {userInfo?.hrDetails?.role_name !== null &&
                copyLoading === userInfo?.hrDetails?.role_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.role_name);
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.role_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>First name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.first_name !== null
                    ? userInfo?.hrDetails?.first_name
                    : "null"}
                </div>
                {userInfo?.hrDetails?.first_name !== null &&
                copyLoading === userInfo?.hrDetails?.first_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.first_name);
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.first_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.last_name !== null
                    ? userInfo?.hrDetails?.last_name
                    : "null"}
                </div>
                {userInfo?.hrDetails?.last_name !== null &&
                copyLoading === userInfo?.hrDetails?.last_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.last_name);
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.last_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Phone number</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.phone_number !== null
                    ? userInfo?.hrDetails?.phone_number
                    : "null"}
                </div>
                {userInfo?.hrDetails?.phone_number !== null &&
                copyLoading === userInfo?.hrDetails?.phone_number ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrDetails?.phone_number);
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.phone_number
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Profile picture</div>
              <div className={styles.userDetailValue}>Profile pic</div>
            </div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "HR Company Details") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.hrDetails?.hrCompanyDetails?._id}</div>
                {copyLoading === userInfo?.hrDetails?.hrCompanyDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?._id
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?._id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>User ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.user_id !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.user_id
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.user_id !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.user_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.user_id
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.user_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Company ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.company_id !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.company_id
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.company_id !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.company_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.company_id
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.company_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Address</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.address !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.address
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.address !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.address ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.address
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.address
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>City</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.city !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.city
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.city !== null &&
                copyLoading === userInfo?.hrDetails?.hrCompanyDetails?.city ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.city
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.city
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>PinCode</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.pin_code !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.pin_code
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.pin_code !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.pin_code ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.pin_code
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.pin_code
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>State</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.state !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.state
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.state !== null &&
                copyLoading === userInfo?.hrDetails?.hrCompanyDetails?.state ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.state
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.state
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Country</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.country !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.country
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.country !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.country ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.country
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.country
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Website</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.website !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.website
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.website !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.website ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.website
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.website
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Legal name</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.legal_name !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.legal_name
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.legal_name !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.legal_name ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.legal_name
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.legal_name
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Industry</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.industry !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.industry
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.industry !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.industry ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.industry
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.industry
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Description</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrDetails?.hrCompanyDetails?.description !== null
                    ? userInfo?.hrDetails?.hrCompanyDetails?.description?.substring(
                        0,
                        20
                      ) + "..."
                    : "null"}
                </div>
                {userInfo?.hrDetails?.hrCompanyDetails?.description !== null &&
                copyLoading ===
                  userInfo?.hrDetails?.hrCompanyDetails?.description ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrDetails?.hrCompanyDetails?.description
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrDetails?.hrCompanyDetails?.description
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Icon</div>
              <div className={styles.userDetailValue}>Icon</div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Logo</div>
              <div className={styles.userDetailValue}>Logo</div>
            </div>
            <div></div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "CD Balance") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>{userInfo?.hrCBBalanceDetails?._id}</div>
                {copyLoading === userInfo?.hrCBBalanceDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrCBBalanceDetails?._id);
                      navigator.clipboard.writeText(
                        userInfo?.hrCBBalanceDetails?._id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Cd Balance ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrCBBalanceDetails?.cdBalance_id !== null
                    ? userInfo?.hrCBBalanceDetails?.cdBalance_id
                    : "null"}
                </div>
                {userInfo?.hrCBBalanceDetails?.cdBalance_id !== null &&
                copyLoading === userInfo?.hrCBBalanceDetails?.cdBalance_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.hrCBBalanceDetails?.cdBalance_id
                      );
                      navigator.clipboard.writeText(
                        userInfo?.hrCBBalanceDetails?.cdBalance_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Balance</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.hrCBBalanceDetails?.cd_balance !== null
                    ? userInfo?.hrCBBalanceDetails?.cd_balance
                    : "null"}
                </div>
                {userInfo?.hrCBBalanceDetails?.cd_balance !== null &&
                copyLoading === userInfo?.hrCBBalanceDetails?.cd_balance ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.hrCBBalanceDetails?.cd_balance);
                      navigator.clipboard.writeText(
                        userInfo?.hrCBBalanceDetails?.cd_balance
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "Wellness Wallet") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?._id !== null
                    ? userInfo?.companyWalletDetails?._id
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?._id !== null &&
                copyLoading === userInfo?.companyWalletDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyWalletDetails?._id);
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?._id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Wallet Type</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.type_of_wallet !== null
                    ? userInfo?.companyWalletDetails?.type_of_wallet
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.type_of_wallet !== null &&
                copyLoading ===
                  userInfo?.companyWalletDetails?.type_of_wallet ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.companyWalletDetails?.type_of_wallet
                      );
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.type_of_wallet
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Balance</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.balance !== null
                    ? userInfo?.companyWalletDetails?.balance
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.balance !== null &&
                copyLoading === userInfo?.companyWalletDetails?.balance ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyWalletDetails?.balance);
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.balance
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Active</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.is_active !== null
                    ? userInfo?.companyWalletDetails?.is_active
                      ? "True"
                      : "False"
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.is_active !== null &&
                copyLoading === userInfo?.companyWalletDetails?.is_active ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyWalletDetails?.is_active);
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.is_active
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Created on</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.created_at !== null
                    ? userInfo?.companyWalletDetails?.created_at
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.created_at !== null &&
                copyLoading === userInfo?.companyWalletDetails?.created_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.companyWalletDetails?.created_at
                      );
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.created_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last updated</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyWalletDetails?.updated_at !== null
                    ? userInfo?.companyWalletDetails?.updated_at
                    : "null"}
                </div>
                {userInfo?.companyWalletDetails?.updated_at !== null &&
                copyLoading === userInfo?.companyWalletDetails?.updated_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(
                        userInfo?.companyWalletDetails?.updated_at
                      );
                      navigator.clipboard.writeText(
                        userInfo?.companyWalletDetails?.updated_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Transaction ID’s</div>
              {userInfo?.companyWalletDetails?.transaction_id?.length > 0 ? (
                showTxnIds ? (
                  <div
                    className={styles.userDetailValue}
                    style={{
                      height: "100px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "scroll",
                    }}
                  >
                    {Array.isArray(
                      userInfo?.companyWalletDetails?.transaction_id
                    ) &&
                    userInfo?.companyWalletDetails?.transaction_id?.length > 0
                      ? userInfo?.companyWalletDetails?.transaction_id?.map(
                          (item) => (
                            <div
                              onClick={(e) => {
                                setShowTxnIds(!showTxnIds);
                                setSelectedTnx(item);
                              }}
                            >
                              {item}
                            </div>
                          )
                        )
                      : ""}
                  </div>
                ) : (
                  <div
                    className={styles.userDetailValue}
                    onClick={(e) => setShowTxnIds(!showTxnIds)}
                  >
                    {selectedTnx ? selectedTnx : "Click to select"}
                    <FaChevronDown />
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else if (selectedTab.tabName === "Wellness Cart") {
      return (
        <div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>_id</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?._id !== null
                    ? userInfo?.companyCartDetails?._id
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?._id !== null &&
                copyLoading === userInfo?.companyCartDetails?._id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?._id);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?._id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Address ID</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.address_id !== null
                    ? userInfo?.companyCartDetails?.address_id
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.address_id !== null &&
                copyLoading === userInfo?.companyCartDetails?.address_id ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.address_id);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.address_id
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Is Active</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.is_active !== null
                    ? userInfo?.companyCartDetails?.is_active
                      ? "True"
                      : "False"
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.is_active !== null &&
                copyLoading === userInfo?.companyCartDetails?.is_active ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.is_active);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.is_active
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rowStyle}>
            <div>
              <div className={styles.userDetailTitle}>Created on </div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.created_at !== null
                    ? userInfo?.companyCartDetails?.created_at
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.created_at !== null &&
                copyLoading === userInfo?.companyCartDetails?.created_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.created_at);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.created_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={styles.userDetailTitle}>Last updated</div>
              <div className={styles.userDetailValue}>
                <div>
                  {userInfo?.companyCartDetails?.updated_at !== null
                    ? userInfo?.companyCartDetails?.updated_at
                    : "null"}
                </div>
                {userInfo?.companyCartDetails?.updated_at !== null &&
                copyLoading === userInfo?.companyCartDetails?.updated_at ? (
                  <BsCheck />
                ) : (
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCopyLoading(userInfo?.companyCartDetails?.updated_at);
                      navigator.clipboard.writeText(
                        userInfo?.companyCartDetails?.updated_at
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className={styles.tabWrapper}>
        {tabs.map((item) => (
          <div
            className={
              selectedTab.tabName === item.tabName
                ? styles.selectedTabItem
                : styles.tabItem
            }
            style={{ opacity: item.enabled ? 1 : 0.4 }}
            onClick={(e) => {
              if (item.enabled) {
                setSelectedTab(item);
              }
            }}
          >
            {item?.tabName}
          </div>
        ))}
      </div>
      {conditionalTabData()}
    </div>
  );
};

export default ClientDetails;
