import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import LoginPage from "./pages/Login/Login";
import BankContextProvider from "./context/Context";
import AppContextProvider from "./context/AppContext";
import { QueryClient, QueryClientProvider } from "react-query";

import Dashboard from "./components/Dashboard";
import Master from "./components/DashboardComponents/Master";
import NewBrokerage from "./pages/NewBrokerage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewAgent from "./pages/NewAgent";
import NewCorporateLead from "./pages/NewCorporateLead";
import NewIndividualLead from "./pages/NewIndividualLead";
import Overlay from "./components/Custom/Overlay";

import "react-loading-skeleton/dist/skeleton.css";
import NewIndividualLeadAgency from "./pages/Agency/NewIndividualLead";
import NewCorporateLeadAgency from "./pages/Agency/NewCorporateLead";
import RegistrationContextProvider from "./context/RegistrationContext";
import HomePage from "./pages/Registration/pages/HomePage";
import FirstPage from "./pages/Registration/pages/FirstPage";
import VisitLayout from "./Layouts/VisitLayout";
import EmployeeLogin from "./pages/Employee/Login";
import EmployeeBenefits from "./pages/Employee/Benefit";
import EmployeeDetails from "./pages/Employee/Details";
import PreLogin from "./pages/PreLogin";
import NewEmployee from "./pages/Employer/NewEmployee";
import GetQuote from "./pages/GetQuote";
import TempLoginEmployees from "./pages/Login/TempLoginEmployees";
import GetQuoteTest from "./pages/GetQuote/getquoteself";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Disclaimer from "./pages/Disclaimer";
import Careers from "./pages/Careers";
import NewHRClient from "./pages/Agency/NewHRClient";
import PreLoginPolicies from "./pages/PreLoginPolicies";
import FeebackForm from "./pages/Employer/FeedbackForm";
import EnrollmentForm from "./pages/EnrollmentForm";
import TransactionHistory from "./pages/TransactionHistory";
import EnrollmentFormVisista from "./pages/EnrollmentFormVisista";
import IobPortal from "./pages/EnrollmentForm/IobPortal";
import OnLoginCompanyForm from "./components/OnLoginCompanyForm";
import ClientOnboardingForm from "./pages/Agency/CRM/tabs/ClientOnboardingForm";
import EmployerEmployeeLogin from "./pages/Login/EmployerEmployeeLogin";
import NewQuote from "./pages/NewQuote";
import BasicHome from "./pages/BasicHome";
import TempLoginEmployeesInternal from "./pages/Login/TempLoginEmployeesInternal";
import TempLoginEmployeesExisting from "./pages/Login/TempLoginEmployeesExisting";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        includedLanguages: "en,es",
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RegistrationContextProvider>
          <BankContextProvider>
            <AppContextProvider>
              <ToastContainer
                toastStyle={{
                  backgroundColor: "var(--main-color)",
                  color: "white",
                  fontWeight: "550",
                  borderRadius: "15px",
                }}
              />
              <Overlay />

              <OnLoginCompanyForm />

              <>
                <Router>
                  <Switch>
                    <Route path="/login" exact component={PreLogin} />
                    <Route
                      path="/employees/internal/:leademail"
                      exact
                      component={TempLoginEmployeesInternal}
                    />
                    <Route
                      path="/employees/external/:leademail"
                      exact
                      component={TempLoginEmployees}
                    />
                    <Route
                      path="/employees/existing/:leademail"
                      exact
                      component={TempLoginEmployeesExisting}
                    />
                    {/* <Route
                      path="/:loggedapp/login"
                      exact
                      component={LoginPage}
                    /> */}
                    <Route
                      path="/:loggedapp/login"
                      exact
                      component={EmployerEmployeeLogin}
                    />
                    <Route
                      exact
                      path="/employeesignup"
                      render={() => <VisitLayout child={<EmployeeLogin />} />}
                    />
                    <Route
                      exact
                      path="/benefits"
                      render={() => (
                        <VisitLayout child={<EmployeeBenefits />} />
                      )}
                    />
                    <Route
                      exact
                      path="/shipdeliverypolicy"
                      component={PreLoginPolicies}
                    />
                    <Route
                      exact
                      path="/refundcancellationpolicy"
                      component={PreLoginPolicies}
                    />
                    <Route
                      exact
                      path="/iob/enrollment/transactionhistory"
                      component={TransactionHistory}
                    />
                    <Route
                      exact
                      path="/iob/enrollmentvisista"
                      component={EnrollmentFormVisista}
                    />
                    <Route
                      exact
                      path="/iob/enrollment/:status"
                      component={EnrollmentForm}
                    />
                    <Route
                      exact
                      path="/iob/enrollment"
                      component={EnrollmentForm}
                    />
                    <Route exact path="/initiateIOB" component={IobPortal} />
                    <Route
                      exact
                      path="/employeedetails"
                      render={() => <VisitLayout child={<EmployeeDetails />} />}
                    />
                    <Route
                      exact
                      path="/register"
                      component={window.innerWidth > 600 ? HomePage : LoginPage}
                    />
                    <Route
                      exact
                      path="/register/affiliate"
                      component={
                        window.innerWidth > 600 ? FirstPage : LoginPage
                      }
                    />
                    <Route
                      exact
                      path="/register/affiliate/:id"
                      component={
                        window.innerWidth > 600 ? FirstPage : LoginPage
                      }
                    />
                    <Route
                      exact
                      path="/register/pre-registered"
                      component={FirstPage}
                    />
                    <Route
                      exact
                      path="/register/pre-registered/:id"
                      component={FirstPage}
                    />
                    <Route
                      exact
                      path="/register/by-myself"
                      component={FirstPage}
                    />
                    <Route
                      exact
                      path="/register/by-myself/:id"
                      component={FirstPage}
                    />
                    <Route path="/getquote" exact component={GetQuoteTest} />
                    <Route path="/newquote" exact component={NewQuote} />
                    {/* <Route path="/rahax/about" exact component={Home} /> */}
                    <Route path="/home" exact component={BasicHome} />
                    <Route
                      exact
                      path="/"
                      // render={() => <Redirect to="/rahax/about" />
                      render={() => <Redirect to="/home" />}
                    />
                    <Route
                      exact
                      path="/app/:apptype/newbrokerage"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewBrokerage />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/:apptype/addnewemployee"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewEmployee />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/:apptype/feedbackform"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<FeebackForm />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/:apptype/newassociate"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewAgent />} />
                        </div>
                      )}
                    />{" "}
                    <Route
                      exact
                      path="/app/agency/individuallead"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewIndividualLeadAgency />} />
                        </div>
                      )}
                    />{" "}
                    <Route
                      exact
                      path="/app/agency/corporatelead"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewCorporateLeadAgency />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/agency/newleadclient"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<ClientOnboardingForm />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/agency/hrclient"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewHRClient />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/:apptype/corporatelead"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewCorporateLead />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/:apptype/individuallead"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master child={<NewIndividualLead />} />
                        </div>
                      )}
                    />
                    <Route
                      exact
                      path="/app/:apptype/:selectedItem"
                      render={() => (
                        <div className="routing">
                          <Dashboard />
                          <Master />
                        </div>
                      )}
                    />
                    <Route exact path="/careers" render={() => <Careers />} />
                    <Route exact path="/terms" render={() => <Terms />} />
                    <Route exact path="/privacy" render={() => <Privacy />} />
                    <Route
                      exact
                      path="/disclaimer"
                      render={() => <Disclaimer />}
                    />
                    <Route
                      path="*"
                      // render={() => <Redirect to="/rahax/about" />
                      render={() => <Redirect to="/home" />}
                    />
                  </Switch>
                </Router>
              </>
            </AppContextProvider>
          </BankContextProvider>
        </RegistrationContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
