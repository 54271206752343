import { ReactComponent as ContactIcon } from "../../images/associates/dashboard/contact.svg";
import { ReactComponent as PoepleIcon } from "../../images/associates/dashboard/people.svg";
import { ReactComponent as BoxIcon } from "../../images/associates/dashboard/box.svg";
import { ReactComponent as ComputerIcon } from "../../images/associates/dashboard/computer.svg";

export const dashboardMainScore = [
  {
    title: "Total Clients",
    icon: <ContactIcon />,
    score: "250",
    growthPerc: " this month",
    growth: true,
  },
  {
    title: "Total Members",
    icon: <PoepleIcon />,
    score: "1,893",
    growthPerc: " this month",
    growth: true,
  },
  {
    title: "Total Claims",
    icon: <BoxIcon />,
    score: "189",
    growthPerc: " this month",
    growth: true,
  },
  {
    title: "Active Leads",
    icon: <ComputerIcon />,
    score: "50",
    growthPerc: " this month",
    growth: false,
  },
];
