import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./legacy.module.scss";

import moment from "moment";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { ReactComponent as SearchIcon } from "../../../assest/images/icons/search.svg";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, {
  CopyBtn,
  EachCustomPolicyNumberDropdown,
  EachCustomProfileDropdown,
} from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect, useLocation } from "react-router-dom";
import ToggleButton from "../../../components/Custom/Toggle";
import { predefinedToast } from "../../../utils/toast";
import NavbarV2 from "../../../components/NavbarV2";
import NavbarV3 from "../../../components/NavbarV3";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutToSameLogin } from "../../../assest/functions";

const fetchLeadSourcesDropdown = (setLeadSourceDropdown) => {
  Axios.get(
    "https://insurancecoverfi.apimachine.com/insurance/lead/getleadsources"
  )
    .then((response) => {
      console.log(response, "lead sources dropdown response");
      if (response?.data?.status) {
        setLeadSourceDropdown(response?.data?.data);
      }
    })
    .catch((error) => {
      console.log(error?.message, "lead sources dropdown error");
    });
};

const LegacyAssociates = () => {
  const history = useHistory();
  const {
    email,
    popularAction,
    setPopularAction,
    actionBtns,
    setActionBtns,
    allLeadsAPI,
    setAllLeadsAPI,
    setUpdateLoading,
    addDependentSidebar,
    setAddDependentSidebar,
    lastSelectedEmployee,
    setLastSelectedEmployee,
  } = useContext(BankContext);

  const location = useLocation();

  const [selectedProfileHRID, setSelectedProfileHRID] = useState("");
  const [selectedProfileNav, setSelectedProfileNav] = useState("Employers");
  const [searchQuery, setSearchQuery] = useState("");
  const [toggleSelectedTable, setToggleSelectedTable] = useState("Simplified");
  const [statusDropdown, setStatusDropdown] = useState("");
  const [statesDropdown, setStatesDropdown] = useState("");
  const [appDropdown, setAppDropdown] = useState("");
  const [allStatusDropdown, setAllStatusDropdown] = useState([
    { name: "New Lead", searchValue: "leadInquired" },
    { name: "Contacted", searchValue: "inConversation" },
    { name: "HR Logged In", searchValue: "HRAccountLoggedIn" },
    { name: "Policy Requirements Defined", searchValue: "HRConfiguredForm" },
    {
      name: "Employee Forms Partially Filled",
      searchValue: "formsPartiallyFilled",
    },
    {
      name: "Employee Forms Completely Filled",
      searchValue: "formsCompletelyFilled",
    },
  ]);
  const [allStatusSelected, setAllStatusSelected] = useState("");
  const [leadSourceDropdown, setLeadSourceDropdown] = useState("");
  const [leadSourceSelected, setLeadSourceSelected] = useState("");

  const [leadsTypeSelected, setLeadsTypeSelected] = useState("");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");

  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");

  async function getHrIdWithLeadId() {
    setUpdateLoading(true);

    const hrId = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?lead_id=${
        Array.isArray(actionBtns) && actionBtns?.length > 0
          ? actionBtns[actionBtns?.length - 1]
          : actionBtns
      }`
    );

    // console.log(hrId?.data?.data?.hrDetails?.hr_id, "got hr iddd from lead id");
    return hrId?.data?.data?.hrDetails?.hr_id;

    setUpdateLoading(false);
  }

  useEffect(() => {
    console.log("API is being called");
    setAllLeads("");
    setAllLeadsAPI("");
    setAllBrokeragesTotalProfile("");
    setAllLeadsLoading(true);

    const fetchAllBrokerages = () => {
      let URL;
      if (selectedProfileNav === "Employers") {
        URL = `https://insurancecoverfi.apimachine.com/insurance/lead/get?reference_email=${email}&lead_type=company`;
      } else if (selectedProfileNav === "Employees") {
        URL = `https://insurancecoverfi.apimachine.com/insurance/lead/get?reference_email=${email}&lead_type=individual`;
      } else if (selectedProfileNav == "EmployerSpecific") {
        URL = `https://insurancecoverfi.apimachine.com/insurance/lead/get/projected?reference_email=${email}&lead_type=individual&hr_id=${selectedProfileHRID}`;
      } else if (selectedProfileNav == "IOB") {
        URL = `https://insurancecoverfi.apimachine.com/insurance/iob/transaction/tracker`;
      } else if (selectedProfileNav === "My Users") {
        URL = `https://insurancecoverfi.apimachine.com/insurance/user/get?agent_email=${email}`;
      } else {
        URL = "";
      }

      if (
        (selectedProfileNav === "Employers" ||
          selectedProfileNav === "Employees") &&
        toggleSelectedTable == "Simplified"
      ) {
        Axios.post(
          `https://insurancecoverfi.apimachine.com/insurance/lead/get/projected?reference_email=${email}&lead_type=${
            selectedProfileNav === "Employers" ? "company" : "individual"
          }`,
          {
            project: {
              follow_up_status: 1,
              first_name: 1,
              last_name: 1,
              company_name: 1,
              lead_email: 1,
              phone_number: 1,
              lead_id: 1,
            },
          }
        )
          .then((response) => {
            console.log(response, "Employers leads response simplified");
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "Employers leads simplified error");
            setAllLeadsLoading(false);
          });
      } else if (selectedProfileNav == "EmployerSpecific") {
        Axios.post(URL, {
          project: {
            follow_up_status: 1,
            first_name: 1,
            last_name: 1,
            employee_company_name: 1,
            lead_email: 1,
            phone_number: 1,
            lead_id: 1,
          },
        })
          .then((response) => {
            // console.log(
            //   response,
            //   "Employees leads response for selected EmployerSpecific"
            // );
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(
              error?.message,
              "Employees leads response for selected EmployerSpecific error"
            );
            setAllLeadsLoading(false);
          });
      } else {
        Axios.get(URL)
          .then((response) => {
            console.log(response, "Leads response");
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "Employers leads error");
            setAllLeadsLoading(false);
          });
      }
    };

    fetchAllBrokerages();
    fetchLeadSourcesDropdown(setLeadSourceDropdown);
  }, [selectedProfileNav, email, toggleSelectedTable]);

  useEffect(() => {
    if (allLeadsAPI?.length > 0 && Array.isArray(allLeadsAPI)) {
      setAllBrokeragesTotalProfile("");
      let res = allLeadsAPI?.filter((row) => {
        const { lead_type, lead_source, ...newObject } = row;

        // Creating a new object with the extracted properties
        const separateObject = { lead_type, lead_source };
        // Convert the row object values to an array and check if any value matches the search query
        const values = Object?.values(separateObject);
        const searchQuery = leadsTypeSelected?.toLowerCase();
        const searchQueryOwner = leadSourceSelected?.toLowerCase();
        const searchQueryStatus = allStatusSelected?.toLowerCase();

        // console.log(separateObject, "values sort", searchQueryOwner);

        // Check if the selected country matches the object's country (if selected)
        const countryMatches =
          !searchQuery ||
          values.some(
            (value) =>
              typeof value === "string" &&
              searchQuery.toLowerCase().includes(value?.toLowerCase())
          );

        // Check if the selected brokerage matches the object's brokerage (if selected)
        // const brokerageMatches =
        //   !searchQueryOwner ||
        //   values.some(
        //     (value) =>
        //       typeof value === "string" &&
        //       searchQueryOwner.toLowerCase().includes(value?.toLowerCase())
        //   );

        const brokerageMatches =
          !searchQueryOwner ||
          row?.lead_source?.toLowerCase().includes(searchQueryOwner);

        const statusMatches =
          !searchQueryStatus ||
          row?.follow_up_status?.toLowerCase().includes(searchQueryStatus);

        return countryMatches && brokerageMatches && statusMatches;
      });
      setAllBrokeragesTotalProfile(res.length);
      setAllLeads(res);
    }
  }, [leadsTypeSelected, leadSourceSelected, allStatusSelected]);

  async function downloadSpreadsheetSelectedUser() {
    let hrId = await getHrIdWithLeadId();

    setUpdateLoading(true);
    window.open(
      `https://insurancecoverfi.apimachine.com/insurance/hr/share/pending?hr_id=${hrId}`,
      "_blank"
    );
    setUpdateLoading(false);
  }

  async function seeAllEmployeesForSelected() {
    setUpdateLoading(true);

    let leadId;

    if (Array.isArray(actionBtns) && actionBtns?.length > 0) {
      leadId = actionBtns[actionBtns?.length - 1];
    } else {
      leadId = actionBtns;
    }

    let selectedLead = allLeads?.filter((eachLead) => {
      return eachLead?.lead_id == leadId;
    });

    const hrId = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/lead/get?lead_email=${selectedLead[0]?.lead_email}`
    );
    let hrData = hrId?.data?.data;
    hrData?.forEach((eachItem) => {
      if (eachItem?.hr_id) {
        setSelectedProfileHRID(eachItem?.hr_id);
        setSelectedProfileNav("EmployerSpecific");
      }
      // console.log(eachItem?.hr_id, "got hr iddd", hrData);
    });

    setActionBtns("");
    setUpdateLoading(false);
  }

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hrIDAvailable, setHrIDAvailable] = useState(false);

  async function seeAllEmployeesForSelectedHRCheck() {
    setHrIDAvailable(false);
    let leadId;

    if (Array.isArray(actionBtns) && actionBtns?.length > 0) {
      leadId = actionBtns[actionBtns?.length - 1];
    } else {
      leadId = actionBtns;
    }

    let selectedLead = allLeads?.filter((eachLead) => {
      return eachLead?.lead_id == leadId;
    });

    const hrId = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/lead/get?lead_email=${selectedLead[0]?.lead_email}`
    );
    let hrData = hrId?.data?.data;
    hrData?.forEach((eachItem) => {
      if (eachItem?.hr_id) {
        setHrIDAvailable(true);
      }
    });
  }

  useEffect(() => {
    if (actionBtns?.length > 0 && Array?.isArray(actionBtns)) {
      seeAllEmployeesForSelectedHRCheck();
    }
  }, [actionBtns]);

  function onCloseSidebar() {
    if (sidebarOpen) {
      setSidebarOpen((prev) => !prev);
    }
  }

  useEffect(() => {
    if (actionBtns?.length > 0 && Array.isArray(actionBtns)) {
      let response =
        Array.isArray(allLeads) &&
        allLeads?.filter(
          (eachLead) =>
            eachLead?.user_employee_id == actionBtns[actionBtns?.length - 1]
        );
      // console.log(response, "see dependents");
      if (response?.length > 0) {
        setLastSelectedEmployee(response[0]);
      }
    }
  }, [actionBtns, addDependentSidebar]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={["Employers", "Employees", "IOB", "My Users"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />
      <div className={classNames.brokerageDetails}>
        <div className={classNames.title}>
          {selectedProfileNav == "IOB"
            ? `There are ${
                allBrokeragesTotalProfile ? allBrokeragesTotalProfile : "0"
              } Registrations`
            : selectedProfileNav == "Employees" ||
              selectedProfileNav == "EmployerSpecific"
            ? `You Have ${
                allBrokeragesTotalProfile ? allBrokeragesTotalProfile : "0"
              } Employee Leads`
            : `You Have ${
                allBrokeragesTotalProfile ? allBrokeragesTotalProfile : "0"
              } Employer Leads`}
          <div
            className={classNames.btnsContainer}
            style={{ display: selectedProfileNav == "IOB" ? "" : "none" }}
          >
            <div
              onClick={() => {
                setUpdateLoading(true);
                window.open(
                  `https://insurancecoverfi.apimachine.com/insurance/iob/transaction/tracker/download`,
                  "_blank"
                );
                setUpdateLoading(false);
              }}
            >
              Download
            </div>
          </div>
        </div>
        {/* <div className={classNames.dropdownItems}>
          {selectedProfileNav == "Employees" ||
          selectedProfileNav == "EmployerSpecific" ? (
            <>
              <EachCustomDropdown
                dropdown={appDropdown}
                name="status"
                stateValue={statusDropdown}
                setState={setStatusDropdown}
                title={"All Status"}
              />
              <EachCustomDropdown
                dropdown={appDropdown}
                name="states"
                stateValue={statesDropdown}
                setState={setStatesDropdown}
                title={"All Employers"}
              />
            </>
          ) : (
            <>
              <EachCustomDropdown
                dropdown={allStatusDropdown}
                name="all status"
                stateValue={allStatusSelected}
                setState={setAllStatusSelected}
                title={"All Status"}
              />
              <EachCustomDropdown
                dropdown={leadSourceDropdown}
                name="leadSourceDropdownSelected"
                stateValue={leadSourceSelected}
                setState={setLeadSourceSelected}
                title={"All Lead Sources"}
              />
            </>
          )}
        </div> */}
        <div className={classNames.allLeads}>
          <div className={classNames.nav}>
            <div className={classNames.searchDiv}>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search.."
                onChange={(event) =>
                  setAllBrokeragesSearch(event?.target?.value)
                }
              />
            </div>
            {/* <ToggleButton
              stateValue={toggleSelectedTable}
              stateFunc={setToggleSelectedTable}
              loading={allLeadsLoading}
            /> */}
            <div
              className={classNames.actionBtn}
              style={{
                display:
                  selectedProfileNav == "IOB"
                    ? "none"
                    : popularAction
                    ? "none"
                    : actionBtns?.length > 0
                    ? ""
                    : "none",
              }}
            >
              <div
                onClick={seeAllEmployeesForSelected}
                style={{
                  display:
                    hrIDAvailable && selectedProfileNav == "Employers"
                      ? ""
                      : "none",
                }}
              >
                See Employees
              </div>
              <div
                onClick={downloadSpreadsheetSelectedUser}
                // style={{
                //   display:
                //     selectedProfileNav == "EmployerSpecific" ? "none" : "",
                // }}
              >
                Download
              </div>
              <div
                onClick={() => {
                  if (actionBtns?.length > 0 && Array.isArray(actionBtns)) {
                    let response = allLeads?.filter(
                      (eachLead) =>
                        eachLead?.lead_id == actionBtns[actionBtns?.length - 1]
                    );
                    // console.log(response, "see dependents", actionBtns);
                    if (response?.length > 0) {
                      setSidebarOpen(response[0]?.lead_email);
                      setLastSelectedEmployee(response[0]);
                    }
                  }
                }}
                // style={{
                //   display:
                //     selectedProfileNav == "EmployerSpecific" ? "none" : "",
                // }}
              >
                See Dependents
              </div>
              <div
                onClick={() => {
                  setPopularAction("addendorsementsagency");
                }}
                // style={{
                //   display:
                //     selectedProfileNav == "EmployerSpecific" ? "none" : "",
                // }}
              >
                Endorsements
              </div>
              <div
                onClick={() => {
                  setPopularAction("editlead");
                }}
                // style={{
                //   display:
                //     selectedProfileNav == "EmployerSpecific" ? "none" : "",
                // }}
              >
                Edit
              </div>
              <div
                onClick={() => {
                  setPopularAction("deletelead");
                }}
                // style={{
                //   display:
                //     selectedProfileNav == "EmployerSpecific" ? "none" : "",
                // }}
              >
                Delete
              </div>
            </div>
          </div>
          {selectedProfileNav === "My Users" ? (
            <TableMyUsers
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
            />
          ) : selectedProfileNav == "Employers" &&
            toggleSelectedTable == "Simplified" ? (
            <TableEmployersSimplified
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
            />
          ) : (selectedProfileNav == "Employees" ||
              selectedProfileNav == "EmployerSpecific") &&
            toggleSelectedTable == "Simplified" ? (
            <TableEmployeesSimplified
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
              selectedProfileNav="EmployerSpecific"
            />
          ) : selectedProfileNav == "IOB" ? (
            <TableIOB
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
              selectedProfileNav="EmployerSpecific"
            />
          ) : (
            <Table
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
            />
          )}
        </div>
        <SidebarMenu
          position={sidebarOpen ? "0" : ""}
          onClose={onCloseSidebar}
          openedItem={sidebarOpen}
          selectedItemMain={lastSelectedEmployee}
        />
      </div>
    </div>
  );
};

export default LegacyAssociates;

export const SidebarMenu = ({
  position,
  onClose,
  openedItem,
  selectedItemMain,
}) => {
  const sidebarRef = useRef(null);
  const { popularAction } = useContext(BankContext);

  const [allDependents, setAllDependents] = useState([]);
  const [allDependentsLoading, setAllDependentsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    setAllDependentsLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${openedItem}`
    )
      .then((response) => {
        // console.log(response, "get all dependents response", openedItem);
        setAllDependents(response?.data?.data);
        setAllDependentsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get all dependents error");
        setAllDependentsLoading(false);
      });
  }, [popularAction, openedItem]);

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      <div className={classNames.dependentsContainer}>
        <div className={classNames.title}>
          Dependents For {selectedItemMain?.first_name}
        </div>
        <div className={classNames.allDependents}>
          {allDependentsLoading ? (
            <div className={classNames.eachDependent}>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
            </div>
          ) : allDependents?.length > 0 ? (
            allDependents?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachDependent}
                  key={eachItem?.dependent_name + index}
                >
                  <div>{eachItem?.dependent_name}</div>
                  <div>{eachItem?.relationship}</div>
                  <div>{eachItem?.primary_phone_number}</div>
                </div>
              );
            })
          ) : (
            <div>No items</div>
          )}
        </div>
      </div>
    </div>
  );
};

const TableEmployersSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>HR Name</th>
            <th>Contact</th>
            <th>Company</th>
            <th>Sales Status</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
            >
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                >
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.lead_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.first_name ? row?.first_name : ""}
                          <CopyBtn
                            copyText={row?.first_name ? row?.first_name : ""}
                          />
                        </div>
                        <div>
                          {row?.last_name ? row?.last_name : ""}
                          <CopyBtn
                            copyText={row?.last_name ? row?.last_name : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.phone_number ? row?.phone_number : ""}
                          <CopyBtn
                            copyText={
                              row?.phone_number ? row?.phone_number : ""
                            }
                          />
                        </div>
                        <div>
                          {row?.lead_email ? row?.lead_email : ""}
                          <CopyBtn
                            copyText={row?.lead_email ? row?.lead_email : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row?.company_name ? row?.company_name : ""}
                    <CopyBtn
                      copyText={row?.company_name ? row?.company_name : ""}
                    />
                  </td>
                  <td>
                    {row?.follow_up_status == "leadInquired"
                      ? "New Lead"
                      : row?.follow_up_status == "inConversation"
                      ? "Contacted"
                      : row?.follow_up_status == "HRAccountLoggedIn"
                      ? "HR Logged In"
                      : row?.follow_up_status == "HRConfiguredForm"
                      ? "Policy Requirements Defined"
                      : row?.follow_up_status == "formsPartiallyFilled"
                      ? "Employee Forms Partially Filled"
                      : row?.follow_up_status == "formsCompletelyFilled"
                      ? "Employee Forms Completely Filled"
                      : row?.follow_up_status == "quotesUploaded"
                      ? "Quote Uploaded"
                      : row?.follow_up_status == "proposalFrozen"
                      ? "Proposal Frozen"
                      : row?.follow_up_status == "activeClient_T"
                      ? "Policy Activated With Tall Tree Reference Number"
                      : row?.follow_up_status == "activeClient_P"
                      ? "Policy Updated With Permanent Number"
                      : row?.follow_up_status == "activeClient_H"
                      ? "Policy Updated With Employee Health Cards"
                      : row?.follow_up_status == "clientLost"
                      ? "Lead Lost"
                      : ""}
                    <CopyBtn
                      copyText={
                        row?.follow_up_status ? row?.follow_up_status : ""
                      }
                    />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableEmployeesSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
  selectedProfileNav,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}>
            <th></th>
            <th>Name</th>
            <th>Contact</th>
            <th>Company</th>
            <th>Form Status</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
            >
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase();

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                >
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.lead_id ? row?.lead_id : row?.lead_email}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.first_name ? row?.first_name : ""}
                          <CopyBtn
                            copyText={row?.first_name ? row?.first_name : ""}
                          />
                        </div>
                        <div>
                          {row?.last_name ? row?.last_name : ""}
                          <CopyBtn
                            copyText={row?.last_name ? row?.last_name : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.phone_number ? row?.phone_number : ""}
                          <CopyBtn
                            copyText={
                              row?.phone_number ? row?.phone_number : ""
                            }
                          />
                        </div>
                        <div>
                          {row?.lead_email ? row?.lead_email : ""}
                          <CopyBtn
                            copyText={row?.lead_email ? row?.lead_email : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row?.employee_company_name
                      ? row?.employee_company_name
                      : ""}
                    <CopyBtn
                      copyText={
                        row?.employee_company_name
                          ? row?.employee_company_name
                          : ""
                      }
                    />
                  </td>
                  <td>
                    {row?.follow_up_status == "leadInquired"
                      ? "New Lead"
                      : row?.follow_up_status == "inConversation"
                      ? "Contacted"
                      : row?.follow_up_status == "HRAccountLoggedIn"
                      ? "HR Logged In"
                      : row?.follow_up_status == "HRConfiguredForm"
                      ? "Policy Requirements Defined"
                      : row?.follow_up_status == "formsPartiallyFilled"
                      ? "Employee Forms Partially Filled"
                      : row?.follow_up_status == "formsCompletelyFilled"
                      ? "Employee Forms Completely Filled"
                      : row?.follow_up_status == "quotesUploaded"
                      ? "Quote Uploaded"
                      : row?.follow_up_status == "proposalFrozen"
                      ? "Proposal Frozen"
                      : row?.follow_up_status == "activeClient_T"
                      ? "Policy Activated With Tall Tree Reference Number"
                      : row?.follow_up_status == "activeClient_P"
                      ? "Policy Updated With Permanent Number"
                      : row?.follow_up_status == "activeClient_H"
                      ? "Policy Updated With Employee Health Cards"
                      : row?.follow_up_status == "clientLost"
                      ? "Lead Lost"
                      : ""}
                    <CopyBtn
                      copyText={
                        row?.follow_up_status ? row?.follow_up_status : ""
                      }
                    />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableIOB = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
  selectedProfileNav,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}>
            <th></th>
            <th>Name</th>
            <th>Phone</th>
            <th>Roll Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
            >
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase();

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                >
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.email_id ? row?.email_id : row?.roll_number}
                      name={row?.name}
                    />
                  </td>
                  <td>
                    <div>
                      {row?.name ? row?.name : ""}
                      <CopyBtn copyText={row?.name ? row?.name : ""} />
                    </div>
                  </td>
                  <td>
                    <div>
                      {row?.phone_number ? row?.phone_number : ""}
                      <CopyBtn
                        copyText={row?.phone_number ? row?.phone_number : ""}
                      />
                    </div>
                  </td>
                  <td>
                    {row?.roll_number ? row?.roll_number : ""}
                    <CopyBtn
                      copyText={row?.roll_number ? row?.roll_number : ""}
                    />
                  </td>
                  <td>
                    {row?.txnstatus ? row?.txnstatus : ""}
                    <CopyBtn copyText={row?.txnstatus ? row?.txnstatus : ""} />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const Table = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>Name</th>
            <th>Email</th>
            <th>Sales Status</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Lead Source</th>
            <th>Lead Type</th>
            <th>Company Name</th>
            <th>Company Size</th>
            <th>Lead Created</th>
            <th>Status</th>
            <th>Lead ID</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr key={"loading"}>
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr key={row?.brokerage_name + index}>
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.lead_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.first_name ? row?.first_name : ""}
                          <CopyBtn
                            copyText={row?.first_name ? row?.first_name : ""}
                          />
                        </div>
                        <div>
                          {row?.last_name ? row?.last_name : ""}
                          <CopyBtn
                            copyText={row?.last_name ? row?.last_name : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row?.lead_email ? row?.lead_email : ""}
                    <CopyBtn
                      copyText={row?.lead_email ? row?.lead_email : ""}
                    />
                  </td>
                  <td>
                    {row?.follow_up_status == "leadInquired"
                      ? "New Lead"
                      : row?.follow_up_status == "inConversation"
                      ? "Contacted"
                      : row?.follow_up_status == "HRAccountLoggedIn"
                      ? "HR Logged In"
                      : row?.follow_up_status == "HRConfiguredForm"
                      ? "Policy Requirements Defined"
                      : row?.follow_up_status == "formsPartiallyFilled"
                      ? "Employee Forms Partially Filled"
                      : row?.follow_up_status == "formsCompletelyFilled"
                      ? "Employee Forms Completely Filled"
                      : row?.follow_up_status == "quotesUploaded"
                      ? "Quote Uploaded"
                      : row?.follow_up_status == "proposalFrozen"
                      ? "Proposal Frozen"
                      : row?.follow_up_status == "activeClient_T"
                      ? "Policy Activated With Tall Tree Reference Number"
                      : row?.follow_up_status == "activeClient_P"
                      ? "Policy Updated With Permanent Number"
                      : row?.follow_up_status == "activeClient_H"
                      ? "Policy Updated With Employee Health Cards"
                      : row?.follow_up_status == "clientLost"
                      ? "Lead Lost"
                      : ""}
                    {/* "leadInquired", //Default "inConversation", //Default
                    "HRAccountLoggedIn", //API "HRConfiguredForm", //automatic
                    "formsPartiallyFilled", //automatic "formsCompletelyFilled",
                    //automatic "quotesUploaded", //automatic "proposalFrozen",
                    //API "activeClient_T", //API "activeClient_P", //API
                    "activeClient_H", "clientLost" */}
                    <CopyBtn
                      copyText={
                        row?.follow_up_status ? row?.follow_up_status : ""
                      }
                    />
                  </td>
                  <td>
                    {row?.phone_number ? row?.phone_number : ""}
                    <CopyBtn
                      copyText={row?.phone_number ? row?.phone_number : ""}
                    />
                  </td>
                  <td>
                    {row?.address ? row?.address : ""}
                    <CopyBtn copyText={row?.address ? row?.address : ""} />
                  </td>
                  <td>
                    {row?.lead_source ? row?.lead_source : ""}
                    <CopyBtn
                      copyText={row?.lead_source ? row?.lead_source : ""}
                    />
                  </td>
                  <td>{row?.lead_type ? row?.lead_type : ""}</td>
                  <td>
                    {row?.company_name ? row?.company_name : ""}
                    <CopyBtn
                      copyText={row?.company_name ? row?.company_name : ""}
                    />
                  </td>
                  <td>
                    {row?.company_size ? row?.company_size : ""}
                    <CopyBtn
                      copyText={row?.company_size ? row?.company_size : ""}
                    />
                  </td>
                  <td>
                    {row?.date ? row?.date : ""}
                    <CopyBtn copyText={row?.date ? row?.date : ""} />
                  </td>
                  <td>
                    {row?.status ? row?.status : ""}
                    <CopyBtn copyText={row?.status ? row?.status : ""} />
                  </td>
                  <td>
                    {row?.lead_id ? row?.lead_id : ""}
                    <CopyBtn copyText={row?.lead_id ? row?.lead_id : ""} />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableMyUsers = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Other Phone Numbers</th>
            <th>Date Of Birth</th>
            <th>Address</th>
            <th>City</th>
            <th>Country</th>
            <th>Postal Code</th>
            <th>Martial Status</th>
            <th>User Id</th>
            <th>Lead ID</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr key={"loading"}>
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>

              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr key={row?.brokerage_name + index}>
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.lead_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.profile_picture} alt="profile_picture" />
                      </div>
                      <div>
                        <div>
                          {row?.first_name ? row?.first_name : ""}
                          <CopyBtn
                            copyText={row?.first_name ? row?.first_name : ""}
                          />
                        </div>
                        <div>
                          {row?.last_name ? row?.last_name : ""}
                          <CopyBtn
                            copyText={row?.last_name ? row?.last_name : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    {row?.user_email ? row?.user_email : ""}
                    <CopyBtn
                      copyText={row?.user_email ? row?.user_email : ""}
                    />
                  </td>
                  <td>
                    {row?.primary_phone_number ? row?.primary_phone_number : ""}
                    <CopyBtn
                      copyText={
                        row?.primary_phone_number
                          ? row?.primary_phone_number
                          : ""
                      }
                    />
                  </td>
                  <td
                    style={{
                      flexDirection: "column",
                      gap: "0.5rem",
                      alignItems: "flex-start",
                    }}
                  >
                    {row?.secondary_phone_numbers?.length > 0
                      ? row?.secondary_phone_numbers?.map((eachNumber) => {
                          return (
                            <div>
                              {eachNumber}{" "}
                              <CopyBtn
                                copyText={eachNumber ? eachNumber : ""}
                              />
                            </div>
                          );
                        })
                      : ""}
                  </td>
                  <td>
                    {row?.date_of_birth
                      ? moment(row?.date_of_birth).format("MMM Do YY")
                      : ""}
                    <CopyBtn
                      copyText={row?.date_of_birth ? row?.date_of_birth : ""}
                    />
                  </td>
                  <td>
                    {row?.address ? row?.address : ""}
                    <CopyBtn copyText={row?.address ? row?.address : ""} />
                  </td>
                  <td>
                    {row?.city ? row?.city : ""}
                    <CopyBtn copyText={row?.city ? row?.city : ""} />
                  </td>
                  <td>
                    {row?.country ? row?.country : ""}
                    <CopyBtn copyText={row?.country ? row?.country : ""} />
                  </td>
                  <td>
                    {row?.pin_code ? row?.pin_code : ""}
                    <CopyBtn copyText={row?.pin_code ? row?.pin_code : ""} />
                  </td>
                  <td>
                    {row?.martial_status ? row?.martial_status : ""}
                    <CopyBtn
                      copyText={row?.martial_status ? row?.martial_status : ""}
                    />
                  </td>
                  <td>
                    {row?.user_id ? row?.user_id : ""}
                    <CopyBtn copyText={row?.user_id ? row?.user_id : ""} />
                  </td>
                  <td>
                    {row?.lead_id ? row?.lead_id : ""}
                    <CopyBtn copyText={row?.lead_id ? row?.lead_id : ""} />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};
