import React, { useState, useEffect, useContext } from "react";
import classNames from "../rahastore.module.scss";
import styles from "./wallet.module.scss";

import Skeleton from "react-loading-skeleton";
import { BankContext } from "../../../../context/Context";
import Axios from "axios";

// Images
import { ReactComponent as PhoneIcon } from "../../../../assest/images/icons/phone.svg";
import { ReactComponent as SearchIcon } from "../../../../assest/images/icons/search.svg";

// Icons
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { BsCheck, BsThreeDotsVertical } from "react-icons/bs";

// Components
import FundWallet from "./FundWallet";
import DropdownUi from "./DropdownUi";
import BulkTransfer from "./BulkTransfer";

const WalletComponent = ({ agentInfo, setAllRahaStoreSearch, searchQuery }) => {
  const { name, email, allCartItems } = useContext(BankContext);
  const [ownerId, setOwnerId] = useState(null);
  const [initiatedById, setInitiatedById] = useState(null);
  const [balance, setBalance] = useState(null);
  const [showFundPopup, setShowFundPopup] = useState(null);
  const [empBalances, setEmpBalances] = useState([]);
  const [empBalancesLoading, setEmpBalancesLoading] = useState(false);

  const [allSelectedPolicyClaims, setSelectedPolicyClaims] = useState([]);
  const [allSelectedPolicyClaimsLoading, setSelectedPolicyClaimsLoading] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queriedFilter, setQueriedFilter] = useState("beneficiarY_NAME");
  const [speaktolead, setSpeakToLead] = useState(false);
  const [speaktoAssociate, setSpeakToAssociate] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [balanceObj, setBalanceObj] = useState(null);

  const [isToggled, setIsToggled] = useState(false);
  const [txns, setTxns] = useState([]);

  const [showBulkTransfer, setShowBulkTransfer] = useState(false);

  function formatDateToDDMMYYYY(inputDate) {
    // const date = new Date(inputDate);

    // const day = String(date.getDate()).padStart(2, "0");
    // const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    // const year = date.getFullYear();
    let datee = inputDate?.split(" ");
    if (datee?.length > 0) {
      return datee[0];
    } else {
      return inputDate;
    }
  }

  const getTxns = () => {
    Axios.get(
      `https://coverfi.apimachine.com/wallet/api/v1/wallet/transaction?owner_id=${ownerId}`
    ).then(({ data }) => {
      if (data.status) {
        setTxns(data.data);
      }
    });
  };

  useEffect(() => {
    if (ownerId) {
      getTxns();
    }
  }, [ownerId]);

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
    ).then(({ data }) => {
      if (data.status) {
        console.log(data?.data?.hrDetails, "kjefkwjebflkwje");
        setInitiatedById(data?.data?.hrDetails?._id);
        setOwnerId(data?.data?.hrDetails?.hrCompanyDetails?._id);
      }
    });
  }, []);

  const activateWallet = () => {
    Axios.post(`https://coverfi.apimachine.com/wallet/api/v1/wallet/create`, {
      owner_id: ownerId,
      type_of_wallet: "Company",
    }).then(({ data }) => {
      if (data.status) {
        getBalance();
      }
    });
  };

  const getBalance = () => {
    Axios.get(
      `https://coverfi.apimachine.com/wallet/api/v1/wallet/?owner_id=${ownerId}`
    ).then(({ data }) => {
      if (data.status) {
        console.log("balance is being set", data.data[0].balance);
        setBalanceObj(data.data[0]);
        setBalance(data.data[0].balance);
      } else {
        setBalance(null);
      }
    });
  };

  const getEmpBalances = () => {
    setEmpBalancesLoading(true);
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/client-user/employee/balance/${ownerId}`
    ).then(({ data }) => {
      if (data.status) {
        setEmpBalances(data.data);
        setEmpBalancesLoading(false);
        getBalance();
        getTxns();
      } else {
        setEmpBalancesLoading(false);
      }
    });
  };

  useEffect(() => {
    if (ownerId) {
      getBalance();
      getEmpBalances();
    }
  }, [ownerId]);

  return (
    <>
      <div className={classNames.walletCards}>
        <div className={classNames.topCard}>
          <div>
            <h6>Corporate Wellness Wallet</h6>
            <div className={classNames.wideCard}>
              <div className={classNames.detailDiv}>
                <div className={classNames.balanceParent}>
                  <div className={classNames.title}>Company Balance</div>
                  <div className={classNames.balanceDiv}>
                    $ {balance !== null ? (balance / 100)?.toFixed(2) : "0.00"}
                  </div>
                  {/* <div className={classNames.growthDiv}>
                          <GrowthGraph />
                          <span> +5% (Over last quarter)</span>
                        </div> */}
                  <div
                    className={classNames.btnContainer}
                    onClick={(e) => {
                      if (balance !== null) {
                        setShowFundPopup(ownerId);
                      } else {
                        activateWallet();
                      }
                    }}
                  >
                    {balance !== null ? "Fund Wallet" : "Activate"}
                  </div>
                  <div className={classNames.unDecoBtn}>
                    View Wallet Transactions
                  </div>
                </div>
                <div className={classNames.countDivs}>
                  <div>
                    <div className={classNames.title}>Assigned</div>
                    <div className={classNames.count}>3</div>
                  </div>
                  <div>
                    <div className={classNames.title}>Un-Assigned</div>
                    <div className={classNames.count}>32</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
                  <h6>Wellness Budget Utilization</h6>
                  <div className={classNames.wideCard2}>
                     <img
                      src={pieChart}
                      alt="pieChart"
                      width="200"
                      height="130"
                    /> 
                  </div>
                </div> */}
          <div>
            <h6 className={classNames.title}>Support</h6>
            <div className={classNames.supportCard}>
              {agentInfo.map((agt, i) => {
                return (
                  <div className={classNames.box}>
                    <div className={classNames.agentDetails} key={agt?._id + i}>
                      <div className={classNames.name}>
                        <img
                          src={agt?.agent_details?.agent_image}
                          alt="supportProfile"
                        />
                        <div>{agt?.agent_details?.agent_name}</div>
                      </div>
                      <div
                        className={classNames.agentBtn}
                        style={{ marginTop: "1.1rem" }}
                        onClick={() => setSpeakToAssociate((prev) => !prev)}
                      >
                        <PhoneIcon />
                        {speaktoAssociate
                          ? "+91 9182463605"
                          : "Speak To Associate"}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className={classNames.box}>
                    <div className={classNames.agentDetails}>
                      <div className={classNames.name}>
                        <img src={supportProfile} alt="supportProfile" />
                        John Doe
                      </div>
                      <div
                        className={classNames.agentBtn}
                        style={{ marginTop: "0.15rem" }}
                        onClick={() => setSpeakToAssociate((prev) => !prev)}
                      >
                        <PhoneIcon />
                        {speaktoAssociate
                          ? "+91 9182463605"
                          : "Speak To Associate"}
                      </div>
                    </div>
                  </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames.walletCards}
        style={{
          background: "white",
          margin: "0px 2rem",
          padding: "1rem 1rem",
          borderRadius: "12px 12px 0 0",
          marginTop: "1.5rem",
        }}
      >
        <div
          style={{
            // marginTop: "40px",
            fontWeight: "700",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "white",
          }}
        >
          <div>{isToggled ? "Transactions" : "Employee List"}</div>
          <div
            className={styles["toggle-switch"]}
            onClick={(e) => setIsToggled(!isToggled)}
          >
            <div
              className={`${styles.slider} ${isToggled ? styles.active : ""}`}
            ></div>
          </div>
        </div>
        <div className={classNames.bottomCard} style={{ background: "white" }}>
          <div
            className={classNames.transparentBtn}
            onClick={(e) => setShowBulkTransfer(true)}
          >
            Bulk Transfer
          </div>
          <div className={classNames.transparentBtn}>Export</div>
          <div className={classNames.searchDiv}>
            <SearchIcon />
            <input
              type="text"
              placeholder="Search.."
              onChange={(event) => setAllRahaStoreSearch(event?.target?.value)}
            />
          </div>
          <div className={classNames.filterBtn}>
            <div className={classNames.title}>View : </div>
            <div className={classNames.filterValue}>Un-Assigned</div>
            <div className={classNames.filterValue}>
              <IoIosArrowDown />
            </div>
          </div>
          <div className={classNames.filterBtn}>
            <div className={classNames.title}>Sort By : </div>
            <div className={classNames.filterValue}> Utilized</div>
            <div className={classNames.filterValue}>
              <IoIosArrowDown />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.proactiveDetails}>
        <div className={classNames.otherGrids}>
          <div className={classNames.trackerTable}>
            <div className={classNames.tableParent}>
              {isToggled ? (
                <div className={classNames.tableTitles1}>
                  <div></div>
                  <div>Date</div>
                  <div>Time</div>
                  <div>Transaction ID</div>
                  <div>Credit</div>
                  <div>Debit</div>
                  <div>Balance</div>
                </div>
              ) : (
                <div className={classNames.tableTitles}>
                  <div></div>
                  <div>Emp. ID</div>
                  <div>Employee Name</div>
                  <div>Total Funded</div>
                  <div>Total Spent</div>
                  <div>Wallet Balance</div>
                  {/* <div>Date of Joining</div> */}
                </div>
              )}
              <div
                className={classNames.tableBody}
                style={{ height: "33vh", overflowY: "scroll" }}
              >
                {!isToggled ? (
                  empBalancesLoading ? (
                    <div className={classNames.eachItem}>
                      <div className={classNames.singleFields}>
                        <Skeleton height={15} width={80} />
                      </div>
                      <div className={classNames.singleFields}>
                        <Skeleton height={15} width={80} />
                      </div>
                      <div className={classNames.singleFields}>
                        <Skeleton height={15} width={80} />
                      </div>
                      <div className={classNames.singleFields}>
                        <Skeleton height={15} width={80} />
                      </div>
                      <div className={classNames.singleFields}>
                        <Skeleton height={15} width={80} />
                      </div>
                      <div className={classNames.singleFields}>
                        <Skeleton height={15} width={80} />
                      </div>
                      <div className={classNames.singleFields}>
                        <Skeleton height={15} width={80} />
                      </div>
                    </div>
                  ) : empBalances?.length > 0 ? (
                    // ?.filter((eachitem) =>
                    //   eachitem[queriedFilter]
                    //     ?.toLowerCase()
                    //     .includes(searchQuery?.toLowerCase())
                    // )
                    empBalances.map((eachItem, index) => {
                      return (
                        <div
                          className={classNames.eachItem}
                          key={eachItem?.beneficiarY_NAME + index}
                        >
                          <div className={classNames.verticalFields}>
                            {selectedRow?._id === eachItem?._id ? (
                              <IoMdClose
                                onClick={(e) => {
                                  setSelectedRow(null);
                                }}
                              />
                            ) : (
                              <BsThreeDotsVertical
                                onClick={(e) => {
                                  setShowMenu(true);
                                  setSelectedRow(eachItem);
                                }}
                              />
                            )}
                          </div>
                          <div className={classNames.verticalFields}>
                            {eachItem?.user_employee_id
                              ? eachItem?.user_employee_id
                              : "N/A"}
                          </div>
                          <div className={classNames.verticalFields}>
                            <div>{eachItem?.name}</div>
                          </div>
                          <div className={classNames.singleFields}>
                            <div>--</div>
                          </div>
                          <div className={classNames.singleFields}>
                            <div>--</div>
                          </div>
                          <div className={classNames.singleFields}>
                            <div>
                              {eachItem?.client_wallets
                                ? (
                                    eachItem?.client_wallets[0]?.balance / 100
                                  ).toFixed(2)
                                : "--"}
                            </div>
                          </div>
                          {/* <div className={classNames.singleFields}>
                            {eachItem?.date_of_joining
                              ? formatDateToDDMMYYYY(eachItem?.date_of_joining)
                              : "--"}
                          </div> */}
                          {selectedRow && selectedRow._id === eachItem._id && (
                            <div
                              className={classNames.singleFields}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  left: 45,
                                  marginTop: "15vh",
                                  zIndex: 1,
                                  display: "flex",
                                }}
                                // ref={dropdownRef}
                              >
                                <DropdownUi
                                  showMenu={showMenu}
                                  setShowMenu={setShowMenu}
                                  selectedRow={selectedRow}
                                  setSelectedRow={setSelectedRow}
                                  balanceObj={balanceObj}
                                  ownerId={ownerId}
                                  initiatedById={initiatedById}
                                  getEmpBalances={getEmpBalances}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    ""
                  )
                ) : empBalancesLoading ? (
                  <div className={classNames.eachItem}>
                    <div className={classNames.singleFields}>
                      <Skeleton height={15} width={80} />
                    </div>
                    <div className={classNames.singleFields}>
                      <Skeleton height={15} width={80} />
                    </div>
                    <div className={classNames.singleFields}>
                      <Skeleton height={15} width={80} />
                    </div>
                    <div className={classNames.singleFields}>
                      <Skeleton height={15} width={80} />
                    </div>
                    <div className={classNames.singleFields}>
                      <Skeleton height={15} width={80} />
                    </div>
                    <div className={classNames.singleFields}>
                      <Skeleton height={15} width={80} />
                    </div>
                    <div className={classNames.singleFields}>
                      <Skeleton height={15} width={80} />
                    </div>
                  </div>
                ) : txns?.length > 0 ? (
                  // ?.filter((eachitem) =>
                  //   eachitem[queriedFilter]
                  //     ?.toLowerCase()
                  //     .includes(searchQuery?.toLowerCase())
                  // )
                  txns.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachItem1}
                        key={eachItem?.beneficiarY_NAME + index}
                      >
                        <div className={classNames.verticalFields}>
                          {selectedRow?._id === eachItem?._id ? (
                            <IoMdClose
                            // onClick={(e) => {
                            //   setSelectedRow(null);
                            // }}
                            />
                          ) : (
                            <BsThreeDotsVertical
                            // onClick={(e) => {
                            //   setShowMenu(true);
                            //   setSelectedRow(eachItem);
                            // }}
                            />
                          )}
                        </div>
                        <div className={classNames.verticalFields}>
                          {eachItem?.transaction_date?.substring(0, 10)}
                        </div>
                        <div className={classNames.verticalFields}>
                          {eachItem?.transaction_date?.substring(11, 16)}
                        </div>
                        <div className={classNames.singleFields}>
                          {eachItem?._id}
                        </div>
                        <div className={classNames.singleFields}>
                          <div
                            style={{
                              color: "var(--opacity-main)",
                              fontWeight: 700,
                            }}
                          >
                            {eachItem?.type === "Credit"
                              ? (eachItem?.amount / 100).toFixed(2)
                              : "--"}
                          </div>
                        </div>
                        <div className={classNames.singleFields}>
                          <div style={{ color: "#F40D0D", fontWeight: 700 }}>
                            {eachItem?.type === "Debit"
                              ? (eachItem?.amount / 100).toFixed(2)
                              : "--"}
                          </div>
                        </div>
                        <div className={classNames.singleFields}>
                          {(eachItem?.balance_after / 100).toFixed(2)}
                          {/* {eachItem?.date_of_joining
                            ? formatDateToDDMMYYYY(eachItem?.date_of_joining)
                            : "--"} */}
                        </div>
                        {selectedRow && selectedRow._id === eachItem._id && (
                          <div
                            className={classNames.singleFields}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                left: 45,
                                marginTop: "15vh",
                                zIndex: 1,
                                display: "flex",
                              }}
                              // ref={dropdownRef}
                            >
                              <DropdownUi
                                showMenu={showMenu}
                                setShowMenu={setShowMenu}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                balanceObj={balanceObj}
                                ownerId={ownerId}
                                initiatedById={initiatedById}
                                getEmpBalances={getEmpBalances}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFundPopup !== null && (
        <FundWallet
          showFundPopup={showFundPopup}
          setShowFundPopup={setShowFundPopup}
          initiatedById={initiatedById}
        />
      )}
      {showBulkTransfer && (
        <BulkTransfer
          showBulkTransfer={showBulkTransfer}
          setShowBulkTransfer={setShowBulkTransfer}
          initiatedById={initiatedById}
          balanceObj={balanceObj}
          empBalances={empBalances}
          getEmpBalances={getEmpBalances}
        />
      )}
      {/* <div className={classNames.paginationBar}>
              <div className={classNames.paginationMsg}>
                Showing data 10 of 100 entries
              </div>
              <div className={classNames.allPagination}>
                {currentPage == 1
                  ? Array.from({ length: 4 })?.map((eachItem, index) => {
                      return (
                        <div
                          key={"pagebtn" + index + currentPage}
                          className={`${classNames.eachBtn} ${
                            currentPage + index == currentPage
                              ? classNames.eachBtnCurrent
                              : ""
                          }`}
                          onClick={() => {
                            setCurrentPage(currentPage + index);
                          }}
                        >
                          {currentPage + index}
                        </div>
                      );
                    })
                  : Array.from({ length: 4 })?.map((eachItem, index) => {
                      return (
                        <div
                          key={"pagebtn" + index + currentPage}
                          className={`${classNames.eachBtn} ${
                            currentPage + index - 1 == currentPage
                              ? classNames.eachBtnCurrent
                              : ""
                          }`}
                          onClick={() => {
                            setCurrentPage(currentPage + index - 1);
                          }}
                        >
                          {currentPage + index - 1}
                        </div>
                      );
                    })}
              </div>
            </div> */}
    </>
  );
};

export default WalletComponent;
