import fullLogo from "../assest/images/logos/fullLogo.svg";
import logoIcon from "../assest/images/logos/logoIcon.svg";
import mobileIcon from "../assest/images/logos/mobileIcon.svg";

export const FULL_LOGO = fullLogo;
export const LOGO_ICON = logoIcon;
export const MOBILE_ICON = mobileIcon;

export const GX_API_ENDPOINT = "https://comms.globalxchange.io";

export const COUNTRY = "India";

export const NEW_CHAT_API = "https://testchatsioapi.globalxchange.io";

export const NEW_CHAT_SOCKET = "https://testsockchatsio.globalxchange.io";

export const SUPPORT_CHAT_URL = "https://chatsapi.globalxchange.io/gxchat/";

export const HEADER_ICON_SIZE = 20;

export const APP_USER_TOKEN = "app_user_token";

export const MOBILE_SIZE = 800;
