import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./coverficompanyprofile.module.scss";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { CopyBtn, EachCustomProfileDropdown } from "../../../components/Custom";

import { ReactComponent as CompleteIcon } from "../../../assest/images/icons/complete.svg";
import { ReactComponent as TrashcanIcon } from "../../../assest/images/icons/trashcan.svg";

//assets icon
import { ReactComponent as PropertyIcon } from "../../../assest/images/profile/property.svg";
import { ReactComponent as VehicleIcon } from "../../../assest/images/profile/vehicle.svg";
import { ReactComponent as WorldIcon } from "../../../assest/images/profile/world.svg";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewCompanyProfileStep2 from "../../Employer/CreateNewCompanyProfileStep2";
import EditAddCompanyProfile from "../../EditAddCompanyProfile";
import NavbarV2 from "../../../components/NavbarV2";
import {
  EachCustomDropdown,
  EachCustomInput,
  EachCustomSelectOne,
} from "../../../components/Custom/Input";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import NavbarV3 from "../../../components/NavbarV3";
import { logoutToSameLogin } from "../../../assest/functions";

const CoverfiCompanyProfile = () => {
  const history = useHistory();
  const {
    name,
    email,
    globalUserId,
    globalCompanyId,
    setGlobalCompanyId,
    isProfileFound,
    setIsProfileFound,
    popularAction,
    setPopularAction,
    updateLoading,
    setUpdateLoading,
  } = useContext(BankContext);
  const [selectedProfileNav, setSelectedProfileNav] =
    useState("My Company Profile");
  const [selectedDiffSection, setSelectedDiffSection] =
    useState("Company Details");
  const [companyProfileDetails, setCompanyProfileDetails] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [newAdministratorForm, setNewAdministratorForm] = useState("");

  const [selecteConfiguration, setSelecteConfiguration] = useState("");

  useEffect(() => {
    setUpdateLoading(true);
    if (globalUserId) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/hr/get?user_id=${globalUserId}`
      )
        .then((response) => {
          console.log(
            response?.data?.data,
            "company profile response main Page"
          );
          if (response?.data?.status && response?.data?.data?.length > 0) {
            console.log(
              response?.data?.data[0]?.company_id,
              "company id response"
            );
            // if (!response?.data?.data[0]?.policyQuotationProfile) {
            //   // setIsProfileFound("createpolicy");
            // } else {
            setIsProfileFound("companyprofile");
            // }
            setGlobalCompanyId(response?.data?.data[0]?.company_id);
            setCompanyProfileDetails(response?.data?.data[0]);
            localStorage.setItem(
              "globalCompanyIdRaha",
              response?.data?.data[0]?.company_id
            );
          } else {
            console.log("no API response company profile");
            setIsProfileFound(false);
          }
          setUpdateLoading(false);
        })
        .catch((error) => {
          console.log(error?.message, "user profile error");
          setUpdateLoading(false);
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }, [globalUserId]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      {updateLoading ? (
        ""
      ) : popularAction == "healthflat" ||
        popularAction == "accidentpolicy" ||
        popularAction == "lifepolicy" ? (
        <EditAddCompanyProfile />
      ) : isProfileFound == "companyprofile" ? (
        <>
          <NavbarV3
            dropdownItems={[]}
            selectedTab={selectedProfileNav}
            setSelectedTab={setSelectedProfileNav}
            setSearchQuery={setSearchQuery}
          />
          <div className={classNames.myProfileDetails}>
            <div className={classNames.mainDiv}>
              <div className={classNames.detailedItem}>
                <div>
                  <img
                    src={
                      companyProfileDetails?.company_profile?.icon
                        ? companyProfileDetails?.company_profile?.icon
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                    }
                    alt="brokerage_icon"
                  />
                </div>
                <div>
                  <div>
                    {companyProfileDetails?.company_profile?.company_name
                      ? companyProfileDetails?.company_profile?.company_name
                      : ""}
                  </div>
                  <div>Update Company Profile</div>
                </div>
              </div>
              <div className={classNames.diffSections}>
                <div
                  className={
                    selectedDiffSection === "Company Details"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    setSelectedDiffSection("Company Details");
                  }}
                >
                  Company Details
                </div>
                <div
                  className={
                    selectedDiffSection === "Company Address"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    setSelectedDiffSection("Company Address");
                  }}
                >
                  Company Address
                </div>
                <div
                  className={
                    selectedDiffSection === "Admin Management"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    setSelectedDiffSection("Admin Management");
                  }}
                >
                  Admin Management
                </div>
              </div>
            </div>
            <div className={classNames.selectedSectionMain}>
              {selectedDiffSection === "Company Details" ? (
                <div className={classNames.personalDetails}>
                  <EachProfileInput
                    title="Legal Name"
                    placeholder="Enter name..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.legal_name
                        ? companyProfileDetails?.company_profile?.legal_name
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Industry"
                    placeholder="Enter last..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.industry
                        ? companyProfileDetails?.company_profile?.industry
                        : ""
                    }
                  />
                  <EachProfileTextArea
                    title="Description"
                    placeholder="Enter email..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.description
                        ? companyProfileDetails?.company_profile?.description
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Website"
                    placeholder="Enter DOB..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.website
                        ? companyProfileDetails?.company_profile?.website
                        : ""
                    }
                  />
                  {/* <EachProfileInput
                    title="Mobile Number"
                    placeholder="Enter number..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.primary_phone_number
                        ? isProfileFound?.primary_phone_number
                        : ""
                    }
                  /> */}
                  {/* <EachProfileInput
                    title="Annual Income"
                    placeholder="Select annual income..."
                    dropdown="true"
                    stateValue="Less then 1Lahk"
                  /> */}
                  {/* <EachProfileInput
                    title="Martial Status"
                    placeholder="Select marriage status..."
                    dropdown="true"
                    stateValue={
                      isProfileFound?.martial_status
                        ? isProfileFound?.martial_status
                        : ""
                    }
                  /> */}
                  {/* <EachProfileInput
                    title="Address"
                    placeholder="Enter Address..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.address ? isProfileFound?.address : ""
                    }
                  />
                  <EachProfileInput
                    title="City"
                    placeholder="Enter city..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.city ? isProfileFound?.city : ""
                    }
                  />
                  <EachProfileInput
                    title="Country"
                    placeholder="Enter Country..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.country ? isProfileFound?.country : ""
                    }
                  />
                  <EachProfileInput
                    title="Postal Code"
                    placeholder="Enter pincode..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.pin_code ? isProfileFound?.pin_code : ""
                    }
                  /> */}
                </div>
              ) : selectedDiffSection === "Admin Management" ||
                selectedDiffSection === "Admin Management Form" ? (
                <div className={classNames.adminContainer}>
                  {selectedDiffSection === "Admin Management" ? (
                    <div className={classNames.tableContainer}>
                      <table className={classNames.tableParent}>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Sr. No.</th>
                            <th>Admin Name</th>
                            <th>Designation</th>
                            <th>Email ID</th>
                            <th>Mobile No.</th>
                            <th>Status</th>
                            <th
                              onClick={() =>
                                setSelectedDiffSection("Admin Management Form")
                              }
                            >
                              +
                            </th>
                          </tr>
                        </thead>
                        <tbody className={classNames.tableBody}>
                          <tr className={classNames.tableRow}>
                            <td>
                              <MdEdit />
                            </td>
                            <td>1</td>
                            <td>Betty Thomas</td>
                            <td>HR Manager</td>
                            <td>betty@starfishgp.com</td>
                            <td>+91 768559222</td>
                            <td>Invited</td>
                            <td>
                              <RiDeleteBin5Line />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className={classNames.adminManagementForm}>
                      <div className={classNames.tableHead}>
                        <div>Add New Adminstrator</div>
                        <div
                          onClick={() =>
                            setSelectedDiffSection("Admin Management")
                          }
                        >
                          X
                        </div>
                      </div>
                      <div className={classNames.formBody}>
                        <div className={classNames.gridFour}>
                          <EachCustomDropdown
                            buttonText={
                              newAdministratorForm ? newAdministratorForm : "Mr"
                            }
                            // color="var(--main-color)"
                            dropdownItems={[]}
                            name="Mr"
                            placeholder="Mr"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                            typee="obj"
                          />
                          <EachCustomInput
                            placeholder="First name"
                            name="firstName"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                          <EachCustomInput
                            placeholder="Last name"
                            name="lastName"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                          <EachCustomInput
                            placeholder="Designation"
                            name="designation"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                        </div>
                        <div className={classNames.gridFour}>
                          <EachCustomDropdown
                            buttonText={
                              newAdministratorForm
                                ? newAdministratorForm
                                : "+91"
                            }
                            // color="var(--main-color)"
                            dropdownItems={[]}
                            name="+91"
                            placeholder="+91"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                            typee="obj"
                          />
                          <EachCustomInput
                            placeholder="Phone number"
                            name="phoneNumber"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                          <EachCustomInput
                            placeholder="Email ID"
                            name="email"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                        </div>
                        <EachCustomSelectOne
                          title="Are they already a Tall Tree user?"
                          placeholder="Designation"
                          name="designation"
                          stateValue={newAdministratorForm}
                          setState={setNewAdministratorForm}
                          dropdown={["Yes", "No"]}
                        />
                        <div className={classNames.greenbtn}>Invite Admin</div>
                      </div>
                    </div>
                  )}
                </div>
              ) : selectedDiffSection === "Company Address" ? (
                <div className={classNames.personalDetails}>
                  {/* <div>
                    <div className={classNames.title}>Mobile Numbers</div>
                    <EachProfileInput
                      title="Mobile Number"
                      placeholder="Enter number..."
                      dropdown=""
                      stateValue={
                        isProfileFound?.primary_phone_number
                          ? isProfileFound?.primary_phone_number
                          : ""
                      }
                      status="primary"
                    />
                    {isProfileFound?.secondary_phone_numbers
                      ? isProfileFound?.secondary_phone_numbers?.map(
                          (number, index) => (
                            <EachProfileInput
                              title="Mobile Number"
                              placeholder="Enter number..."
                              dropdown=""
                              stateValue={number ? number : ""}
                              status="secondary"
                              removeState={setMobileAdding}
                            />
                          )
                        )
                      : ""}
                    <div
                      className={classNames.addNewBtn}
                      onClick={() => {
                        setMobileAdding((prev) => prev + 1);
                      }}
                    >
                      <div className={classNames.icon}>+</div>
                      <span>Add new number</span>
                    </div>
                  </div>
                  <div>
                    <div className={classNames.title}>Email Addresses</div>
                    <EachProfileInput
                      title="Email Address"
                      placeholder="Enter email..."
                      dropdown=""
                      stateValue={
                        isProfileFound?.user_email
                          ? isProfileFound?.user_email
                          : ""
                      }
                      status="primary"
                    />
                  
                  </div> */}
                  <EachProfileInput
                    title="Street Address"
                    placeholder="Enter name..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.address
                        ? companyProfileDetails?.company_profile?.address
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="City"
                    placeholder="Enter name..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.city
                        ? companyProfileDetails?.company_profile?.city
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="State"
                    placeholder="Enter name..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.state
                        ? companyProfileDetails?.company_profile?.state
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Postal Code"
                    placeholder="Enter Postal Code..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.pin_code
                        ? companyProfileDetails?.company_profile?.pin_code
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Country"
                    placeholder="Enter name..."
                    dropdown=""
                    stateValue={
                      companyProfileDetails?.company_profile?.country
                        ? companyProfileDetails?.company_profile?.country
                        : ""
                    }
                  />
                </div>
              ) : (
                <>
                  <div className={classNames.assetsDetails}>
                    <div className={classNames.assetsContainer}>
                      <div
                        className={classNames.eachAsset}
                        style={{
                          opacity:
                            companyProfileDetails?.policyQuotationProfile?.health?.toLowerCase() ==
                            "yes"
                              ? "1"
                              : "",
                          pointerEvents:
                            companyProfileDetails?.policyQuotationProfile?.health?.toLowerCase() ==
                            "yes"
                              ? "unset"
                              : "",
                        }}
                        onClick={() => {
                          setSelecteConfiguration("health");
                        }}
                      >
                        <div>Health</div>
                        <div>
                          <PropertyIcon />
                          <div className={classNames.shadeArea}></div>
                        </div>
                      </div>
                      <div
                        className={classNames.eachAsset}
                        style={{
                          opacity:
                            companyProfileDetails?.policyQuotationProfile?.accident?.toLowerCase() ==
                            "yes"
                              ? "1"
                              : "",
                          pointerEvents:
                            companyProfileDetails?.policyQuotationProfile?.accident?.toLowerCase() ==
                            "yes"
                              ? "unset"
                              : "",
                        }}
                        onClick={() => {
                          setSelecteConfiguration("accident");
                        }}
                      >
                        <div>Accident</div>
                        <div>
                          <VehicleIcon />
                          <div className={classNames.shadeArea}></div>
                        </div>
                      </div>
                      <div
                        className={classNames.eachAsset}
                        style={{
                          opacity:
                            companyProfileDetails?.policyQuotationProfile?.life?.toLowerCase() ==
                            "yes"
                              ? "1"
                              : "",
                          pointerEvents:
                            companyProfileDetails?.policyQuotationProfile?.life?.toLowerCase() ==
                            "yes"
                              ? "unset"
                              : "",
                        }}
                        onClick={() => {
                          setSelecteConfiguration("life");
                        }}
                      >
                        <div>Life</div>
                        <div>
                          <WorldIcon />
                          <div className={classNames.shadeArea}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {selecteConfiguration == "health" ? (
                    <>
                      <div
                        className={classNames.title}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "500",
                          marginTop: "2rem",
                        }}
                      >
                        Health Configuration
                      </div>
                      <div
                        className={classNames.personalDetails}
                        style={{ marginTop: "1rem" }}
                      >
                        <EachProfileInput
                          title="Insurance Type"
                          placeholder="Enter name..."
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.coverage_type
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.coverage_type
                              : ""
                          }
                        />
                        <EachProfileInput
                          title="Covered Persons"
                          placeholder=""
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.covered_persons
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.covered_persons
                              : ""
                          }
                        />
                        <EachProfileInput
                          title="Insured Sum"
                          placeholder=""
                          dropdown="-"
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.health_sum_insured
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.health_sum_insured
                              : "" +
                                " " +
                                companyProfileDetails?.policyQuotationProfile
                                  ?.currency
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.currency
                              : ""
                          }
                        />
                      </div>
                    </>
                  ) : selecteConfiguration == "accident" ? (
                    <>
                      <div
                        className={classNames.title}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "500",
                          marginTop: "2rem",
                        }}
                      >
                        Accident Configuration
                      </div>
                      <div
                        className={classNames.personalDetails}
                        style={{ marginTop: "1rem" }}
                      >
                        <EachProfileInput
                          title="Insurance Type"
                          placeholder="Enter name..."
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.coverage_type
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.coverage_type
                              : ""
                          }
                        />
                        <EachProfileInput
                          title="Covered Persons"
                          placeholder=""
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.covered_persons
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.covered_persons
                              : ""
                          }
                        />
                        <EachProfileInput
                          title="Insured Sum"
                          placeholder="-"
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.accident_sum_insured
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.accident_sum_insured
                              : "" +
                                " " +
                                companyProfileDetails?.policyQuotationProfile
                                  ?.currency
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.currency
                              : ""
                          }
                        />
                      </div>
                    </>
                  ) : selecteConfiguration == "life" ? (
                    <>
                      <div
                        className={classNames.title}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "500",
                          marginTop: "2rem",
                        }}
                      >
                        Life Configuration
                      </div>
                      <div
                        className={classNames.personalDetails}
                        style={{ marginTop: "1rem" }}
                      >
                        <EachProfileInput
                          title="Insurance Type"
                          placeholder="Enter name..."
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.coverage_type
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.coverage_type
                              : ""
                          }
                        />
                        <EachProfileInput
                          title="Covered Persons"
                          placeholder=""
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.covered_persons
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.covered_persons
                              : ""
                          }
                        />
                        <EachProfileInput
                          title="Insured Sum"
                          placeholder="-"
                          dropdown=""
                          stateValue={
                            companyProfileDetails?.policyQuotationProfile
                              ?.life_sum_insured
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.life_sum_insured
                              : "" +
                                " " +
                                companyProfileDetails?.policyQuotationProfile
                                  ?.currency
                              ? companyProfileDetails?.policyQuotationProfile
                                  ?.currency
                              : ""
                          }
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={classNames.noProfile}>
          <div>
            <div>Looks Like You Haven't Completed</div>
            <div>Updating Your Company Profile</div>
          </div>
          <div>No Problem. It Only Takes A Few Minutes.</div>
          <Link to="/app/coverfi/newcompanyprofile">
            Create Company Profile
          </Link>
        </div>
      )}
    </div>
  );
};

export default CoverfiCompanyProfile;

const EachProfileInput = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
}) => {
  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputParent}>
        <input
          type="text"
          name=""
          placeholder={placeholder}
          value={stateValue}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
        />
        {status == "secondary" ? (
          <div
            className={classNames.trashCan}
            onClick={() => {
              removeState((prev) => prev - 1);
            }}
          >
            <TrashcanIcon />
          </div>
        ) : (
          ""
        )}
        {status == "primary" ? (
          <div className={classNames.status}>
            <CompleteIcon />
            <span>Primary</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {dropdown && <IoMdArrowDropdown />} */}
    </div>
  );
};

const EachProfileTextArea = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
}) => {
  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputParent}>
        <textarea
          type="text"
          name=""
          placeholder={placeholder}
          value={stateValue}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
        >
          {stateValue}
        </textarea>
        {status == "secondary" ? (
          <div
            className={classNames.trashCan}
            onClick={() => {
              removeState((prev) => prev - 1);
            }}
          >
            <TrashcanIcon />
          </div>
        ) : (
          ""
        )}
        {status == "primary" ? (
          <div className={classNames.status}>
            <CompleteIcon />
            <span>Primary</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {dropdown && <IoMdArrowDropdown />} */}
    </div>
  );
};
