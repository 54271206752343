import React from "react";
import classNames from "./benefits.module.scss";

import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaBriefcaseMedical } from "react-icons/fa";

const EmployeeBenefits = () => {
  const allBenefitList = [
    {
      title: "Maternity Benefit",
      desc: "Normal Delivery - USD 35,000, C-Sec - USD 50,000 - Applicable for Self and Spouse for First two deliveries only",
    },
    {
      title: "Pre & Post Natal expenses",
      desc: "Covered within Maternity Limit on IPD basis upto USD 5,000",
    },
    { title: "9 month waiting period", desc: "Waived Off" },
    { title: "New born Baby Coverage", desc: "Covered Within Family SI" },
    { title: "Congenital Internal diseases", desc: "Covered" },
    {
      title: "Congenital External diseases",
      desc: "Covered in Life threatening condition only",
    },
    {
      title: "Ayush Treatment",
      desc: "Ayush treatment covered under the policy upto 25% of FSI in govt. recognised hospitals only",
    },
    {
      title: "Pre & Post Hospitalisation Expenses",
      desc: "Covered for 30 & 60 days respectively",
    },
    { title: "Room Rent Restriction", desc: "No Room Rent Capping" },
    {
      title: "Emergency Ambulance Charges",
      desc: "Covered upto 1% of SI with maximum amount of USD 2,000 Per Hospitalisation",
    },
    {
      title: "Sub Limit for various Ailments / Treatments",
      desc: "No Disease Wise Capping",
    },
    { title: "Co-Payment", desc: "No Co-payment on claims" },
    {
      title: "Domiciliary Hospoitalisation",
      desc: "Covered Up to Sum Insured",
    },
    {
      title: "Terrorism Hospitalization",
      desc: "Any Hospitalisation due to terrorism activities will be covered upto IPD Sum Insured",
    },
    { title: "Cataract Limit", desc: "No Limit" },
    {
      title: "Functional Endoscopic Sinus",
      desc: "Liability for Nasal Sinus Surgeries upto Rs.35,000",
    },
    {
      title: "Emergency Air Ambulance",
      desc: "Air ambulance is covered upto Rs.5lac per family in case of emergency",
    },
    {
      title: "Psychiatric/Mental disorder treatments",
      desc: "Hospitalisation arising out of Psychiatric ailments upto Rs.30,000.",
    },
    {
      title: "Refractive Error Correction",
      desc: "Lasik surgery covered above +/- 7.5",
    },
    {
      title: "Special Condition",
      desc: "Cyberknife treatment, Stem Cell Transplantation, Uterine Artery Embolization and HIFU, Balloon Sinuplasty, Deep Brain stimulation, Immunotherapy- Monoclonal Antibody to be given as injection, Robotic surgeries, Stereotactic radio surgeries, Bronchical Thermoplasty, Vaporisation of the prostrate (Green laser treatment or holmium laser treatment), IONM - (Intra Operative Neuro Monitoring). Cochlear Implant treatment and Oral chemotherapy shall be covered up to 100% of the Sum Insured(SI). Intra vitreal injection is covered upto 50% of SI.",
    },
    {
      title: "Portability/ Continunity benefit",
      desc: "Benefits Waiver of waiting period if employee exit from GMC policy and port it to retail policy by charging retail policy premium. Benefit has to be extended for the number of years an employee has been associated with the company - Obeetee and not with the insurer.",
    },
    {
      title: "Claim Intimation Submission",
      desc: "All reimbursement claims have to be intimated within 15 days of discharge. Claims have to be submitted for reimbursement within 30 days of date of discharge of the patient. However, the Company may at its absolute discretion consider waiver of this Condition in extreme cases of hardship where it is proved to the satisfaction of the Company that under the circumstances in which the insured was placed it was not possible for him or any other person to give such notice or file claim within the prescribed time-limit. The claims would invite additional 10% co payment over and above payable amount only in case of delay in submission of claims beyond 30 days.",
    },
  ];

  return (
    <div className={classNames.employeeBenefits}>
      <div className={classNames.backBtn}>
        <AiOutlineArrowLeft />
      </div>
      <div className={classNames.title}>Benefits</div>
      <div className={classNames.allBenefits}>
        {allBenefitList?.length > 0 &&
          allBenefitList?.map(({ title, desc }, index) => {
            return (
              <div className={classNames.eachBenefit} key={title + index}>
                <div className={classNames.suitIcon}>
                  <FaBriefcaseMedical />
                </div>
                <div className={classNames.content}>
                  <div className={classNames.title}>{title}</div>
                  <div className={classNames.desc}>{desc}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmployeeBenefits;
