import React, { useState } from "react";
import classNames from "./employeelogin.module.scss";

import OtpInput from "react-otp-input";
import { CustomInputVisit } from "../../../components/Custom";

const EmployeeLogin = () => {
  const [loginCurrent, setLoginCurrent] = useState("login");
  const [otp, setOtp] = useState("");
  return (
    <div className={classNames.employeeLogin}>
      {loginCurrent == "login" ? (
        <>
          <div className={classNames.title}>Please enter your details</div>
          <CustomInputVisit title="Phone" placeholder="Enter Phone Number..." />
          <CustomInputVisit
            title="Employee ID"
            placeholder="Enter Employee ID..."
          />
          <CustomInputVisit
            title="Your Corporate Email"
            placeholder="Enter Corporate Email..."
          />
          <div className={classNames.loginBtn}>Continue</div>
        </>
      ) : (
        <>
          <div className={classNames.title}>
            Please enter the OTP sent to 8181818181
          </div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            className={classNames.otpInputContainer}
          />
          <div className={classNames.otpBtn}>
            <span>Didn't receive?</span>
            <span>Resend OTP</span>
          </div>
          <div className={classNames.loginBtn}>Verify</div>
        </>
      )}
    </div>
  );
};

export default EmployeeLogin;
