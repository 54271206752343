import React, { useContext, useEffect } from "react";
import classNames from "./overviewtabs.module.scss";
import { useState } from "react";
import styles from "./overviewtabsstyle.module.scss";

//assets
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import completePercentageIcon from "../../../../assest/images/icons/completepercentage.svg";
import downloadFileIcon from "../../../../assest/images/icons/downloadfile.svg";
import uploadFileIcon from "../../../../assest/images/icons/uploadfileIcon.svg";
import calendarIcon from "../../../../assest/images/icons/calendar.svg";
import youtubeIcon from "../../../../assest/images/icons/youtube.svg";
import cupcake from "../../../../assest/images/employer/overview/cupcake.svg";
import halfCircle from "../../../../assest/images/halfCirclePie.svg";
import {
  EachCustomDatePicker,
  EachCustomDocumentUploadPlain,
  EachCustomDropdown,
  EachCustomInput,
  EachCustomTextAreaGeneral,
} from "../../../../components/Custom/Input";
import { Skeleton } from "antd";
import Axios from "axios";
import { CiCircleRemove } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { BankContext } from "../../../../context/Context";
import { predefinedToast } from "../../../../utils/toast";
import { uploadFileFunc } from "../../../../utils/imageUpload";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { FaEye } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import moment from "moment/moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import fullLogo from "../../../../assest/images/fullLogo.svg";
import { EachInputDatePicker } from "../../../Agency/CDBalance/AddTransaction";

const NewQuote = () => {
  const { email } = useContext(BankContext);
  const [allQuotes, setAllQuotes] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/policy/get/quote?email_id=${email}`
    ).then((response) => {
      console.log(response?.data?.data, "all quotes response");
      setAllQuotes(response?.data?.data);
    });
  }, [refreshPage]);

  return (
    <div className={classNames.newQuote}>
      <div className={classNames.allQuotes}>
        {allQuotes?.length > 0 &&
          allQuotes?.map((eachItem, index) => {
            return (
              <div className={classNames.eachQuote} key={"eachquotes" + index}>
                <EachQuoteHeader
                  eachQuote={eachItem}
                  indexx={index}
                  refreshPage={refreshPage}
                  setRefreshPage={setRefreshPage}
                  style={{ noBorder: true }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NewQuote;

export const NewQuoteMini = ({
  openedQuoteMini,
  setOpenedQuoteMini,
  claimsApi,
  setClaimsApi,
}) => {
  const { email } = useContext(BankContext);
  const [allQuotes, setAllQuotes] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/policy/get/quote?email_id=${email}`
    ).then((response) => {
      console.log(response?.data?.data, "all quotes response");
      setAllQuotes(response?.data?.data);
    });
  }, [refreshPage]);

  return (
    <div className={`${classNames.newQuote} ${classNames.newQuoteMini}`}>
      <div className={classNames.allQuotes}>
        {allQuotes?.length > 0 ? (
          allQuotes?.map((eachItem, index) => {
            if (openedQuoteMini && openedQuoteMini != "Quote " + (index + 1)) {
            } else {
              return (
                <div
                  className={classNames.eachQuote}
                  key={"eachquotes" + index}
                  style={{
                    border: setOpenedQuoteMini ? "none" : "",
                  }}
                >
                  <EachQuoteHeader
                    eachQuote={eachItem}
                    indexx={index}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                    openedQuoteMini={openedQuoteMini}
                    setOpenedQuoteMini={setOpenedQuoteMini}
                    mobile={"mobile"}
                  />
                </div>
              );
            }
          })
        ) : (
          <div className={classNames.emptyPoliciesDiv}>
            <div>
              Looks like you are new. Lets finish you’re onboarding process
            </div>
            <div className={classNames.btnsContainer}>
              <button
                onClick={() => {
                  setClaimsApi("startQuote");
                }}
              >
                New Quote
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const EachQuoteHeader = ({
  eachQuote,
  indexx,
  refreshPage,
  setRefreshPage,
  openedQuoteMini,
  setOpenedQuoteMini,
  style,
  mobile,
}) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (!openedQuoteMini) {
      setOpened(false);
    }
  }, [openedQuoteMini]);

  return (
    <>
      <div
        className={classNames.quotesHeader}
        onClick={() => {
          setOpened(!opened);
          if (opened && setOpenedQuoteMini) {
            setOpenedQuoteMini("");
          } else if (setOpenedQuoteMini) {
            setOpenedQuoteMini("Quote " + (indexx + 1));
          }
        }}
        style={{
          background: opened ? "var(--main-color)" : "",
          color: opened ? "white" : "",
          display: !openedQuoteMini
            ? ""
            : // : openedQuoteMini && openedQuoteMini != "Quote " + (indexx + 1)
            // ? "none"
            setOpenedQuoteMini && opened
            ? "none"
            : "",
          border: style?.noBorder ? "none" : "",
        }}
      >
        <div className={classNames.title}>Quote {indexx + 1}</div>
        {opened ? <IoCaretUpOutline /> : <IoCaretDownOutline />}
      </div>

      {eachQuote?.next_Step === "Completed" && opened ? (
        <RFQHistory
          setOpened={setOpened}
          quoteReviewForms={eachQuote}
          setOpenedQuoteMini={setOpenedQuoteMini}
          mobile={mobile}
        />
      ) : eachQuote?.next_Step === "Configurations" && opened ? (
        <Configurations
          setOpened={setOpened}
          quoteReviewForms={eachQuote}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          setOpenedQuoteMini={setOpenedQuoteMini}
        />
      ) : eachQuote?.next_Step === "Members" && opened ? (
        <Members
          setOpened={setOpened}
          quoteReviewForms={eachQuote}
          setRefreshPage={setRefreshPage}
          setOpenedQuoteMini={setOpenedQuoteMini}
        />
      ) : opened === "Success" ? (
        <Success
          setOpened={setOpened}
          setOpenedQuoteMini={setOpenedQuoteMini}
        />
      ) : eachQuote?.next_Step === "Pre-Enrollment Tracking" && opened ? (
        <PreEnrollment
          setOpened={setOpened}
          quoteReviewForms={eachQuote}
          setOpenedQuoteMini={setOpenedQuoteMini}
        />
      ) : eachQuote?.next_Step === "History" && opened ? (
        <RFQHistory
          setOpened={setOpened}
          quoteReviewForms={eachQuote}
          setOpenedQuoteMini={setOpenedQuoteMini}
          mobile={mobile}
        />
      ) : eachQuote?.next_Step === "Review Request" && opened ? (
        <QuoteReview
          setOpened={setOpened}
          eachQuote={eachQuote}
          setRefreshPage={setRefreshPage}
          setOpenedQuoteMini={setOpenedQuoteMini}
        />
      ) : (
        ""
      )}
    </>
  );
};

// QuoteReview

const QuoteReview = ({
  eachQuote,
  setOpened,
  setRefreshPage,
  setOpenedQuoteMini,
}) => {
  const { email } = useContext(BankContext);
  const [quoteReviewForm, setQuoteReviewForm] = useState({});

  //functions

  async function getQuoteInformationOneLead() {
    let response = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/lead/get?lead_email=${email}`
    );
    if (response?.data?.data?.length > 0) {
      setQuoteReviewForm(response?.data?.data[0]);
    }
    console.log(response?.data, "getQuoteInformationOneLead");
  }

  async function updateQuoteStateConfiguration(quoteId) {
    let response = await Axios.put(
      `https://insurancecoverfi.apimachine.com/insurance/policy/update/quote?quote_id=${quoteId}`,
      {
        completed_Step: "Review Request",
        next_Step: "Configurations",
      }
    );
    if (response?.data?.status) {
      predefinedToast(response?.data?.message);
      setRefreshPage("QuoteReview");
    } else {
      predefinedToast(response?.data?.message);
    }
    console.log(response?.data, "updateQuoteStateConfiguration");
  }

  // rendering

  useEffect(() => {
    getQuoteInformationOneLead();
  }, []);

  return (
    <div
      className={classNames.quoteReview}
      style={{ padding: setOpenedQuoteMini ? "0" : "" }}
    >
      <div className={classNames.title}>
        <span>Review</span>
        {/* <img src={completePercentageIcon} alt="completePercentageIcon" /> */}
        <CircularProgressbar
          value={16.66}
          styles={buildStyles({
            trailColor: "#d6d6d6",
            backgroundColor: "var(--opacity-main)",
            path: {
              // Path color
              stroke: `var(--opacity-main)`,
            },
          })}
        />
      </div>
      <div className={classNames.fieldsContainer}>
        <InsuranceTable quoteReviewForm={eachQuote} />
        <div
          className={classNames.singleInput}
          style={{ pointerEvents: "none", margin: "2rem 0" }}
        >
          <EachCustomInput
            title="No. of Employees"
            placeholder="No of employees"
            name="company_size"
            stateValue={quoteReviewForm}
            setState={setQuoteReviewForm}
          />
        </div>
        <div
          className={classNames.manyInputs}
          style={{ pointerEvents: "none" }}
        >
          <div className={classNames.title}>Company Contact Details</div>
          <div className={classNames.gridContainer}>
            <EachCustomInput
              placeholder="name"
              name="first_name"
              stateValue={quoteReviewForm}
              setState={setQuoteReviewForm}
            />
            <EachCustomInput
              placeholder="phone number"
              name="phone_number"
              stateValue={quoteReviewForm}
              setState={setQuoteReviewForm}
            />
            <EachCustomInput
              placeholder="email"
              name="lead_email"
              stateValue={quoteReviewForm}
              setState={setQuoteReviewForm}
            />
            <div>
              {/* {quoteReviewForm?.agent_image && (
                <img
                  src={quoteReviewForm?.agent_image}
                  alt=""
                  style={{
                    width: "2rem",
                    height: "2rem",
                    borderRadius: "50%",
                  }}
                />
              )} */}
              <EachCustomInput
                placeholder="email"
                name="agent_name"
                stateValue={quoteReviewForm}
                setState={setQuoteReviewForm}
                image={quoteReviewForm?.agent_image}
              />
            </div>
            <EachCustomInput
              placeholder="location"
              name="pin_code"
              stateValue={quoteReviewForm}
              setState={setQuoteReviewForm}
            />
            <EachCustomInput
              placeholder="Parent company"
              name="company_name"
              stateValue={quoteReviewForm}
              setState={setQuoteReviewForm}
            />
          </div>
        </div>
        <div className={classNames.btnsContainer}>
          <button style={{ display: "none" }}></button>
          <button
            onClick={() => updateQuoteStateConfiguration(eachQuote?.quote_id)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const InsuranceTable = ({ quoteReviewForm }) => {
  const [selectedInsurance, setSelectedInsurance] = useState("");

  const handleRadioChange = (insuranceType) => {
    setSelectedInsurance(insuranceType);
  };

  return (
    <table
      className={classNames.switchTable}
      style={{
        display:
          quoteReviewForm?.what_are_you_looking_for?.length > 0 ? "" : "none",
      }}
    >
      <thead>
        <tr>
          <td></td>
          <td>Renewal</td>
          <td>Fresh Policy</td>
          <td>Existing Policy Copy</td>
        </tr>
      </thead>
      <tbody>
        {(quoteReviewForm?.what_are_you_looking_for?.includes("health") ||
          quoteReviewForm?.what_are_you_looking_for?.includes("Health")) && (
          <tr>
            <td>Group Health Insurance</td>
            <td>
              <input
                type="radio"
                name="insuranceType"
                value="groupHealth"
                checked={quoteReviewForm?.external_policy_health}
                // onChange={() => handleRadioChange("groupHealth")}
              />
            </td>
            <td>
              <input
                type="radio"
                name="insuranceType"
                value="groupTermLife"
                checked={!quoteReviewForm?.external_policy_health}
                // onChange={() => handleRadioChange("groupTermLife")}
              />
            </td>
            <td
              style={{
                opacity: quoteReviewForm?.external_policy_health_doc
                  ? ""
                  : "0.5",
                pointerEvents: quoteReviewForm?.external_policy_health_doc
                  ? ""
                  : "none",
              }}
            >
              <img
                src={downloadFileIcon}
                alt="downloadFileIcon"
                onClick={() => {
                  if (quoteReviewForm?.external_policy_health_doc) {
                    window.open(
                      quoteReviewForm?.external_policy_health_doc,
                      "_blank"
                    );
                  }
                }}
              />
            </td>
          </tr>
        )}
        {(quoteReviewForm?.what_are_you_looking_for?.includes("accidental") ||
          quoteReviewForm?.what_are_you_looking_for?.includes(
            "Accidental"
          )) && (
          <tr>
            <td>Group Personal Accident</td>
            <td>
              <input
                type="radio"
                name="personalAccidentRenewal"
                value="personalAccidentRenewal"
                checked={quoteReviewForm?.external_policy_accidental}
                // onChange={() => handleRadioChange("personalAccidentRenewal")}
              />
            </td>
            <td>
              <input
                type="radio"
                name="personalAccidentFresh"
                value="personalAccidentFresh"
                checked={!quoteReviewForm?.external_policy_accidental}
                // onChange={() => handleRadioChange("personalAccidentFresh")}
              />
            </td>
            <td
              style={{
                opacity: quoteReviewForm?.external_policy_accidental_doc
                  ? ""
                  : "0.5",
                pointerEvents: quoteReviewForm?.external_policy_accidental_doc
                  ? ""
                  : "none",
              }}
            >
              <img
                src={downloadFileIcon}
                alt="downloadFileIcon"
                onClick={() => {
                  if (quoteReviewForm?.external_policy_accidental_doc) {
                    window.open(
                      quoteReviewForm?.external_policy_accidental_doc,
                      "_blank"
                    );
                  }
                }}
              />
            </td>
          </tr>
        )}
        {(quoteReviewForm?.what_are_you_looking_for?.includes("life") ||
          quoteReviewForm?.what_are_you_looking_for?.includes("Life")) && (
          <tr>
            <td>Group Term Life Insurance</td>
            <td>
              <input
                type="radio"
                name="lifeInsuranceRenewal"
                value="lifeInsuranceRenewal"
                checked={quoteReviewForm?.external_policy_life}
                // onChange={() => handleRadioChange("lifeInsuranceRenewal")}
              />
            </td>
            <td>
              <input
                type="radio"
                name="lifeInsuranceFresh"
                value="lifeInsuranceFresh"
                checked={!quoteReviewForm?.external_policy_life}
                // onChange={() => handleRadioChange("lifeInsuranceFresh")}
              />
            </td>
            <td
              style={{
                opacity: quoteReviewForm?.external_policy_life_doc ? "" : "0.5",
                pointerEvents: quoteReviewForm?.external_policy_life_doc
                  ? ""
                  : "none",
              }}
            >
              <img
                src={downloadFileIcon}
                alt="downloadFileIcon"
                onClick={() => {
                  if (quoteReviewForm?.external_policy_life_doc) {
                    window.open(
                      quoteReviewForm?.external_policy_life_doc,
                      "_blank"
                    );
                  }
                }}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

// Configurations

const Configurations = ({
  setOpened,
  quoteReviewForms,
  refreshPage,
  setRefreshPage,
  setOpenedQuoteMini,
}) => {
  const {
    email,
    globalUserId,
    globalHrId,
    globalCompanyId,
    setGlobalCompanyId,
    globalCompanyProfile,
    setGlobalHrId,
    setGlobalUserId,
  } = useContext(BankContext);
  const [selectedType, setSelectedType] = useState(
    quoteReviewForms?.what_are_you_looking_for?.length > 0
      ? quoteReviewForms?.what_are_you_looking_for[0]
      : []
  );
  const [quoteReviewForm, setQuoteReviewForm] = useState("");
  const [viewOnlyQuote, setViewOnlyQuote] = useState(false);

  async function healthConfigurationFunc() {
    if (!globalHrId) {
      function getUserId() {
        Axios.get(
          `https://insurancecoverfi.apimachine.com/insurance/user/getuser?user_email=${email}`
        )
          .then(async (response) => {
            if (response?.data?.data?.length > 0) {
              localStorage?.setItem(
                "globalUserIdRaha",
                response?.data?.data[0]?.user_id
              );
              setGlobalUserId(response?.data?.data[0]?.user_id);
              let globalHRID = await getGlobalHrId(
                response?.data?.data[0]?.user_id
              );
            }
          })
          .catch((error) => {
            console.log(error?.message, "user profile error");
          });
      }
      function getGlobalHrId(userid) {
        if (userid) {
          Axios.get(
            `https://insurancecoverfi.apimachine.com/insurance/hr/get?user_id=${userid}`
          )
            .then((response) => {
              console.log(response?.data, "Global HR ID response", userid);
              if (response?.data?.status && response?.data?.data?.length > 0) {
                // console.log(response?.data?.data[0]?.hr_id, "hr id response");
                if (response?.data?.data[0]?.hr_id) {
                  setGlobalHrId(response?.data?.data[0]?.hr_id);
                  localStorage.setItem(
                    "globalUserHrIdRaha",
                    response?.data?.data[0]?.hr_id
                  );
                }
              }
            })
            .catch((error) => {
              console.log(error?.message, "user profile error");
              // setAllInsuranceCompaniesLoading(false);
            });
        }
      }

      getUserId();
    }
    //updating values prepound

    if (quoteReviewForm?.coverage_type === "Flat") {
      quoteReviewForm.sum_insured = Array.isArray(quoteReviewForm?.sum_insured)
        ? quoteReviewForm?.sum_insured
        : [quoteReviewForm?.sum_insured];
    }

    if (
      quoteReviewForm?.sum_insured[quoteReviewForm?.sum_insured?.length - 1] ==
      ""
    ) {
      setQuoteReviewForm((prev) => {
        return {
          ...prev,
          sum_insured: [...quoteReviewForm?.sum_insured?.slice(0, -1)],
        };
      });
    }

    console.log(quoteReviewForm, "quoteReviewForm");
    console.log(
      quoteReviewForm?.sum_insured[quoteReviewForm?.sum_insured?.length - 1],
      "quoteReviewForm last value"
    );

    quoteReviewForm.company_id = globalCompanyId
      ? globalCompanyId
      : globalCompanyProfile?.company_id
      ? globalCompanyProfile?.company_id
      : localStorage.getItem("globalCompanyIdRaha")
      ? localStorage.getItem("globalCompanyIdRaha")
      : "";
    quoteReviewForm.hr_id = globalHrId
      ? globalHrId
      : localStorage.getItem("globalUserHrIdRaha")
      ? localStorage.getItem("globalUserHrIdRaha")
      : "";
    quoteReviewForm.quote_id = quoteReviewForms?.quote_id;
    quoteReviewForm.insurance_type = selectedType;
    quoteReviewForm.currency = "USD";
    quoteReviewForm.day1coverage = quoteReviewForm?.day1coverage ? true : false;
    quoteReviewForm.maternity = quoteReviewForm?.maternity ? true : false;
    // quoteReviewForm.day1coverage =
    //   quoteReviewForm.day1coverage === "Applicable"
    //     ? true
    //     : quoteReviewForm.day1coverage === "Not applicable"
    //     ? false
    //     : "";
    // quoteReviewForm.maternity =
    //   quoteReviewForm.maternity === "Applicable"
    //     ? true
    //     : quoteReviewForm.maternity === "Not applicable"
    //     ? false
    //     : "";

    if (quoteReviewForm?.covered_persons === "Employee only") {
      quoteReviewForm.covered_persons = ["Only The Employee"];
    } else if (quoteReviewForm?.covered_persons === "Employee plus spouse") {
      quoteReviewForm.covered_persons = ["Employee + Spouse"];
    } else if (
      quoteReviewForm?.covered_persons ===
      "Employee plus spouse, 2 children up to 25 years"
    ) {
      quoteReviewForm.covered_persons = ["Employee + Spouse + 2 Children"];
    } else if (
      quoteReviewForm?.covered_persons ===
      "Employee plus spouse, 2 Children up to 25 years, Parents OR Parent-in-Law to 80 years"
    ) {
      quoteReviewForm.covered_persons = [
        "Employee + Spouse + 2 Children + 2 Parents",
      ];
    } else if (
      quoteReviewForm?.covered_persons ===
      "Employee plus spouse, 2 Children up to 25 years, Parents & Parent-in-Law to 80 years"
    ) {
      quoteReviewForm.covered_persons = [
        "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws",
      ];
    }

    let response = await Axios.post(
      `https://insurancecoverfi.apimachine.com/insurance/policy/createQuotation`,
      quoteReviewForm
    );

    if (response?.data?.status) {
      predefinedToast("Save policy configuration successful!");
      setRefreshPage(selectedType + "Configurations");
    }

    console.log(quoteReviewForm, "response from health configuration func");
  }

  async function updateConfigurationToMembers() {
    let response = await Axios.put(
      `https://insurancecoverfi.apimachine.com/insurance/policy/update/quote?quote_id=${quoteReviewForms?.quote_id}`,
      {
        completed_Step: "Configurations",
        next_Step: "Members",
      }
    );
    if (response?.data?.status) {
      predefinedToast(response?.data?.message);
      setRefreshPage("updateConfigurationToMembers");
    } else {
      predefinedToast(response?.data?.message);
    }
    console.log(response?.data, "updateConfigurationToMembers");
  }

  async function configurationsValueCheck() {
    let response = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/policy/getQuotation?quote_id=${quoteReviewForms?.quote_id}&insurance_type=${selectedType}`
    );
    if (response?.data?.message === "No profiles found") {
      setQuoteReviewForm({ sum_insured: [] });
      setViewOnlyQuote(false);
    } else if (response?.data?.data?.length > 0) {
      setQuoteReviewForm(response?.data?.data[0]);
      setViewOnlyQuote(true);
    }
    console.log(response?.data?.data, "quoteReviewForms valuecheck", response);
  }

  useEffect(() => {
    if (quoteReviewForms?.quote_id && selectedType) {
      configurationsValueCheck();
    }
  }, [selectedType, refreshPage]);

  console.log(quoteReviewForms, "quoteReviewForms on configurations");

  return (
    <div
      className={classNames.configurations}
      style={{ padding: setOpenedQuoteMini ? "0" : "" }}
    >
      <div className={classNames.title}>
        <span>Configurations</span>
        {/* <img src={completePercentageIcon} alt="completePercentageIcon" /> */}
        <CircularProgressbar value={33.32} />
      </div>
      <div className={classNames.selectedType}>
        <button
          className={`${
            selectedType === "Health" ? classNames.selectedBtn : ""
          }`}
          style={{
            display:
              quoteReviewForms?.what_are_you_looking_for?.includes("health") ||
              quoteReviewForms?.what_are_you_looking_for?.includes("Health")
                ? "flex"
                : "",
          }}
          onClick={(event) => {
            setSelectedType(event?.target?.innerText);
          }}
        >
          Health
        </button>
        <button
          className={`${
            selectedType === "Accidental" ? classNames.selectedBtn : ""
          }`}
          style={{
            display:
              quoteReviewForms?.what_are_you_looking_for?.includes(
                "accidental"
              ) ||
              quoteReviewForms?.what_are_you_looking_for?.includes("Accidental")
                ? "flex"
                : "",
          }}
          onClick={(event) => {
            setSelectedType("Accidental");
          }}
        >
          Accident
        </button>
        <button
          className={`${selectedType === "Life" ? classNames.selectedBtn : ""}`}
          style={{
            display:
              quoteReviewForms?.what_are_you_looking_for?.includes("life") ||
              quoteReviewForms?.what_are_you_looking_for?.includes("Life")
                ? "flex"
                : "",
          }}
          onClick={(event) => {
            setSelectedType(event?.target?.innerText);
          }}
        >
          Life
        </button>
      </div>
      <div
        className={classNames.fieldsContainer}
        style={{
          pointerEvents: viewOnlyQuote ? "none" : "unset",
        }}
      >
        {selectedType === "Health" ? (
          <>
            <EachCustomDropdown
              title="Click to select"
              dropdown={[
                "Employee only",
                "Employee plus spouse",
                "Employee plus spouse, 2 children up to 25 years",
                "Employee plus spouse, 2 Children up to 25 years, Parents OR Parent-in-Law to 80 years",
                "Employee plus spouse, 2 Children up to 25 years, Parents & Parent-in-Law to 80 years",
              ]}
              name="covered_persons"
              stateValue={quoteReviewForm}
              setState={setQuoteReviewForm}
              typee="single"
              option={viewOnlyQuote ? "alreadySet" : ""}
              indexx={viewOnlyQuote ? 0 : ""}
            />

            <div className={classNames.selectInsuredType}>
              <div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.coverage_type === "Graded"}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.coverage_type === "Graded"
                          ? { ...prev, coverage_type: "", sum_insured: [] }
                          : {
                              ...prev,
                              coverage_type: "Graded",
                              sum_insured: [],
                            };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.coverage_type === "Graded"
                        ? "checked"
                        : ""
                    }`}
                  >
                    {quoteReviewForm?.coverage_type === "Graded" && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Graded Sum Insured</span>
                </label>
              </div>
              <div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.coverage_type === "Flat"}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.coverage_type === "Flat"
                          ? { ...prev, coverage_type: "", sum_insured: [] }
                          : { ...prev, coverage_type: "Flat", sum_insured: [] };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.coverage_type === "Flat" ? "checked" : ""
                    }`}
                  >
                    {quoteReviewForm?.coverage_type === "Flat" && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Flat Sum Insured</span>
                </label>
              </div>
            </div>
            <div className={classNames.sumInsuredInputs}>
              {quoteReviewForm?.coverage_type === "Graded" ? (
                <div className={classNames.gradedInputs}>
                  {Array.from({
                    length:
                      quoteReviewForm?.sum_insured?.length === 0
                        ? 1
                        : quoteReviewForm?.sum_insured?.length + 1,
                  })?.map((eachInput, index) => {
                    return (
                      <div
                        className={classNames.eachGrade}
                        key={"gradedinputs" + index}
                        style={{
                          display:
                            viewOnlyQuote &&
                            quoteReviewForm?.sum_insured?.length - 1 < index
                              ? "none"
                              : "",
                        }}
                        // style={{
                        //   display:
                        //     viewOnlyQuote &&
                        //     quoteReviewForm?.sum_insured?.length >= index &&
                        //     quoteReviewForm?.sum_insured[index]
                        //       ? ""
                        //       : "none",
                        // }}
                      >
                        <CiCircleRemove
                          className={classNames.addInput}
                          style={{ display: viewOnlyQuote ? "none" : "" }}
                          onClick={() => {
                            setQuoteReviewForm((prev) => {
                              return {
                                ...prev,
                                sum_insured: [
                                  ...quoteReviewForm?.sum_insured?.slice(0, -1),
                                ],
                              };
                            });
                          }}
                        />
                        <EachCustomInput
                          placeholder="0.00"
                          name="sum_insured"
                          stateValue={quoteReviewForm}
                          setState={setQuoteReviewForm}
                          indexx={index?.toString()}
                        />
                        <span>
                          {"Grade " +
                            String.fromCharCode(97 + index)?.toUpperCase()}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : quoteReviewForm?.coverage_type === "Flat" ? (
                <EachCustomInput
                  placeholder="0.00"
                  name="sum_insured"
                  stateValue={quoteReviewForm}
                  setState={setQuoteReviewForm}
                />
              ) : (
                ""
              )}
            </div>
            <div className={classNames.selectOtherOptions}>
              <div>
                <label
                  className="custom-checkbox"
                  style={{ display: viewOnlyQuote ? "none" : "" }}
                >
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.roomtype}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.roomtype
                          ? { ...prev, roomtype: false }
                          : { ...prev, roomtype: true };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.roomtype ? "checked" : ""
                    }`}
                  >
                    {quoteReviewForm?.roomtype && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Hospital stays</span>
                </label>
                <div style={{ display: !viewOnlyQuote ? "none" : "" }}>
                  Hospital stays
                </div>
              </div>
              <div
                className={classNames.singleInput}
                style={{
                  pointerEvents: quoteReviewForm?.roomtype ? "unset" : "none",
                }}
              >
                <EachCustomDropdown
                  title="Click to select"
                  dropdown={[
                    "0.5 % of the SI",
                    "1.0 % of the SI",
                    "1.5 % of the SI",
                    "2.0 % of the SI",
                    "Single A/C Room",
                    "Twin Sharing",
                    "No Limit",
                  ]}
                  name="roomtype"
                  stateValue={quoteReviewForm}
                  setState={setQuoteReviewForm}
                  typee="single"
                  option={viewOnlyQuote ? "alreadySet" : ""}
                />
              </div>
            </div>
            <div className={classNames.selectOtherOptions}>
              <div>
                <label
                  className="custom-checkbox"
                  style={{ display: viewOnlyQuote ? "none" : "" }}
                >
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.day1coverage}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.day1coverage
                          ? { ...prev, day1coverage: false }
                          : { ...prev, day1coverage: true };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.day1coverage ? "checked" : ""
                    }`}
                  >
                    {quoteReviewForm?.day1coverage && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Day 1 Coverage</span>
                </label>
                <div style={{ display: !viewOnlyQuote ? "none" : "" }}>
                  Day 1 Coverage
                </div>
              </div>
              <div
                className={classNames.singleInput}
                style={{
                  pointerEvents: quoteReviewForm?.day1coverage
                    ? "unset"
                    : "none",
                  display: "none",
                }}
              >
                <EachCustomDropdown
                  title="Click to select"
                  dropdown={["Applicable", "Not applicable"]}
                  name="day1coverage"
                  stateValue={quoteReviewForm}
                  setState={setQuoteReviewForm}
                  typee="single"
                  typeee="removeArrow"
                  option={viewOnlyQuote ? "alreadySet" : ""}
                />
              </div>
            </div>
            <div className={classNames.selectOtherOptions}>
              <div>
                <label
                  className="custom-checkbox"
                  style={{ display: viewOnlyQuote ? "none" : "" }}
                >
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.maternity}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.maternity
                          ? { ...prev, maternity: false }
                          : { ...prev, maternity: true };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.maternity ? "checked" : ""
                    }`}
                  >
                    {quoteReviewForm?.maternity && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Maternity</span>
                </label>
                <div style={{ display: !viewOnlyQuote ? "none" : "" }}>
                  Maternity
                </div>
              </div>
              <div
                className={classNames.singleInput}
                style={{
                  pointerEvents: quoteReviewForm?.maternity ? "unset" : "none",
                  display: "none",
                }}
              >
                <EachCustomDropdown
                  title="Click to select"
                  dropdown={["Applicable", "Not applicable"]}
                  name="maternity"
                  stateValue={quoteReviewForm}
                  setState={setQuoteReviewForm}
                  typee="single"
                  typeee="removeArrow"
                  option={viewOnlyQuote ? "alreadySet" : ""}
                />
              </div>
            </div>
          </>
        ) : selectedType === "Accidental" ? (
          <>
            <div className={classNames.selectInsuredType}>
              <div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.coverage_type === "Graded"}
                    onChange={() => {
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.coverage_type === "Graded"
                          ? { ...prev, coverage_type: "", sum_insured: [] }
                          : {
                              ...prev,
                              coverage_type: "Graded",
                              sum_insured: [],
                            };
                      });
                    }}
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.coverage_type === "Graded"
                        ? "checked"
                        : ""
                    }`}
                  >
                    {quoteReviewForm?.coverage_type === "Graded" && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Graded Sum Insured</span>
                </label>
              </div>
              <div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.coverage_type === "Flat"}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.coverage_type === "Flat"
                          ? { ...prev, coverage_type: "", sum_insured: [] }
                          : { ...prev, coverage_type: "Flat", sum_insured: [] };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.coverage_type === "Flat" ? "checked" : ""
                    }`}
                  >
                    {quoteReviewForm?.coverage_type === "Flat" && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Flat Sum Insured</span>
                </label>
              </div>
            </div>
            <div className={classNames.sumInsuredInputs}>
              {quoteReviewForm?.coverage_type === "Graded" ? (
                <div className={classNames.gradedInputs}>
                  {Array.from({
                    length:
                      quoteReviewForm?.sum_insured?.length === 0
                        ? 1
                        : quoteReviewForm?.sum_insured?.length + 1,
                  })?.map((eachInput, index) => {
                    return (
                      <div
                        className={classNames.eachGrade}
                        key={"gradedinputs" + index}
                        style={{
                          display:
                            viewOnlyQuote &&
                            quoteReviewForm?.sum_insured?.length - 1 < index
                              ? "none"
                              : "",
                        }}
                        // style={{
                        //   display:
                        //     viewOnlyQuote &&
                        //     quoteReviewForm?.sum_insured?.length >= index &&
                        //     quoteReviewForm?.sum_insured[index]
                        //       ? ""
                        //       : "none",
                        // }}
                      >
                        <CiCircleRemove
                          className={classNames.addInput}
                          style={{ display: viewOnlyQuote ? "none" : "" }}
                          onClick={() => {
                            setQuoteReviewForm((prev) => {
                              return {
                                ...prev,
                                sum_insured: [
                                  ...quoteReviewForm?.sum_insured?.slice(0, -1),
                                ],
                              };
                            });
                          }}
                        />
                        <EachCustomInput
                          placeholder="0.00"
                          name="sum_insured"
                          stateValue={quoteReviewForm}
                          setState={setQuoteReviewForm}
                          indexx={index?.toString()}
                        />
                        <span>
                          {"Grade " +
                            String.fromCharCode(97 + index)?.toUpperCase()}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : quoteReviewForm?.coverage_type === "Flat" ? (
                <EachCustomInput
                  placeholder="0.00"
                  name="sum_insured"
                  stateValue={quoteReviewForm}
                  setState={setQuoteReviewForm}
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : selectedType === "Life" ? (
          <>
            <div className={classNames.selectInsuredType}>
              <div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.coverage_type === "Graded"}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.coverage_type === "Graded"
                          ? { ...prev, coverage_type: "", sum_insured: [] }
                          : {
                              ...prev,
                              coverage_type: "Graded",
                              sum_insured: [],
                            };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.coverage_type === "Graded"
                        ? "checked"
                        : ""
                    }`}
                  >
                    {quoteReviewForm?.coverage_type === "Graded" && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Graded Sum Insured</span>
                </label>
              </div>
              <div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={quoteReviewForm?.coverage_type === "Flat"}
                    onChange={() =>
                      setQuoteReviewForm((prev) => {
                        return quoteReviewForm?.coverage_type === "Flat"
                          ? { ...prev, coverage_type: "", sum_insured: [] }
                          : { ...prev, coverage_type: "Flat", sum_insured: [] };
                      })
                    }
                  />
                  <span
                    className={`checkmark ${
                      quoteReviewForm?.coverage_type === "Flat" ? "checked" : ""
                    }`}
                  >
                    {quoteReviewForm?.coverage_type === "Flat" && (
                      // <TiTick />
                      <div className="checkedBox"></div>
                    )}
                  </span>
                  <span>Flat Sum Insured</span>
                </label>
              </div>
            </div>
            <div className={classNames.sumInsuredInputs}>
              {quoteReviewForm?.coverage_type === "Graded" ? (
                <div className={classNames.gradedInputs}>
                  {Array.from({
                    length:
                      quoteReviewForm?.sum_insured?.length === 0
                        ? 1
                        : quoteReviewForm?.sum_insured?.length + 1,
                  })?.map((eachInput, index) => {
                    return (
                      <div
                        className={classNames.eachGrade}
                        key={"gradedinputs" + index}
                        style={{
                          display:
                            viewOnlyQuote &&
                            quoteReviewForm?.sum_insured?.length - 1 < index
                              ? "none"
                              : "",
                        }}
                        // style={{
                        //   display:
                        //     viewOnlyQuote &&
                        //     quoteReviewForm?.sum_insured?.length >= index &&
                        //     quoteReviewForm?.sum_insured[index]
                        //       ? ""
                        //       : "none",
                        // }}
                      >
                        <CiCircleRemove
                          className={classNames.addInput}
                          style={{ display: viewOnlyQuote ? "none" : "" }}
                          onClick={() => {
                            setQuoteReviewForm((prev) => {
                              return {
                                ...prev,
                                sum_insured: [
                                  ...quoteReviewForm?.sum_insured?.slice(0, -1),
                                ],
                              };
                            });
                          }}
                        />
                        <EachCustomInput
                          placeholder="0.00"
                          name="sum_insured"
                          stateValue={quoteReviewForm}
                          setState={setQuoteReviewForm}
                          indexx={index?.toString()}
                        />
                        <span>
                          {"Grade " +
                            String.fromCharCode(97 + index)?.toUpperCase()}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : quoteReviewForm?.coverage_type === "Flat" ? (
                <EachCustomInput
                  placeholder="0.00"
                  name="sum_insured"
                  stateValue={quoteReviewForm}
                  setState={setQuoteReviewForm}
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className={classNames.configBtns}>
        <button
          className={viewOnlyQuote ? classNames.unClickableBtn : ""}
          onClick={healthConfigurationFunc}
        >
          Save{" "}
          {selectedType === "Accidental"
            ? "Accident"
            : selectedType
            ? selectedType
            : ""}{" "}
          Configurations
        </button>
        <button
          className={viewOnlyQuote ? "" : classNames.unClickableBtn}
          onClick={updateConfigurationToMembers}
        >
          Save All Configurations
        </button>
      </div>
    </div>
  );
};

// Members

const Members = ({
  setOpened,
  quoteReviewForms,
  setRefreshPage,
  setOpenedQuoteMini,
}) => {
  const { globalHrId } = useContext(BankContext);
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const [fileName, setFileName] = useState("");
  const [downloadSpreadsheet, setDownloadSpreadsheet] = useState("");
  const [quoteReviewForm, setQuoteReviewForm] = useState({ document: "" });
  const [quoteReviewFormLoading, setQuoteReviewFormLoading] = useState(false);
  const [uploadedSheetValidationErrors, setUploadedSheetValidationErrors] =
    useState("");

  async function preEnrollmentFunc() {
    setQuoteReviewFormLoading(true);
    let url =
      quoteReviewForm?.enrollmentType === "Employee fills the data"
        ? "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/upload"
        : quoteReviewForm?.enrollmentType === "Employee validates the data"
        ? "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/upload-with-dependents"
        : "";

    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append(
      "quote_id",
      quoteReviewForms?.quote_id ? quoteReviewForms?.quote_id : ""
    );
    formData.append("file", quoteReviewForm?.document);

    try {
      let response = await Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.status) {
        predefinedToast("Initiate pre-enrollment successful!");
        setQuoteReviewFormLoading(false);
        setOpened("Success");
        setRefreshPage("Successs");
      } else {
        predefinedToast("Error while uploading members");
        setQuoteReviewFormLoading(false);
      }
      console.log(response, "response from initiate pre-enrollment func");
    } catch (error) {
      console.dir(error, "upload fills data error");
      predefinedToast(error?.response?.data?.message);
      setQuoteReviewFormLoading(false);
    }
  }

  async function getDataFillsAndValidate(document) {
    try {
      let url =
        quoteReviewForm?.enrollmentType === "Employee validates the data"
          ? "https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate-with-dependents"
          : `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/validate`;

      const formData = new FormData();
      formData.append("file", document);

      let response = await Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (
        Array?.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setUploadedSheetValidationErrors(response?.data?.data[0]);
      }

      console.log(response?.data?.data, "getDataFills response");
    } catch (error) {
      console.log(error?.message, "Employee fills data error");
    }
  }

  async function fetchDownloadLinks() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/employee-spreadsheet`
      );
      if (
        response?.data?.data?.length > 0 &&
        Array.isArray(response?.data?.data)
      ) {
        setDownloadSpreadsheet(response?.data?.data[0]);
      }
      console.log(response, "fetch spreadsheet");
    } catch (error) {
      console.log(error?.message, "fetch spreadsheet error");
    }
  }

  useEffect(() => {
    fetchDownloadLinks();
  }, []);

  useEffect(() => {
    if (quoteReviewForm?.document) {
      setQuoteReviewForm((prev) => {
        return { ...prev, document: "" };
      });
      setUploadedSheetValidationErrors("");
      setFileName("");
    }
  }, [quoteReviewForm?.enrollmentType]);

  const hasItems = (arr) => arr.length > 0;
  const fieldsWithItems = Object.entries(uploadedSheetValidationErrors).filter(
    ([_, value]) => hasItems(value)
  );

  return (
    <div
      className={classNames.members}
      style={{ padding: setOpenedQuoteMini ? "0" : "" }}
    >
      <div
        className={classNames.uploadLoading}
        style={{ display: quoteReviewFormLoading ? "" : "none" }}
      >
        <div>
          <img src={fullLogo} alt="fullLogo" />
          <div>Uploading members data</div>
        </div>
      </div>
      <div className={classNames.title}>
        <span>Members</span>
        {/* <img src={completePercentageIcon} alt="completePercentageIcon" /> */}
        <CircularProgressbar value={49.98} />
      </div>
      <div className={classNames.fieldsContainer}>
        <div className={classNames.singleInput}>
          <EachCustomDropdown
            title="Select type of enrolment"
            dropdown={[
              "Employee fills the data",
              "Employee validates the data",
            ]}
            name="enrollmentType"
            stateValue={quoteReviewForm}
            setState={setQuoteReviewForm}
            typee="single"
          />
        </div>
      </div>
      {quoteReviewForm?.enrollmentType && (
        <div className={classNames.uploadAndDownloads}>
          <div className={classNames.filesContainer}>
            <div
              onClick={() => {
                if (
                  quoteReviewForm?.enrollmentType ===
                    "Employee fills the data" &&
                  downloadSpreadsheet?.employee_pre_enrollment
                ) {
                  window.open(
                    downloadSpreadsheet?.employee_pre_enrollment,
                    "_blank"
                  );
                } else if (
                  quoteReviewForm?.enrollmentType ===
                    "Employee validates the data" &&
                  downloadSpreadsheet?.employee_and_dependent_pre_enrollment
                ) {
                  window.open(
                    downloadSpreadsheet?.employee_and_dependent_pre_enrollment,
                    "_blank"
                  );
                }
              }}
            >
              <img src={downloadFileIcon} alt="downloadFileIcon" />
              <div>Download Template File</div>
            </div>
            <div>
              <label
                htmlFor={"profileUpdateImgPlanBdocument"}
                className={classNames.uploadFileDiv}
                style={{ height: "100%" }}
              >
                <img src={uploadFileIcon} alt="uploadFileIcon" />
                <div>{fileName ? fileName : "Upload File"}</div>
                <div className={classNames.uploadFile}>
                  <input
                    className={classNames.uploadNewPicPlanB}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      console.log(event?.target?.files, "files list");
                      if (event?.target?.files?.length > 0) {
                        setFileName(event?.target?.files[0]?.name);
                        setQuoteReviewForm({
                          ...quoteReviewForm,
                          document: event?.target?.files[0],
                        });
                        if (
                          quoteReviewForm?.enrollmentType ===
                          "Employee fills the data"
                        ) {
                          getDataFillsAndValidate(event?.target?.files[0]);
                        } else if (
                          quoteReviewForm?.enrollmentType ===
                          "Employee validates the data"
                        ) {
                          getDataFillsAndValidate(event?.target?.files[0]);
                        }
                      }
                    }}
                    accept="*/*"
                    id={"profileUpdateImgPlanBdocument"}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      )}
      {uploadedSheetValidationErrors && (
        <ValidateSpreadsheetComponent data={uploadedSheetValidationErrors} />
      )}
      <div className={classNames.configBtns}>
        <button style={{ display: "none" }}></button>
        <button
          onClick={preEnrollmentFunc}
          style={{
            pointerEvents:
              Array.isArray(fieldsWithItems) && fieldsWithItems?.length > 0
                ? "none"
                : "",
            opacity:
              Array.isArray(fieldsWithItems) && fieldsWithItems?.length > 0
                ? "0.5"
                : "",
          }}
        >
          Initiate Pre-Enrollement
        </button>
      </div>
    </div>
  );
};

// Success

const Success = ({ setOpened, setOpenedQuoteMini }) => {
  const [quoteReviewForm, setQuoteReviewForm] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setOpened("PreEnrollment");
    }, 4000);
  }, []);
  return (
    <div
      className={classNames.members}
      style={{ padding: setOpenedQuoteMini ? "0" : "" }}
    >
      <div className={classNames.successContainer}>
        <img src={cupcake} alt="cupcake" />
        <div className={classNames.title}>Success</div>
        <p>
          What Next ? Your Tall Tree Associate will Reach out to you within next
          48 Hours. Meanwhile you can go through our Demo Videos or schedule a
          call at your convenience
        </p>
        <div className={classNames.externalContainer}>
          <div>
            <img src={calendarIcon} alt="calendarIcon" />
            <div>Schedule a Meeting (Calendly)</div>
          </div>
          <div>
            <img src={youtubeIcon} alt="youtubeIcon" />
            <div>Watch Demo Videos</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// PreEnrollment

const PreEnrollment = ({ setOpened, quoteReviewForms, setOpenedQuoteMini }) => {
  const [quoteReviewForm, setQuoteReviewForm] = useState("");

  async function getPreEnrollmentStatus() {
    let response = await Axios.get(
      `https://coverfi.apimachine.com/api/v1/employee-spreadsheet/get/quote/progress?quote_id=${quoteReviewForms?.quote_id}`
    );
    if (response?.data?.data?.length > 0) {
      setQuoteReviewForm(response?.data?.data[0]);
    }
    console.log(response, "response from getPreEnrollmentStatus");
  }

  useEffect(() => {
    getPreEnrollmentStatus();
  }, []);
  return (
    <div
      className={classNames.preEnrollment}
      style={{ padding: setOpenedQuoteMini ? "0" : "" }}
    >
      <div className={classNames.title}>
        <span>Pre-Enrolment Progress</span>
        {/* <img src={completePercentageIcon} alt="completePercentageIcon" /> */}
        <CircularProgressbar value={83.3} />
      </div>
      <div className={classNames.preEnrollmentContainer}>
        <div>
          {/* <img src={halfCircle} alt="halfCircle" /> */}
          <SemiCircleProgressBar
            percentage={
              quoteReviewForm?.percentage
                ? Number(quoteReviewForm?.percentage)?.toFixed(2)
                : ""
            }
            // percentage={85}
            showPercentValue
            strokeWidth={25}
            stroke="var(--main-color)"
            // diameter={200}
          />
          <div className={classNames.externalContainer}>
            <div>
              <img src={calendarIcon} alt="calendarIcon" />
              <div>Schedule a Meeting (Calendly)</div>
            </div>
            <div>
              <img src={youtubeIcon} alt="youtubeIcon" />
              <div>Watch Demo Videos</div>
            </div>
          </div>
        </div>
        <div>
          <div className={classNames.detailsContainer}>
            <div className={classNames.title}>Total employees</div>
            <div className={classNames.count}>
              {quoteReviewForm?.totalUsers ? quoteReviewForm?.totalUsers : 0}
            </div>
          </div>
          <div className={classNames.detailsContainer}>
            <div className={classNames.title}>Pending users</div>
            <div className={classNames.count}>
              {quoteReviewForm?.pendingUser ? quoteReviewForm?.pendingUser : 0}
            </div>
          </div>
          <div className={classNames.detailsContainer}>
            <div className={classNames.title}>Logged-in users</div>
            <div className={classNames.count}>
              {quoteReviewForm?.acceptedUser
                ? quoteReviewForm?.acceptedUser
                : 0}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={classNames.preEnrollmentBtns}>
        <button
          onClick={() => {
            setOpened("Members");
          }}
        >
          Close Enrolment
        </button>
        <button
          onClick={() => {
            setOpened("RFQHistory");
          }}
        >
          Download Data
        </button>
      </div> */}
    </div>
  );
};

// RFQHistory

const RFQHistory = ({
  setOpened,
  quoteReviewForms,
  setOpenedQuoteMini,
  mobile,
}) => {
  const [quoteReviewForm, setQuoteReviewForm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  async function getRFQHistory() {
    let response = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/policy/get/rfq?quote_id=${quoteReviewForms?.quote_id}`
    );
    if (response?.data?.data?.length > 0) {
      setQuoteReviewForm(response?.data?.data);
    }
    console.log(response, "response from getRFQHistory");
  }

  useEffect(() => {
    getRFQHistory();
  }, []);

  return (
    <>
      <div
        className={classNames.rfqHistory}
        style={{ padding: setOpenedQuoteMini ? "0" : "" }}
      >
        <div className={classNames.title}>
          <span>RFQ History</span>
          {/* <img src={completePercentageIcon} alt="completePercentageIcon" /> */}
          <CircularProgressbar value={100} />
        </div>
      </div>
      <TableEmployeesSimplified
        allLeads={quoteReviewForm}
        setAllLeads={setQuoteReviewForm}
        allBrokeragesSearch={searchQuery}
        allLeadsLoading={false}
        mobile={mobile}
      />
    </>
  );
};

const TableEmployeesSimplified = ({
  allLeads,
  setAllLeads,
  allBrokeragesSearch,
  allLeadsLoading,
  editTable,
  mobile,
}) => {
  const [openedDetails, setOpenedDetails] = useState(false);

  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr
            style={{ gridTemplateColumns: "min-content repeat(4, 1fr) 100px" }}
            className={mobile ? classNames.mobileHeader : ""}
          >
            <th>S No</th>
            <th>Requested Submitted</th>
            <th>Date Submitted</th>
            <th>Request ID</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{
                gridTemplateColumns: "min-content repeat(4, 1fr) 100px",
              }}
            >
              <td></td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td></td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                if (!editTable) {
                  delete row?.newItem;
                }

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <>
                  <tr
                    key={row?.brokerage_name + index}
                    style={{
                      gridTemplateColumns: "min-content repeat(4, 1fr) 100px",
                      borderBottom: openedDetails === row?.rfq_id ? "none" : "",
                    }}
                  >
                    <td>
                      {/* <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.tpA_CLAIM_NO}
                      name={row?.first_name + " " + row?.last_name}
                    /> */}
                      {index + 1}
                    </td>
                    <td>{row?.name ? row?.name : ""}</td>
                    <td>
                      {row?.updatedAt
                        ? moment
                            .unix(Math.floor(row?.updatedAt / 1000))
                            .format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>{row?.rfq_id ? row?.rfq_id : ""}</td>
                    <td>
                      <div
                        className={`${classNames.statusBtn} ${
                          row?.rfq_status === "Uploaded"
                            ? classNames.reviewBtn
                            : row?.rfq_status === "resumbit"
                            ? classNames.requestedBtn
                            : row?.rfq_status === "reject"
                            ? classNames.rejectedBtn
                            : row?.rfq_status === "accept"
                            ? classNames.acceptedBtn
                            : ""
                        } ${mobile ? classNames.statusBtnMobile : ""}`}
                      >
                        {row?.rfq_status === "Uploaded"
                          ? "Under Review"
                          : row?.rfq_status === "resumbit"
                          ? "Requested New RFQ"
                          : row?.rfq_status === "reject"
                          ? "Rejected"
                          : row?.rfq_status === "accept"
                          ? "Accepted"
                          : ""}
                      </div>
                    </td>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        fontSize: "1rem",
                      }}
                    >
                      {openedDetails === row?.rfq_id ? (
                        <IoMdClose
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenedDetails(false);
                          }}
                        />
                      ) : (
                        <FaEye
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenedDetails(row?.rfq_id);
                          }}
                        />
                      )}
                      <IoDocumentTextOutline
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (row?.rfq_pdf) {
                            window.open(row?.rfq_pdf, "_blank");
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <div
                    style={{
                      display: openedDetails === row?.rfq_id ? "" : "none",
                      borderBottom: "1px solid #dddddd",
                      padding: "0 0.5rem",
                    }}
                  >
                    {row?.rfq_status === "Uploaded" ? (
                      <div classNames={classNames.editContainer}>
                        <div className={classNames.desc}>
                          <div className={classNames.title}>Notes</div>
                          <div className={classNames.para}>
                            {row?.notes
                              ? row?.notes
                              : `Inquire about available discounts. Insurance
                            companies often offer various discounts, such as
                            multi-policy, good driver, or bundled coverage
                            discounts. Make sure you've explored all potential
                            ways to reduce your premium.`}
                          </div>
                        </div>
                        <RFQInput row={row} />
                      </div>
                    ) : (
                      <div classNames={classNames.editContainer}>
                        <div className={classNames.desc}>
                          <div className={classNames.title}>Notes</div>
                          <div className={classNames.para}>
                            {row?.notes
                              ? row?.notes
                              : `Inquire about available discounts. Insurance
                            companies often offer various discounts, such as
                            multi-policy, good driver, or bundled coverage
                            discounts. Make sure you've explored all potential
                            ways to reduce your premium.`}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const RFQInput = ({ row }) => {
  const [selectedOptionRFQ, setSelectedOptionRFQ] = useState("");
  const [selectedOptionRFQValue, setSelectedOptionRFQValue] = useState("");

  async function oneRFQFunc() {
    let body = {
      rfq_status:
        selectedOptionRFQ === "Accept QCR"
          ? "accept"
          : selectedOptionRFQ === "Reject QCR"
          ? "reject"
          : selectedOptionRFQ === "Request Another QCR"
          ? "resumbit"
          : "",
      notes: selectedOptionRFQValue?.rfqInput,
    };
    let response = await Axios.put(
      `https://insurancecoverfi.apimachine.com/insurance/policy/update/rfq?rfq_id=${row?.rfq_id}`,
      body
    );

    if (response?.data?.status) {
      predefinedToast(response?.data?.message);
    }
  }

  return (
    <div className={classNames.respondRFQ}>
      <div className={classNames.title}>Respond To QCR</div>
      <div className={classNames.selectOptions}>
        <div
          className={
            selectedOptionRFQ === "Accept QCR" ? classNames.selectedOption : ""
          }
          onClick={() => {
            setSelectedOptionRFQ("Accept QCR");
          }}
        >
          Accept QCR
        </div>
        <div
          className={
            selectedOptionRFQ === "Reject QCR" ? classNames.selectedOption : ""
          }
          onClick={() => {
            setSelectedOptionRFQ("Reject QCR");
          }}
        >
          Reject QCR
        </div>
        <div
          className={
            selectedOptionRFQ === "Request Another QCR"
              ? classNames.selectedOption
              : ""
          }
          onClick={() => {
            setSelectedOptionRFQ("Request Another QCR");
          }}
        >
          Request Another QCR
        </div>
      </div>
      <EachCustomTextAreaGeneral
        placeholder="Notes..."
        name="rfqInput"
        stateValue={selectedOptionRFQValue}
        setState={setSelectedOptionRFQValue}
        typee="normalInput"
      />
      <button className={classNames.submitbtn} onClick={oneRFQFunc}>
        Submit
      </button>
    </div>
  );
};

export const ValidateSpreadsheetComponent = ({ data }) => {
  // Function to check if an array has at least one item
  const hasItems = (arr) => arr.length > 0;

  // Function to generate HTML for heading and unordered list
  const generateHTML = (fieldName, items) => (
    <div key={fieldName} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      <div style={{ fontSize: "1rem", fontWeight: "520" }}>
        {fieldName === "alreadyExists"
          ? "Already Exist"
          : fieldName === "duplicateCompanyEmployeeId"
          ? "Duplicate Company Employee ID"
          : fieldName === "duplicateEmails"
          ? "Duplicate Emails"
          : fieldName === "duplicateName"
          ? "Duplicate Name"
          : fieldName === "duplicatePhoneNumbers"
          ? "Duplicate Phone numbers"
          : fieldName === "missingFields"
          ? "Missing Fields"
          : fieldName === "duplicateDependantName"
          ? "Duplicate Dependant Name"
          : fieldName === "duplicateEmployeeName"
          ? "Duplicate Employee Name"
          : fieldName === "missingFieldsInDependant"
          ? "Missing Fields In Dependant"
          : fieldName === "missingFieldsInEmployee"
          ? "Missing Fields In Employee"
          : ""}
      </div>
      <ul style={{ listStyle: "none", marginTop: "0.7rem" }}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );

  // Filter fields with items
  const fieldsWithItems = Object.entries(data).filter(([_, value]) =>
    hasItems(value)
  );

  return (
    <div style={{ marginTop: "2rem", textAlign: "left" }}>
      {fieldsWithItems?.length > 0 ? (
        fieldsWithItems.map(([fieldName, items]) =>
          generateHTML(fieldName, items)
        )
      ) : (
        <div
          style={{ fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}
        >
          No Issues With Spreadsheet. Please Go Ahead
        </div>
      )}
    </div>
  );
};

export const AddTransaction = ({ setShowAddTxn, showAddTxn }) => {
  const { setUpdateLoading, globalHrId } = useContext(BankContext);

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [addEmail, setAddEmail] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [affiliate, setAffiliate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [associate, setAssociate] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const [verticalHead, setVerticalHead] = useState("");
  const [businessHead, setBusinessHead] = useState("");

  const source = ["Online", "Offline", "Affiliate"];

  const [policyNumbers, setPolicyNumbers] = useState([]);
  const [selectedPolicyNo, setSelectedPolicyNo] = useState(null);
  const [showAllPolicies, setShowAllPolicies] = useState(false);

  const [policyDate, setPolicyDate] = useState(null);
  const [txnType, setTxnType] = useState(null);
  const [txnAmount, setTxnAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState();
  const [txnId, setTxnId] = useState("");
  const [cdBalId, setCdBalId] = useState(null);

  const getPolicyNumbers = () => {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/policy?hr_id=${globalHrId}`
    ).then(({ data }) => {
      if (data.status) {
        setPolicyNumbers(data.data);
      }
    });
  };

  const getCdBalId = () => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/cdBalance/hr/get?hr_id=${globalHrId}`
    ).then(({ data }) => {
      if (data.status) {
        setCdBalId(data.data[0]?.cdBalance_id);
      }
    });
  };

  useEffect(() => {
    getPolicyNumbers();
    getCdBalId();
  }, []);

  const handleAddTxn = () => {
    setLoading(true);
    let bodyData;
    if (txnType === "Credit") {
      bodyData = {
        amount: txnAmount,
        transacted_for: "Manual",
        credit: "yes",
        payment_mode: paymentMode,
        payment_reference_number: txnId,
        date_of_event: policyDate,
        policy_number:
          selectedPolicyNo === "nopolicy"
            ? ""
            : selectedPolicyNo?.policy_number,
      };
    } else {
      bodyData = {
        amount: txnAmount,
        transacted_for: "Manual",
        debit: "yes",
        payment_mode: paymentMode,
        payment_reference_number: txnId,
        date_of_event: policyDate,
        policy_number:
          selectedPolicyNo === "nopolicy"
            ? ""
            : selectedPolicyNo?.policy_number,
      };
    }
    if (bodyData?.amount) {
      Axios.put(
        `https://insurancecoverfi.apimachine.com/insurance/cdBalance/hr/manual?cdBalance_id=${cdBalId}`,
        bodyData
      ).then(({ data }) => {
        if (data.status) {
          setLoading(false);
          setShowAddTxn("");
          predefinedToast("Transaction added successfully!");
        } else {
          predefinedToast("Error while adding transaction!");
        }
      });
    } else {
      setLoading(false);
      predefinedToast("Error while adding transaction!");
    }
  };

  return (
    <>
      <div
        className={styles.overlay}
        onClick={(e) => {
          setShowAddTxn("");

          //   setShowQuoteDetails(null);
          //   setConfData(null);
          //   setSelectedQuoteTab("Overview");
          //   setSelectedConfTab("Health");
        }}
      ></div>
      <div className={styles.quoteDetailsWrapper} style={{ width: "50vw" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setShowAddTxn("");
            // setConfData(null);
            // setSelectedQuoteTab("Overview");
            // setSelectedConfTab("Health");
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Add Transaction</div>
          <div className={styles.rowWrapper1}>
            {!showAllPolicies ? (
              <div
                className={styles.dropdown}
                onClick={(e) => setShowAllPolicies(!showAllPolicies)}
              >
                <div>
                  {selectedPolicyNo === "nopolicy"
                    ? "This transaction is not related to a policy"
                    : selectedPolicyNo?.policy_number
                    ? selectedPolicyNo?.policy_number
                    : "Select policy number"}
                </div>
                {/* <img
                  src={arrowDown}
                  alt=""
                  onClick={(e) => setShowAllPolicies(false)}
                /> */}
                <IoIosArrowDown onClick={(e) => setShowAllPolicies(false)} />
              </div>
            ) : (
              <div className={styles.dropdown1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  onClick={(e) => setShowAllPolicies(!showAllPolicies)}
                >
                  <div>Select policy number</div>
                  <IoIosArrowDown />
                  {/* <img src={arrowDown} alt="" /> */}
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    height: "110px",
                    overflowY: "scroll",
                  }}
                >
                  {policyNumbers.map((item, index) => (
                    <div
                      className={styles.individualQuote}
                      onClick={(e) => {
                        setSelectedPolicyNo(item);
                        setShowAllPolicies(false);
                      }}
                    >
                      <div></div>
                      <div>{item?.policy_number}</div>
                    </div>
                  ))}
                </div>
                <div
                  className={styles.noPolicy}
                  onClick={(e) => {
                    setSelectedPolicyNo("nopolicy");
                    setShowAllPolicies(false);
                  }}
                >
                  This transaction is not related to a policy
                </div>
              </div>
            )}
          </div>

          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup}>
              <div>Select date</div>
              <EachInputDatePicker
                placeholder="Select Date"
                stateValue={policyDate}
                setState={setPolicyDate}
              />
            </div>
            <div className={styles.inputGroup}></div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>Type of transaction</div>
              <div className={styles.leadSourceWrapper}>
                {["Credit"].map((item) => (
                  <div
                    className={
                      txnType === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      setTxnType(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Amount</div>
              <input
                type="text"
                placeholder="0.00"
                value={txnAmount}
                onChange={(event) => {
                  if (/^-?\d+$/.test(event?.target?.value)) {
                    setTxnAmount(event?.target?.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setTxnAmount("");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>Payment mode</div>
              <div className={styles.leadSourceWrapper}>
                {["Cheque", "EFT", "Other"].map((item) => (
                  <div
                    className={
                      paymentMode === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      setPaymentMode(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Transaction ID</div>
              <input
                type="text"
                placeholder="Affiliate.."
                value={txnId}
                onChange={(e) => setTxnId(e.target.value)}
              />
            </div>
          </div>

          <div
            onClick={(e) => {
              if (policyDate && txnType && txnAmount && paymentMode && txnId) {
                handleAddTxn();
              } else {
                predefinedToast("All fields are required!");
              }
            }}
            className={styles.addBtn}
            style={{
              opacity: loading
                ? 0.5
                : policyDate && txnType && txnAmount && paymentMode && txnId
                ? 1
                : 0.5,
              pointerEvents: loading ? "none" : "",
            }}
          >
            {loading ? "Adding Lead.." : "Add"}
          </div>
        </div>
      </div>
    </>
  );
};
